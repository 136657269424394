<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point"></i>
  </button>
  Editando Template de Prompt
</h4>

<form (ngSubmit)="submitForm()">
  <div class="form-group">
    <label for="prompt">Nome:</label>
    <div>
      <h4 class="mt-0" id="prompt">{{ templatePrompt.nome }}</h4>
    </div>
  </div>
  <div class="form-group">
    <label for="descricao">Descrição:</label>
    <input
      type="text"
      class="form-control"
      id="descricao"
      [(ngModel)]="templatePrompt.descricao"
      name2="descricao"
    />
  </div>

  <div class="form-group">
    <div class="d-flex align-items-left mb-2">
      <label class="mr-2 mt-1 text-left">Instruções:</label>

      <div class="d-flex justify-content-start" style="min-width: 350px;">
        <div class="input-group" style="border: 1px solid #ced4da;border-radius: 3px;">
          <input type="text" name="txtBuscaTrechos" class="form-control border-0" (keyup)="onKeyPress($event)" [(ngModel)]="termoBusca"
          placeholder="Busque trechos de prompt ou intents"/>
          <div class="input-group-append" (click)="realizarBusca()">
            <span class="input-group-text border-0 bg-white"><i class="fas fa-search"></i></span>
          </div>
        </div>
      </div>
      <div style="flex-grow: 1;">
        &nbsp;
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <button
          type="button"
          class="btn btn-primary"
          (click)="abrirDialogNovaInstrucao()"
          *ngIf="usuario.admin"
        >
          Nova Instrução
        </button>
      </div>
    </div>

    <div class="border-2 p-1 rounded-2" style="background: #fff;border: solid 1px rgba(0, 0, 0, 0.08);">
      <p *ngIf="termoBusca" class="text-muted m-1 font-18">
        <i class="fas fa-search"></i> Exibindo trechos de prompt com <span class="font-weight-bold text-dark">{{termoBusca}}</span>
      </p>

      <kendo-listview [data]="listaFiltrada" class="lst-trechos-Prompt" [loading]="carregando">
        <ng-template kendoListViewLoaderTemplate>
          <div class="loader-container">
            <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;"></div>
          </div>
        </ng-template>
        <ng-template kendoListViewItemTemplate let-dataItem let-index="index">
          <kendo-popover #info width="400px" position="bottom">
            <ng-template kendoPopoverTitleTemplate>
              <strong>Trecho Original</strong>
            </ng-template>

            <ng-template kendoPopoverBodyTemplate>
        <span *ngIf="dataItem.trechoOriginal.tipo === 'texto'" title="Instruções" kendoTooltip class="tipo font-16">
                        <i class="fas fa-file-alt" style="color: #666;"></i>
                      </span>
              <span
                *ngIf="dataItem.trechoOriginal.tipo === 'exemplos'"
                title="Exemplos" class="tipo font-16"
                kendoTooltip>
                        <i class="fas fa-question-circle"></i>
                      </span>&nbsp;
              <span [innerHTML]="dataItem.modificado ? dataItem.textoHtml : ''"> </span>
            </ng-template>
          </kendo-popover>

          <div [@moveAnimation] [ngClass]="{ 'blink': blinkingRow === index }">
          <div style="background: #fff;padding: 1px;margin-top: 1px;" class="item pl-1 font-12" [ngClass]="{'modificado': dataItem.escopo === 'global' && dataItem.modificado, 'texto-desativado': !dataItem.ativo, 'global': dataItem.escopo === 'global', 'personalizado': dataItem.escopo === 'empresa'}"
            [title]="dataItem.modificado ? 'Trecho Original' + dataItem.trechoOriginal.texto : ''"
               kendoPopoverAnchor  [popover]="info"  [showOn]="dataItem.modificado ? 'hover' : 'none'">
            <!-- Botões de Mover para Cima e para Baixo -->
{{dataItem.posicao}}.
            <button
              type="button"
              class="btn btn-secondary btn-sm ml-1"
              (click)="moverParaCima(index)"
              [disabled]="index === 0"
              title="Mover para cima"
              kendoTooltip
            >
              <i class="fas fa-arrow-up"></i>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary ml-1 mr-1"
              (click)="moverParaBaixo(index)"
              [disabled]="index === listaFiltrada.length - 1"
              title="Mover para baixo"
              kendoTooltip
            >
              <i class="fas fa-arrow-down"></i>
            </button>

            <div style="display: inline-block;width: 28px;height: 25px;position: absolute;line-height: 28px;" class="mr-1 text-center">
              <button type="button" class="btn btn-sm btn-primary btn-editar hoverable"
              style="padding: 0.16rem 0.44rem;" (click)="editarTrechoDePrompt(dataItem)">
                <i class="fas fa-edit"></i>
              </button>

              <span *ngIf="dataItem.tipo === 'texto'" title="Instruções" kendoTooltip class="tipo font-16">
                <i class="fas fa-file-alt"></i>
              </span>
              <span
                *ngIf="dataItem.tipo === 'exemplos'"
                title="Exemplos" class="tipo font-16"
                kendoTooltip>
                <i class="fas fa-question-circle"></i>
              </span>
            </div>
            <span class="font-weight-bold" style="margin-left: 32px;">[{{dataItem.intent}}]</span> -
            <span [innerHTML]="dataItem.textoHtml2"></span>
            <kendo-switch class="ml-1" style="font-size: 8px;"
            [id]="'ativo_' + dataItem.id"
            [(ngModel)]="dataItem.ativo"
            [name]="'ativo' + dataItem.id"
            (ngModelChange)="ativeDesativeTrechoDePrompt(dataItem)"
          ></kendo-switch>
          </div>
          </div>
        </ng-template>
      </kendo-listview>
    </div>
  </div>

  <div class="form-group">
    <button type="submit" class="btn btn-primary mr-2">Salvar</button>
    <button type="button" class="btn btn-secondary" (click)="cancelar()">
      Cancelar
    </button>
  </div>
</form>

<kendo-dialog
  [title]="'Nova Instrução'"
  [width]="600"
  [minWidth]="250"
  [maxHeight]="800"
  *ngIf="dialogOpened"
  (close)="fecheJanelaEditarTrecho()"
  [ngClass]="{ 'wndNovaInstrucao': novaInstrucao.escopo === 'global' }"
  class="my-dialog"
>
  <form
    #frmInstrucao="ngForm"
    (ngSubmit)="salvarNovaInstrucao(frmInstrucao)"
    class="form"
    [ngClass]="{
      'needs-validation': !frmInstrucao.submitted,
      'was-validated': frmInstrucao.submitted
    }"
  >
  <span *ngIf="novaInstrucao.tipo === 'global'" title="Global" kendoTooltip>
    <div class="alert alert-info rounded-0 mb-1 py-2 bg-danger text-white">
      <i class="fas fa-globe"></i>
      Prompt GLOBAL: Válido para todas as empresas
    </div>
  </span>
    <div class="form-group">
      <label for="intent">Intenção:</label>
      <kendo-multiselect
        id="intent"
        [(ngModel)]="novaInstrucao.listaDeIntents"
        #intent="ngModel"
        name="intent"
        required
        class="form-control"
        [filterable]="true"
        [data]="intencoesFiltradas"
        (filterChange)="handleFilter($event)"
      ></kendo-multiselect>
      <div *ngIf="intent?.errors?.required" class="invalid-feedback">
        <strong>
          O campo de intenção é obrigatório. Por favor, selecione uma intenção.
        </strong>
      </div>

      <small class="form-text text-muted" style="color: #888;">
        <i class="fas fa-info-circle"></i> A Intenção identifica o objetivo da
        mensagem do cliente. Ele poderia ser:
        <ul>
          <li>uma solicitação de informações sobre promoções</li>
          <li>uma pergunta sobre o menu</li>
          <li>um pedido de informação sobre o horário de funcionamento</li>
          <li>entre outros</li>
        </ul>
        Identificar corretamente a intenção ajuda a Mia a fornecer a resposta
        mais apropriada.
      </small>
    </div>

    <div class="form-group">
      <label>Expressão Condicional:</label>
      <app-controle-editor-mensagem #ctrlEditorMensagem [(ngModel)]="novaInstrucao.condicao"></app-controle-editor-mensagem>

      <small class="form-text text-muted">
        Insira a expressão condicional que determina se este trecho será incluído.<br>
        Exemplo: <span class="text-black font-weight-bold">temPix === true</span>. Complementa a intent para determinar se o trecho será incluído na resposta.
        Se não tiver preenchido, a expressão condicional será ignorada.
      </small>
    </div>

    <div class="form-group">
      <label>Tipo de Instrução:</label>
      <div class="k-inline-block">
        <label class="k-radio-label mr-1" for="tipoTexto">
          <input type="radio" name="tipo" #rdTipoDeInstrucao="ngModel" id="tipoTexto" kendoRadioButton value="texto" [(ngModel)]="novaInstrucao.tipo" required/>
          Texto Livre
        </label>
        <small class="form-text text-muted">
          Use esta opção para inserir instruções de texto que guiam o comportamento do bot. Isso inclui declarações constantes, tais como o nome do bot,
          orientações sobre como o bot deve se comunicar, e informações específicas que o bot deve estar ciente (por exemplo, que não conhece o conteúdo do cardápio).
          Você também pode incluir variáveis, como o nome da empresa e a hora atual, que serão preenchidas dinamicamente com os valores relevantes.
        </small>
        <label class="k-radio-label" for="tipoExemplos">
          <input type="radio" name="tipo" #rdTipoDeInstrucao="ngModel" id="tipoExemplos" kendoRadioButton value="exemplos" [(ngModel)]="novaInstrucao.tipo" required/>
          Exemplos
        </label>
        <small class="form-text text-muted">
          Use esta opção para fornecer exemplos de diálogos entre o cliente e o bot. Cada exemplo deve conter uma possível mensagem do cliente, seguida pela resposta adequada
          que o bot deve dar. Isso ajuda o bot a aprender como responder a consultas semelhantes no futuro.
          Por exemplo, se um cliente perguntar sobre um item específico do cardápio, o bot pode ser treinado para informar que não tem acesso ao cardápio
          e direcionar o cliente para o link do cardápio digital.
        </small>
      </div>
      <div *ngIf="rdTipoDeInstrucao?.errors?.required" class="invalid-feedback">
        <strong>
          O tipo instrução é obrigatório. Por favor, selecione uma das opções.
        </strong>
      </div>
    </div>


<div *ngIf="novaInstrucao.tipo === 'exemplos'">
  <div class="card">
    <div class="card-body p-0">
      <h4 class="card-title pt-2 pb-0 pl-2 mb-1">Exemplos</h4>
      <kendo-listview #lstExemplos [data]="novaInstrucao.exemplos">
      <ng-template kendoListViewItemTemplate let-item let-i="index">
        <div class="row no-gutters">
          <div class="col">
            <div class="pl-2 pt-1" style="display: grid; grid-template-columns: auto 50px 1fr; border-bottom: 1px solid #f1f1f1; padding-bottom: 10px;">
              <div style="align-self: center;">
                <span class="font-13"><i class="fas fa-user text-muted"></i> Cliente</span>
              </div>
              <div style="align-self: center;" class="ml-1">
                <i class="fas fa-plus-circle font-13" style="color: rgba(51, 103, 214, 0.6);" *ngIf="!frmInstrucao.submitted || (frmInstrucao.submitted && !txtPergunta.invalid)"></i>
                <span class="invalid-feedback">
                    <i class="fas fa-exclamation-triangle text-danger" kendoTooltip *ngIf="txtPergunta?.errors?.required" title="A pergunta é obrigatória"></i>
                  </span>
              </div>
              <div>
                  <textarea #txtPergunta2 class="font-13" name="pergunta-{{i}}" id="pergunta-{{i}}" class="form-control font-13"
                            id="exampleFormControlTextarea1" rows="1" placeholder="Adicione uma pergunta que o cliente possa fazer."
                            (input)="resize(txtPergunta2)" style="max-height: 300px;border: none;resize: none;padding-left: 5px;" [(ngModel)]="item.pergunta"
                            required="true" #txtPergunta="ngModel"></textarea>
              </div>
              <div style="align-self: center;" class="mt-1">
                <span style="margin-right: 10px;" class="text-black font-13"><i class="fas fa-robot text-muted"></i> Mia </span>
              </div>
              <div style="align-self: center;" class="ml-1 mt-1">
                <i class="fas fa-plusN-circle font-13" style="color: rgba(51, 103, 214, 0.6);" *ngIf="!frmInstrucao.submitted || (frmInstrucao.submitted && !txtResposta.invalid)"></i>
                <span class="invalid-feedback">
                    <i class="fas fa-exclamation-triangle text-danger" kendoTooltip *ngIf="txtResposta?.errors?.required" title="A resposta da Mia é obrigatória"></i>
                  </span>
              </div>
              <div class="mt-1">
                  <textarea #txtResposta2  class="font-13" name="resposta-{{i}}" id="resposta-{{i}}" class="form-control font-13" id="exampleFormControlTextarea1" rows="1"
                            placeholder="Resposta que a Mia possa dar para a pergunta"
                            (input)="resize(txtResposta2)" style="max-height: 300px;border: none;resize:none;padding-left: 5px;" [(ngModel)]="item.resposta"
                            required="true" #txtResposta="ngModel"></textarea>
              </div>
            </div>
          </div>
          <div class="col-fixed d-flex align-items-center justify-content-center" style="width: 50px;">
            <div (click)="removerPerguntaResposta(item)" title="Excluir" kendoTooltip style="cursor: pointer;">
              <i class="fas fa-trash-alt"></i>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-listview>
    </div>
    <div class="p-2">
      <button (click)="adicionarExemplo()" type="button" class="btn btn-sm btn-primary">Adicionar Novo Exemplo</button>
    </div>
  </div>
</div>

  <div class="form-group" *ngIf="novaInstrucao.tipo === 'texto'">
      <label for="texto">Instrução:</label>
      <textarea
        type="text"
        id="texto"
        #texto="ngModel"
        [(ngModel)]="novaInstrucao.texto"
        name="texto"
        required
        class="form-control"
        rows="5"
      ></textarea>
      <div *ngIf="texto?.errors?.required" class="invalid-feedback">
        <strong>
          O campo de instrução é obrigatório. Por favor, preencha o campo.
        </strong>
      </div>

      <small class="form-text text-muted">
        <i class="fas fa-info-circle"></i> O campo "Instrução" é vital para
        orientar a <strong>Mia</strong> na formulação de respostas apropriadas.
        Para ser eficaz, a instrução deve ser:
        <ul>
          <li>Clara e concisa</li>
          <li>Específica e alinhada com a intenção selecionada</li>
          <li>Redigida em linguagem simples e direta</li>
          <li>
            Capaz de indicar a próxima etapa ao usuário, quando necessário
          </li>
        </ul>
        Uma instrução bem elaborada assegura que a Mia forneça a resposta mais
        adequada e conduza o usuário à próxima etapa, se necessário.
      </small>
    </div>

    <div class="form-group">
      <label class="form-check-label" for="ativo">Ativo:</label>
      <div class="mt-1">
        <kendo-switch
          id="ativo"
          [(ngModel)]="novaInstrucao.ativo"
          name="ativo"
        ></kendo-switch>
      </div>
    </div>

    <hr />
    <div class="button-wrapper">
      <button
        type="submit"
        class="btn btn-primary mr-2"
        *ngIf="!novaInstrucao.id"
      >
        Salvar
      </button>
      <button type="submit" class="btn btn-blue mr-2" *ngIf="novaInstrucao.id">
        Atualizar
      </button>
      <button type="button" class="btn btn-secondary" (click)="fecheJanelaEditarTrecho()">
        Cancelar
      </button>
    </div>
  </form>
</kendo-dialog>
