import {Component, OnInit, ViewChild} from '@angular/core';
import {GridComponent, RemoveEvent, SelectableSettings} from "@progress/kendo-angular-grid";
import {DialogRef} from "@progress/kendo-angular-dialog";
import {ProdutoService} from "../../services/produto.service";
import {VitrinesService} from "../../superadmin/services/vitrines.service";
import {ConstantsService} from "../ConstantsService";

@Component({
  selector: 'app-tela-associar-vitrine-produtos',
  templateUrl: './tela-associar-vitrine-produtos.component.html',
  styleUrls: ['./tela-associar-vitrine-produtos.component.scss']
})
export class TelaAssociarVitrineProdutosComponent implements OnInit {
  @ViewChild('gridNovos', {static: false}) gridNovos: GridComponent;
  vitrine: any = { produtos: []};
  windowRef: DialogRef;
  produtos  = []
  produtosAdicionar  = []
  carregando: any;
  selectableSettings: SelectableSettings = {
    checkboxOnly: false,
    mode: 'multiple'
  };
  adicionar: any = false;
  public mySelection: number[] = [];
  salvando: boolean;
  totalAdicionado: number
  erro: any;
  filtro: any = {};
  private timerBusca;
  paginacao: any = {
    pagina: 0,
    qtde: 25
  }
  empresa: any = {}
  modalDeletar: boolean;
  produtoApagar: any;
  opcoesSubir = [
    {
      text: 'Subir 1 posição',
      valor: 'SUBIR_1'
    },
    {
      text: 'Subir para Topo',
      valor: 'SUBIR_TOPO'
    }
  ];

  opcoesDescer = [
    { text: 'Descer 1 posição', valor: 'DESCER_1'},
    { text: 'Descer para final', valor: 'DESCER_TUDO' }
  ];

  trocandoOrdem
  constructor(private produtoService: ProdutoService,  private vitrinesService: VitrinesService,
              private constantsService: ConstantsService) { }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if(empresa )  this.empresa = empresa;
    });
  }

  onFilter(event: any) {

  }

  adicionarProduto(){
    this.adicionar = true;
    this.mySelection = [];
    delete this.filtro.texto;
    this.carregueProdutosDisponiveis();
  }

  salveNovosProdutos() {
    if(this.salvando ) return;
    delete this.erro;
    this.salvando = true;
    this.vitrinesService.adicioneProdutosVitrine(this.vitrine, this.mySelection).then( (resp: any) => {
      this.salvando = false;
      this.totalAdicionado = resp.total;
    }).catch( (err: any) => {
      this.erro = err;
      this.salvando = false;
    })
  }

  removerProduto($event: RemoveEvent) {
    let produtoNaVitrine = $event.dataItem;

    if(   produtoNaVitrine.removendo) return;

    produtoNaVitrine.removendo = true;

    this.vitrinesService.removaProdutoDaLista(this.vitrine, produtoNaVitrine.produto).then( () => {
      produtoNaVitrine.removendo = false;
      produtoNaVitrine.removido = true;
    }).catch( () => {
      produtoNaVitrine.removendo = false;
    })
  }

  public carregueProdutosDisponiveis(filtro: string = null) {
    this.produtosAdicionar = [];
    this.carregando   = true;
    this.produtoService.liste(this.empresa.catalogo.id, true, 0 , 50, filtro,
      this.filtro.categoria).then( (resposta: any) => {
      this.carregando = false;
      this.produtosAdicionar = resposta.produtos.filter(
        (produto: any) => !this.vitrine.produtosNaVitrine.find( (produtoVitrine: any) => produtoVitrine.produto.id === produto.id))
    } )
  }

  volteAdicionar(recarregar) {
    this.adicionar = false;
    delete this.totalAdicionado
    if(recarregar) this.carregueProdutosVitrine();
  }

  selectionChange(e: any) { }

  onSelectedKeysChange(e: any) { }

  fecheMensagemErro() {
    delete  this.erro;
  }


  onFilterDisponiveis(event: any) {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.carregueProdutosDisponiveis(event.target.value)
    }, 1000)
  }

  adicionarMais() {
    delete this.totalAdicionado;
    this.adicionarProduto();
  }
  subir1Posicao(produtoNaVitrine: any){
    this.trocandoOrdem = true;
    this.vitrinesService.subir1PosicaoProduto(this.vitrine, produtoNaVitrine).then( (dados) => {
      this.trocandoOrdem = false;
      this.carregueProdutosVitrine();
    }).catch( erro => {
      this.trocandoOrdem = false;
      alert(erro);
    });
  }

  descer1Posicao(produtoNaVitrine) {
    this.trocandoOrdem = true;
    this.vitrinesService.descer1PosicaoProduto(this.vitrine, produtoNaVitrine).then( (dados) => {
      this.trocandoOrdem = false;
      this.carregueProdutosVitrine();
    }).catch( erro => {
      this.trocandoOrdem = false;
      alert(erro);
    });
  }

  clicouSubir(produtoNaVitrine: any, indice: number,  opcao: any) {
    if( opcao.valor === 'SUBIR_1' ) {
      this.subir1Posicao(produtoNaVitrine);
    } else if( opcao.valor === 'SUBIR_TOPO' ) {
      this.trocandoOrdem = true;
      this.vitrinesService.subirProdutoParaTopo(this.vitrine, produtoNaVitrine).then( (dados) => {
        this.trocandoOrdem = false;
        this.carregueProdutosVitrine();
      }).catch( erro => {
        this.trocandoOrdem = false;
        alert(erro);
      });
    }
  }

  clicouDescer(produtoNaVitrine: any, indice: number, opcao: any) {
    if( opcao.valor === 'DESCER_1' ) {
      this.descer1Posicao(produtoNaVitrine);
    } else if( opcao.valor === 'DESCER_TUDO' ) {
      this.trocandoOrdem = true;
      this.vitrinesService.descerProdutoParaFinal(this.vitrine, produtoNaVitrine).then( (dados) => {
        this.trocandoOrdem = false;
        this.carregueProdutosVitrine();
      }).catch( erro => {
        alert(erro);
      });
    }
  }

  private carregueProdutosVitrine() {
    this.vitrinesService.obtenhaVitrine(this.vitrine.id).then( (resposta: any) => {
      this.vitrine.produtosNaVitrine = resposta.produtosNaVitrine;
    })
  }
}
