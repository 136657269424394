import {Component, OnInit, ViewChild} from '@angular/core';
import {ConstantsService} from "../ConstantsService";
import {VendasPedidoService} from "../../services/vendas-pedido.service";
import {PedidosService} from "../../services/pedidos.service";
import {GridPedidosComponent} from "../../pedidos/grid-pedidos/grid-pedidos.component";
import {Router} from "@angular/router";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {AggregateDescriptor, GroupDescriptor, orderBy, process, SortDescriptor} from "@progress/kendo-data-query";
declare var moment;
@Component({
  selector: 'app-financeiro-mesas',
  templateUrl: './financeiro-mesas.component.html',
  styleUrls: ['./financeiro-mesas.component.scss', '../financeiro/financeiro.component.scss']
})
export class FinanceiroMesasComponent implements OnInit {
  @ViewChild('gridPedidos', { static: false}) gridPedidos: GridPedidosComponent;
  filtro: any = { comada: 'ambas'};
  empresa: any = {};
  resumoVendas = {
    total: 0,
    qtde: 0,
    ticketMedio: 0,
    totalTaxaServico: 0,
    totalComTaxa: 0,
    formasDePagamento:  [],
    diarias: [],
    lojas: []
  }
  resumoProdutos: any = {
    produtos: [],
    carregando: false
  }
  public aggregates: AggregateDescriptor[] = [
    { field: "qtde", aggregate: "sum" },
    { field: "total", aggregate: "sum" },
    { field: "totalDesconto", aggregate: "sum" },
  ];

  public group: GroupDescriptor[] = [
    { field: "Produtos", aggregates: this.aggregates },
  ];


  resumoGarcons: any = {
    garcons: [],
    carregando: false
  }
  carregando: boolean;
  vendasDoDia: any;
  rede = false;
  garcons = [];
  periodoEspecifico = false;
  hoje: Date = new Date();
  dataAtual = null;
  valorMes: Date = new Date();
  sortLoja:  SortDescriptor[] = [{dir: 'desc', field: 'qtde'}  ];
  vendasLojasResultSet: any
  constructor(private router: Router, private constantsService: ConstantsService, private pedidosService: PedidosService,
              private vendasService: VendasPedidoService, private empresaService: EmpresasService) {
    if( this.router.url.indexOf('/admin-rede') !== -1 ) {
      this.rede = true;
    }
  }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa: any) => { if(empresa) this.empresa = empresa});
    this.filtro.valorMes =  new Date();

    this.mudouValorMes(this.filtro.valorMes);

    this.empresaService.listeGarcons(this.empresa).then((resposta: any) => {
      this.garcons = resposta.data;
    })
  }

  carregueResumoVendas() {
    if(this.carregando) return;

    this.carregando = true;

    this.vendasService.obtenhaResumoComandas(this.obtenhaFiltro()).then( (resposta: any) => {
      this.carregando = false;
      this.resumoVendas = resposta;
      if(resposta.lojas)
        this.vendasLojasResultSet = orderBy(process(resposta.lojas, {}).data, this.sortLoja);

    })
  }

  public carregueResumoProdutos() {
    this.resumoProdutos.carregando = true;

    let filtro: any = this.obtenhaFiltro();

    if(!this.filtro.porAdicionais){
      this.group[0].field = 'Produtos'
      this.vendasService.obtenhaResumoPrudutos(filtro).then( (resposta) => {
        this.resumoProdutos.carregando = false;
        this.resumoProdutos = resposta;
      })
    } else {
      this.group[0].field = 'Adicionais'
      this.resumoProdutos.carregando = true;
      this.vendasService.obtenhaResumoAdicionais(this.obtenhaFiltro()).then( (resposta) => {
        this.resumoProdutos.carregando = false;
        this.resumoProdutos = resposta;
        this.resumoProdutos.produtos = resposta.adicionais;
      })
    }
  }

  private carregueResumoGarcons(){
    this.resumoGarcons.carregando = true;


    this.vendasService.obtenhaResumoGarcons(this.obtenhaFiltro()).then( (resposta) => {
      this.resumoGarcons.carregando = false;
      this.resumoGarcons = resposta;
    })
  }

  obtenhaFiltro( ){
    let dados: any = { mesas: true , pg: true}

    let dtInicio = this.filtro.inicio ?  moment(this.filtro.inicio).format('YYYYMMDD') : null;
    let dtFim = this.filtro.fim ? moment(this.filtro.fim).format('YYYYMMDD') : null;
    let formaDePagamento = this.filtro.formasDePagamento && this.filtro.formasDePagamento.length > 0 ?
      this.filtro.formasDePagamento.map( forma => forma.id).join(',') : null;

    let garcons = this.filtro.garcons && this.filtro.garcons.length > 0 ?
      this.filtro.garcons.map( garcon => garcon.id).join(',') : null;


    if(dtInicio) dados.di = dtInicio;
    if(dtFim) dados.df = dtFim;
    if(formaDePagamento) dados.fp = formaDePagamento;
    if(garcons) dados.gs = garcons;
    if(this.rede ) dados.r = 1;

    if(this.filtro.porAdicionais) dados.pad = true;

    if(this.filtro.comanda === 'multiplo') dados.pm = true;
    if(this.filtro.comanda === 'unico') dados.pu = true;

    return dados;
  }

  verPedidos(resumoDia: any) {
    this.vendasDoDia = resumoDia;

    if(!this.vendasDoDia.pedidos){
      this.vendasDoDia.carregando = true;

      let dia = moment(this.vendasDoDia.dia, 'DD/MM/YYYY').format('YYYYMMDD')

      let filtro: any = {
        apenasPagos : true, dtInicio: dia, dtFim: dia,
        destino: 'mesas'
      };

      if( this.rede ) {
        filtro.r = 1;
      }

      this.pedidosService.listePedidos(0, 10000, filtro).then( (resposta) => {
        this.vendasDoDia.pedidos = resposta ? (resposta.pedidos || []) : [];
        this.vendasDoDia.carregando = false;
        this.gridPedidos.atualizeGridPedidos(this.vendasDoDia.pedidos, 'mesas');
      }).catch( () => {
        this.vendasDoDia.carregando = false;
      });
    }

  }

  voltarVendasDia() {
    delete this.vendasDoDia;
  }

  trocouAba($event) {
    if($event.nextId === 'produtos' && ( !this.resumoProdutos.produtos || !this.resumoProdutos.produtos.length))
      this.carregueResumoProdutos();

    if($event.nextId === 'garcons' && ( !this.resumoGarcons.garcons.length))
      this.carregueResumoGarcons();
  }

  sortChangeLojas(sort: SortDescriptor[]){
    this.sortLoja = sort;
    this.vendasLojasResultSet = orderBy(process(this.resumoVendas.lojas, {}).data, this.sortLoja);
  }


  mudouValorMes(mes: Date) {
    this.filtro.inicio = moment(mes).startOf('month').toDate();
    this.filtro.fim = moment(mes).endOf('month').toDate();

    this.carregueResumoVendas();
  }

  obtenhaDescricaoFiltro() {
    if(moment(this.filtro.inicio).isSame(moment(this.filtro.fim), 'day'))
      return String(` do dia ${moment(this.filtro.inicio).format('DD/MM/YYYY')}`)

    return  String(`entre ${moment(this.filtro.inicio).format('DD/MM/YYYY')} e  ${moment(this.filtro.fim).format('DD/MM/YYYY')}`);
  }

  obtenhaNomeArquivoDownload(nome: string) {
    let sufixo = '', horario = new Date().getTime();

    let inicio =  moment(this.filtro.inicio).format('DD-MM-YYYY');
    let fim =  moment(this.filtro.fim).format('DD-MM-YYYY');

    if(this.filtro.rede)
      nome = String(`${nome}-${this.filtro.rede.nome.toLowerCase()}`)

    sufixo = String(`${inicio}_e_${fim}`)

    return String(`${nome}(${sufixo}).xls`)
  }

  exibirPeriodoEspecifico() {
    this.periodoEspecifico = true;
  }

  exibirPeriodoMensal() {
    this.periodoEspecifico = false;
  }
}
