import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject, from, merge, Observable, interval } from 'rxjs';
import { switchMap, map, windowCount, scan, take, tap } from 'rxjs/operators';

import { ChatModule, Message, User, Action, ExecuteActionEvent, SendMessageEvent } from '@progress/kendo-angular-conversational-ui';
import {ChatService} from "../../services/chat.service";
import {BotsService} from "../../services/bots.service";
import {debug} from "util";
import {DomSanitizer} from "@angular/platform-browser";
import {AutorizacaoService} from "../../services/autorizacao.service";
import { TextAreaComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-testadorchat-bot',
  templateUrl: './testadorchat-bot.component.html',
  styleUrls: ['./testadorchat-bot.component.scss']
})
export class TestadorchatBotComponent implements OnInit {
  public feed: Observable<Message[]>;

  public readonly user: User = {
    id: 1
  };

  public readonly bot: User = {
    id: 0
  };

  cenarioEscolhido = {
    nome: '',
    mensagens: []
  };

  cenarios = [
    {
      nome: 'Pedir um Poke de Salmão',
      mensagens: [
        'reset',
        'quero fazer um pedido',
        'Quero um poke de salmão',
        'sim',
        'arroz e 1 coca',
        'vou querer um.',
        'finalizar',
        'rua dona stela, 422, setor negrão de lima, goiania.',
        'sim',
        'cartão crédito visa'
      ]
    },
    {
      nome: 'Combo para 2 pessoas',
      mensagens: [
        'reset',
        'quero fazer um pedido',
        'Quero um combo',
        'pode ser o combo para 2 pessoas',
        'sim',
        /*
        'Frango Xadrez e frango tarê',
        'adiciona o rolinho',
        '1',
        'finalizar',
        'rua dona stela, 422, setor negrão de lima, goiania.',
        'sim',
        'cartão crédito visa'
        */
      ]
    }
  ];
  indice = 0;

  currentMessage = '';
  timeoutId: any;

  novoCliente = false;

  telefoneContato: string = '';

  altura: number;
  local: Subject<Message> = new Subject<Message>();
  private usuario: any;
  innerInputFocus: boolean;
  messages: any[];
  @Input() idFluxoTypebot: string;

  @ViewChild("messageBoxInput", { static: false })
  public messageBoxInput: TextAreaComponent;

  constructor(private botsService: BotsService, private sanitizer: DomSanitizer, private autoriazacaoService: AutorizacaoService) {
    this.altura = window.innerHeight - 120;
    this.usuario = this.autoriazacaoService.getUser();

    this.telefoneContato = this.usuario.numeroWhatsapp.whatsapp;

    const hello: Message = {
      author: this.bot,
      suggestedActions: [{
        type: 'reply',
        value: 'Neat!'
      }, {
        type: 'reply',
        value: 'Thanks, but this is boring.'
      }],
      timestamp: new Date(),
      text: 'Hello, this is a demo bot. I don`t do much, but I can count symbols!'
    };
  }

  ngOnInit(): void {
    this.feed = merge(
      this.local
    ).pipe(
      scan((acc: Message[], x: Message) => [...acc, x], [])
    );
  }

  handleMessage(msg: string) {
    this.currentMessage += msg;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.checkIfMessageComplete.bind(this), 500);
  }

  private checkIfMessageComplete() {
    if (this.currentMessage.length > 0) {
      // Send the complete message to the server or do something else with it
      console.log(this.currentMessage);
      this.envieMensagemParaServidor(this.currentMessage);
      this.currentMessage = "";
    }
  }

  public sendMessage(e: SendMessageEvent): void {
    this.local.next(e.message);

    this.handleMessage(e.message.text);
  }

  private async envieMensagemParaServidor(msgUsuario: string) {
    return new Promise((resolve, reject) => {
      this.local.next({
        author: this.bot,
        typing: true
      });

      if (msgUsuario === 'reset') {
        this.indice = 0;
      }

      debugger;
      this.botsService.executeComando('Eu', '+55' + this.telefoneContato, msgUsuario, 'chat', 'eu',
        '', false, 1, '', this.idFluxoTypebot).then((resposta: any) => {
        if (resposta.sucesso) {
          if (!resposta.dados) {
          }

          let msg = resposta.data.resposta;

          let mensagem = msg.mensagem;

          const dividirEmVariasMsgs = /(\[Nova_Mensagem\])/g;
          const mensagens = mensagem.split(dividirEmVariasMsgs);

          this.indice++;
          mensagens.forEach((textoMensagem: string) => {
            if (textoMensagem === '[Nova_Mensagem]') {
              return;
            }

            const regexAsteriscos = /\*(.*?)\*/g;

            textoMensagem = textoMensagem.trimLeft();

            textoMensagem = textoMensagem.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

            // Encontra os URLs na string e transforma-os em tags <a>
            textoMensagem = textoMensagem.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a> ');
            textoMensagem = textoMensagem.replace(/\n/g, '<br>');

            textoMensagem = this.makeBoldText(textoMensagem);
            //textoMensagem = textoMensagem.replace(/\_([^<\_\s][^<\_]*[^<\_\s])\_/g, "<em>$1</em>");
            //textoMensagem = textoMensagem.replace(/\B\*([^<\*\s][^<\*]*[^<\*\s])\*\B/g, "<strong>$1</strong>");
            //textoMensagem = textoMensagem.replace(/\*\*([^\*\*]*?)\*\*/g, "*<strong>$1</strong>*");

            let attachments = [];

            const suggestedActions = [];

            debugger;
            if (msg.menu && msg.menu.opcoes) {
              for (let opcao of msg.menu.opcoes) {
                suggestedActions.push({
                  type: 'reply',
                  value: opcao.texto
                });
              }
            }

            if (msg.imagem) {
              if (msg.imagem.indexOf('.pdf') !== -1) {
                attachments = [{
                  contentType: 'application/pdf',
                  content: this.sanitizer.bypassSecurityTrustResourceUrl(this.obtenhaUrlServidor() + msg.imagem)
                }];
              } else {
                attachments = [{
                  contentType: 'image/png',
                  content: this.obtenhaUrlServidor() + msg.imagem
                }];
              }
            }

            this.indice++;

            resolve(true);
            this.local.next({
              author: this.bot,
              text: this.indice + ' - ' + textoMensagem,
              suggestedActions: suggestedActions,
              attachments: attachments
            });
          });
        } else {
          reject();
          this.local.next({
            author: this.bot,
            text: resposta.erro,
            attachments: []
          });
        }
      });
    });
  }

  makeBoldText(orimessage) {
    console.log(orimessage);

    const styles = { '*': 'strong', '_': 'i', '~': 'strike' };

    return orimessage;
    /*
    return orimessage.replace(/(?<!\w)([*~_])(.+?)\1(?!\w)/g, (match, p1, p2) => {
      return `<${styles[p1]}>${p2}</${styles[p1]}>`;
    });
     */
  }

  obtenhaUrlServidor() {
    return window.location.protocol + '//' + window.location.hostname +
      (window.location.port ?  ':' + window.location.port : '');
  }

  resetConversa() {
    this.botsService.reset('+5562982301144').then((resposta: any) => {
      if( !resposta.sucesso ) {
        return;
      }

      this.ngOnInit();
    });
  }

  async delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async executarTeste() {
    if( !this.cenarioEscolhido ) {
      return;
    }

    for( let mensagem of this.cenarioEscolhido.mensagens ) {
      //await this.delay(1000);

      this.local.next({
        author: this.user,
        text: mensagem,
        timestamp: new Date()
      });

      await this.envieMensagemParaServidor(mensagem);
    }
  }

  enviarLocalizacao() {
    //this.sendMessage({message: {text: 'localizacao: -16.653411544173274, -49.24099837444075', author: this.user}});
    this.sendMessage({message: {text: 'localizacao: -16.62451598219859, -49.22814803720541', author: this.user}});
  }

  alternarNovoCliente() {
    debugger;
    if( this.novoCliente ) {
      this.telefoneContato = '99999999988';
    } else {
      this.telefoneContato = this.usuario.numeroWhatsapp.whatsapp;
    }
  }
}
