<div class="card mt-4" style="max-width: 800px;">
  <div class="modal-header">
    <button class="btn-voltar btn btn-outline-light btn-rounded" (click)="voltar()">
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
    <h4 class="modal-title" id="myModalLabel">
      <span>
          <i class="fas fa-utensils" style="font-size: 22px;"></i> {{tituloPagina}} <i class="fas fa-beer fa-lg"></i>
      </span>
    </h4>
  </div>

  <div class="card-body" style="background: #f6f6f6;">
    <app-tela-mesa-escolhida #telaMesaEscolhida></app-tela-mesa-escolhida>

    <info-contato [contato]="pedido?.contato" [exibirUltimaVisita]="false" [empresa]="empresa"></info-contato>



    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
          novalidate #frm="ngForm" (ngSubmit)="onSubmit()"  >

      <div class="card mt-2" style="margin: 0px;" >
        <div class="card-body" >
          <label class="mb-2"><b>Entrega:</b>   </label>

          <p *ngIf="!empresa.formasDeEntrega?.length" class="text-muted">Nenhuma forma de entrega está disponível no momento</p>
          <div class="form-group mb-2 escolher">
            <span    *ngFor="let formaEntrega of formasDeEntrega; let i = index;" class="mr-1 radio radio-blue">
                <input id="formaDeEntrega{{i}}" name="formaDeEntrega" type="radio"    kendoRadioButton
                       [(ngModel)]="pedido.entrega.formaDeEntrega" [value]="formaEntrega.nome" (ngModelChange)="calculeTaxaDeEntrega()"
                       [required]="true"/>
                <label for="formaDeEntrega{{i}}" class="ml-1">{{formaEntrega.nome}}</label>

            </span>

            <div class="invalid-feedba'ck" *ngIf="!pedido.entrega.formaDeEntrega">
              <p>Escolha a forma de retirada do seu pedido</p>
            </div>

          </div>

          <span *ngIf="pedido.entrega.formaDeEntrega  === tipoFormaEntrega.RECEBER_EM_CASA">

              <div *ngIf="enderecos.length == 0" >
                   <h4 style="font-size: 14px;">
                      <i class="fas fa-map-pin"></i>
                      Cliente ainda não tem endereço cadastrado.
                    </h4>

                    <div class="mt-3">
                      <button class="btn btn-primary btn-block"  type="button" (click)="abraTelaNovoEndereco()">Criar Endereço</button>
                    </div>

                    <div class="invalid-feedback mt-2">
                    <p *ngIf="endereco.errors?.required">Selecione o Local de Entrega</p>
                    </div>
               </div>

              <div [hidden]="enderecos.length == 0">
              <div class="mt-3">
                <button class="btn btn-primary btn-block" type="button" (click)="abraTelaNovoEndereco()">Novo Endereço</button>
              </div>

              <div class="form-group mt-3">
                <label for="descricao">Endereço Para Entrega:</label>
                <kendo-combobox id="descricao" name="descricao" [data]="enderecos"
                                class="form-control" appAutoFocus [autoFocus]="true"  [allowCustom]="false" #endereco="ngModel"
                                [(ngModel)]="pedido.entrega.endereco" (ngModelChange)="calculeTaxaDeEntrega()" required textField="descricaoCompleta">
                </kendo-combobox>

                <small id="emailHelp" class="form-text text-muted">Exemplos: Casa, Trabalho</small>

                <div class="invalid-feedback">
                  <p *ngIf="endereco.errors?.required">Selecione o Local de Entrega</p>
                </div>
              </div>

              <div class="form-group" *ngIf="pedido?.entrega?.endereco">
                <label for="endereco" class="text-muted">Endereço de Entrega:</label>
                <div id="endereco" style="color: #000;">
                  <span>{{pedido?.entrega?.endereco?.obtenhaEnderecoCompleto()}}
                  </span>
                  <button class="btn btn-success" (click)="editarEndereco()" *ngIf="!pedido.entrega.endereco.id"><i class="fa fa-edit"></i> Alterar</button>
                </div>
              </div>
            </div>
           </span>

        </div>

      </div>


      <div class="form-group mt-2 mb-3" *ngIf="pedido.entrega.formaDeEntrega  === tipoFormaEntrega.RECEBER_EM_CASA">
            <label for="taxaDeEntrega">Valor Taxa de Entrega</label><i class="k-icon k-i-loading  " *ngIf="calculandoTaxa"></i>

            <kendo-numerictextbox id="taxaDeEntrega" name="taxaDeEntrega" class="form-control" [format]="formatOptions"
                                  [(ngModel)]="pedido.entrega.taxaDeEntrega" appRemovaWheel [min]="0" [disabled]="msgErro != null"
                                  #txtTaxaDeEntrega="ngModel" autocomplete="off" required></kendo-numerictextbox>

            <small id="taxaDeEntregaHelp" class="form-text text-muted">Se não tiver taxa de entrega coloque 0.</small>

            <div class="invalid-feedback">
              <p *ngIf="txtTaxaDeEntrega.errors?.required">Taxa de Entrega é obrigatório</p>
            </div>

            <div class="text-danger" *ngIf="msgErro">
                <b> {{msgErro}}</b>
            </div>


     </div>



      <div *ngIf="pedido.permiteAgendamento(pedido.entrega.formaDeEntrega, empresa.formasDeEntrega)">
        <div class="form-group mb-0 escolher ">
          <label class="mt-2 k-checkbox-label"  >
            <input type="checkbox" id="agendarEntrega" name="agendarEntrega" class="k-checkbox " kendoCheckBox
                   [(ngModel)]="agendarEntrega" />
            Quer agendar entrega?
          </label>
        </div>
        <div class="form-group mt-2" *ngIf="agendarEntrega">
          <label  >Data de Entrega: </label><br>
          <kendo-datepicker [(ngModel)]="pedido.dataEntrega" [min]="agora" #dataEntrega="ngModel"
                            class="form-control ml-2 mr-2 k-display-inline-block" id="dataEntrega"
                            name="dataEntrega" style="width: 160px" required>
            <kendo-datepicker-messages
              today="Hoje"
              toggle="Trocar calendário"
            ></kendo-datepicker-messages>

          </kendo-datepicker>
          <kendo-timepicker [(ngModel)]="pedido.horarioEntrega"  [hidden]="empresa.naoPerguntarHorario" [required]="!empresa.naoPerguntarHorario"
                            class="form-control k-display-inline-block" id="horaEntrega"  name="horaEntrega"
                            #horaEntrega="ngModel"    style="width: 150px" >
            <kendo-timepicker-messages
              accept="Aceitar"
              cancel="Cancelar"
              cancelLabel="Cancelar mudanças"
              now="Agora"
              nowLabel="Agora"
            ></kendo-timepicker-messages>

          </kendo-timepicker>

          <div class="invalid-feedback">
            <p *ngIf="dataEntrega.errors?.required">Data de Entrega não informada</p>
            <p *ngIf="dataEntrega.errors?.minDate">Data de Entrega invalida</p>
            <p *ngIf="horaEntrega.errors?.required">Hora da Entrega não informada</p>

          </div>
        </div>

      </div>
      <div class="mt-3">
        <button class="btn btn-blue btn-block" type="submit" [disabled]="msgErro">Pagamento</button>
      </div>
    </form>
  </div>
</div>
