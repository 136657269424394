<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()" style="margin-right: 5px;">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  <i class="fe-user "></i> Finalizar Pedido</h4>


<div class="row"  [hidden]="!pedido.id" >

  <div class="col-12">
    <info-contato [contato]="contato" [exibirUltimaVisita]="false" [empresa]="empresa"></info-contato>
  </div>
  <div class="col-12 mt-2">

    <div class="card contato">
      <div class="card-body">
        <ng-container  >
          <info-pedido [contato]="contato"  [pedido]="pedido" [permiteAlterarStatus]="false"></info-pedido>
        </ng-container>

        <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
              novalidate #frm="ngForm" (ngSubmit)="onSubmit()" >

          <div class="form-group mt-2"  >
            <label>Escolha a opção de pagamento que o cliente utilizou</label>

            <div class="form-group mb-2 mt-2 escolher">
              <ng-container *ngFor="let formaDePagamento of empresa.formasDePagamento">
                <div class="radio radio-blue mb-1"  *ngIf="!formaDePagamento.online"  >
                  <input id="formaPagamento{{formaDePagamento.id}}" name="formaDePagamento" type="radio"
                         [(ngModel)]="pagamento.formaDePagamento" [value]="formaDePagamento" class="k-radio" kendoRadioButton
                         [required]="true"/>
                  <label for="formaPagamento{{formaDePagamento.id}}" class="ml-1">{{formaDePagamento.descricao}}</label>
                </div>
              </ng-container>


              <div class="invalid-feedback" *ngIf="!pagamento.formaDePagamento">
                <p>Escolha a forma de pagamento do pedido</p>
              </div>

            </div>

          </div>

          <hr />


          <div class="alert alert-danger alert-dismissible fade show mt-2 mb-2" *ngIf="erro" role="alert">
            {{erro}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>


          <button   class="btn btn-primary mr-2"   [disabled]="finalizando">
            <i class="k-i-loading k-icon mr-1" *ngIf="finalizando"></i> Finalizar Pedido</button>

        </form>



      </div>
    </div>

  </div>

</div>

<div class="alert alert-danger alert-dismissible fade show" *ngIf="carregou && !pedido.id" role="alert">
  <p>Pedido não encontrado</p>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
