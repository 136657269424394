import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {LabelFn, LabelSettings} from "@progress/kendo-angular-progressbar";
import {GroupDescriptor, process} from "@progress/kendo-data-query";
import {ImportadorIfoodService} from "../../services/importador-ifood.service";
import {ProdutoService} from "../../services/produto.service";

@Component({
  selector: 'app-painel-importacao-produtos',
  templateUrl: './painel-importacao-produtos.component.html',
  styleUrls: ['./painel-importacao-produtos.component.scss']
})
export class PainelImportacaoProdutosComponent implements OnInit {
  @Input() modoExibicao = false;
  importacao: any = {total: 0, totalImportar: 0, totalAtualizar: 0, sincronizando: false, totalProcessados: 0, finalizou: false};
  produtosSincronizarPorCategoria: any  = []
  labelSincronizados: LabelSettings;
  labelCategoria: LabelSettings;
  naoImportados = []
  naoAtualizados = []
  emptyStyles: {[key: string]: any} = {  background: '' };
  progressStyles: {[key: string]: any} = {}
  categorias = [];
  finalizouSincronizacao: boolean;
  importou: boolean
  buscando = true;
  modoRede = false;
  catalogo: any = {}
  constructor( private importador:  ImportadorIfoodService,
               private produtoService: ProdutoService) { }

  ngOnInit(): void {
    this.labelSincronizados  = {
      format: this.formatterGlobal,
      position: 'center',
    };


  }

  setModoRede(catalogo){
    this.catalogo = catalogo;
    this.modoRede = true;
  }

  obtenhaLabel(categoriaImportando: any): LabelSettings{
    return {
      format: (value) => {
        if(!categoriaImportando) return '0/0'

        return `${value}/${categoriaImportando.total}`;
      },
      position: 'center',
    }
  }

  public formatterGlobal: LabelFn = (value: number): string => {
    return `${value}/${this.importacao.total}`;
  }

  setProdutosSincronizar(produtosNovos, produtosAtualizar, produtosRemovidos, categorias){
    this.categorias = categorias;
    this.produtosSincronizarPorCategoria = [];
    this.importacao  = {
      total: 0,
      totalImportar: 0,
      totalAtualizar: 0,
      totalProcessados: 0,
      totalSincronizados: 0,
      importando: false,
      finalizou: false
    }
    this.progressStyles = {
      color: '#fff',
      background: '#1050bb'
    };
    this.naoImportados = [];
    this.naoAtualizados = [];
    this.importacao.totalImportar = produtosNovos.length;
    this.importacao.totalAtualizar = produtosAtualizar.length;
    this.importacao.totalRemover = produtosRemovidos.length;
    this.importacao.total =     this.importacao.totalImportar +     this.importacao.totalAtualizar;

    for(let i = 0 ; i  < this.categorias.length ; i++) {
      let categoria: any = this.categorias[i];
      let categoriaKey = categoria.id ? categoria.id : (categoria.codigoIfood || categoria.codigoPdv || categoria.nome);

      let produtosNovosCategoria = produtosNovos.filter((produto: any) => produto.categoria.id ? produto.categoria.id === categoriaKey :
        (produto.categoria.codigoIfood || produto.categoria.codigoPdv || produto.categoria.nome) === categoriaKey);

      let produtosRemovidosCategoria =
        produtosRemovidos.filter( (produto: any) =>  produto.categoria.id ? produto.categoria.id === categoriaKey :
        (produto.categoria.codigoIfood || produto.categoria.codigoPdv || produto.categoria.nome) === categoriaKey)

      let produtosAtualizarCategoria = produtosAtualizar.filter((produto: any) => produto.categoria.id ?
        produto.categoria.id === categoriaKey :
        (produto.categoria.codigoIfood || produto.categoria.codigoPdv || produto.categoria.nome) === categoriaKey);

      if( produtosNovosCategoria.length || produtosAtualizarCategoria.length || produtosRemovidosCategoria.length){

        let categoriaSincronizar =  {
          categoriaKey: categoriaKey,
          produtos: [],
          importouTodos: false,
          finalizou: false,
          nomeCategoria: categoria.nome,
          totalImportar: produtosNovosCategoria.length,
          totalAtualizar: produtosAtualizarCategoria.length,
          totalRemover: produtosRemovidosCategoria.length,
          total: produtosNovosCategoria.length + produtosAtualizarCategoria.length + produtosRemovidosCategoria.length,
          totalProcessados: 0,
          totalSincronizados: 0,
          progressStyles: {   color: '#fff',
            background: '#1050bb'
          },
          exibirProdutos: this.importacao.totalImportar < 200
        }

        for(let j = 0;  j < produtosNovosCategoria.length; j++){
          let novoProduto = produtosNovosCategoria[ j];

          novoProduto.importar = true;

          categoriaSincronizar.produtos.push(novoProduto)
        }

        for(let j = 0;  j < produtosAtualizarCategoria.length; j++){
          let produto = produtosAtualizarCategoria[ j];

          produto.sincronizar = true;

          produto.qtdeAdicionaisNovos =  produto.camposAdicionais.filter( adicional => !adicional.id).length;
          produto.qtdeAdicionaisAtualizar =  produto.camposAdicionais.filter( adicional => adicional.id != null).length;

          categoriaSincronizar.produtos.push(produto)
        }
        for(let j = 0;  j < produtosRemovidosCategoria.length; j++){
          let produto = produtosRemovidosCategoria[ j];

          produto.remover = true;

          categoriaSincronizar.produtos.push(produto)
        }

        this.produtosSincronizarPorCategoria.push(categoriaSincronizar)

      }

    }

    this.buscando = false;

  }

  public expandInStockProducts({ dataItem, index }: any): boolean {
    if(this.importacao && this.importacao.importando) return false;

    return  dataItem.camposAdicionais && dataItem.camposAdicionais.length > 0
  }

  async importeCategoria(categoriaSincronizar: any){
    if(categoriaSincronizar.sincronizando ) return;

    console.log('categoria sincronizar' + categoriaSincronizar.nomeCategoria)

    categoriaSincronizar.sincronizando = true;

    for(let j = 0; j <  categoriaSincronizar.produtos.length ; j++){
      let produto: any = categoriaSincronizar.produtos[j];

      if(produto.importar ) {
        await this.importeProduto(produto);
      } else if(produto.remover ){
        await this.removaProdutoCatalogo(produto);

      } else {
        await this.atualizeProduto(produto);
      }

      this.importacao.totalProcessados++;
      categoriaSincronizar.totalProcessados++;

      if(!produto.erro){
        categoriaSincronizar.totalSincronizados++;
        this.importacao.totalSincronizados++;
      }

    }

    categoriaSincronizar.importouTodos  =  categoriaSincronizar.totalSincronizados ===  categoriaSincronizar.total;

    if(categoriaSincronizar.importouTodos){
      categoriaSincronizar.progressStyles.background = '#8EBC00';
    }  else {
      categoriaSincronizar.progressStyles.background = '#f1556c';
    }
    await this.sleep(1000);
    categoriaSincronizar.sincronizando = false;
    categoriaSincronizar.finalizou = true;
  }

  async sincronizeTodos(){
    if(  this.importacao.sincronizando ) return;
    this.naoImportados = [];
    this.naoAtualizados = [];
    this.finalizouSincronizacao = false;
    this.importacao.sincronizando = true;

    for(let i = 0; i <  this.produtosSincronizarPorCategoria.length ; i++){
      let categoriaSincronizar: any = this.produtosSincronizarPorCategoria[i];

      await this.importeCategoria(categoriaSincronizar)

    }

    this.importacao.importouTodos =  this.importacao.totalSincronizados === this.importacao.total;

    if(  this.importacao.importouTodos){
      this.progressStyles.background = '#8EBC00';
      this.importador.finalizouImportacao().then( () => {})
    } else {
      this.progressStyles.background = '#f1556c';
    }

    this.importacao.sincronizando = false;
    this.importacao.finalizou = true;
  }

  async importeProduto(produto: any) {
    console.log('importar: ' + produto.nome)
    delete produto.erro;

    if(produto.id || produto.sincronizando) return;

    produto.sincronizando = true;

    let produtoSalvo = await this.importador.importeProduto(produto).catch( (erro) => {
      produto.erro = erro;

      this.naoImportados.push(produto)
    })

    if(produtoSalvo && produtoSalvo.id){
      Object.assign( produto, produtoSalvo)
      produto.importou = true;
      this.importou = true; //so marca que houve importação
    }

    produto.sincronizando = false;
  }
  async removaProdutoCatalogo(produto: any){
    produto.sincronizando = true;
    delete   produto.erro;

    await this.produtoService.removaProdutoCatalogo( [produto]).catch( erro => {
      produto.erro = erro;
    })

    produto.sincronizando = false;

    if(!produto.erro){
      produto.sincronizado = true;
    }
  }
  async atualizeProduto(produto: any) {
    produto.sincronizando = true;
    delete   produto.erro;

    if(this.modoRede){
      await this.produtoService.sincronizeProdutoDaRede(this.catalogo, produto).catch( erro => {
        produto.erro = erro;
      })
    } else {
      await this.produtoService.sincronizeProduto( [produto]).catch( erro => {
        produto.erro = erro;
      })
    }



    produto.sincronizando = false;

    if(!produto.erro){
      produto.sincronizado = true;
    }
  }


  obtenhaAdicionais(produto: any = {}) {
    let opcoes = [];
    let groups: GroupDescriptor[] =  [{ field: 'nomeAdicional' }];

    if(produto.camposAdicionais && produto.camposAdicionais.length){
      produto.camposAdicionais.forEach( (adicionalProduto: any) => { // Novo produto ou novos adicionais
        if(adicionalProduto.opcoesDisponiveis && adicionalProduto.opcoesDisponiveis.length){
          adicionalProduto.opcoesDisponiveis.forEach((opcaoAdicional) => {
            let novaOpcao: any = Object.assign({}, opcaoAdicional)
            novaOpcao.nomeAdicional = adicionalProduto.nome;
            novaOpcao.idAdicional = adicionalProduto.id;
            if(adicionalProduto.codigoIfood)
              novaOpcao.nomeAdicional = String(`${ novaOpcao.nomeAdicional} (${adicionalProduto.codigoIfood})`)

            opcoes.push(novaOpcao)
          })
        }
      })
    }

    if(produto.camposAdicionaisRemovidos && produto.camposAdicionaisRemovidos.length){
      produto.camposAdicionaisRemovidos.forEach( (adicionalProduto: any) => { // Novo produto ou novos adicionais
        if(adicionalProduto.opcoesDisponiveis && adicionalProduto.opcoesDisponiveis.length){
          adicionalProduto.opcoesDisponiveis.forEach((opcaoAdicional) => {
            let novaOpcao: any = Object.assign({}, opcaoAdicional)
            novaOpcao.removida = true;
            novaOpcao.nomeAdicional = adicionalProduto.nome;

            if(adicionalProduto.codigoIfood)
              novaOpcao.nomeAdicional = String(`${ novaOpcao.nomeAdicional} (${adicionalProduto.codigoIfood})`)

            novaOpcao.idAdicional = adicionalProduto.id;
            opcoes.push(novaOpcao)
          })
        }
      })
    }

    if(produto.opcoesAtualizadas && produto.opcoesAtualizadas.length){ // DTOProdtuSincronizar
      produto.opcoesAtualizadas.forEach( (novaOpcao) => {
        opcoes.push(novaOpcao)
      })
    }

    if(produto.opcoesRemovidas && produto.opcoesRemovidas.length){ // DTOProdtuSincronizar
      produto.opcoesRemovidas.forEach( (novaOpcao) => {
        novaOpcao.removida = true;
        opcoes.push(novaOpcao)
      })
    }

    if(!opcoes.length) produto.naoTemAdicionais = true;

    return process(opcoes, { group: groups });
  }

  reimporteProdutos() {
    this.setProdutosSincronizar(this.naoImportados, this.naoAtualizados, [], this.categorias)

    this.sincronizeTodos();
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  toogleExibirProdutos(categoriaSincronizar: any) {
    categoriaSincronizar.exibirProdutos = !categoriaSincronizar.exibirProdutos;
  }
}
