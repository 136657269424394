<h4   class="page-title">
  <i class="fe-bar-chart-2"></i>
  Financeiro Mesas</h4>



<kendo-panelbar class="mb-0 pb-0" style="    border-bottom: 0;">
  <kendo-panelbar-item [title]="'Filtros'" [expanded]="true"  >
    <ng-template kendoPanelBarContent>
      <div class="card card-body ">
        <div class="row filtro"  >
          <div class="col-md-auto" *ngIf="!periodoEspecifico">
            <label>
              <span class="label lh">Mês <br></span>
              <kendo-datepicker style="width: 13em"  class="form-control"
                                [bottomView]="'year'"
                                [topView]="'year'"
                                [format]="'MMMM yyyy'"
                                [activeView]="'year'"
                                [max]="hoje"
                                (valueChange)="mudouValorMes($event)"
                                [(value)]="valorMes">
              </kendo-datepicker>

              <p class="text-blue cpointer mt-2" (click)="exibirPeriodoEspecifico()">
                Período especifico</p>
            </label>

          </div>
          <div  class="col-md-auto" *ngIf="periodoEspecifico">
            <kendo-daterange  class="mr-2" >
              <label>
                <span class="label lh">De <br></span>
                <kendo-datetimepicker style="width: 13em"  [(ngModel)]="filtro.inicio"  (ngModelChange)="carregueResumoVendas()">
                  <kendo-datetimepicker-messages
                    today="Hoje"
                    toggle="Alterar data"
                    cancel="Cancelar"
                    dateTab="Data"
                    timeTab="Hora"
                    now="Agora"
                    accept="Ok"
                  ></kendo-datetimepicker-messages>

                </kendo-datetimepicker>
              </label>
              <label class="ml-2 ">
                <span class="label  lh">Até </span><br>
                <kendo-datetimepicker style="width: 13em" [(ngModel)]="filtro.fim" (ngModelChange)="carregueResumoVendas()" >
                  <kendo-datetimepicker-messages
                    today="Hoje"
                    toggle="Alterar data"
                    cancel="Cancelar"
                    dateTab="Data"
                    timeTab="Hora"
                    now="Agora"
                    accept="Ok"
                  ></kendo-datetimepicker-messages>
                </kendo-datetimepicker>

              </label>
              <p class="text-blue cpointer" (click)="exibirPeriodoMensal()">
                Período mensal</p>

            </kendo-daterange>
          </div>

          <div class="col">
            <div class="form-group mb-3  "  *ngIf="garcons.length"  >
              <label for="garcon">Garçons</label>
              <kendo-multiselect id="garcon" name="garcon" [(ngModel)]="filtro.garcons" [data]="garcons"
                                 placeholder="Filtre por garçom" class="form-control"  textField="nome" valueField="id"
                                 (ngModelChange)="carregueResumoVendas()">
              </kendo-multiselect>

            </div>
          </div>

        </div>

        <div class="row filtro"  >
          <div class="col-md-auto">
            <label>Tipo da comanda</label> <br>
            <div class="form-group  d-inline-block ml-0">
              <input type="radio" id="ambas"  name="comanda"   value="ambas" class="k-radio" kendoRadioButton   [(ngModel)]="filtro.comanda"  (ngModelChange)="carregueResumoVendas()"/>
              <label class="k-checkbox-label pl-1" for="ambas">Ambas</label>
            </div>
            <div class="form-group d-inline-block ml-2">
              <input type="radio" id="unicos"  name="comanda" value="unico" class="k-radio " kendoRadioButton   [(ngModel)]="filtro.comanda"  (ngModelChange)="carregueResumoVendas()"/>
              <label class="k-checkbox-label pl-1" for="unicos">Somente comandas pedidos unicos</label>
            </div>

            <div class="form-group  d-inline-block ml-2">
              <input type="radio" id="multiplos"  name="comanda"   value="multiplo" class="k-radio" kendoRadioButton   [(ngModel)]="filtro.comanda"  (ngModelChange)="carregueResumoVendas()"/>
              <label class="k-checkbox-label pl-1" for="multiplos">Somente comandas multiplos pedidos</label>
            </div>


          </div>
        </div>
      </div>

    </ng-template>
  </kendo-panelbar-item>
</kendo-panelbar>



<h4>Resumo</h4>
<div class="row">
  <div class="col-4 col-xl-3">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted">      Total de vendas       </h4>
      <h5 class="card-text text-warning">
        {{resumoVendas.total | currency: "BRL"}}
      </h5>
    </div>
  </div>

  <div class="col-4 col-xl-3">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted"> Qtde. Pedidos   </h4>
      <h5 class="card-text ">
        {{resumoVendas.qtde | number}}
      </h5>
    </div>
  </div>

  <div class="col-4 col-xl-3">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted"> Ticket Médio   </h4>
      <h5 class="card-text">
        {{resumoVendas.ticketMedio | currency: "BRL"}}
      </h5>
    </div>
  </div>



</div>

<ng-container *ngIf="empresa.cobrarTaxaServico">
  <div class="row">
    <div class="col4 col-xl-3">
      <div class="card card-body text-xs-center">
        <h4 class="card-title text-muted"> Total Taxa Serviço   </h4>
        <h5 class="card-text ">
          {{resumoVendas.totalTaxaServico | currency: "BRL"}}
        </h5>
      </div>


    </div>
    <div class="col4 col-xl-3">
      <div class="card card-body text-xs-center">
        <h4 class="card-title text-muted"> Total com Taxa   </h4>
        <h5 class="card-text ">
          {{resumoVendas.totalComTaxa | currency: "BRL"}}
        </h5>
      </div>


    </div>
  </div>

</ng-container>

<div class="card card-body ">
  <ng-container *ngIf="!vendasDoDia">

    <kendo-tabstrip   [class]="'tabs'" (tabSelect)="trocouAba($event)">
      <kendo-tabstrip-tab [title]="'Por loja'" [id]="'loja'" *ngIf="rede" [selected]="rede">
        <ng-template kendoTabContent>
          <h5>Vendas por loja <b>{{obtenhaDescricaoFiltro()}}</b></h5>

          <kendo-grid  [style]=""
                       [data]="vendasLojasResultSet"
                       [loading]="carregando" style="min-height: 300px;"
                       [sortable]="{mode: 'single'}"
                       [sort]="sortLoja"
                       (sortChange)="sortChangeLojas($event)">

            <ng-template kendoGridToolbarTemplate>
              <button type="button" kendoGridExcelCommand icon="file-excel">Exportar</button>
            </ng-template>

            <kendo-grid-column title="Cod. Loja"  field="codigoLoja"   [width]="150"  >
              <ng-template kendoGridCellTemplate let-resumo  >
                <span class="text-dark">{{resumo.codigoLoja}}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Loja"     field="nomeLoja">
              <ng-template kendoGridCellTemplate let-resumo  >
                <span class="text-dark">{{resumo.nomeLoja}}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Qtde Comandas"   field="qtde"    [width]="150"  >
              <ng-template kendoGridCellTemplate let-resumo  >
                <b>{{resumo.qtde | number}}</b>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Total vendido"    field="totalComTaxa"  [width]="250" >
              <ng-template kendoGridCellTemplate let-resumo  >
              <span class="text-success">
                 <b>   {{resumo.totalComTaxa | currency: "BRL"}}</b>
              </span>

              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Taxa de Entrega">
              <ng-template kendoGridCellTemplate let-resumo  >
              <span class="text-success">
                 <b>   {{resumo.totalTaxaDeEntrega | currency: "BRL"}}</b>
              </span>

              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Ticket médio"    >
              <ng-template kendoGridCellTemplate let-resumo  >
                <span class="text-muted"><b>{{resumo.ticketMedio | currency: "BRL"}}</b></span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('lojas')"></kendo-grid-excel>

          </kendo-grid>

          <div class="mt-2 mb-2"></div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Vendas diárias'" [id]="'vendas'"  [selected]="!rede" >
        <ng-template kendoTabContent>
          <kendo-grid
            [style]=""
            [kendoGridBinding]="resumoVendas.diarias"
            [loading]="carregando" style="min-height: 300px;">

            <kendo-grid-messages
              pagerPage="Página"
              pagerOf="de"
              pagerItems="itens"
              noRecords="nenhum venda no periódo"
              loading="Carregando"
              pagerItemsPerPage="ítems por página"
            >
            </kendo-grid-messages>
            <kendo-grid-column title="Dia"   [width]="150"  >
              <ng-template kendoGridCellTemplate let-resumoDia  >
                <span class="text-dark">{{resumoDia.dia}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Total vendido"     [width]="250" >
              <ng-template kendoGridCellTemplate let-resumoDia  >
              <span class="text-success">
                 <b>   {{resumoDia.total | currency: "BRL"}}</b>
              </span>

              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Total com Taxa"     [width]="250" *ngIf="empresa.cobrarTaxaServico" >
              <ng-template kendoGridCellTemplate let-resumoDia  >
              <span class="text-success">
                 <b>   {{resumoDia.totalComTaxa | currency: "BRL"}}</b>
              </span>

              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Qtde Pedidos"  [width]="150"  >
              <ng-template kendoGridCellTemplate let-resumoDia  >
                <b>{{resumoDia.qtde | number}}</b>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Ticket médio"    >
              <ng-template kendoGridCellTemplate let-resumoDia  >
                <span class="text-muted"><b>{{resumoDia.ticketMedio | currency: "BRL"}}</b></span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column      >

              <ng-template kendoGridCellTemplate let-resumoDia  >
                <button class="btn btn-outline-blue btn-sm ml-1 " (click)="verPedidos(resumoDia)"  title="ver pedidos" kendoTooltip>
                  <i class="fe-list fa-lg"></i>
                </button>
              </ng-template>

            </kendo-grid-column>
          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Mais vendidos'" [id]="'produtos'"  >
        <ng-template kendoTabContent>
          <div class="ml-2 form-group d-inline-block ">

            <input type="checkbox" id="porAdicionais" name="porAdicionais" class="k-checkbox " kendoCheckBox
                   [(ngModel)]="filtro.porAdicionais"
                   (ngModelChange)="carregueResumoProdutos()"/>
            <label class="k-checkbox-label" for="porAdicionais">Exibir vendas dos adicionais</label>
          </div>

          <kendo-grid
            [style]=""   [groupable]="true"  [group]="group"
            [kendoGridBinding]="resumoProdutos.produtos"
            [loading]="resumoProdutos.carregando" style="min-height: 300px;">

            <kendo-grid-messages
              pagerPage="Página"
              pagerOf="de"
              pagerItems="itens"
              noRecords="nenhuma produto vendido no periódo"
              loading="Carregando"
              pagerItemsPerPage="ítems por página"
            >
            </kendo-grid-messages>
            <kendo-grid-column title="Codigo"      [width]="100">
              <ng-template kendoGridCellTemplate let-resumoProduto  >
                <span class="text-blue"><b>{{resumoProduto.codigo}}</b></span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Produto"     >
              <ng-template kendoGridCellTemplate let-resumoProduto  >
                <span class="text-dark">{{resumoProduto.nome}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Qtde vendido"     [width]="250" >
              <ng-template kendoGridCellTemplate let-resumoProduto  >
                <b>   {{resumoProduto.qtdeDescricao   }}</b>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Total vendido"  [width]="150"  >
              <ng-template kendoGridCellTemplate let-resumoProduto  >
             <span class="text-success">
                 <b>{{resumoProduto.total | currency: "BRL"}}</b>
              </span>
              </ng-template>

              <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates" >
                <ng-container *ngIf="resumoProdutos.totalDescontos ">
                  <label> Total produtos: <span class="text-success"> +{{ aggregates.total.sum  | currency: 'BRL'}}</span> </label>
                  <label> Total descontos: <span class="text-danger"> -{{ resumoProdutos.totalDescontos  | currency: 'BRL'}}</span></label>
                </ng-container>
                <label> Total:  <span class="text-success">

                    <b>{{ (aggregates.total.sum - resumoProdutos.totalDescontos)   | currency: 'BRL'}}</b> </span> </label>
              </ng-template>
            </kendo-grid-column>



          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Garçons'" [id]="'garcons'"   >
        <ng-template kendoTabContent>
          <kendo-grid
            [style]=""
            [kendoGridBinding]="resumoGarcons.garcons"
            [loading]="resumoGarcons.carregando" style="min-height: 300px;">

            <kendo-grid-messages
              pagerPage="Página"
              pagerOf="de"
              pagerItems="itens"
              noRecords="nenhuma venda no periódo"
              loading="Carregando"
              pagerItemsPerPage="ítems por página"
            >
            </kendo-grid-messages>
            <kendo-grid-column title="Codigo"      [width]="100">
              <ng-template kendoGridCellTemplate let-resumo   >
                <span class="text-blue"><b>{{resumo.codigo}}</b></span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Garçon"     >
              <ng-template kendoGridCellTemplate let-resumo  >
                <span class="text-dark">{{resumo.garcon}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Qtde pedidos"     [width]="250" >
              <ng-template kendoGridCellTemplate let-resumo  >
                <b>   {{resumo.qtde  }}</b>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Total vendido"  [width]="150"  >
              <ng-template kendoGridCellTemplate let-resumo  >
             <span class="text-success">
                 <b>{{resumo.total | currency: "BRL"}}</b>
              </span>
              </ng-template>
            </kendo-grid-column>

          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>

    </kendo-tabstrip>

  </ng-container>


  <ng-container *ngIf="vendasDoDia">
    <h4>
      <button class="btn btn-outline-blue btn-rounded" (click)="voltarVendasDia()">
        <i class="fa fa-arrow-left ct-point  "></i>
      </button>
      Vendas do dia <span><b>{{vendasDoDia.dia}}</b></span></h4>

    <app-grid-pedidos #gridPedidos [pedidos]="vendasDoDia.pedidos" [carregando]="vendasDoDia.carregando" [mesa]="true"></app-grid-pedidos>

  </ng-container>
</div>



