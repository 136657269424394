import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {process, State} from "@progress/kendo-data-query";
import {DataStateChangeEvent, GridDataResult, SelectionEvent} from "@progress/kendo-angular-grid";
import {TelaExpandidaComponent} from "../TelaExpandida";

@Component({
  selector: 'app-tela-adicionar-brinde',
  templateUrl: './tela-adicionar-brinde.component.html',
  styleUrls: ['./tela-adicionar-brinde.component.scss']
})
export class TelaAdicionarBrindeComponent extends TelaExpandidaComponent implements OnInit {
  @Output() onAdicionou = new EventEmitter();
  @Output() onCancelou = new EventEmitter();
  resgate: any = {};
  msgErro: string;
  public state: State = {
    skip: 0,
    take: 100
  };
  configuracoesDeSelecao: any = {
    checkboxOnly: false,
    mode: 'multiple'
  };
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  public gridDataResult: GridDataResult;
  loading: any;
  brindeSelecionado: any;
  qtdeItens: any = 1;

  constructor() {
    super();
  }

  exibaResgate(resgate: any) {
    delete this.brindeSelecionado;
    delete this.msgErro;
    this.qtdeItens = 1;
    this.resgate = resgate;
    this.gridDataResult = process(resgate.brindes, this.state);
  }

  ngOnInit() {
  }

  adicioneBrindes() {
    delete this.msgErro;
    let ptsNecessarios: number = this.brindeSelecionado.valorEmPontos * this.qtdeItens;

    if(this.resgate.cartao.pontos <   ptsNecessarios ){
      this.msgErro = 'Saldo insuficiente para realizar a troca';
      return;
    }

    let brindeResgatado: any = {
           brinde: this.brindeSelecionado,
           qtde: this.qtdeItens ,
           cartao: this.resgate.cartao
    };

    this.onAdicionou.emit( brindeResgatado)
  }

  cancelar() {
    this.onCancelou.emit()
  }

  ehCashback(plano: any) {
    return plano &&  plano.tipoDeAcumulo === 'Reais';
  }


  onFilterEvento(evento: Event) {
    this.onFilter((evento.target as HTMLInputElement).value);
  }

  onFilter(value: any) {
    const estado: State = {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'nome',
            operator: 'contains',
            value: value
          }
        ],
      }
    };
    this.gridDataResult = process(this.resgate.brindes, estado);
  }

  selecionouBrinde($event: SelectionEvent) {
    this.brindeSelecionado = $event.selectedRows[0].dataItem;


  }

  dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.gridDataResult = process(this.resgate.brindes, this.state);
  }
}
