<kendo-grid [data]="gridView"  [sort]="sort"
            (sortChange)="sortChange($event)"
            (pageChange)="pageChange($event)"
            scrollable="virtual"
            [rowHeight]="36"
            [height]="450"
            [skip]="skip"
            [pageSize]="pageSize"
            [sortable]="{mode: 'single'}"
            [loading]="carregando"
            (detailCollapse)="colapsou($event)" (detailExpand)="expandiu($event)">
  <ng-template kendoGridNoRecordsTemplate>
    <div style="padding: 30px;">
      <h3>
        Selecione uma categoria de clientes no gráfico
      </h3>
    </div>
  </ng-template>
  <ng-template kendoGridToolbarTemplate>
    <button type="button" kendoGridExcelCommand icon="file-excel">Exportar para Excel</button>
  </ng-template>

  <kendo-grid-column field="id" title="ID" [width]="30" media="lg">
  </kendo-grid-column>


  <kendo-grid-column field="nome" title="Nome" [width]="120" class="abrir-detalhes">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>
        {{dataItem.nome}} <span class="text-muted" style="font-size: 12px;" title="Quantidade de dias como cliente" kendoTooltip>({{dataItem.qtdeDiasCliente}} dias)</span>
      </span>
      &nbsp;<contato-status [contato]="{status: categoria}"></contato-status>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="telefone" media="lg" title="Telefone" [width]="60">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span style="font-size: 12px;">{{dataItem.telefone | tel}}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="nomeLoja" title="Loja" [width]="120"  *ngIf="rede" >
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span>
        {{dataItem.nomeLoja}}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="ticketMedio" media="lg" title="Ticket Médio" [width]="60">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.ticketMedio | currency: 'BRL'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="qtdeVisitas" media="lg" title="Qtde. Visitas" [width]="60">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.qtdeVisitas}} &nbsp;<span>visitas</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column id="cicloDeRecorrencia" media="lg" field="cicloDeRecorrencia" title="Ciclo de Recorrência" [width]="80">
    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
      Ciclo de<br>Recorrência
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.cicloDeRecorrencia.toFixed(1).replace(".", ",")}} <span>dias</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column id="qtdeDiasNaoVolta" field="qtdeDiasNaoVolta" title="Qtde. Dias Não Volta" [width]="80">
    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
      Qtde. Dias<br> Não Volta
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.qtdeDiasNaoVolta}} &nbsp;<span>dias</span>&nbsp;
      <span *ngIf="dataItem.exibirEmRisco" class="badge badge-warning"> Em Risco</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel [fileName]="nomeArquivo"   [fetchData]="todosContatos"></kendo-grid-excel>

  <ng-template kendoGridDetailTemplate let-dataItem>
    <section>
      <h4>
        <a [href]="'/admin/contatos/' + dataItem.codigoPais+ dataItem.telefone" target="_blank">{{dataItem.nome}}
          <i class="fas fa-link"></i></a>
      </h4>

      <hr>
      <div class="row">
        <div class="col-6 mb-1">
          <p class="mb-1 rotulo">Status</p>
          <p class="valor">
            <contato-status [contato]="{status: categoria}"></contato-status>
          </p>
        </div>
        <div class="col-6 mb-1">
          <p class="mb-1 rotulo">Telefone</p>
          <p class="valor">
            <span> {{dataItem.telefone | mask: '(99) 9-9999-9999' }} </span>
          </p>
        </div>
        <div class="col-6 mb-1">
          <p class="mb-1 rotulo">Ciclo de Recorrência</p>
          <p class="valor">
            {{(dataItem.cicloDeRecorrencia).toFixed(1).replace('.', ',')}} dias
          </p>
        </div>
        <div class="col-6 mb-1">
          <p class="mb-1 rotulo">Tempo que Não Volta</p>
          <p class="valor">
            {{dataItem.qtdeDiasNaoVolta}} &nbsp;dias
          </p>
        </div>
        <div class="col-6 mb-1">
          <p class="mb-1 rotulo">Ticket Médio</p>
          <p class="valor">
            {{ dataItem.ticketMedio | currency: 'BRL' }}
          </p>
        </div>
        <div class="col-6 mb-1">
          <p class="mb-1 rotulo">Tempo Como Cliente</p>
          <p class="valor">
            {{dataItem.qtdeDiasCliente }} dias
          </p>
        </div>
      </div>
    </section>
  </ng-template>
</kendo-grid>
