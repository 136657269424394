<kendo-grid [pageSize]="this.tamanhoPagina"
            [skip]="page"
            [style]=""
            [kendoGridBinding]="planos"
            [loading]="loading" style="min-height: 300"
            [scrollable]="'none'"
            [pageable]="{
              buttonCount: buttonCount,
              info: info,
              type: type,
              pageSizes: pageSizes,
              previousNext: previousNext
            }"
            (edit)="editHandler($event)"
            (remove)="abraDialogRemoverPlano($event)"
            (pageChange)="carregue($event)">
  <ng-template kendoGridToolbarTemplate [position]="'top'">
    <button class="btn btn-blue btn-sm" (click)="novoPlano()">
      <i class="fe-plus"></i> Novo Plano
    </button>
  </ng-template>
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Sem contatos"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column title="ID" class="coluna_menor"  [width]="75">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>{{dataItem.id}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Nome" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>{{dataItem.nome}}</span>
      <p class="mt-1 mb-0 text-muted">
        <span>{{dataItem.telefone }}</span>
      </p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Tipo" class="coluna_menor" [width]="125">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>{{dataItem.tipoDeAcumulo}}</span>
      <p class="mt-1 mb-0 text-muted">
        <span>{{dataItem.telefone }}</span>
      </p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Descrição"   media="(min-width: 900px)" >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>{{dataItem.descricao}}</span>
      <p class="mt-1 mb-0 text-muted">
        <span>{{dataItem.telefone }}</span>
      </p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Validade" class="coluna_menor"  [width]="125">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>{{dataItem.validade || '-'}}</span>
      <span *ngIf="dataItem.validade"> (dias)</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Vencimento" class="coluna_menor"  [width]="125">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>{{dataItem.vencimento  | date: 'dd/MMM/yyyy'}}</span>
      <span *ngIf="!dataItem.vencimento"> - </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Referência" class="coluna_menor"  [width]="110"  media="(min-width: 900px)" >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <i class="fa fa-check text-success" *ngIf="dataItem.referenciaExterna"></i>
      <i class="fa fa-times text-danger" *ngIf="!dataItem.referenciaExterna"></i>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Ativo" class="coluna_menor"  [width]="110">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <i class="fa fa-check text-success" *ngIf="dataItem.ativo"></i>
      <i class="fa fa-times text-danger" *ngIf="!dataItem.ativo"></i>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
      <button class="btn btn-success btn-xs" title="ativar" kendoTooltip (click)="ativarPlano(dataItem)" [hidden]="dataItem.ativo" >
        <i class="fa fa-play"></i></button>
      <button class="btn btn-danger btn-xs" title="desativar" kendoTooltip (click)="confirmeDesativarPlano(dataItem)" [hidden]="!dataItem.ativo" >
        <i class="fa fa-pause"></i></button>

      <button kendoGridEditCommand [primary]="true" title="editar" kendoTooltip><i class="fa fa-edit"></i></button>

      <button kendoGridRemoveCommand title="remover" kendoTooltip><i class="fa fa-trash"></i></button>

    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<kendo-dialog title="Atenção!" *ngIf="exibaConfirmacaoDesativar" (close)="fechar()" [minWidth]="250" [width]="450">
  <div  [hidden]="!planoSelecionado">
    <p style="margin: 30px; text-align: center;"> Confirma a desativação do plano   "<strong>{{planoSelecionado.nome}}?</strong>"</p>

    <div *ngIf="atualizandoPlano">
      <p style="margin: 30px; text-align: center;">Desativando o plano "<strong>{{planoSelecionado.nome}}"...</strong></p>
      <div class="k-i-loading ml-1 mr-1" style="font-size: 20px; padding-bottom: 20px  " ></div>
    </div>

    <div class="alert alert-danger" *ngIf="mensagemRemocao">
      <p style="margin: 30px; text-align: center;">{{mensagemRemocao}}</p>
      <button type="button" class="close" (click)="fechar()">&times;</button>
    </div>


    <div class="modal-footer" *ngIf="!atualizandoPlano">
      <button type="button" *ngIf="planoSelecionado" (click)="desativarPlano()" class="btn btn-primary waves-effect waves-light" >Desativar</button>
      <button type="button" *ngIf="planoSelecionado" (click)="fechar()" kendoButton [primary]="true" class="btn btn-light waves-effect">Cancelar</button>
      <button type="button" *ngIf="!planoSelecionado" (click)="fechar()" class="btn btn-primary waves-effect waves-light" >Fechar</button>
    </div>

  </div>
</kendo-dialog>

<kendo-dialog title="Atenção!" *ngIf="abraDialogo" (close)="fechar()" [minWidth]="250" [width]="450">
  <div *ngIf="planoSelecionado && !atualizandoPlano">
    <p style="margin: 30px; text-align: center;">Esta operação é irreversível. Tem certeza que deseja excluir o plano "<strong>{{planoSelecionado.nome}}?</strong>"</p>
  </div>
  <div *ngIf="atualizandoPlano">
    <p style="margin: 30px; text-align: center;">Excluindo o plano "<strong>{{planoSelecionado.nome}}"...</strong></p>
    <div class="k-i-loading ml-1 mr-1" style="font-size: 20px; padding-bottom: 20px  " ></div>
  </div>
  <div *ngIf="mensagemRemocao">
    <p style="margin: 30px; text-align: center;">{{mensagemRemocao}}</p>
  </div>

  <div class="modal-footer" *ngIf="!atualizandoPlano">
    <button type="button" *ngIf="planoSelecionado" (click)="removerPlano()" class="btn btn-primary waves-effect waves-light" >Remover</button>
    <button type="button" *ngIf="planoSelecionado" (click)="fechar()" kendoButton [primary]="true" class="btn btn-light waves-effect">Cancelar</button>
    <button type="button" *ngIf="!planoSelecionado" (click)="fechar()" class="btn btn-primary waves-effect waves-light" >Fechar</button>
  </div>
</kendo-dialog>
