
<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSubmit()" >
  <div class="form-group mb-2" *ngIf="nomeLocal">
    <label for="descricao">Nome Local</label>
    <input kendoTextBox id="descricao" name="descricao"
           class="form-control" appAutoFocus [autoFocus]="!iframe" allowCustom="true" #descricao="ngModel"
           [(ngModel)]="endereco.descricao" required autocomplete="nope"/>

    <small id="emailHelp" class="form-text text-muted">Exemplos: Casa, Trabalho</small>

    <div class="invalid-feedback">
      <p *ngIf="descricao.errors?.required">Nome do Local é obrigatório</p>
    </div>
  </div>
  <div class="form-group mb-2 ">
    <label for="cep">CEP</label>
    <div class="input-group">
      <input [mask]="mask2" kendoTextBox #txtCEP (change)="alterou($event)" [required]="cepObrigatorio"
             type="text" class="form-control" autocomplete="off"
             id="cep" name="cep" [(ngModel)]="endereco.cep" #nome="ngModel" autocomplete="nope"
             placeholder="Informe o CEP." value=""/>


      <span class="input-group-prepend">
                <button class="btn btn-secondary" tabindex="-1" type="button" style="border-top-right-radius: 3px;border-bottom-right-radius: 0px; ">
                    <i class="fa fa-search" *ngIf="!buscandoCEP"></i>
                  <div class="k-i-loading ml-1 mr-1" style="font-size: 20px;" *ngIf="buscandoCEP" ></div>
                </button>
          </span>
    </div>
    <small id="cepHelp" class="form-text text-muted font-11">{{!cepObrigatorio ? "CEP não é obrigatório. " : ""}} Buscamos automaticamente logradouro, bairro e
      cidade através do CEP.</small>

    <div class="invalid-feedback">
      <p *ngIf="nome.errors?.required">CEP é obrigatório</p>
    </div>
  </div>
  <div class="form-group mb-2">
    <label for="logradouro">Logradouro</label>
    <input type="text" class="form-control" autocomplete="nope"
           id="logradouro" name="logradouro" [(ngModel)]="endereco.logradouro" #nome="ngModel"
           placeholder="Nome do Contato" value="" required>
    <div class="invalid-feedback">
      <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
    </div>
  </div>


  <div class="row">
    <div class="form-group mb-2 col-4">
      <label for="numero">Número</label>
      <input type="text" class="form-control" autocomplete="nope"
             id="numero" name="numero" [(ngModel)]="endereco.numero" #numero="ngModel"
             placeholder="Número" value="">
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.nomeCompleto">Informe um nome completo</p>
      </div>
    </div>

    <div class="form-group mb-2 col-8" *ngIf="zonasDeEntrega.length">
      <label>Selecione {{formaDoPedido?.selecionarBairroDaZona ? 'o bairro' : 'a região' }} onde você mora</label>
      <kendo-combobox id="zonaDeEntrega" name="zonaDeEntrega" [data]="zonasDeEntrega" placeholder="selecionar" (ngModelChange)="selecionouZona()"
                      #cboZonaDeEntrega class="form-control" appAutoFocus [autoFocus]="true"  [allowCustom]="false" [required]="true"
                      #zona="ngModel" [(ngModel)]="endereco.zonaDeEntrega" [valueField]="'id'" autocomplete="nope"
                      [textField]="'nome'">
      </kendo-combobox>
      <div class="invalid-feedback">
        <p *ngIf="zona.errors?.required">Zona de entrega é obrigatório</p>
      </div>
    </div>

    <div class="form-group mb-2 col-8"   *ngIf="!selecionarBairroDaZona">
      <label for="bairro">Bairro</label>
      <input type="text" class="form-control" autocomplete="nope"
             id="bairro" name="bairro" [(ngModel)]="endereco.bairro" #bairro="ngModel"
             placeholder="Nome do Bairro" value="" [required]="bairroObrigatorio">
      <div class="invalid-feedback">
        <p *ngIf="bairro.errors?.required">Bairro é obrigatório</p>
        <p *ngIf="bairro.errors?.nomeCompleto">Informe um nome completo</p>
      </div>
    </div>
  </div>


  <div class="form-group mb-2">
    <label for="complemento">Complemento</label>
    <input type="text" class="form-control" autocomplete="nope"
           id="complemento" name="complemento" [(ngModel)]="endereco.complemento" #complemento="ngModel"
           placeholder="Complemento quara, lote, apt etc" value="">
    <div class="invalid-feedback">
      <p *ngIf="nome.errors?.required">Complemento é obrigatório</p>
      <p *ngIf="nome.errors?.nomeCompleto">Informe o complemento do endereço</p>
    </div>
  </div>

  <div class="form-group mb-2">
    <label for="complemento">Ponto de referência</label>
    <input type="text" class="form-control" autocomplete="nope"
           name="pontoDeReferencia" [(ngModel)]="endereco.pontoDeReferencia"   #pontoDeReferencia
           placeholder="ponto de referência" value="">

  </div>


  <div class="form-group mb-2">
    <label for="estado">Estado</label>
    <kendo-combobox id="estado" name="estado" [(ngModel)]="endereco.estado" [data]="estados"
                    placeholder="Selecione um Estado" class="form-control" [textField]="'nome'"
                    required="true" #estado="ngModel"
                    (valueChange)="mudouEstado($event)">
    </kendo-combobox>
    <div class="invalid-feedback">
      <p *ngIf="nome.errors?.required">Estado é obrigatório</p>
      <p *ngIf="nome.errors?.nomeCompleto">Informe o Estado</p>
    </div>
  </div>
  <div class="form-group mb-2">
    <label for="cidade">Cidade</label>
    <kendo-combobox id="cidade" name="cidade" [(ngModel)]="endereco.cidade" [data]="cidades"
                    placeholder="Selecione uma Cidade" class="form-control" [textField]="'nome'"
                    required="true">
    </kendo-combobox>
    <div class="invalid-feedback">
      <p *ngIf="nome.errors?.required">Cidade é obrigatória</p>
      <p *ngIf="nome.errors?.nomeCompleto">Informe a cidade</p>
    </div>
  </div>
  <div class="mt-3">
    <button class="btn btn-blue btn-block" type="submit" [disabled]="!frm.dirty">{{textoBotaoSalvarEndereco}}</button>
  </div>
</form>
