import {Component, Input, OnInit} from '@angular/core';
import {PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {CadInsumoComponent} from "../cad-insumo/cad-insumo.component";
import {EstoqueService} from "../../services/estoque.service";

@Component({
  selector: 'app-listagem-insumos',
  templateUrl: './listagem-insumos.component.html',
  styleUrls: ['./listagem-insumos.component.scss']
})
export class ListagemInsumosComponent implements OnInit {
  @Input() grupos: any = [];
  @Input() insumos: any = [];
  @Input() carregando: boolean
  insumosPorGrupo = [];
  pageSize = 20;
  pageSizes: any = [10, 20, 30, 40, 50, 100]
  skip = 0;
  itemSelecionado: any;
  excluindo: boolean;
  abraDialogo: boolean;
  mensagemRemocao: string
  constructor(private estoqueService: EstoqueService,
              private dialogService: DialogService) { }

  ngOnInit(): void {
    this.insumosPorGrupo = this.agruparPorGrupo(this.insumos)
    this.carregando = !this.insumos.length;
  }

  setInsumos(insumos: any){
    this.insumos  = insumos;
    this.insumosPorGrupo = this.agruparPorGrupo(this.insumos)
    this.carregando = false;
  }

  abraModalMovimentacoes(insumo: any){
    this.abraModalInsumo(insumo, 4);
  }

  editarInsumo(row: any) {
    this.abraModalInsumo(row.dataItem)
  }

  mudouPaginacao($event: PageChangeEvent) {
    this.pageSize = $event.take;

  }

  abraModalVincularInsumo(insumo: any){
    this.abraModalInsumo(insumo, 3);
  }


  abraDialogRemoverInsumo($event: RemoveEvent){
    this.itemSelecionado = $event.dataItem;
    this.abraDialogo = true;
  }


  removaInsumo(){
    this.excluindo = true;

    this.estoqueService.removaInsumo(this.itemSelecionado).then(() => {
      this.excluindo = false;
      this.itemSelecionado.removido = true;
      this.fecheRemover();
    }).catch((err) => {
      this.excluindo = false;
      this.mensagemRemocao = err;
    });

  }

  // Função para agrupar insumos por grupo
  agruparPorGrupo = (insumos: any[]) => {
    return insumos.reduce((acc, insumo) => {
      const grupoId = insumo.grupo ? insumo.grupo.id : -1;

      if (!acc[grupoId])
        acc[grupoId] = []; // Se o grupo ainda não existe, cria um array para ele


      acc[grupoId].push(insumo); // Adiciona o insumo ao grupo

      return acc;
    }, {} as Record<number, any[]>); // Inicializa como um objeto vazio
  };

  abraModalInsumo(insumo: any, aba: number = null) {
    CadInsumoComponent.abraModalInsumo(this.dialogService, this.grupos, insumo, aba, null, (result: any) => {

    })
  }

  beneficiado(item: any){
    return item.tipo === 'produto-beneficiado'
  }


  fecheRemover(){
    delete  this.mensagemRemocao;
    delete  this.itemSelecionado;
    this.abraDialogo = false;
  }

}
