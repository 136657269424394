

<ng-container *ngIf="!adicionar">
  <h4 class="mb-2">Contatos na lista</h4>

  <kendo-grid
    [kendoGridBinding]="contatos"   (remove)="removerContato($event)"
    [loading]="carregando" [height]="500" (pageChange)="pageChange($event)"
    (scrollBottom)="loadMore()"
    footerStyle="font-size: 11px;">
    <ng-template kendoGridToolbarTemplate [position]="'top'">
      <div class="row">
          <div class="col">
            <input kendoTextBox placeholder="Busque por nome ou telefone" class="form-control busca" [(ngModel)]="filtro.texto"
                   appAutoFocus   (input)="onFilter($event)"/>
          </div>
          <div class="col" [hidden]="cupom.restritoContatoPerdido">
            <button class="btn btn-blue" (click)="adicionarContatos()">
              <i class="fe-plus mr-1"></i>
              Adicionar
            </button>

            <button class="btn btn-warning ml-2" (click)="carregueContatos()" [disabled]="carregando" title="Recarregar" kendoTooltip>
              <i class="fe-loader" ></i>
            </button>
          </div>
      </div>

    </ng-template>
    <kendo-grid-messages
      pagerPage="Página"
      pagerOf="de"
      pagerItems="itens"
      noRecords="Nenhum contato na lista ainda "
      loading="Carregando"
      pagerItemsPerPage="ítems por página"
    >
    </kendo-grid-messages>

    <kendo-grid-column    [width]="50"  >
      <ng-template kendoGridCellTemplate  let-rowIndex="rowIndex" >
         {{rowIndex + 1}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Nome"   >
      <ng-template kendoGridCellTemplate let-contato  >
        <span class="text-blue" [ngClass]="{'riscado': contato.removido}" ><b>{{contato.nome}}</b></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Telefone">
      <ng-template kendoGridCellTemplate let-contato >
        <span [ngClass]="{'riscado': contato.removido}" ><b>{{contato.telefone | tel }}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="" [width]="75">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-contato>

        <button kendoGridRemoveCommand kendoTooltip title="remover" [disabled]="contato.removendo"
                *ngIf="!contato.removido" [hidden]="cupom.restritoContatoPerdido"><i class="fa fa-trash text-danger"></i></button>


        <i class="k-i-loading k-icon" *ngIf="contato.removendo"></i>
      </ng-template>
    </kendo-grid-command-column>

  </kendo-grid>
</ng-container>


<ng-container *ngIf="adicionar">
  <h4>Contatos disponíveis</h4>

  <ng-container *ngIf="totalAdicionado > 0">
    <div class="alert alert-success alert-dismissible mt-2" role="alert">
      <i class="mdi mdi-check-all mr-2"></i>
      <b>{{totalAdicionado}}</b> contato(s) adicionados com sucesso!
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="mt-2">
        <button class="btn btn-blue" (click)="adicionarMais()">
          <i class="fe-plus mr-1"></i>
          Adicionar mais
        </button>
        <button class="btn btn-secondary ml-1" (click)="volteAdicionarContatos(true)" [disabled]="salvando">
          <i class="fe-chevron-left mr-1"></i>
          Voltar
        </button>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="!totalAdicionado">

    <div class="alert alert-danger alert-dismissible mt-2" role="alert" *ngIf="erro">
      <i class="mdi mdi-check-all mr-2"></i> {{erro}}
      <button type="button" class="close" aria-label="Close" (click)="fecheMensagemErro()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <kendo-grid #gridNovos
                [kendoGridBinding]="contatosAdicionar" [selectable]="selectableSettings"
                [(selectedKeys)]="mySelection"  kendoGridSelectBy="id"           (selectedKeysChange)="onSelectedKeysChange($event)"
                [loading]="carregando" [height]="500"

                footerStyle="font-size: 11px;">
      <ng-template kendoGridToolbarTemplate [position]="'top'">
        <div class="row">
          <div class="col">
            <input kendoTextBox placeholder="Busque por nome ou telefone" class="form-control busca" [(ngModel)]="filtro.texto"
                   appAutoFocus   (input)="onFilterDisponiveis($event)"/>
          </div>
          <div class="col">
            <button class="btn btn-success" (click)="salveNovosContatos()" [disabled]="salvando">
              <i class="fe-save mr-1"></i>
              Salvar <i class="k-icon k-i-loading" *ngIf="salvando"></i>
            </button>

            <button class="btn btn-secondary ml-2" (click)="volteAdicionarContatos()" [disabled]="salvando">
              <i class="fe-chevron-left "></i>
              Voltar
            </button>

          </div>
        </div>

      </ng-template>
      <kendo-grid-messages
        pagerPage="Página"
        pagerOf="de"
        pagerItems="itens"
        noRecords="Nenhum contato disponível "
        loading="Carregando"
        pagerItemsPerPage="ítems por página"
      >
      </kendo-grid-messages>
      <kendo-grid-checkbox-column [width]="50" [showSelectAll]="true"></kendo-grid-checkbox-column>
      <kendo-grid-column title="Nome"   >
        <ng-template kendoGridCellTemplate let-contato  >
          <span class="text-blue"><b>{{contato.nome}}</b></span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Telefone">
        <ng-template kendoGridCellTemplate let-contato >
          <span><b>{{contato.telefone | tel }}</b></span>
        </ng-template>
      </kendo-grid-column>

    </kendo-grid>
  </ng-container>


</ng-container>


