<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title">
    <img src="assets/fidelidade/icones/grupo-insumo.png" style="height: 32px;"/>
    <ng-container *ngIf="grupo.id"> Grupo {{grupo.id}} - {{grupo.nome}}</ng-container>
    <ng-container *ngIf="!grupo.id"> Novo Grupo </ng-container>
  </h4>
</kendo-dialog-titlebar>

<div   class="modal-body">

  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        novalidate #frm="ngForm" (ngSubmit)="onSalvar()" >
    <div class="row">
      <div class="form-group mb-3 col-8"  >
        <label for="nome">Nome do Grupo</label>
        <input type="text" class="form-control" autocomplete="off"
               id="nome" name="nome" [(ngModel)]="grupo.nome" #nome="ngModel"
               placeholder="Nome do Grupo" value="" required appAutoFocus [autoFocus]="true"
               (ngModelChange)="grupo.nome = $event.toUpperCase()">

        <div class="invalid-feedback">
          <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
        </div>
      </div>


    </div>

    <div class="modal-footer">

      <p  *ngIf="mensagemErro" class="text-danger ">
        <i class="mdi mdi-check-all mr-1"></i> {{mensagemErro}}
      </p>

      <button type="submit" class="btn btn-primary waves-effect waves-light"
              [disabled]="!frm.dirty || salvando"  >
        <i *ngIf="salvando" class="k-icon k-i-loading"></i>Salvar</button>
      <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
              (click)="fecheModal()">Fechar</button>
    </div>
  </form>
</div>
