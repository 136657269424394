import {Component, Input, OnInit} from '@angular/core';
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {CadGrupoInsumoComponent} from "../cad-grupo-insumo/cad-grupo-insumo.component";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";
import {EstoqueService} from "../../services/estoque.service";

@Component({
  selector: 'app-listagem-grupo-insumo',
  templateUrl: './listagem-grupo-insumo.component.html',
  styleUrls: ['./listagem-grupo-insumo.component.scss']
})
export class ListagemGrupoInsumoComponent implements OnInit {
  @Input() grupos: any = [];
  @Input() carregando: boolean;
  gruposTela: any = []
  itemSelecionado: any;
  excluindo: boolean;
  abraDialogo: boolean;
  mensagemRemocao: string
  constructor(private estoqueService: EstoqueService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.gruposTela =  this.grupos.filter((item: any) => item.id !== -1);;
  }

  abraModalGrupoInsumos(grupo: any = null){
    //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      content: CadGrupoInsumoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      cssClass: 'bsModal',
      maxHeight:  window.innerHeight - 100

    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)


    if(grupo)
      windowRef.content.instance.grupo = grupo

    windowRef.result.subscribe( (result: any) => {
        if(!grupo){
          if(result && result.id){
            this.grupos.push(result)
            this.gruposTela.push(result)
          }
        }
      },
      (a) => {      });
  }

  editarGrupo(row: any){
    this.abraModalGrupoInsumos(row.dataItem)
  }

  abraDialogRemoverGrupo($event: any){
    console.log($event)
    this.itemSelecionado = $event.dataItem;
    this.itemSelecionado.indice = $event.rowIndex;

    this.abraDialogo = true;
  }

  removaGrupo(){
    this.excluindo = true;

    this.estoqueService.removaGrupo(this.itemSelecionado).then(() => {
      this.excluindo = false;
//      this.mensagemRemocao = "O grupo '" + this.itemSelecionado.nome + "' foi removido ";
      this.gruposTela.splice(    this.itemSelecionado.indice , 1);
      this.fecheRemover();
    }).catch((err) => {
      this.excluindo = false;
      this.mensagemRemocao = err;
    });

  }


  fecheRemover(){
    delete  this.itemSelecionado;
    this.abraDialogo = false;
  }




}
