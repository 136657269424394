import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalKendo} from "../../lib/ModalKendo";
import {NgForm} from "@angular/forms";
import {EstoqueService} from "../../services/estoque.service";

@Component({
  selector: 'app-cad-grupo-insumo',
  templateUrl: './cad-grupo-insumo.component.html',
  styleUrls: ['./cad-grupo-insumo.component.scss']
})
export class CadGrupoInsumoComponent  extends ModalKendo implements OnInit {
  @ViewChild('frm', {static: true}) frm: NgForm;
  grupo: any = {};
  catalogo: any = {};
  empresa: any = {};
  salvando = false;
  mensagemErro: string;
  unidades = []
  constructor( private estoqueService: EstoqueService) {
    super()
  }

  ngOnInit(): void {

  }

  onSalvar(){
    if(this.frm.valid){
      this.salvando = true;
      this.estoqueService.salveGrupo(this.grupo).then((grupo) => {
        this.salvando = false;
        this.fecheModal(grupo);
      }).catch((err) => {
        this.mensagemErro = err
        this.salvando = false;
      })
    } else {
      this.mensagemErro = 'Verifique os campos do cadastro'
    }

  }

}
