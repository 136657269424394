<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    <i class="fa fa-file-import fa-lg mr-1"></i>  Sincronizar com catálogo modelo
  </h4>

</kendo-dialog-titlebar>

<div #pnl   class="modal-body"  >
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm"
        (ngSubmit)="carregueProdutosNovos()"
        *ngIf="buscarProdutos" >

    <div class="col-12 col-sm-7 centralizado ">
      <div class="card card-box">
        <div class="card-body">
          <h4>Sincronizar com <b>{{empresa.modeloCatalogoDaRede.catalogo.nome}}</b> </h4>
          <div class="row">
            <div class="col">
              <div class="form-group mt-2 mb-3   "  >
                <label class="mt-2 k-checkbox-label"  >
                  <input type="checkbox" id="agendarEntrega" name="agendarEntrega" class="k-checkbox " kendoCheckBox
                         [(ngModel)]="opcoes.sincronizarPrecos"/>

                  Sincronizar Preços

                </label>
              </div>
            </div>
            <div class="col">
              <div class="form-group mt-2 mb-3   "  >
                <label class="mt-2 k-checkbox-label"  >
                  <input type="checkbox" id="atualizarFotos" name="atualizarFotos" class="k-checkbox " kendoCheckBox
                         [(ngModel)]="opcoes.sincronizarImagens"
                  />

                  Atualizar Fotos

                </label>
              </div>
            </div>
          </div>

          <div class="alert alert-danger alert-dismissible mt-2" *ngIf="mensagemErro">
            <button type="button" class="close" aria-label="Close" (click)="fecheMensagemErro()">
              <span aria-hidden="true">&times;</span>
            </button>
            <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
          </div>

          <h4 *ngIf="carregandoProdutos" >
            Buscando o catálogo de produtos ...
            <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;    height: 90px;" ></div>

          </h4>

          <div class="alert alert-info alert-dismissible fade show" *ngIf="buscouProdutos && tudoSincronizado" role="alert">
            <h6>
              Não há nenhum produto novo no catálogo
            </h6>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

        </div>
      </div>
    </div>
  </form>


  <app-painel-importacao-produtos #painelImportacao [hidden]="buscarProdutos"  ></app-painel-importacao-produtos>

</div>

<div class="modal-footer"    >

  <button type="button" class="btn btn-primary waves-effect waves-light"
          [disabled]="carregandoProdutos"   *ngIf="buscarProdutos" (click)="submitFormBusca()">
    <i class="k-icon k-i-loading" *ngIf="carregandoProdutos"></i>  Buscar Produtos</button>

  <button class="btn btn-danger mr-2" *ngIf="!buscarProdutos"  (click)="painelImportacao.sincronizeTodos()"
          [disabled]="painelImportacao && painelImportacao.importacao.sincronizando"
          [hidden]="painelImportacao && painelImportacao.importacao.finalizou"   >

    <i class="k-icon k-i-loading" *ngIf="painelImportacao && painelImportacao.importacao.sincronizando"></i>
    Sincronizar todos</button>

  <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
          (click)="feche()">Cancelar</button>
</div>
