<div class="input-group mb-2">
  <input  name="multiplasFormasPagamentos" id='multiplasFormasPagamentos' kendoCheckBox    class="k-checkbox  " type="checkbox"
          [(ngModel)]="multiplasFormasPagamentos" (ngModelChange)="mudouMultiplasFormas()"/>
  <label  class="k-checkbox-label" for="multiplasFormasPagamentos">Pagar com mais de uma forma de pagamento</label>


</div>

<div *ngIf="!multiplasFormasPagamentos">
  <b>Escolha a opção de pagamento que o cliente irá utilizar</b>


  <div *ngIf="!formasDePagamentoDisponiveis().length" class="alert alert-warning mt-2">
     <i>Nenhuma forma de pagamento disponível</i>
  </div>

  <div class="form-group mb-2 mt-2 escolher">
    <ng-container *ngFor="let formaDePagamento of formasDePagamentoDisponiveis()">

        <div class="radio radio-blue mb-1"    >
          <input id="formaPagamento{{formaDePagamento.id}}" name="formaDePagamento" type="radio"
                 [(ngModel)]="formaDePagamentoEscolhida" [value]="formaDePagamento" class="k-radio" kendoRadioButton
                 (ngModelChange)="adicionePagamentoUnico()"
                 [required]="true"/>
          <label for="formaPagamento{{formaDePagamento.id}}" class="ml-1">{{formaDePagamento.descricao}}
           <span *ngIf="formaDePagamento.taxaCobranca">
             (<i>+{{formaDePagamento.taxaCobranca.percentual}}% taxa </i>)
           </span>

            <span *ngIf="formaDePagamento.desconto">
             (<i>-{{formaDePagamento.desconto}}% desconto</i>)
           </span>

          </label>
        </div>

    </ng-container>

    <div class="invalid-feedback" *ngIf="!formaDePagamentoEscolhida">
      <p>Escolha uma forma de pagamento</p>
    </div>

  </div>

  <div class="row" *ngIf="pagarEmDinheiro()">
    <div class="col-12">
      <label>Vai precisar de troco?</label>

      <div class="radio form-group escolher troco">
            <span [ngClass]="{'selecionado': temTroco==='sim' }">
              <input id="trocoSim" name="temTroco" type="radio"    kendoRadioButton
                     [(ngModel)]="temTroco" value="sim"  required   />
              <label for="trocoSim" class="ml-1">&nbsp;Sim</label>
            </span>

        <span [ngClass]="{'selecionado':  temTroco=== 'nao' }">
                    <input id="trocoNao" name="temTroco" type="radio"    kendoRadioButton
                           [(ngModel)]="temTroco" value="nao" required
                           [required]="pagarEmDinheiro()" (ngModelChange)="mudouOpcaoParaSemTroco()"/>
                    <label for="trocoNao" class="ml-1">&nbsp;Não</label>

            </span>
        <div class="invalid-feedback" *ngIf="!temTroco">
          informe se vai precisar de troco.
        </div>
      </div>

    </div>

    <div class="col-12" *ngIf="temTroco==='sim'">
      <div class="form-group col col-sm-6 col-lg-4">
        <label >Levar Troco Para:</label>
        <kendo-numerictextbox name="trocoPara"  #trocoParatxt="ngModel"  format="c2" appRemovaWheel  class="form-control"
                              [(ngModel)]="trocoPara" [min]="totalPagar"  (ngModelChange)="calculeTroco()"  >
        </kendo-numerictextbox>

        <div class="invalid-feedback">
          <p *ngIf="trocoParatxt.errors?.minError">
            A nota tem que ser maior que  {{totalPagar | currency: "R$"}}</p>
        </div>
      </div>

      <div class="form-group col-12" *ngIf="pagarEmDinheiro()">
        <label>Troco</label>
        <div class="mt-0 font-22 valor">{{troco | currency: 'BRL' }}</div>
      </div>
    </div>
  </div>
</div>

<div class="" *ngIf="multiplasFormasPagamentos">
  <div class="card-header">
    <b>Escolha a opção de pagamento que o cliente irá utilizar</b>
  </div>
  <div class="form-group mb-2 escolher">
    <kendo-grid
      #grdFormasDePagamento
      [data]="formasDePagamentoDisponiveis()"
      [selectable]="selectableSettings"
      [navigable]="true"
      [hideHeader]="true"
      [kendoGridSelectBy]="'id'"
      [(selectedKeys)]="mySelection"
      [height]="150"
      (selectionChange)="selecionouForma($event)"
    >
      <kendo-grid-column class="forma_de_pagamento"
                         title="Descricao">

        <ng-template kendoGridCellTemplate let-formaDePagamento let-rowIndex="rowIndex">
          {{formaDePagamento.descricao}}
          <span *ngIf="formaDePagamento.taxaCobranca">
             (<i>+{{formaDePagamento.taxaCobranca.percentual}}% taxa </i>)
           </span>

        </ng-template>

      </kendo-grid-column>
    </kendo-grid>

    <div class="mt-3">
      <div class="" *ngIf="formaDePagamentoEscolhida.id">
        Valor Pago: <b>{{formaDePagamentoEscolhida.descricao}}</b>
      </div>
      <div class="mt-2">
        <kendo-numerictextbox name="valorFormaDePagamento"   format="c2" appRemovaWheel style="width: 12em" class="form-control"
                              [(ngModel)]="formaDePagamentoEscolhida.valor"  (valueChange)="calculeTroco()"
                              [min]="0">
        </kendo-numerictextbox>
        <button class="btn btn-primary ml-2" type="button"
                (click)="adicioneFormaDePagamento()">Adicionar Pagamento</button>

        <label class="ml-2">Faltam: </label>
        <span class="ml-1 mt-0 mb-1 font-24 valor" *ngIf="obtenhaValorRestante() > 0">
                        {{obtenhaValorRestante() | currency: 'BRL' }}</span>
        <span class="ml-1 mt-0 mb-1 font-24 valor" *ngIf="obtenhaValorRestante() === 0"><i class="fas fa-check-circle"></i></span>

        <label class="ml-2">Troco: </label>
        <span class="ml-1 mt-0 mb-1 font-24 valor">{{troco | currency: 'BRL' }}</span>
      </div>
      <div>
        <h6 class="alert alert-danger d-block" style="padding: 5px;" *ngIf="msgErroAdicionar">
          <i class="fas fa-exclamation-triangle fal-lg ml-1 mr-1 "></i>
          {{msgErroAdicionar}}
        </h6>
      </div>
    </div>
    <div class="invalid-feedback" *ngIf="!pagamentos.length">
      <p>Escolha a forma de pagamento do seu pedido</p>
    </div>
  </div>

  <h4>Pagamentos Escolhidos</h4>
  <kendo-grid
    [data]="pagamentos"
    [navigable]="true"
  >
    <kendo-grid-column  title="Descricao"  >
      <ng-template kendoGridCellTemplate let-pagamento >
        <span [ngClass]="{'riscado text-muted': pagamento.removido}">{{pagamento.formaDePagamento.descricao}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="forma_de_pagamento"
                       field="valor"
                       title="Valor">
      <ng-template kendoGridCellTemplate let-pagamento >
        <span [ngClass]="{'riscado text-muted': pagamento.removido}">

          {{pagamento.obtenhaValorSemTaxa() | currency: "BRL"}}</span>

        <span *ngIf="pagamento.taxa"> <i> +{{pagamento.taxa | currency: "BRL"}}  taxa </i></span>
        <span *ngIf="pagamento.desconto"> <i> -{{pagamento.desconto | currency: "BRL"}}  desconto </i></span>


        <span *ngIf="pagamento.trocoPara && pagamento.valor != pagamento.trocoPara" class="ml-1" [hidden]="!pagamento.valor">
          troco para {{pagamento.trocoPara | currency: "BRL"}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column    [width]="140">
      <ng-template kendoGridCellTemplate let-pagamento let-rowIndex="rowIndex">
        <button type="button" class="btn btn-sm btn-secondary"
                [hidden]="pagamento.removido || (pagamento.id && pagamento.formaDePagamento.nome === 'cashback')"
                (click)="editarPagamento(pagamento)">Editar</button>

        <button type="button" class="btn btn-sm btn-danger ml-1"  [hidden]="pagamento.removido"
                (click)="removaPagamento(pagamento)"><i class="fas fa-trash"></i></button>

        <button   type="button" class="btn btn-sm btn-warning ml-1"
                  (click)="desremoverPagamento(pagamento)" *ngIf="pagamento.removido">
          <i class="fas fa-undo"></i>
        </button>
      </ng-template>

    </kendo-grid-column>
  </kendo-grid>
</div>
