import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ProdutoService} from "../../../services/produto.service";
import {ConstantsService} from "../../../fidelidade/ConstantsService";
import {AssociarInsumoComponent} from "../../cad-produto/associar-insumo/associar-insumo.component";

@Component({
  selector: 'app-form-opcaoadicional',
  templateUrl: './form-cadastro-opcaoadicional.component.html',
  styleUrls: ['./form-cadastro-opcaoadicional.component.scss']
})
export class FormCadastroOpcaoadicionalComponent implements OnInit {
  @Input() catalogo: any;
  @Input() podeEditar = true;
  @Input() podeEditarPrecos  = true;
  @Input() pizzaria = false;
  @Input() produto: any = {};
  @Input() adicionalDeProduto: any = {};
  @Input()   templatesPiza: any = [];
  @Input()   sistemaIntegrado: any = [];
  @Output() onSalvarOpcao = new EventEmitter();
  @Output() onFechouModal = new EventEmitter();
  @ViewChild('telaInsumoAssociado', {static: false}) telaInsumoAssociado: AssociarInsumoComponent;
  private novaOpcao: boolean;
  novasOpcoes: any = [];
  private opcaoEditada: any;
  opcaoDeAdicional: any = {};
  selecionarProduto: boolean;
  tipoProduto: string;
  filtro: any = {}
  produtosAdicionar = []
  selectableSettings: any = {
    checkboxOnly: false,
    mode: 'multiple'
  };
  carregandoProdutos = false;
  produtosSelecionados:  number[] = [];
  erroAdicionarProduto
  mensagemSemProdutos = 'Nenhum produto disponível';
  private timerBusca;
  TIPOSDEPRODUTOS = {
    Normal: 'normal',
    Pizza: 'pizza'
  }
  salvando: boolean;
  erroSalvar: string;
  temModuloEstoque: boolean;

  constructor(private produtoService: ProdutoService ,   private constantsService: ConstantsService) { }

  ngOnInit(): void {
    this.constantsService.moduloEstoque$.subscribe( data => this.temModuloEstoque  = data  )
  }

  setNovaOpcao(){
    this.novaOpcao = true;
    this.opcaoDeAdicional = { nova: true, disponivel: true}

    this.selecionarProduto = this.adicionalDeProduto.combo;
    if( !this.pizzaria)
      this.carregueProdutosDisponiveis();
  }

  setEditarOpcao(opcao: any) {
    this.opcaoDeAdicional = {    }
    this.opcaoEditada = opcao.dataItem

    Object.assign(this.opcaoDeAdicional, this.opcaoEditada)

    if(this.opcaoDeAdicional.insumo) this.opcaoDeAdicional.controlarEstoque = true;

    if(this.opcaoDeAdicional.dependencias && this.opcaoDeAdicional.dependencias.length){
      let idAdicional = this.opcaoDeAdicional.dependencias[0].adicionalId;
      this.opcaoDeAdicional.depende = true;
      this.opcaoDeAdicional.adicionalDependente =  this.produto.camposAdicionais.find( (adicional: any) => adicional.id === idAdicional);
      this.opcaoDeAdicional.opcoesDependentes =
        this.opcaoDeAdicional.adicionalDependente.opcoesDisponiveis.filter( (opcaoAdicional: any) => {
          return this.opcaoDeAdicional.dependencias.find( dependencia => dependencia.opcaoDependente.id === opcaoAdicional.id)
        })
    }


    this.novaOpcao = false;
  }

  escolheuTemplate(tipoProduto, template = null) {
    this.tipoProduto = tipoProduto;
    this.filtro.template = template;
    this.filtro.tamanho = template ?  template.tamanhos[0] : null

    this.carregueProdutosDisponiveis();
  }

  async salvarOpcaoDeAdicional(form){
    if(form.valid){
      if(this.telaInsumoAssociado)
        this.telaInsumoAssociado.setInsumoDesvinculado();
      //ja salvar no server
      if(this.adicionalDeProduto.id){
        delete this.erroSalvar;
        this.salvando = true;
        await this.produtoService.salveOpcaoAdicional(this.catalogo, this.produto,
          this.adicionalDeProduto, this.opcaoDeAdicional).catch((err) => {
          this.erroSalvar = err;
        });
        this.salvando =  false;
      }

      if(this.erroSalvar) return false;

      if(this.novaOpcao) {
        this.notifiqueNovasOpcoes(this.novasOpcoes.length ? this.novasOpcoes :  [this.opcaoDeAdicional])
      } else{
        Object.assign(this.opcaoEditada , this.opcaoDeAdicional)
        if(this.telaInsumoAssociado) {
          this.telaInsumoAssociado.salvou();
          if(!this.opcaoDeAdicional.insumo)
            this.opcaoEditada.insumo = null;
        }
        this.onSalvarOpcao.emit([])
      }
    }

    return false;
  }

  notifiqueNovasOpcoes(novasOpcoes: any){
    this.onSalvarOpcao.emit(novasOpcoes)
  }


  public carregueProdutosDisponiveis(filtro: string = null) {
    this.produtosAdicionar = [];
    this.carregandoProdutos   = true;

    let tipo =  this.filtro.template ? 'Pizza' : 'normal'

    this.produtoService.liste(this.catalogo.id, true, 0 , 50, filtro,
      this.filtro.categoria, null, null,null ,
      tipo, this.filtro.tamanho, this.obtenhaIdsExistente() ).then( (resposta: any) => {
      this.carregandoProdutos = false;
      this.produtosAdicionar = resposta.produtos;
    } ).catch((err) => {
      this.carregandoProdutos = false;
      alert(err)
    })
  }

  obtenhaAdicionaisProdutos(adicionalDeProduto: any) {
    return  this.produto.camposAdicionais.filter( (adicional: any) => adicional.id !== adicionalDeProduto.id);
  }

  obtenhaOpcoesDoAdicional(){
    if(!this.opcaoDeAdicional.adicionalDependente) return []

    return   this.opcaoDeAdicional.adicionalDependente.opcoesDisponiveis;
  }

  alterouOpcao() {
    let dependencias = [];

    this.opcaoDeAdicional.opcoesDependentes.forEach( (opcao) => {
      dependencias.push({adicionalId: this.opcaoDeAdicional.adicionalDependente.id,
        opcaoDependente: opcao});
    })


    this.opcaoDeAdicional.dependencias =  dependencias;
  }

  obtenhaTamanhoProduto(produto: any){
    if(this.filtro.tamanho)
      return  produto.tamanhos.find((tamanho: any) => tamanho.template.id ===  this.filtro.tamanho.id)

    return {}
  }

  crieOpcaoDoProduto(produto: any){
    let opcaoDeAdicional: any = {
      nova: true,
      nome: produto.nome,
      descricao: produto.descricao,
      codigoPdv: produto.codigoPdv,
      disponivel: true,
      valor: produto.novoPreco || produto.preco
    }

    opcaoDeAdicional.produto = { id: produto.id};
    opcaoDeAdicional.idProduto =  produto.id;

    if(this.filtro.tamanho){
      let produtoTamanho = this.obtenhaTamanhoProduto(produto);

      opcaoDeAdicional.valor =  produtoTamanho.novoPreco || produtoTamanho.preco;
      opcaoDeAdicional.nome = String(`${opcaoDeAdicional.nome} - ${produtoTamanho.descricao}`);
      opcaoDeAdicional.codigoPdv = produtoTamanho.codigoPdv;

      opcaoDeAdicional.tamanho = {id:  produtoTamanho.id};
      opcaoDeAdicional.idTamanho = produtoTamanho.id;
    }


    if(produto.imagens && produto.imagens.length)
      opcaoDeAdicional.linkImagem =  produto.imagens[0].linkImagem;

    return opcaoDeAdicional;
  }

  adicionarProdutosComoOpcao(){
    delete this.erroAdicionarProduto;

    if( this.produtosSelecionados.length){
      this.novasOpcoes = [];
      this.produtosSelecionados.forEach((idProduto: number) => {
        let produtoAdicionar: any = this.produtosAdicionar.find((produto: any) => produto.id === idProduto)

        if(produtoAdicionar){
          this.novasOpcoes.push(this.crieOpcaoDoProduto(produtoAdicionar))
        } else {
          this.erroAdicionarProduto = 'Produto não encontrado: ' + this.produtosSelecionados.join(', ')
        }
      })

      if(this.novasOpcoes.length === 1){ // exibir tela modo adicionar 1 opçao apenas
        this.opcaoDeAdicional = this.novasOpcoes[0];
        this.novasOpcoes = [];
      }

      if(!this.erroAdicionarProduto)
        this.selecionarProduto = false;

    } else {
      this.erroAdicionarProduto = 'Selecione um produto na grid'
    }
  }

  carregueTemplates(){

  }


  selecionouProduto(e: any){

  }

  onFilterProdutos(event: any) {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.carregueProdutosDisponiveis(event.target.value)
    }, 1000)
  }



  alterouTamanho(tamanho: any){
    this.carregueProdutosDisponiveis();
  }

  fecharDialogOpcao() {
    this.opcaoDeAdicional = null;
    this.onFechouModal.emit({})
  }


  private obtenhaIdsExistente() {
     let opcoesDeProduto =  this.adicionalDeProduto.opcoesDisponiveis.filter(  (opcao: any) => opcao.idProduto) ;

     if(this.filtro.tamanho)
       opcoesDeProduto = opcoesDeProduto.filter(
         (opcao: any) => opcao.produtoTamanho && opcao.produtoTamanho.idTamanho === this.filtro.tamanho.id)


      return opcoesDeProduto.map((opcao: any) => opcao.idProduto)
  }

  zereTodos() {
    this.novasOpcoes.forEach((novaOpcao) => {
       novaOpcao.valor = 0;
    })
  }

  obtenhaFormatoEstoque(insumo: any) {
    if(!insumo.unidadeMedida || insumo.unidadeMedida.sigla === 'und')
      return '0';

    let sigla: string = insumo.unidadeMedida.sigla;

    if( sigla === 'g' || sigla === 'ml')
      return String(`0 ${sigla}`);

    return String(`0.00 ${sigla}`);
  }
}
