<h4   class="page-title" >
  <i class="fa fa-times-circle"></i> Pedidos nao importados com erros</h4>



<div class="card">
  <div class="card-body">

    <kendo-tabstrip class="nav-bordered mt-3" >
      <kendo-tabstrip-tab [title]="'Historico erros'" [selected]="true">
        <ng-template kendoTabContent>
          <kendo-grid (detailExpand)="abriuErro($event)" #gridErrosNovos="kendoGrid"
                      [kendoGridBinding]="historicoErros" [pageSize]="this.pageSize"
                      [pageable]=" true"
                      [sortable]="true"
                      (pageChange)="onPageChange($event)"
                      [loading]="carregando" style="min-height: 300px;">
            <kendo-grid-messages
              pagerPage="Página"
              pagerOf="de"
              pagerItems="itens"
              noRecords="nenhum erro de importação novo"
              loading="Carregando"
              pagerItemsPerPage="ítems por página"
            >
            </kendo-grid-messages>

            <kendo-grid-column title="Id" field="id"  [width]="50">
              <ng-template kendoGridCellTemplate let-item >
                <span  ><b>{{item.id}}</b></span>  </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Sistema" field="sistema"    [width]="120"   > </kendo-grid-column>
            <kendo-grid-column title="Codigo"  [width]="350"  >
              <ng-template kendoGridCellTemplate let-item >
                <span class="text-blue">{{item.payload.id}}</span>  </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Data" field="horario"    [width]="150"   >
              <ng-template kendoGridCellTemplate let-item >
               <span class="text-muted">  {{item.horario | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Erro" field="erro"    >
              <ng-template kendoGridCellTemplate let-item >
              <b> {{item.erro.operacao}}                                                                                                                                                            </b>  -  <span class="text-danger"><b>{{item.erro.descricao}}</b></span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Detalhes"     [width]="170" >
              <ng-template kendoGridCellTemplate let-item >
                <a href="#" class="text-primary" (click)="verJson(item, $event)"> Ver json pedido</a>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Açoes"     [width]="120" *ngIf="exibirReimportar()">
              <ng-template kendoGridCellTemplate let-item >
                <button type="button" class="btn btn-xs  btn-light ml-1" (click)="reimportePedido(item)"
                        kendoTooltip title="reimportar pedido"  [hidden]="item.integrado"
                        [disabled]="item.importando"   >
                  <i class="fa fa-sync" *ngIf="!item.importando"></i>
                  <i class="k-icon k-i-loading"     *ngIf="item.importando"></i>
                </button>

                <a routerLink="/admin/pedido/{{item.pedido.guid}}" class="btn btn-primary" *ngIf="item.integrado">
                  Ver pedido</a>
              </ng-template>

            </kendo-grid-column>
          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>

    </kendo-tabstrip>
  </div>
</div>
