<div>
  <h3>Painel do SDR</h3>

  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        novalidate #frm="ngForm">
    <div class="alert alert-success" role="alert" *ngIf="msgSucesso">
      <strong>{{msgSucesso}}</strong>
    </div>
    <div class="row">
      <div class="form-group mb-2 col">
        <input id="naoGerarComanda" name="naoGerarComanda" type="checkbox"    kendoCheckBox [(ngModel)]="usuario.assinarMensagens"  class="k-checkbox" (ngModelChange)="salveAlteracao()"/>
        <label for="naoGerarComanda" class="ml-1"  >
          Assinar Mensagens
        </label>
      </div>
    </div>

    <div class="row" *ngIf="usuario.assinarMensagens">
      <div class="form-group mb-2 col">
        <label>Assinatura das Mensagens</label>
        <div><b>{{usuario?.nome}}</b></div>
      </div>
    </div>
    <div>
      As mensagems enviadas terão o seguinte formato:
      <br>
      <div class="card">
        <div class="card-body" style="padding: 6px 7px 8px 9px">
          <b *ngIf="assinatura">{{assinatura}}:<br></b>
          <span>
            Olá
          </span>
        </div>
      </div>
    </div>
  </form>

  <div>
    {{msgErroMarcarLabel}}
    <button class="btn btn-primary" (click)="marcarLabel()">Marcar Lead como Meu</button>
  </div>
  <div>
  </div>
</div>
