import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AutorizacaoService} from "../services/autorizacao.service";
import {EnumOperacaoSistema} from "../../../server/lib/permissao/EnumOperacaoSistema";
import {Usuario} from "../../../server/domain/Usuario";
import { map, filter, take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthLojaGuard implements CanActivate {
  constructor(private router: Router, private autorizacaoService: AutorizacaoService) {

  }
  canActivate( route: ActivatedRouteSnapshot,
               state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.autorizacaoService.fetchUser().pipe(
      map(user => {
        if (RotaGuardClient.gerenciaLoja(user)) {
          return true;
        } else {
          this.router.navigate(['/admin/login']);
          return false;
        }
      })
    );
  }
}


@Injectable({
  providedIn: 'root'
})
export class AuthProdutosGuard implements CanActivate {
  constructor(private router: Router, private autorizacaoService: AutorizacaoService) {

  }
  canActivate( route: ActivatedRouteSnapshot,
               state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    return this.autorizacaoService.fetchUser().pipe(
      map(user => {
        if (RotaGuardClient.cadastrarProdutos(user)) {
          return true;
        } else {
          this.router.navigate(['/admin/login']);
          return false;
        }
      })
    );
  }

}


@Injectable({
  providedIn: 'root'
})
export class AuthEstoqueGuard implements CanActivate {
  constructor(private router: Router, private autorizacaoService: AutorizacaoService) {

  }
  canActivate( route: ActivatedRouteSnapshot,
               state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    return this.autorizacaoService.fetchUser().pipe(
      map(user => {
        if (RotaGuardClient.gerenciarEstoque(user)) {
          return true;
        } else {
          this.router.navigate(['/admin/login']);
          return false;
        }
      })
    );
  }

}

@Injectable({
  providedIn: 'root'
})
export class AuthNoticacoesGuard implements CanActivate {
  constructor(private router: Router, private autorizacaoService: AutorizacaoService) {

  }
  canActivate( route: ActivatedRouteSnapshot,
               state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.autorizacaoService.fetchUser().pipe(
      map(user => {
        if (RotaGuardClient.cadastrarNotificacoes(user)) {
          return true;
        } else {
          this.router.navigate(['/admin/login']);
          return false;
        }
      })
    );
  }

}

@Injectable({
  providedIn: 'root'
})
export class AuthContatosGuard implements CanActivate {
  constructor(private router: Router, private autorizacaoService: AutorizacaoService) {

  }
  canActivate( route: ActivatedRouteSnapshot,
               state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    return this.autorizacaoService.fetchUser().pipe(
      map(user => {
        if (RotaGuardClient.cadastrarContatos(user)) {
          return true;
        } else {
          this.router.navigate(['/admin/login']);
          return false;
        }
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthPedidosGuard implements CanActivate {
  constructor(private router: Router, private autorizacaoService: AutorizacaoService) {

  }
  canActivate( route: ActivatedRouteSnapshot,
               state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.autorizacaoService.fetchUser().pipe(
      map(user => {
        if (RotaGuardClient.editarPedido(user)) {
          return true;
        } else {
          this.router.navigate(['/admin/login']);
          return false;
        }
      })
    );

  }

}


@Injectable({
  providedIn: 'root'
})
export class AuthSuperAdminGuard implements CanActivate {
  constructor(private router: Router, private autorizacaoService: AutorizacaoService) {

  }
  canActivate( route: ActivatedRouteSnapshot,
               state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.autorizacaoService.fetchUser().pipe(
      map(user => {
        if (user && user.admin) {
          return true;
        } else {
          return false;
        }
      })
    );

  }

}

export class RotaGuardClient{

  static alterarPedido(usuario){
    if(usuario && usuario.operador) return true;
    return   RotaGuardClient.temUmaDasPermissoes(usuario, [EnumOperacaoSistema.CancelarPedido, EnumOperacaoSistema.AterarStatusPedido]);
  }

  static cancelarPedido(usuario: any)
  {
    //login antigos setados como operador ainda
    if(usuario && usuario.operador) return true;
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.CancelarPedido)
  }

  static alterarStatusPedido(usuario: any) {
    //login antigos setados como operador ainda
    if(usuario && usuario.operador) return true;
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.AterarStatusPedido)
  }

  static editarPedido(usuario: any){
    //login antigos setados como operador ainda
    if(usuario && usuario.operador) return true;
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.EditarPedido)
  }

  static adicionarPontosFidelidade(usuario: any){
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.PontuarFidelidadeManual)
  }

  static resgatarBrindesFidelidade(usuario: any){
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.TrocarBrindesFidelidadeManual)
  }


  static gerenciaLoja(usuario: any){
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.GerenciarLoja)
  }

  static cadastrarProdutos(usuario: any){
    //login antigos setados como operador ainda
    if(usuario && usuario.operador) return true;
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.CadastrarProduto)
  }

  static gerenciarEstoque(usuario: any){
    //login antigos setados como operador ainda
    if(usuario && usuario.operador) return true;
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.GerenciarEstoque)
  }

  static cadastrarNotificacoes(usuario: any){
    //login antigos setados como operador ainda
    if(usuario && usuario.operador) return true;
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.CadastrarNotificacoes)
  }

  static operarCaixa(usuario: any) {
    if(usuario && usuario.operador) return true;
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.OperarCaixa)
  }


  static cadastrarContatos(usuario: any){
    //login antigos setados como operador ainda
    if(usuario && usuario.operador) return true;
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.CadastrarContatos)
  }

  static alterarPrecoCardapioCompartilhado(usuario: any) {
    if( usuario && (usuario.admin || usuario.adminRede)) return true;
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.AlterarPrecoCardapioCompartilhado)
  }


  static visualizaRelatorios(usuario: any){
    return RotaGuardClient.temPermissaoOperacao(usuario, EnumOperacaoSistema.VisulizarVendas)
  }

  static temUmaDasPermissoes(usuario: Usuario, codigosOperacoes: Array<number>){
    let temUmaPermissao = false;

    codigosOperacoes.forEach((operacao) => {
     if( RotaGuardClient.temPermissaoOperacao(usuario, operacao))
       temUmaPermissao = true;
    })

    return temUmaPermissao;
  }

  static temPermissaoOperacao(usuario: any, codigoOperacao: number){
    if(usuario){
      if(usuario.admin || usuario.adminRede) return  true;

      if(usuario.papeis){
        let temPapel = usuario.papeis.find((papel: any) => {
          let temOperacao = papel.operacoes.find((operacao: any) => operacao.id === codigoOperacao) != null

          return temOperacao;
        })  != null

        return temPapel;
      } else {
        //login antigos setados  papeis ainda
        if(usuario.adminRede) return true;
      }
    }

    return false;
  }

}
