<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  &nbsp; <i class="fab fa-whatsapp"></i>&nbsp;
  <span *ngIf="!campanha.id">Nova Campanha</span>
  <span *ngIf="campanha.id">Editar Campanha</span>
</h4>

<kendo-tabstrip class="nav-bordered">
  <kendo-tabstrip-tab id="tabPasso1" [title]="'Dados Da Campanha'" [selected]="true">
    <ng-template kendoTabContent>
    <form (ngSubmit)="concluiuPasso1()">
      <div class="form-group mb-3">
        <label for="nome">Nome da Campanha</label>
        <input type="text" class="form-control" autocomplete="off"
               id="nome" name="nome" [(ngModel)]="campanha.nome" #nome="ngModel"
               placeholder="Nome da Campanha" value="" required appAutoFocus [autoFocus]="true">
        <div class="invalid-feedback">
          <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
        </div>
      </div>
      <div class="form-group mb-3"  *ngIf="this.rede === 'chinainbox'" >
        <label for="nome">Essa campanha vale para quais redes?</label>
        <kendo-multiselect class="form-control"
          [data]="listItems" [valueField]="'id'" [textField]="'nome'"
          [(ngModel)]="campanha.redes"
        ></kendo-multiselect>
        <div class="invalid-feedback">
          <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
        </div>
      </div>
      <div class="form-group mb-3">
        <label for="nome">Tipo de Envio</label>
        <div class="mt-1 mb-1" style="width:250px;">
          <label class="k-radio-label mr-1" for="tipoDeEnvio1">
            <input type="radio" name="sexo" id="tipoDeEnvio1" class="k-radio right" value="Unico" [(ngModel)]="campanha.tipoDeEnvio" required kendoRadioButton/>
            Único
          </label>
          <label class="k-radio-label" for="tipoDeEnvio2">
            <input type="radio" name="sexo" id="tipoDeEnvio2" class="k-radio right ml-2" value="Agendado" [(ngModel)]="campanha.tipoDeEnvio" required kendoRadioButton/>
            Agendado
          </label>

          <div class="invalid-feedback"  >
            Tipo de campanha é obrigatório
          </div>
        </div>
      </div>

      <div class="p-2 rounded">
        <div class="form-group mb-3 mt-2" style="position: relative;">
          <div style="max-width: 400px">
            <app-upload-imagem [objeto]="campanha"></app-upload-imagem>
          </div>
        </div>
      </div>

      <div class="form-group mb-3" *ngIf="campanha.tipoDeEnvio === 'Agendado'">
        <label for="nome">Enviar campanha no horário</label>
        <div class="mt-1 mb-1" style="width:250px;">
          <kendo-datetimepicker class="form-control" id="horarioEnvio" name="horarioEnvio"
                                [min]="agora"
                                [steps]="steps" [(ngModel)]="campanha.horarioEnvio">
          </kendo-datetimepicker>
          <div class="invalid-feedback"  >
            Horário é obrigatório
          </div>
        </div>
      </div>

      <div class="form-group mb-3 mt-2" style="position: relative;">
        <label for="txtMensagem">Mensagem</label>
        <div>
          <div style="position: absolute;right: 0px;top: -13px;">
            <kendo-dropdownbutton [data]="campos" buttonClass="btn btn-azul" textField="nome" (itemClick)="adicioneCampo(textArea, campanha, $event)" >
              Adicionar Campo <i class="mdi mdi-chevron-down"></i>
            </kendo-dropdownbutton>
          </div>

          <textarea class="form-control" id="txtMensagem" rows="10" name="txtMensagem" [(ngModel)]="campanha.mensagem" #txtMensagem="ngModel" #textArea
                    required (ngModelChange)="alterouMensagem(campanha)" maxlength="1000"></textarea>
          <div>
            <span [ngClass]="{'text-warning': qtdeCaracteresRestantes < 50, 'text-success': qtdeCaracteresRestantes >= 50, 'text-danger': qtdeCaracteresRestantes < 20}">{{qtdeCaracteresRestantes}} caracteres restantes</span>
          </div>
          <div *ngIf="txtMensagem.invalid && (txtMensagem.dirty || txtMensagem.touched)" class="invalid-feedback">
            <div *ngIf="txtMensagem.errors.required">
              Informe uma mensagem que será enviada ao cliente.
            </div>
          </div>
        </div>
      </div>

      <div class="row d-flex align-content-center mt-1 mb-5 mx-auto">
        <button class="btn btn-lg btn-primary" (click)="apenasSalvar()" [disabled]="salvando">
          Salvar
        </button>
        <button class="btn btn-lg btn-danger ml-2" (click)="voltar()" [disabled]="salvando">
          Cancelar
        </button>
      </div>
    </form>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

