import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {NotificacaoAppService} from "../../../services/notificacao-app.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ConstantsService} from "../../../fidelidade/ConstantsService";

@Component({
  selector: 'app-nova-campanha-app',
  templateUrl: './nova-campanha-app.component.html',
  styleUrls: ['./nova-campanha-app.component.scss']
})
export class NovaCampanhaAppComponent implements OnInit {
  campanha: any = {};
  qtdeCaracteresRestantes = 160;
  QTDE_MAXIMA_CARACTERES = 160;
  @ViewChild("frm") frm: NgForm;
  mensagemSucesso = '';
  mensagemErro = '';
  salvando: boolean;
  empresa = {
    dominio: ''
  };
  linkEstaCorreto: boolean;

  constructor(private router: Router, private notificacaoAppService: NotificacaoAppService,
              private activatedRoute: ActivatedRoute, private constantsService: ConstantsService) {

  }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) { return; }

      this.empresa = empresa;
    });

    this.activatedRoute.params.subscribe(params => {
      let id = +params['idc']; // (+) converts string 'id' to a number
      if( id ) {
        this.notificacaoAppService.obtenhaNotificacao(id).then((resposta: any) => {
          this.campanha = resposta;
        });
      }
    });
  }

  voltar() {
    this.router.navigateByUrl('/admin/marketing-app').then( () => {});
  }

  adicioneNomeContato(textArea, notificacao: any) {
    const posicao = this.getCaretPos(textArea);

    notificacao.mensagem = notificacao.mensagem.substr(0, posicao) + '[Nome_Contato]' + notificacao.mensagem.substr(posicao);
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart === '0') {
      return oField.selectionStart;
    }

    return 0;
  }

  setCaretPosition(elem, caretPos) {
    if (elem !== null) {
      if (elem.createTextRange) {
        let range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
      } else {
        if (elem.setSelectionRange) {
          elem.focus();
          elem.setSelectionRange(caretPos, caretPos);
        } else {
          elem.focus();
        }
      }
    }
  }

  adicioneCampo(textArea, campanha: any, campo: any) {
    const posicao = this.getCaretPos(textArea);

    campanha.mensagem = campanha.mensagem.substr(0, posicao) + campo.texto + campanha.mensagem.substr(posicao);

    textArea.focus();

    setTimeout( () => {
      textArea.setSelectionRange(posicao + campo.texto.length, posicao + campo.texto.length);
    }, 50);
  }

  alterouMensagem(notificacao: any) {
    // tslint:disable-next-line:max-line-length
    const expressaoRegularURL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;

    let TAMANHO_URL_ENCURTADA = 20;
    let qtdARemover = 0;
    let qtdAAdicionar = 0;

    //tratando links detectados
    const urls = notificacao.mensagem.match(expressaoRegularURL);

    if(urls) {
      for(let i = 0; i < urls.length; i++)
        qtdARemover += urls[i].length;

      qtdAAdicionar = urls.length * TAMANHO_URL_ENCURTADA;

      console.log("Qtd a remover: " + qtdARemover);
      console.log("Qtd a adicionar: " + qtdAAdicionar);
    }

    //tratando campos detectados
    const reg = /\[(.*?)\]/g;

    let result;
    while ((result = reg.exec(notificacao.mensagem)) !== null) {
      const campo: string = result[0];

      if ( campo === '[NomeContato]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if ( campo === '[LinkCartao]' ) {
        qtdARemover +=  campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      } else if ( campo === '[Empresa]' ) {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      }
    }

    this.qtdeCaracteresRestantes = this.QTDE_MAXIMA_CARACTERES - notificacao.mensagem.length + qtdARemover - qtdAAdicionar;
  }

  salvarNotificacao() {
    this.mensagemErro = '';
    if( !this.campanha.foiTestada ) {
      this.mensagemErro = 'Você deve testar o link para ter certeza que ele está correto. Clique no botão Testar link.';
      return;
    }

    if( !this.frm.valid ) {
      return;
    }

    this.salvando = true;
    this.notificacaoAppService.salveNotificacaoApp(this.campanha).then( (resp: any) => {
      this.campanha.id = resp.id;
      this.mensagemSucesso = 'Notificação criada com sucesso criado!';
      this.salvando = false;
      this.router.navigateByUrl('/admin/marketing-app').then( () => {});
    });
  }

  trocouImagem(imagem: any) {
    this.campanha.urlImagem = imagem;
  }

  testeLink(campanha: any) {
    window.open(campanha.link);

    setTimeout( () => {
      this.linkEstaCorreto = true;
    }, 500);
  }

  mudouLink() {
    this.linkEstaCorreto = false;
    this.campanha.foiTestada = false;
  }
}
