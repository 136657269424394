import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "./ServerService";
import {FiltroTelaProdutos} from "../lib/FiltroTelaProdutos";


@Injectable({
  providedIn: 'root'
})
export class ProdutoService extends ServerService {
  produtosTelaInical: any;
  constructor(private httpCliente: HttpClient) {
    super(httpCliente);
  }
  setProdutosTelaInicial(categorias: any, produtosPorCategoria: any){
    this.produtosTelaInical  = {
      categorias: categorias,
      produtosPorCategoria: produtosPorCategoria
    }
  }

  liste(idCatalogo: number, temEstoque: boolean, inicio: number = null, total: number = null, texto: string = null,
        categoria: any = null, disponibilidade: any[] = null, semCodigoPdv: boolean = false, rede: boolean = false,
        tipo = null, tamanho: any = null, notIn = null, apenasNaoSincronizados = false) {

    let link =  '/catalogos/' + idCatalogo + "/produtos"
    let qs =
      new FiltroTelaProdutos(temEstoque, categoria, disponibilidade, texto,
        semCodigoPdv, inicio, total, rede, tipo, tamanho, notIn, apenasNaoSincronizados).toQueryString()

    if(qs) link =  link + qs;

    return this.httpCliente.get(link)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeComplementos(idCatalogo: number,  inicio: number = null, total: number = null, texto: string = null,
                    porCodigo = null){

    let querystring = `?i=${inicio}&t=${total}`

    if(texto)
      querystring =   porCodigo ? `${querystring}&cod=${texto}` : `${querystring}&q=${texto}`

    let link: string =  '/catalogos/' + idCatalogo + "/complementos" + querystring

    return this.httpCliente.get(link)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeTodos(idCatalogo: number, inicio: number, total: number, temEstoque: boolean){
    let querystring = "?i=" + inicio + "&t=" + total
    if(temEstoque)  querystring +=  "&temEstoque=true"

    let link =  '/catalogos/' + idCatalogo + "/produtos" + querystring

    return this.httpCliente.get(link)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeDoTipo(tipo: string){

  }

  autoComplete(texto: string = '', buscarObrigatorios = false, disponivel = true, esconderZerados = false){
    let link = '/api/produtos/autocomplete?q=' + texto ;
    if(disponivel)
      link = link + "&d=1"
    if(buscarObrigatorios)
      link = link + "&bo=1"
    if(esconderZerados)
      link = link + "&ez=1" //esconde zerados, exceto os marcados como É BRINDE

    return this.httpCliente.get(link)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeAhVenda(tipoCardapio  = 'DELIVERY', termo = null) {
    let link =  String(`/api/produtos/venda/${tipoCardapio}`) ;

    if(termo) link = link + '?termo=' + termo;

    return this.httpCliente.get(link)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  listeUnidades(){
    return this.obtenha('/produtos/unidades/liste', {});
  }

  obtenhaProdutosAC(catalogo: any, texto: string) {
    return this.httpCliente.get( '/produtos/autocomplete/?cid=' + catalogo.id + ' &q=' + texto)
      .toPromise().then(this.retorno).catch(this.handleError);


  }
  obtenhaProduto(idProduto: any, catalogo: any, empresa: any) {
    return this.httpCliente.get('/produtos/' + idProduto + '?cid=' + catalogo.id +
      (empresa ? '&eid=' + empresa.id : ''))
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  cloneProduto(idProduto: any, catalogo: any) {
    return this.httpCliente.get('/produtos/' + catalogo.id  + '/' + idProduto + '/clone')
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveProduto(produto: any): Promise<any> {
    return this.salve('/produtos', produto );
  }

  sincronizeProduto( produtos: any){
    return this.http.put('/produtos/sincronize', { produtos: produtos })
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  sincronizeProdutoDaRede( catalogo: any, produto: any){
    return this.http.put(String(`/importadorrede/catalogo/${catalogo.id}/produto/sincronize`), produto)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  removaProdutoCatalogo(produtos: any){
    return this.http.put('/produtos/remova/catalogo', { produtos: produtos })
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveImagemProduto(empresa: any, produto: any): Promise<any> {
    produto.eid = empresa.id;
    return this.salve('/produtos/imagem', produto );
  }

  salveImagensProduto(empresa: any, produto: any): Promise<any> {
    produto.eid = empresa.id;
    return this.salve('/produtos/imagens', produto );
  }

  salvePontosProduto( produto: any): Promise<any> {
    return this.salve('/produtos/pontosfidelidade', produto );
  }

  salvePrecoProduto(catalogo: any, empresa: any, produto: any, novoPreco: any){
    produto.cid = catalogo.id;


    if(catalogo.precoPorEmpresa && empresa && empresa.id) //se não estiver no contexto de rede
      produto.precoNaEmpresa = novoPreco;
    else
      produto.preco = novoPreco;

    return this.salve('/produtos/preco', produto );
  }

  salveTagsProdutos(catalogo: any, produto: any, classificacao: any){
    produto.cid = catalogo.id;

    return this.facaPut(`/produtos/${produto.id}/tags`, classificacao );

  }

  salveDisponibilidadeProduto(catalogo: any, produto: any): Promise<any> {
    produto.cid = catalogo.id;


    return this.salve('/produtos/disponibilidade', produto );
  }

  atualizeDisponilidadeERP(empresa: any, produto: any): Promise<any> {
    produto.eid = empresa.id;
    return this.salve('/produtos/sincronize/disponibilidade', produto );
  }

  removaProduto(produto: any) {
    return this.remova( "/produtos/" + produto.catalogo.id + "/" + produto.id, {})
  }

  obtenhaAdicionaisPedido(empresa: any) {
    return this.httpCliente.get('/produtos/adicionais-pedido?eid=' + empresa.id).toPromise()
      .then(this.retorno).catch(this.handleError);
  }


  salveOpcaoAdicional(catalogo: any, produto: any, adicional: any, opcao: any, rede: any = false){
    let parametros: any =  { cid: catalogo.id, pid: produto.id,   aid: adicional.id , opcao: opcao};

    return this.httpCliente.post('/produtos/adicional/opcao', parametros )
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  salveAdicional(catalogo: any, produto: any, adicional: any, rede: any = false) {
    adicional.entidade = 'produto';

    let parametros: any =  { cid: catalogo.id, pid: produto.id, adicional: adicional }

    if(rede)
      parametros.rede = rede

    if(!adicional.id){
      return this.httpCliente.post('/produtos/adicional', parametros )
        .toPromise().then(this.retorno).catch(this.handleError);
    } else {
      return this.httpCliente.put('/produtos/adicional', parametros )
        .toPromise().then(this.retorno).catch(this.handleError);
    }
  }

  atualizeOrdemAdicionais(catalogo: any, produto: any, adicionais: any[]) {
    let dados = []
    for (let i = 0; i < adicionais.length; i++) {
      dados.push({
        id: adicionais[i].id,
        objeto: {
          id: produto.id
        },
        ordem: adicionais[i].ordem
      });
    }

    return this.httpCliente.post('/produtos/adicional/reordenar', {cid: catalogo.id, pid: produto.id, adicionais: dados}).toPromise()
      .then(this.retorno).catch(this.handleError);
  }


  removaAdicional(produto: any, adicional: any) {
    return this.httpCliente.put('/produtos/adicional/delete', { pid: produto.id, adicional: adicional } )
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  copieAdicionais(catalogo: any, produto: any, produtoCopiar: any, adicionais: any, clonar: boolean) {
    let dados = {cid: catalogo.id, pid: produto.id,
      dadosCopiar: { pid: produtoCopiar.id, ids: adicionais.map(adicional => adicional.id)},
      clonar: clonar
    };
    return this.httpCliente.post('/produtos/adicional/copiar', dados )
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  atualizeOrdem(empresa: any, produtos: any[]) {
    return this.httpCliente.put('/produtos/ordens', {produtos: produtos, eid: empresa.id} )
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  subir1Posicao(catalogo: any, produto: any) {

    const dados: any = {
      idCatalogo: catalogo.id,
      idProduto: produto.id,
      ordem: produto.ordem,
      comando: 'SUBIR'
    };

    return this.httpCliente.put('/produtos/subir1Posicao', dados)
      .toPromise().then(this.retorno).catch(this.handleError);
  }



  descer1Posicao(empresa: any, produto: any) {
    const dados = {
      idProduto: produto.id,
      ordem: produto.ordem,
      eid: empresa.id,
      comando: 'DESCER'
    };

    return this.httpCliente.put('/produtos/subir1Posicao', dados)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  subirParaTopo(empresa: any, produto: any) {
    const dados = {
      idProduto: produto.id,
      ordem: produto.ordem,
      eid: empresa.id
    };

    return this.httpCliente.put('/produtos/subirParaTopo', dados)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  descerParaFinal(empresa: any, produto: any) {
    const dados = {
      idProduto: produto.id,
      ordem: produto.ordem,
      eid: empresa.id
    };

    return this.httpCliente.put('/produtos/descerParaFinal', dados)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  pausarAtivarOpcional(empresa: any, opcao: any, disponivel: boolean) {
    const dados = {
      idOpcao: opcao.id,
      eid: empresa.id,
      disponivel: disponivel
    };

    return this.httpCliente.post('/produtos/pausarOpcional', dados)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  pausarAtivarCategoria(empresa: any, categoria: any, disponivel: boolean) {
    const dados = {
      idCategoria: categoria.id,
      eid: empresa.id,
      disponivel: disponivel
    };

    return this.httpCliente.post('/produtos/pausarCategoria', dados)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  historicoProduto(produto: any, filtro: any = null) {
    let queryFiltro = ''
    if(filtro) {
      let parametros = []

      if(filtro.texto) parametros.push('texto=' + filtro.texto)
      if(filtro.operacao) parametros.push('operacao=' + filtro.operacao.id)
      if(filtro.t) {
        parametros.push('i=' + (filtro.i ? filtro.i : '0'))
        parametros.push('t=' + filtro.t)
      }

      queryFiltro = '?' + parametros.join('&')
    }

    return this.httpCliente.get('/produtos/historico/' + produto.id + queryFiltro)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  recalculeOrdemPorNome(empresa: any) {
    return this.httpCliente.get('/produtos/ordens/recalcule/pornome?eid=' + empresa.id, {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  finalizouSincronizarPrecos(){
    return this.facaPost('/produtos/sincronizou/precos', {})
  }

  finalizouSincronizarEstoque(){
    return this.facaPost('/produtos/sincronizou/estoque', {})
  }

  listeTagsAlimentar(){
    return this.httpCliente.get('/produtos/tags/alimentar'  , {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }
}
