<fieldset *ngIf="temModuloEstoque">
  <legend>Controle de estoque</legend>
  <div class="row" >
    <div class="form-group mb-3 col-12 " >

      <div class="input-group mt-0">
        <input  name="controlarEstoque" id='controlarEstoque'    class="k-checkbox  " type="checkbox"
                [(ngModel)]="objeto.controlarEstoque" (ngModelChange)="alterouControlarEstoque()"  />
        <label  class="k-checkbox-label" for="controlarEstoque">
          Ativar controle de estoque</label>

      </div>

    </div>

    <ng-container *ngIf=" objeto.controlarEstoque ">

      <ng-container *ngIf="!empresa.estoqueVinculadoProduto">
        <div class="form-group  col-6" >
          <label  >Insumo vinculado
            <i class="k-icon k-i-loading ml-1" *ngIf="carregandoInsumo"></i>
          </label>

          <kendo-dropdowntree #dropdowntree  kendoDropDownTreeExpandable name="item" *ngIf="data.length" class="form-control furniture" required
                              [kendoDropDownTreeHierarchyBinding]="data"    [(ngModel)]="objeto.insumo"   [filterable]="true"
                              textField="nome"  valueField="id"  childrenField="items"
                              [isNodeExpanded]="isNodeExpanded"  (nodeExpand)="handleExpand($event)" (nodeCollapse)="handleCollapse($event)"
                               [disabled]=" carregandoInsumo"  (valueChange)="onValueChange($event)">
          </kendo-dropdowntree>


        </div>

        <ng-container *ngIf="objeto.insumo && objeto.insumo.estoque">
          <div class="form-group  col-3">
            <label  >Estoque atual</label>
            <kendo-numerictextbox id="estoque" [min]="0"   [format]="obtenhaFormatoEstoque()"
                                  class="form-control" name="incremento" #incremento=ngModel

                                  [(ngModel)]="objeto.insumo.estoque.quantidade" required placeholder="Estoque atual">
            </kendo-numerictextbox>


          </div>

          <div class="form-group  col-3">
            <label  >Estoque minimo</label>
            <kendo-numerictextbox id="estoqueMinimo" [min]="1"   [format]="obtenhaFormatoEstoque()"
                                  class="form-control" name="estoqueMinimo" #incremento=ngModel
                                  [(ngModel)]="objeto.insumo.estoque.quantidadeMinima" required placeholder="Estoque minimo">
            </kendo-numerictextbox>

          </div>
        </ng-container>

      </ng-container>

      <ng-container *ngIf="empresa.estoqueVinculadoProduto">
        <div class="form-group  col-3">
          <label  >Estoque atual
            <i class="k-icon k-i-list-bulleted cpointer text-blue ml-1" *ngIf="objeto.estoque.id"
               (click)="abraModalMovimentacoes(objeto)"  style="    margin-top: -3px  " title="ver movimentações" kendoTooltip></i>
          </label>
          <kendo-numerictextbox   [min]="0"   [format]="obtenhaFormatoEstoque()"
                                class="form-control" name="incremento" #incremento=ngModel

                                [(ngModel)]="objeto.estoque.quantidade" required placeholder="Estoque atual">
          </kendo-numerictextbox>


        </div>

        <div class="form-group  col-3">
          <label  >Estoque minimo</label>
          <kendo-numerictextbox   [min]="1"   [format]="obtenhaFormatoEstoque()"
                                class="form-control" name="estoqueMinimo" #estoqueMinimo=ngModel

                                [(ngModel)]="objeto.estoque.quantidadeMinima" required placeholder="Estoque minimo">
          </kendo-numerictextbox>

        </div>

        <div class="form-group  col-3" *ngIf="objeto.estoque?.id">
          <label  >Estoque reservado</label>
          <kendo-numerictextbox      [format]="obtenhaFormatoEstoque()"
                                  class="form-control" name="quantidadeReservada" #quantidadeReservada=ngModel
                                  [(ngModel)]="objeto.estoque.quantidadeReservada"  >
          </kendo-numerictextbox>

        </div>

      </ng-container>

    </ng-container>
  </div>
</fieldset>
