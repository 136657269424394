<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    Request do Pedido <b>{{pedido.id}}</b>
  </h4>

</kendo-dialog-titlebar>
<div   class="modal-body">

  <kendo-grid [data]="requests"
              [loading]="carregando" style="min-height: 300px;">
    <kendo-grid-messages
      pagerPage="Página"
      pagerOf="de"
      pagerItems="itens"
      noRecords="Sem requests"
      loading="Carregando"
      pagerItemsPerPage="ítems por página"
    >
    </kendo-grid-messages>

    <kendo-grid-column title="sistema"  [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span><b>{{dataItem.sistema}}</b></span>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column title="status" [width]="90" >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span>Http <b>{{dataItem.httpstatus}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Detalhes request"  >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span class="text-muted">
          {{dataItem.header}}
        </span>
      </ng-template>
    </kendo-grid-column>



    <kendo-grid-column title="Payload"     [width]="120" >
      <ng-template kendoGridCellTemplate let-item >
        <a href="#" class="text-primary" (click)="verJson(item.payload, $event)"> Ver dados</a>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column title="Resposta"   [width]="120"   >
      <ng-template kendoGridCellTemplate let-item >
        <a href="#" class="text-primary" (click)="verJson(item.retorno, $event)"> Ver retorno</a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Horário" [width]="150"  >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span class="text-muted"><b>{{dataItem.horario | date: 'dd/MM/YYYY HH:mm:ss'}}</b></span>
      </ng-template>
    </kendo-grid-column>


  </kendo-grid>
</div>
