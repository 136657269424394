import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IntegracaouberdirectService} from "../../services/integracaouberdirect.service";

@Component({
  selector: 'app-cad-integracao-uberdirect',
  templateUrl: './cad-integracao-uberdirect.component.html',
  styleUrls: ['./cad-integracao-uberdirect.component.scss']
})
export class CadIntegracaoUberdirectComponent implements OnInit {
  @Input() empresa: any = {};
  @ViewChild('frm', {}) frm:  any;
  textoCopiado: string;
  integracao: any = { acaoretorno :  1}
  mensagemErro: string;
  salvando = false;
  processando = false;
  acoesRetorno  = [ { id: 1, descricao: 'Retornar a loja'}, { id: 2, descricao: "Descartar"}];
  constructor(private integracaoUberDirectService: IntegracaouberdirectService) { }

  ngOnInit(): void {
    if(this.empresa.integracaoUberdirect)
      this.integracao = Object.assign({},  this.empresa.integracaoUberdirect)

    if(this.integracao.acaoretorno)
      this.integracao.acaoretorno = this.acoesRetorno.find((item: any) => item.id ===  this.integracao.acaoretorno)

  }

  urlWebHooks(){
    return String(`https://${location.host}/uberdirect/hooks/event`)
  }


  onSubmit(){
    delete this.mensagemErro;

    if (this.frm.valid) {
      this.salvando = true;
      this.integracaoUberDirectService.salveParametrizacao(this.integracao).then((integracao) => {
        this.integracao = this.empresa.integracaoUberdirect = integracao;
        this.salvando = false;
      }).catch((err) => {
        this.salvando = false;
        this.mensagemErro = err;
      })
    } else {
      this.mensagemErro = 'Verifique os campos obrigatorios'
    }
  }


  desativeIntegracao(){
    this.processando = true;

    this.integracaoUberDirectService.desativeIntegracao(this.integracao).then((integracao) => {
      this.integracao.ativa = false;
      this.processando = false;
    }).catch((err) => {
      this.processando = false;
      this.mensagemErro = err;
    })

  }

  ativeIntegracao(){
    this.processando = true;

    this.integracaoUberDirectService.ativeIntegracao(this.integracao).then((integracao) => {
      this.integracao.ativa = true;
      this.processando = false;
    }).catch((err) => {
      this.processando = false;
      this.mensagemErro = err;
    })
  }



  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.textoCopiado = val;
  }

  fecharAlerta() {

  }
}
