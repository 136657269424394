<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  &nbsp; <i class="fas fa-mail-bulk"></i> {{campanha.nome}}</h4>


  <label class="k-form-field float-right">
    <input type="checkbox" name="recebeu" id="recebeu" class="k-checkbox" (change)="filtre()"  [(ngModel)]="filtro.recebeu" kendoCheckBox>
    <label class="k-checkbox-label" for="recebeu">Recebeu</label>
  </label>

 <div class="clearfix"></div>


<kendo-grid [data]="gridDataResult" [pageSize]="tamanhoPagina"
            [skip]="page"
            [style]=""
            [loading]="loading" style="min-height: 300px;"
            [scrollable]="'none'"
            footerStyle="font-size: 11px;"
            [pageable]="{
              buttonCount: buttonCount,
              info: info,
              type: type,
              pageSizes: pageSizes,
              previousNext: previousNext
            }"
            (pageChange)="paginacaoMudou($event)">
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Sem contatos"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column field="status" title="Status" class="coluna_menor text-center" [width]="80">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <i class="far fa-check-circle" *ngIf="dataItem.status === 'ENVIADA'" style="font-size: 18px;color: green" kendoTooltip [title]="dataItem.status"></i>
      <i class="fas fa-clock" *ngIf="dataItem.status === 'ENVIANDO'" style="font-size: 18px;color: #333" kendoTooltip [title]="dataItem.status"></i>
      <i class="fas fa-exclamation-circle" *ngIf="dataItem.status !== 'ENVIADA' && dataItem.status !== 'ENVIANDO'" style="font-size: 18px;color: red" kendoTooltip [title]="dataItem.status"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="contato.nome" title="Nome" class="coluna_menor"  [width]="200">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a routerLink="/admin/contatos/{{dataItem.contato.telefone}}">
        <span>{{dataItem.contato.nome}}</span>
        <p class="mt-1 mb-0 text-muted">
          <span>{{dataItem.contato.telefone | mask: '(99) 9-9999-9999' }}</span>
        </p>
      </a>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="horario" title="Horário" media="lg" class="coluna_menor" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.horario | date: 'dd/MM/yy hh:mm:ss'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="links" title="Links" media="sm" class="coluna_menor" [width]="80">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.links.length  }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="links" title="Visitas" media="sm" class="coluna_menor" [width]="80">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span *ngIf="dataItem.links.length ==0"> - </span>
      <span *ngIf="dataItem.links.length  > 0"> {{getVisitas(dataItem.links)}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="links" title="Ultima Visita" media="sm" class="coluna_menor" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span *ngIf="!getUltimaVisita(dataItem.links)"> - </span>
      <span *ngIf="dataItem.links.length  > 0"> {{getUltimaVisita(dataItem.links) | date: 'dd/MM/yy HH:mm:ss'}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="mensagem" title="Mensagem" media="sm" class="coluna_menor">
  </kendo-grid-column>
  <ng-template kendoGridDetailTemplate let-dataItem>
    <section>
      <h4>Dados da Mensagem: </h4>
      <p><strong>Telefone:</strong> {{dataItem.contato.telefone | mask: '(99) 9-9999-9999' }}</p>
      <p><strong>Mensagem:</strong> {{dataItem.mensagem}}</p>
      <p><strong>Horário:</strong> {{dataItem.horario | date: 'dd/MM/yyyy HH:mm:ss'}}</p>
      <p><strong>Status:</strong> {{dataItem.status}}</p>
      <p>
        <strong>Links:</strong>
        <span  *ngIf="!dataItem.links || dataItem.links.length==0">Nenhum link enviado</span>
        <span   *ngFor="let link of dataItem.links">
          {{link.url}} - última visita: <b>{{link.ultimaVisita | date: 'dd/MM/yy HH:mm:ss'}}</b><br>
        </span>
      </p>
    </section>
  </ng-template>
</kendo-grid>
