<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

  <div class="row">
    <div class="form-group mb-3 col-12">
      <label for="nome">Descrição</label>
      <input type="text" class="form-control" autocomplete="off" appAutoFocus [autoFocus]="true"
             id="nome" name="nome" [(ngModel)]="promocao.descricao" #nome="ngModel"
             placeholder="Descrição da promoção" value="" required/>
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Descrição da promoção é obrigatória</p>
      </div>
    </div>
    <div class="form-group mb-3 col-6">
      <label for="dataInicio">Data de Início</label>

      <div class="input-group">

        <kendo-datepicker [(ngModel)]="promocao.dataInicio" id="dataInicio"    name="dataInicio" [format]="'dd/MM/yyyy'"
                          class="form-control   k-display-inline-block"  [min]="(promocao.dataInicio && (promocao.dataInicio < hoje)) ? promocao.dataInicio : hoje" >
          <kendo-datepicker-messages
            today="Hoje"
            toggle="Alterar data"
          ></kendo-datepicker-messages>

        </kendo-datepicker>

      </div>
    </div>

    <div class="form-group mb-3 col-6">
      <label for="dataFim">Data Fim</label>

      <div class="input-group">

        <kendo-datepicker [(ngModel)]="promocao.dataFim" id="dataFim"    name="dataFim" [format]="'dd/MM/yyyy'"
                          class="form-control   k-display-inline-block"  [min]="promocao.dataInicio" >
          <kendo-datepicker-messages
            today="Hoje"
            toggle="Alterar data"
          ></kendo-datepicker-messages>

        </kendo-datepicker>

      </div>
    </div>

    <div class="col-12">
      <p>Disponibilizar apenas em dias específicos da semana</p>
      <p class="k-block">
        <button type="button" class="mr-1"  kendoButton (selectedChange)="onSelectedChange(ds, $event)" *ngFor="let ds of disponibilidadesSemana"
                [look]="'outline'"     [toggleable]="true" [selected]="ds.disponivel"  >{{ds.label}}</button>


      </p>
    </div>

    <div class="form-group mb-3 col-6">
      Ativa? <kendo-switch [(ngModel)]="promocao.ativa" name="ativa" id="ativa"  ></kendo-switch>
    </div>
    <div class="form-group mb-3 col-6">
      Cumulativa? <kendo-switch [(ngModel)]="promocao.cumulativa" name="cumulativa" id="cumulativa"  ></kendo-switch>
    </div>

  </div>

    <div class="row">
      <div class="col">
        <div>
          <label>Regras da Promoção</label>
        </div>

        <kendo-grid    [kendoGridBinding]="promocao.regras"
                       editable="incell"
                       [loading]="carregando" style="min-height: 150px;"
                       (edit)="editarRegra($event)"
                       (remove)="removerRegra($event)"
                       (pageChange)="carregue($event)" [height]="220">
          <ng-template kendoGridToolbarTemplate [position]="'top'">
            <div class="row">
              <div class="col-2">
                <button class="k-button btn-primary" (click)="adicionarRegra()">
                  Adicionar
                </button>
              </div>
            </div>



          </ng-template>
          <kendo-grid-messages
            loading="Carregando"
            noRecords="Sem regras definidas"
          >
          </kendo-grid-messages>
          <kendo-grid-column  title="Descricao">
            <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"  >
              <ng-container *ngIf="dataItem.tipo === 'percentual' || dataItem.tipo === 'percentual-no-segundo'">
                <span [ngClass]="{'removida': dataItem.removida}">{{dataItem.percentual}}% no
                  {{dataItem.tipo === 'percentual-no-segundo' ? 'segundo ' : ' '}}
                  {{dataItem.adicional ? dataItem.adicional.nome : dataItem.produto.nome}}</span>

              </ng-container>
              <ng-container *ngIf="dataItem.tipo === 'percentual-todos'">
                <span [ngClass]="{'removida': dataItem.removida}">{{dataItem.percentual}}% em todos os produtos</span>
              </ng-container>
              <ng-container *ngIf="dataItem.tipo === 'adicionar-produto'">
                <span [ngClass]="{'removida': dataItem.removida}">Adicionar o produto {{dataItem.produto.nome}} no carrinho
                  com {{dataItem.percentual}}% de desconto </span>
              </ng-container>
              <ng-container *ngIf="dataItem.tipo === 'compre-pizza-ganhe-outra'">
                <span [ngClass]="{'removida': dataItem.removida}">Na compra de {{dataItem.quantidade}} unidade(s)
                  de pizza tamanho "{{dataItem.tamanhoPizzaComprar.descricao}}",
                  ganhe uma unidade de pizza tamanho "{{dataItem.tamanhoPizzaGanhar.descricao}}"</span>
              </ng-container>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Aplicações" [width]="125">
            <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
              <span *ngIf="dataItem.tipo === 'percentual' || dataItem.tipo === 'percentual-no-segundo'" [ngClass]="{'removida': dataItem.removida}">{{dataItem.maximoAplicacoes}}</span>
              <span *ngIf="dataItem.tipo === 'percentual-todos'" [ngClass]="{'removida': dataItem.removida}">-</span>
              <span *ngIf="dataItem.tipo === 'adicionar-produto'" [ngClass]="{'removida': dataItem.removida}">-</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Ativa" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >

              <input  name="disponivel{{rowIndex}}"
                      class="k-checkbox ng-untouched ng-pristine ng-valid" type="checkbox" kendoCheckBox
                      [(ngModel)]="dataItem.ativa"/>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-command-column title="" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
            <span *ngIf="!dataItem.removida && dataItem.id">
               <button kendoGridEditCommand [primary]="true"   ><i class="fa fa-edit"></i></button>
               <button kendoGridRemoveCommand  class="ml-1"><i class="fa fa-trash "></i></button>
            </span>
              <span *ngIf="dataItem.removida">
              <button  class="btn btn-light btn-xs"   (click)="cancelarRemocao($event, dataItem)" >
               <i class="fa fa-undo"></i></button>
            </span>

            </ng-template>
          </kendo-grid-command-column>
        </kendo-grid>
        <input type="hidden" [(ngModel)]="promocao.regras" name="regras" #regras="ngModel" [minLength]="1">
        <div class="invalid-feedback">
          <p *ngIf="regras.errors?.minimoLista">A promoção deve ter ao menos uma regra.</p>
        </div>
      </div>
    </div>

  <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
    <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
    {{mensagemErro}}
  </span>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >Salvar</button>
    <button type="button" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
  </div>
</form>

<kendo-dialog title="Regra da Promoção" *ngIf="abraDialogoRegra" (close)="fecharDialogRegra()" [minWidth]="250" [width]="450">
  <form [ngClass]="{'needs-validation': !frmRegra.submitted, 'was-validated': frmRegra.submitted}"  novalidate #frmRegra="ngForm" (ngSubmit)="salvarRegra(frmRegra)">
    <div class="row">
      <div class="col-12">
        <label>Tipo de Regra</label><br>
      </div>
      <ng-container *ngFor="let tipo of tiposDeRegra">
        <div class="col-12 input-group">

            <input [id]="tipo" [attr.name]="tipo" type="radio"  class="k-radio"   kendoRadioButton
                   [(ngModel)]="regra.tipo" [value]="tipo.id"  [disabled]="regra.id"
                   [required]="true"/>

            <label [attr.for]="tipo" class="ml-1 mr-5">{{tipo.descricao}}</label><br>
        </div>
      </ng-container>
      <div class="invalid-feedback" *ngIf="!regra.tipo">
        Informe um tipo
      </div>
    </div>
    <div class="row">
      <div class="form-group mb-3 col-12" >
        <label for="nome">Definição da Regra</label>
        <ng-container *ngIf="regra.tipo === 'percentual-no-segundo'">
          <p *ngIf="!regra.adicional">Se adicionar o produto <b>{{regra.produto ? regra.produto.nome : 'Escolha o Produto'}}</b> no carrinho, ganha
            desconto de <b>{{regra.percentual ? regra.percentual : '0'}}%</b> no segundo {{regra.produto ? regra.produto.nome : 'Escolha o Produto'}}
          </p>
          <p *ngIf="regra.adicional">Se adicionar o produto <b>{{regra.produto.nome}}</b> com o adicional
            <b>{{regra.adicional.nome}}</b>  no carrinho, ganha
            desconto de <b>{{regra.percentual ? regra.percentual : '0'}}%</b> no segundo adicional
          </p>

        </ng-container>
        <ng-container *ngIf="regra.tipo === 'percentual'">
          <p *ngIf="!regra.adicional">Se adicionar o produto <b>{{regra.produto ? regra.produto.nome : 'Escolha o Produto'}}</b> no carrinho, ganha
            desconto de <b>{{regra.percentual ? regra.percentual : '0'}}%</b> no valor do produto
          </p>
          <p *ngIf="regra.adicional">Se adicionar o produto <b>{{regra.produto.nome}}</b> com o adicional
            <b>{{regra.adicional.nome}}</b>  no carrinho, ganha
            desconto de <b>{{regra.percentual ? regra.percentual : '0'}}%</b> no valor do produto
          </p>

        </ng-container>
        <ng-container *ngIf="regra.tipo === 'adicionar-produto'">
          <p *ngIf="!regra.adicional">Quando o carrinho alcançar o valor de R$ {{regra.valorMinimoPedido | number : '1.2-2'}}, o produto <b>{{regra.produto ? regra.produto.nome : 'Escolha o Produto'}}</b>
            será adicionado no carrinho com um desconto de <b>{{regra.percentual ? regra.percentual : '0'}}%</b>
          </p>

        </ng-container>
        <ng-container *ngIf="regra.tipo === 'percentual-todos'">
          <p>Ao adicionar qualquer produto no carrinho, ganha
            desconto de <b>{{regra.percentual ? regra.percentual : '0'}}%</b> no valor do produto
          </p>
        </ng-container>
        <ng-container *ngIf="regra.tipo === 'compre-pizza-ganhe-outra'">
          <p>Ao adicionar {{regra.quantidade}} unidade(s) de pizza tamanho "{{regra.tamanhoPizzaComprar ? regra.tamanhoPizzaComprar.descricao : 'Escolha o tamanho'}}",
            ganhe uma unidade de pizza tamanho "{{regra.tamanhoPizzaGanhar ? regra.tamanhoPizzaGanhar.descricao : 'Escolha o tamanho'}}"
          </p>
        </ng-container>
      </div>

    </div>

    <div class="row" >
      <div class="form-group mb-3 col-12" *ngIf="regra.tipo === 'percentual' || regra.tipo === 'percentual-no-segundo'  || regra.tipo === 'adicionar-produto'" >
        <label  >Escolha o produto</label><br>

        <kendo-dropdownlist class="form-control" name="produto"
                            [data]="produtos"
                            [filterable]="true"
                            [textField]="'descricao'" [valueField]="'id'"
                            [(ngModel)]="regra.produto"
                            (filterChange)="search($event, (regra.tipo === 'adicionar-produto'))"
                            (focus)="busquePrimeiraVez(regra.tipo === 'adicionar-produto')"
        >
        </kendo-dropdownlist>

      </div>
      <div class="form-group mb-3 col-12" *ngIf="regra.tipo === 'compre-pizza-ganhe-outra'">
        <label> Escolha o tamanho da pizza a comprar</label><br>
        <kendo-dropdownlist class="form-control" name="tamanhoPizzaComprar"
                            [data]="tamanhos"
                            [filterable]="false"
                            [textField]="'descricao'" [valueField]="'id'"
                            [(ngModel)]="regra.tamanhoPizzaComprar">
        </kendo-dropdownlist>

      </div>
      <div class="form-group mb-3 col-6" *ngIf="regra.tipo === 'compre-pizza-ganhe-outra'"  >
        <label for="valor">Quantidade de pizzas a comprar:</label>
        <kendo-numerictextbox id="quantidade" [min]="0"  class="form-control" name="quantidade" #quantidade=ngModel
                              [decimals]="0"
                              [format]="'#'"
                              [(ngModel)]="regra.quantidade" required>
        </kendo-numerictextbox>
      </div>
      <div class="form-group mb-3 col-12" *ngIf="regra.tipo === 'compre-pizza-ganhe-outra'">
        <label> Escolha o tamanho da pizza a ganhar</label><br>
        <kendo-dropdownlist class="form-control" name="tamanhoPizzaGanhar"
                            [data]="tamanhos"
                            [filterable]="false"
                            [textField]="'descricao'" [valueField]="'id'"
                            [(ngModel)]="regra.tamanhoPizzaGanhar">
        </kendo-dropdownlist>

      </div>


      <div class="form-group mb-3 col-12" *ngIf="regra.tipo === 'percentual' || regra.tipo === 'percentual-no-segundo'">
        <label for="nome">Selecione o adicional (opcional)</label>

        <kendo-combobox   textField="nome" valueField="id"
                          [(ngModel)]="regra.adicional" [data]="regra.produto ? regra.produto.camposAdicionais : []"

                          class="form-control w-75 d-inline-block"  name="adicional"
                          placeholder="Selecione o adicional" >
        </kendo-combobox>
      </div>

      <div class="form-group mb-3 col-6" *ngIf="regra.tipo !== 'compre-pizza-ganhe-outra'">
        <label for="percentual">Percentual</label>
        <div class="input-group">
          <kendo-numerictextbox  [attr.type]="'number'" id="percentual"    name="percentual"  class="form-control"
                                 [(ngModel)]="regra.percentual" [min]="1" [max]="100"
                                 [format]="obtenhaFormatoPercentual()"
                                 required     >
          </kendo-numerictextbox>
          <div class="invalid-feedback" >
            Informe um percentual de desconto no segundo produto
          </div>
        </div>
      </div>
      <div class="form-group mb-3 col-6" *ngIf="regra.tipo === 'percentual' || regra.tipo === 'percentual-no-segundo'"  >
        <label for="maximoAplicacoes">Máximo de aplicações:</label>
        <kendo-numerictextbox id="valor"   class="form-control" name="maximoAplicacoes" #maximoAplicacoes=ngModel
                              [decimals]="0"
                              [format]="'#'"
                              [(ngModel)]="regra.maximoAplicacoes"  >
        </kendo-numerictextbox>
      </div>

      <div class="form-group mb-3 col-12">
        Ativa? <kendo-switch [(ngModel)]="regra.ativa" name="ativa" id="regraAtiva"  ></kendo-switch>
      </div>
      <div class="form-group mb-3 col-6"  *ngIf="regra.tipo !== 'compre-pizza-ganhe-outra'">
        <label for="valorMinimoPedido">Valor Mínimo do Pedido</label>
        <kendo-numerictextbox id="valorMinimoPedido" [min]="0"  class="form-control" name="valorMinimoPedido" #valorMinimoPedido=ngModel
                              [(ngModel)]="regra.valorMinimoPedido" required>
        </kendo-numerictextbox>

      </div>
      <div class="form-group mb-3 col-6" *ngIf="regra.valorMinimoPedido > 0 && (regra.tipo === 'percentual' || regra.tipo === 'adicionar-produto')">
        Produto compõe mínimo? <kendo-switch [(ngModel)]="regra.produtoCompoeMinimo" name="produtoCompoeMinimo" id="produtoCompoeMinimo"  ></kendo-switch>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary waves-effect waves-light" >Salvar</button>
      <button type="button" kendoButton (click)="fecharDialogRegra()" [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
    </div>

  </form>
</kendo-dialog>
<kendo-dialog title="Remover Regra" *ngIf="abraDialogoRemover" (close)="fecharDialogRemover()" [minWidth]="250" [width]="450">
  <div class="form-group mb-3">
    Tem certeza que você deseja remover a regra "{{regraRemover.id}}"?
  </div>
  <div class="modal-footer">
    <button type="button" (click)="confirmouRemoverOpcao()" class="btn btn-primary waves-effect waves-light" >Remover</button>
    <button type="button" (click)="fecharDialogRemover()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
  </div>
</kendo-dialog>
