import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EnumFiltroDePedidos, PedidosService} from "../../services/pedidos.service";
import {interval} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";
import {GridPedidosComponent} from "../grid-pedidos/grid-pedidos.component";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {ModalFinalizarPedidosComponent} from "../../fidelidade/modal-finalizar-pedidos/modal-finalizar-pedidos.component";
import {MonitoradorPedidos} from "../../fidelidade/MonitoradorPedidos";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";
import {PedidoLocalService} from "../../services/pedido-local.service";

const secondsCounter = interval(1000 * 30);
declare var _;
declare var $;
declare var moment;

@Component({
  selector: 'app-tela-acompanhar-pedidos',
  templateUrl: './tela-acompanhar-pedidos.component.html',
  styleUrls: ['./tela-acompanhar-pedidos.component.scss']
})
export class  TelaAcompanharPedidosComponent implements OnInit, OnDestroy {
  @ViewChild('gridPedidos', { static: true}) gridPedidos: GridPedidosComponent;
  pedidos = [];
  novosPedidos = [];
  pedidosContestados = [];
  pedidosEnderecoAlterado = [];
  falhasNaImportacao = []
  paginacao = { total: 1000};
  carregando: any = false;
  @Input() empresa: any = {}
  resumoPedidos: any = { total: 0 , emPreparacao: 0, emEntrega: 0 , novos: 0}
  page: any = 0;
  pedidoAlterar: any;
  timerPedidos;

  mensagemSucesso: string;
  novoPedido: any;
  ultimaAtualizacao: any;
  usuario = null;
  @Input() mesa: boolean;
  @Input() apenasAgendados: boolean;
  objFiltro: any = {
    q: ''
  };
  timerBusca: any;
  ordenarPorCodigo = false;
  constructor(private pedidosService: PedidosService, private router: Router,
              public monitoradorPedidos: MonitoradorPedidos, private dialogService: DialogService,
              private pedidoLocalService: PedidoLocalService,
              private route: ActivatedRoute, private autorizacaoService: AutorizacaoService) {


    if(localStorage.getItem('ordenarPorCodigo') != null)
        this.ordenarPorCodigo = true;

  }

  ngOnInit() {
    this.usuario = this.autorizacaoService.getUser();
    if(this.route.snapshot.routeConfig.path.endsWith('erros'))
      this.objFiltro.comErros = true;


    if(this.route.snapshot.routeConfig.path.endsWith('onlineaguardando'))
      this.objFiltro.onlineAguardando = true;

    if(this.route.snapshot.routeConfig.path.endsWith('contestados'))
      this.objFiltro.contestados = true;

    if(this.route.snapshot.routeConfig.path.endsWith('alteracaoendereco'))
      this.objFiltro.alteracaoendereco = true;

    delete this.mensagemSucesso
    delete  this.novoPedido

    if( this.route.snapshot.queryParams.msg){
      this.mensagemSucesso = this.route.snapshot.queryParams.msg
      this.novoPedido = { codigo: this.route.snapshot.queryParams.codigo}
    }

    if(window.history.state.mensagem){
      this.mensagemSucesso =  window.history.state.mensagem
      this.novoPedido = { codigo:  window.history.state.codigo}
    }

    if(this.apenasAgendados && !this.objFiltro.dia)
      this.objFiltro.dia = new Date();

    this.carreguePedidos();

    this.timerPedidos = secondsCounter.subscribe(n =>
      this.carregueNovosPedidos());

  }

  ngOnDestroy(){
    this.timerPedidos.unsubscribe();
  }

  onFilter($event: Event) {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout(() =>  {
      this. carreguePedidos();
    }, 1000)
  }


  alterouDia(){
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout(() =>  {
      this. carreguePedidos();
    }, 1000)
  }

  limpeFiltrosData(){
    delete this.objFiltro.dia;
    delete this.objFiltro.horaInicio;
    delete this.objFiltro.horaFim;

    this.carreguePedidos();
  }

  alterouHorario(){
    if(!this.objFiltro.dia) return;

    if(this.objFiltro.horaInicio && this.objFiltro.horaFim){
      if(this.timerBusca) clearTimeout(this.timerBusca);

      this.timerBusca = setTimeout(() =>  {
          this. carreguePedidos();
      }, 1000)
    }
  }

  carreguePedidos(){
    if(this.carregando) return;

    let filtro: any = { };

    this.setFiltrosComuns(filtro);

    this.carregando = true;

    this.pedidosService.listePedidos(0, this.paginacao.total, filtro, filtro.filtroEmum).then( (resposta) => {
      this.pedidos = resposta.pedidos || [];
      this.pedidosContestados = resposta.contestados || [];
      this.pedidosEnderecoAlterado = resposta.pedidosEnderecoAlterado || [];
      this.falhasNaImportacao = resposta.falhasNaImportacao || [];
      this.ultimaAtualizacao = resposta.ultimaAtualizacao;
      this.setPedidosExibir();

      this.carregando = false;
    }).catch( () => {
      this.carregando = false;
    });
  }

  setPedidosExibir(){
    let pedidosFiltrados = this.pedidos;

    if(this.objFiltro.comErros)
      pedidosFiltrados = this.pedidos.filter((pedido: any) => pedido.erroExterno);

    if(this.objFiltro.onlineAguardando)
      pedidosFiltrados = this.pedidos.filter((pedido: any) => pedido.aguardandoPagamentoOnline);

    if(this.objFiltro.contestados)
      pedidosFiltrados = this.pedidosContestados;

    if(this.objFiltro.alteracaoendereco)
      pedidosFiltrados =  this.pedidosEnderecoAlterado;

    this.gridPedidos.atualizeGridPedidos(pedidosFiltrados, 'em-aberto', true);

    this.setResumoPedidos(pedidosFiltrados);

    this.monitoradorPedidos.notifiqueResumo(this.pedidos, this.pedidosContestados, this.falhasNaImportacao)
  }

  carregueNovosPedidos(){
    if(this.carregando) return;

    let filtro: any = {   ultimaAtualizacao: this.ultimaAtualizacao };

    this.setFiltrosComuns(filtro)

    this.carregando = true;

    this.pedidosService.listePedidos(0, this.paginacao.total, filtro ,  filtro.filtroEmum).then( (resposta: any) => {

      if(resposta.pedidos.length){
        this.ultimaAtualizacao = resposta.ultimaAtualizacao;
        resposta.pedidos.forEach((pedido: any) => {
          let pedidoExisente = this.pedidos.find( pedidoLocal => pedidoLocal.id === pedido.id)

          if(!pedidoExisente){
            this.pedidos.push(pedido);
            this.novosPedidos.push(pedido);
          } else {
            let indice = this.pedidos.indexOf(pedidoExisente);
            this.pedidos[indice]  = pedido;
          }
        });

        this.setPedidosExibir();
      }

      this.carregando = false;
    }).catch( () => {
      this.carregando = false;
    });
  }


  setFiltrosComuns(filtro: any){
    filtro.emAberto =  true;
    if(this.objFiltro.q) filtro.q = this.objFiltro.q

    let filtroEmum = EnumFiltroDePedidos.pedidos;

    if( this.mesa )
      filtroEmum = EnumFiltroDePedidos.comanda;

    if (this.apenasAgendados)
      filtroEmum = EnumFiltroDePedidos.agendados;

    filtro.filtroEmum = filtroEmum;

    delete this.objFiltro.filtroData;

    if(this.objFiltro.dia){
      this.objFiltro.filtroData = true;
      filtro.dia = moment(this.objFiltro.dia).format('YYYYMMDD');

      if(this.objFiltro.horaInicio && this.objFiltro.horaFim) {
        delete  filtro.dia;

        const inicio =  moment(this.objFiltro.dia).set({
          hour: this.objFiltro.horaInicio.getHours(),
          minute: this.objFiltro.horaInicio.getMinutes(),
          second: 0
        });

        const fim = moment(this.objFiltro.dia).set({
          hour: this.objFiltro.horaFim.getHours(),
          minute: this.objFiltro.horaFim.getMinutes(),
          second: 0
        });

        filtro.dtInicio = inicio.format('YYYYMMDDHHmmss');
        filtro.dtFim = fim.format('YYYYMMDDHHmmss');
      }
    }
  }

  setResumoPedidos(pedidos: any){
    this.resumoPedidos.total = pedidos.length;
    this.resumoPedidos.emPreparacao = _.filter(pedidos, {status: 'Em preparação'}).length;
    this.resumoPedidos.emEntrega = _.filter(pedidos, {status: 'Saiu para entrega'}).length;
    this.resumoPedidos.novos = _.filter(pedidos, {status: 'Novo'}).length;
  }

  imprimaPedido(pedido: any) {
    window.open('/imprimir/pedido/' + pedido.guid);
  }


  fecheMensagemSucesso() {
    delete  this.mensagemSucesso;
  }


  vaParaNovoPedido(balcao = null) {
    let url = '/admin/pedidos/novo?urlRetorno=' + window.location.pathname;

    if(balcao){
      url += '&bal=1'
      this.pedidoLocalService.removaPedido('balcao');
    }

    this.router.navigateByUrl(url).catch( () => {});
  }

  abraModalFinalizar(){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalFinalizarPedidosComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.pedidos = this.pedidos;

    windowRef.result.subscribe( (result: any) => {
        if(result)
           this.carreguePedidos();
      } );

  }
}
