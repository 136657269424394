import {Component, OnInit} from "@angular/core";
import {ModalKendo} from "../lib/ModalKendo";

@Component({
  selector: 'app-modal-movimentacoes',
  template: `

    <kendo-dialog-titlebar (close)="fecheModal(null)"  >
      <h4 class="modal-title">
        <img src="assets/fidelidade/icones/adicionar-insumo.png" style="height: 32px;"/>
        Movimentações do estoque
      </h4>
    </kendo-dialog-titlebar>

    <div   class="modal-body">
      <app-tela-historico-movimentacoes [produto]="produto"></app-tela-historico-movimentacoes>
    </div>

  `
})
export class ModalMovimentacoesEstoqueComponent   extends ModalKendo implements OnInit {
  produto: any;
  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
