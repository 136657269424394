<div class="row" *ngIf="!integracaoFoodyDelivery.id || integracaoFoodyDelivery.editar">

  <div class="col-12 " style="max-width: 750px;" >
    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
          novalidate #frm="ngForm" (ngSubmit)="onSubmit()" >


      <div class="form-group mb-2"  >
        <label for="token">Token </label>
        <input kendoTextBox id="token" name="token"  placeholder="Token"
               class="form-control"   #token="ngModel"
               [(ngModel)]="integracaoFoodyDelivery.token" required/>

        <div class="invalid-feedback">
          <p  >Token é obrigatório</p>
        </div>

      </div>

      <div class="form-group mb-2 " *ngIf="empresa.integracaoUberdirect || empresa.integracaoOpendeliveryLogistica">
        <div class="input-group mt-2">
          <input  name="integrarAuto" id='integrarAuto'    class="k-checkbox  " type="checkbox" kendoCheckBox
                  [(ngModel)]="integracaoFoodyDelivery.integrarAutoSempre"/>
          <label  class="k-checkbox-label">Integrar automaticante todos pedidos</label>
        </div>

      </div>

      <button class="btn btn-success mt-2" type="submit" [disabled]="salvando">
        <i class="k-icon k-i-loading " *ngIf="salvando"></i>  Salvar
      </button>

      <button class="btn btn-secondary mt-2 ml-1" type="button"
              (click)="integracaoFoodyDelivery.editar = false" [hidden]="!integracaoFoodyDelivery.id" >
        Voltar
      </button>

      <div class="alert alert-danger mt-2" role="alert" *ngIf="mensagemErro">
        {{mensagemErro}}
        <button type="button" class="close" aria-label="Close" (click)="fecharAlerta()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

    </form>
  </div>
</div>

<div   *ngIf="integracaoFoodyDelivery.id"  class="text-center" [hidden]="integracaoFoodyDelivery.editar">
  <img src="/assets/icons/icon-success.png" height="100px" >

  <h3>Sistema <b>{{integracaoFoodyDelivery.tipo}}</b>  integrado <b>{{integracaoFoodyDelivery.sistema}}</b>
  </h3>


  <p>Integração ativa desde de <b>{{integracaoFoodyDelivery.data | date: 'dd/MMM/yyyy'}}</b></p>

  <p *ngIf="integracaoFoodyDelivery.token" style="    max-width: 500px;margin: 0 auto;" class="abreviar">
    Token de autorização: <b>{{integracaoFoodyDelivery.token}}</b></p>

  <p>Url receber notificações:  <b>{{obtenhaUrlHook()}}</b></p>

  <button class="btn btn-blue mr-2" (click)="editarIntegracao()" [hidden]="confirmarRemover">
    Editar</button>


  <button class="btn btn-danger" (click)="confirmeRemover()" [hidden]="confirmarRemover">
    Remover</button>


  <h4 class="mt-2 " *ngIf="confirmarRemover">
    Confirma a remoção da integração  ?

    <div class="mt-1">
      <button class="btn btn-success mr-1"  (click)="removerIntegracao()" [disabled]="removendo">Sim</button>
      <button class="btn btn-light" (click)="canceleRemover()">Cancelar</button>
    </div>

  </h4>

</div>
