import {Component, OnInit, ViewChild} from '@angular/core';
import {ConstantsService} from "../fidelidade/ConstantsService";
import {AutorizacaoService} from "../services/autorizacao.service";
import {EmpresasService} from "../superadmin/services/empresas.service";
import {DialogRef, DialogService, WindowComponent} from "@progress/kendo-angular-dialog";

import {EstoqueService} from "../services/estoque.service";

import {GroupDescriptor, process, SortDescriptor} from "@progress/kendo-data-query";
import {CadInsumoComponent} from "./cad-insumo/cad-insumo.component";
import {ListagemInsumosComponent} from "./listagem-insumos/listagem-insumos.component";
import {
  TelaHistoricoMovimentacoesComponent
} from "./tela-historico-movimentacoes/tela-historico-movimentacoes.component";
import {MonitoradorPedidos} from "../fidelidade/MonitoradorPedidos";
import {ActivatedRoute} from "@angular/router";
import {TabStripComponent} from "@progress/kendo-angular-layout";
import {ModalMovimentacoesEstoqueComponent} from "./modal-movimentacoes-estoque";
import {KendoPopupUtils} from "../lib/KendoPopupUtils";
import {ModalGerarMovimentacoesEstoqueComponent} from "./modal-gerar-movimentacoes-estoque";

@Component({
  selector: 'app-tela-gerenciar-estoque',
  templateUrl: './tela-gerenciar-estoque.component.html',
  styleUrls: ['./tela-gerenciar-estoque.component.scss']
})
export class TelaGerenciarEstoqueComponent implements OnInit {
  @ViewChild('windowAlerta') windowAlerta: WindowComponent;
  @ViewChild('listaInsumos') listaInsumos: ListagemInsumosComponent;
  @ViewChild('listaFichas') listaFichas: ListagemInsumosComponent;
  @ViewChild('historicoMovimentacoes') historicoMovimentacoes: TelaHistoricoMovimentacoesComponent;

  empresa: any;
  catalogo: any;
  usuario: any = {};
  confirmeRemover = false;
  removendo = false;
  carregando = false;
  grupos = [];
  gruposFichas = [];
  insumos = [];
  fichasTecnicas = [];
  exibirInsumosBaixoEstoque = false;
  insumosAlertaGrid: any;
  cordenadas = {top: 50, left: 50}
  filtro: any = {};
  timerBusca: any
  exibirAlertaEstoque: boolean
  tab: number;
  public groups: GroupDescriptor[] = [{ field: 'nivelDescricao' }];
  constructor(private constantsService: ConstantsService, private autorizacaoService: AutorizacaoService,
              private empresaService: EmpresasService, private dialogService: DialogService,  private route: ActivatedRoute,
              private estoqueService: EstoqueService,  public monitoradorPedidos: MonitoradorPedidos) {
    this.constantsService.empresa$.subscribe( data => this.empresa  = data  )

  }

  ngOnInit() {
    this.tab = 0;
    this.usuario = this.autorizacaoService.getUser() || {};
    this.exibirAlertaEstoque  = this.route.snapshot.routeConfig.path.endsWith('alertas');

    if(!this.constantsService.insumosDaLoja.length){
      this.carregueDados();
    } else {
      this.grupos = this.constantsService.gruposDeInsumo;
      this.setInsumosExibir(this.constantsService.insumosDaLoja)
      this.setInsumosAlertas( this.constantsService.insumosEmAlerta)
    }
  }

  async selecionouTab($event: any) {
    this.tab = $event.index;
  }

  private   carregueDados() {
    this.carregando = true;

    this.estoqueService.listeGrupos().then((grupos: any) => {
      this.grupos = grupos;
      this.carregueInsumos();
    })

    this.estoqueService.listeAlertasMinimo().then((resposta: any) => {
      if(resposta.total)
        this.setInsumosAlertas(resposta.items)
    })
  }

  setInsumosAlertas(items: any){

    if(!this.constantsService.insumosEmAlerta.length)
      this.constantsService.insumosEmAlerta = items;

    this.insumosAlertaGrid =  process(items, {
      group: this.groups,
      sort: [ { field: 'quantidade', dir: 'desc' }] as SortDescriptor[]
    });

    this.monitoradorPedidos.notifiqueEstoqueAlertas(items.length)
    if(this.exibirAlertaEstoque) this.exibaModalAlertaEstoque();
  }

  exibaModalAlertaEstoque(){
    this.exibirInsumosBaixoEstoque = true;
    this.setCoordenadas();
  }


  public  carregueInsumos(inicio = 0, texto: string = null) {
    let qs: any = {}

    if(this.filtro.grupo) qs.gid = this.filtro.grupo.id;
    if(texto) qs.termo = texto

    this.carregando = true;
    this.estoqueService.listeInsumos(inicio, 50, qs).then((insumos: any) => {
      if(!this.constantsService.gruposDeInsumo.length)
        this.constantsService.gruposDeInsumo = this.grupos;

      if(!this.constantsService.insumosDaLoja.length)
        this.constantsService.insumosDaLoja = insumos;

      this.setInsumosExibir(insumos)
      this.carregando = false;
    })
  }

  public setInsumosExibir(insumos: any){
    this.insumos =  insumos.filter((item: any) => item.objeto  === 'insumo');
    this.fichasTecnicas = insumos.filter((item: any) => item.objeto  === 'ficha-tecnica');

    if( this.listaInsumos)
      this.listaInsumos.setInsumos(this.insumos)

    if( this.listaFichas)
      this.listaFichas.setInsumos(this.fichasTecnicas)

    if(this.exibirAlertaEstoque)
      this.exibaModalAlertaEstoque();

  }

  onFilter(event: any) {
    //this.skip = 0;
    if(this.timerBusca) clearTimeout(this.timerBusca);
    this.timerBusca = setTimeout( () => {
      this.executeFiltroNaAba(event.target.value)
    }, 1000);
  }

  async executeFiltroNaAba(termo: string = null){
    if(this.tabMovimentacoes()){
      await this.historicoMovimentacoes.carregueMovimentacoes(0, termo, this.filtro.grupo)
    } else {
      this.carregueInsumos(0, termo);
    }
  }

  tabMovimentacoes(){
    return this.tab === 3
  }

  setCoordenadas(){

   // const larguraTela = window.innerWidth;
   // const alturaTela = window.innerHeight;

  //  const deslocamentoScrollY = window.scrollY;
  //  const posY = 25  ;
    //posY + deslocamentoScrollY;
    this.cordenadas.top =  -25 ;

   // const posX = (larguraTela - 500) / 2 ;
   // const deslocamentoScrollX = window.scrollX;
    this.cordenadas.left =  -25;

  }


  abraModalInsumo(insumo: any = null, fichaTecnica: boolean = false, aba: number =  null){
    CadInsumoComponent.abraModalInsumo(this.dialogService, this.grupos, insumo, aba, fichaTecnica, () => {
      this.carregueInsumos()
    })
  }

  abraModalFichaTecnica() {
   this.abraModalInsumo(null, true)
  }

  editarInsumo(row: any) {
    this.abraModalInsumo(row.dataItem)
  }

  abraModalMovimentacoes(insumo: any) {
    this.abraModalInsumo(insumo, false,  4);
  }

  abraModalNovaMovimentacao() {
    //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      content: ModalGerarMovimentacoesEstoqueComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      cssClass: 'bsModal',
      maxHeight:  window.innerHeight - 100

    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.result.subscribe( async (result: any) => {
        if(result){
          await this.historicoMovimentacoes.carregueMovimentacoes(0)
        }

      },
      (a) => {


      });
  }
}
