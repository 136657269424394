<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

  <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
    <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
    {{mensagemErro}}
  </span>
  </div>

  <div class="row">
    <div class="form-group mb-3 col">
      <label>Tempo que a loja ficará fechada</label>
      <div style="width: 100%;">
        <div class="form-check">
          <input type="radio" value="15" id="tempo_15" name="tempo" class="k-radio right" #valor="ngModel" kendoRadioButton
                 (ngModelChange)="mudouValor()" required [(ngModel)]="value"/>
          <label for="tempo_15" class="ml-1">15 minutos</label>
        </div>
        <div class="form-check">
          <input type="radio" value="30" id="tempo_30" name="tempo" class="k-radio" #valor="ngModel" kendoRadioButton
                 (ngModelChange)="mudouValor()" required [(ngModel)]="value"/>
          <label for="tempo_30" class="ml-1">30 minutos</label>
        </div>
        <div class="form-check">
          <input type="radio" value="60" id="tempo_60" name="tempo"  class="k-radio" #valor="ngModel" kendoRadioButton
                 (ngModelChange)="mudouValor()" required [(ngModel)]="value"/>
          <label for="tempo_60" class="ml-1">1 hora</label>
        </div>
        <div class="form-check">
          <input type="radio" value="180" id="tempo_180" name="tempo" class="k-radio" #valor="ngModel" kendoRadioButton
                 (ngModelChange)="mudouValor()" required [(ngModel)]="value"/>
          <label for="tempo_180" class="ml-1">3 horas</label>
        </div>
        <div class="form-check">
          <input type="radio" value="240" id="tempo_240" name="tempo"  class="k-radio" #valor="ngModel" kendoRadioButton
                 (ngModelChange)="mudouValor()" required  [(ngModel)]="value"/>
          <label for="tempo_240" class="ml-1">4 horas</label>
        </div>
        <div class="form-check">
          <input type="radio" value="360" id="tempo_360" name="tempo"  class="k-radio" #valor="ngModel" kendoRadioButton
                 (ngModelChange)="mudouValor()" required  [(ngModel)]="value"/>
          <label for="tempo_360" class="ml-1">6 horas</label>
        </div>
        <div class="form-check">
          <input type="radio" value="480" id="tempo_480" name="tempo"  class="k-radio" #valor="ngModel" kendoRadioButton
                 (ngModelChange)="mudouValor()" required  [(ngModel)]="value"/>
          <label for="tempo_480" class="ml-1">8 horas</label>
        </div>
        <div class="form-check">
          <input type="radio" value="720" id="tempo_720" name="tempo"  class="k-radio" #valor="ngModel" kendoRadioButton
                 (ngModelChange)="mudouValor()" required  [(ngModel)]="value"/>
          <label for="tempo_720" class="ml-1">12 horas</label>
        </div>
      <div class="invalid-feedback">
        <p *ngIf="valor.errors?.required">Tempo que a loja ficará fechada é obrigatório</p>
      </div>
    </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group mb-3 col">
      <label for="campoMensagemFechada">Mensagem de Pausa (opcional)</label>
      <input type="text" class="form-control" autocomplete="off"
             id="campoMensagemFechada" name="campoMensagemFechada" [(ngModel)]="mensagemFechada"
             placeholder="Mensagem que será exibida no cardápio durante a pausa" value="" >
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="enviando" >Fechar Loja</button>
    <!--<button type="button" (click)="onRemover()" class="btn btn-danger waves-effect waves-light" *ngIf="formaDePagamento.id">Remover</button>-->
    <button type="button" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Fechar</button>
  </div>
</form>
