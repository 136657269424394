import {DialogRef} from "@progress/kendo-angular-dialog";

export class KendoPopupUtils{

  static abriuPopupNgBootstrap(windowRef: DialogRef){
    let telaModal: any =  windowRef.content.instance;

    telaModal.window = windowRef;

    const $divDialog = windowRef.dialog.location.nativeElement;

    if( $divDialog ) {

      let $kWindow = $divDialog.getElementsByClassName('k-window');

      if($kWindow)
        $kWindow[0].classList.add('borda-redonda');

      const $divDialogContent = $divDialog.getElementsByClassName('k-window-content');

      if($divDialogContent)
        $divDialogContent[0].classList.add('padding0');

    }
  }
}
