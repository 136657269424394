<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

  <h4>A loja ficará indisponível nessa data mesmo estando dentro do horário de funcionamento.</h4>

  <div class="row mt-2">
    <div class="form-group mb-3 col">
      <label for="descricao">Descrição da pausa programada</label>
      <input type="text" class="form-control" autocomplete="off"
             id="descricao" name="descricao" [(ngModel)]="pausaProgramada.descricao" #descricao="ngModel"
             placeholder="Descricao da Pausa Programada" value="" required appAutoFocus [autoFocus]="true">
      <div class="invalid-feedback">
        <p *ngIf="descricao.errors?.required">Descrição é obrigatório</p>
      </div>
    </div>

  </div>


  <div class="row mt-2">
    <div class="form-group  col">
      <label >Data Inicío</label>
      <kendo-datetimepicker class="form-control" [required]="true" name="dataInicio" [required]="true"
        [format]="format"   #dataInicio="ngModel"      [(ngModel)]="pausaProgramada.dataInicio"

      >
      </kendo-datetimepicker>
    </div>

    <div class="form-group  col">
      <label >Data Fim</label>
      <kendo-datetimepicker class="form-control" name="dataFim" [required]="true"
        [format]="format" #dataFim="ngModel"      [(ngModel)]="pausaProgramada.dataFim" >
      </kendo-datetimepicker>
    </div>
  </div>

  <div class="row mt-2">
    <div class="form-group mb-3 col">
      <label for="mensagem">Mensagem de Pausa (opcional)</label>
      <input type="text" class="form-control" autocomplete="off"
             id="mensagem" name="mensagem" [(ngModel)]="pausaProgramada.mensagem" #mensagem="ngModel"
             placeholder="Mensagem que será exibida no cardápio durante a pausa" value="" >
    </div>

  </div>



  <div class="modal-footer">

    <div class="alert alert-danger mt-2 mb-2" *ngIf="mensagemErro">
      <i class="fas fa-exclamation-triangle mr-2"></i>
      {{mensagemErro}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="clearfix"></div>

    <button type="button" class="btn mr-5 btn-danger waves-effect waves-light" [disabled]="excluindo"
            (click)="exclua()" *ngIf="pausaProgramada.id">
      <i class="k-icon k-i-loading mr-2" *ngIf="excluindo" ></i>Excluir</button>

    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >
      <i class="k-icon k-i-loading mr-2" *ngIf="salvando"></i>Salvar</button>
    <!--<button type="button" (click)="onRemover()" class="btn btn-danger waves-effect waves-light" *ngIf="formaDePagamento.id">Remover</button>-->
    <button type="button" kendoButton (click)="fechar()" [primary]="true"  class="btn btn-light waves-effect">Fechar</button>
  </div>




</form>
