import {Injectable} from "@angular/core";
import {BehaviorSubject, timer} from "rxjs";
import {EnumFiltroDePedidos, PedidosService} from "../services/pedidos.service";
import {AutorizacaoService} from "../services/autorizacao.service";
import {ConstantsService} from "./ConstantsService";
import {TelaImprimirPedido} from "./TelaImprimirPedido";
import {ImpressaoService} from "../services/impressao.service";
import {EnumMomentoImprimirAuto} from "../../../server/lib/emun/EnumMomentoImprimirAuto";
import {GrupolojasService} from "../superadmin/services/grupolojas.service";
import {EnumOrigemPedido} from "../../../server/lib/emun/EnumOrigemPedido";
import {ArmazenamentoService} from "../services/armazenamento.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
const segundosTimer = 30;

declare var $;

@Injectable()
export class MonitoradorPedidos extends TelaImprimirPedido{
  public novosPedidos = [];
  public pedidosConfirmados = [];
  public pedidosContestados = [];
  public pedidosEnderecoAlterado = [];
  public falhasImportacao = [];
  public resumoMesas: any = new BehaviorSubject<any>(null);  //
  public resumoPedidos: any = new BehaviorSubject<any>(null);  //
  public mesasComErros: any = new BehaviorSubject<any>(null);  //
  public resumoEstoque: any = new BehaviorSubject<any>(null);  //
  public  resumoMesas$ = this.resumoMesas.asObservable();
  public  resumoPedidos$ = this.resumoPedidos.asObservable();
  public  mesasComErros$ = this.mesasComErros.asObservable();
  public  resumoEstoque$ = this.resumoEstoque.asObservable();
  public pedidos = [];
  tarefaAlerta;
  beep;
  timerPedidos;
  timerLoja;
  ultimoPedido;
  origens = [];
  tocarAlerta;
  KEYALERTAIFRAME = 'alerta-iframe'
  lojasIfood: any = [];
  modoGarcom: boolean;
  modoGrupoLojas: boolean
  pedidosMonitorarPagamento = []
  avisos: any [];
  isMobile: boolean;
  constructor(  protected pedidosService: PedidosService, private autorizacaoService: AutorizacaoService,
                private     grupolojasService: GrupolojasService, protected http: HttpClient,
                private router: Router,
                private constantsService: ConstantsService, impressao: ImpressaoService,
                private armazenamentoService: ArmazenamentoService,
                private detectorDevice: DeviceDetectorService) {
    super(impressao, pedidosService)
    let usuario = this.autorizacaoService.getUser();
    this.modoGarcom = usuario && usuario.garcom
    this.isMobile = this.detectorDevice.isMobile();
    this.tocarAlerta =  !this.inIframe() && !this.modoGarcom && !this.isMobile;
    this.resumoMesas.next ({ totalNovo: 0, mesas: []})
    this.constantsService.empresa$.subscribe( (empresa: any) => {
       if(!empresa) return;
       this.empresa = empresa;
    })

    try{
      if(this.armazenamentoService.carregue(this.KEYALERTAIFRAME, false) === '1')
        this.tocarAlerta =  !this.modoGarcom;


    }catch(e){}
  }

  autalizeStatusIfood(){
    this.http.get('/ifood/lojas/status', {}).toPromise().then((resposta: any) => {
        this.lojasIfood = resposta;
    })
  }

  inicieMonitoramentoGrupoLojas(){
    this.modoGrupoLojas = true;
    this.inicieMonitoramento();
  }

  inicieMonitoramento() {
    if(this.empresa && this.empresa.integracoesIfood.length) this.inicieMonitoramentoLojaAberta();
    if(!this.monitorarNovosPedidos() || this.timerPedidos)
      return;

    this.beep = document.getElementById("beep");
    if( this.beep && this.empresa){
      this.beep.load();

      this.inicieMonitoramentoPedidos();
    } else {
      setTimeout( () => { this.inicieMonitoramento()}, 1000);
    }

  }

  toogleTocarIframe(){

    if(this.tocarAlerta){
      this.inicieMonitoramento()
    } else {
      this.pareMonitoramento();
    }
    this.armazenamentoService.salveSemExpirar(this.KEYALERTAIFRAME, this.tocarAlerta ? '1' : '0')
  }

  monitorarNovosPedidos(){

    return this.tocarAlerta;

  }

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  getService() {
    if (this.modoGrupoLojas)
      return this.grupolojasService;

    return this.pedidosService;
  }

  private inicieMonitoramentoLojaAberta(){
    if(!this.timerLoja){
      this.timerLoja =  timer(0, 1000 * segundosTimer  ).subscribe( () => {
        this.autalizeStatusIfood()
      });
    }
  }

  private async inicieMonitoramentoPedidos() {
    this.configurarOrigens();

    let resposta: any = await this.getService().listeUltimoPedidosAtras(segundosTimer    , this.origens);

    if(resposta){
      this.ultimoPedido = resposta.ultimo;
      this.pedidosMonitorarPagamento = resposta.pedidosMonitorarPagamento || [];
      this.resumoMesas.next( resposta.resumoMesas )
      if(!this.timerPedidos){
        this.timerPedidos =  timer(2000, 1000 * segundosTimer  ).subscribe( () => {
          this.carregueNovosPedidos()
        });
      }

    }
  }

  private configurarOrigens() {
    this.origens = [EnumOrigemPedido.Loja, EnumOrigemPedido.Ifood];

    if (this.empresa.darkPrincipal)
      this.origens.push(EnumOrigemPedido.Multiloja)

    if (!this.constantsService.naoTocarGarcom)
      this.origens.push(EnumOrigemPedido.Garcom)
  }

  atualizeFoiLido(pedido){
    let pedidoLido = this.novosPedidos.find( pedidoNovo => pedidoNovo.id === pedido.id);

    if(pedidoLido)
      this.novosPedidos.splice( this.novosPedidos.indexOf(pedidoLido), 1);

  }

  fecheEZerePedidosNovos(){
    this.novosPedidos = [];
    this.pedidosConfirmados = [];
    this.pedidosContestados = [];
    this.pedidosEnderecoAlterado = []
    this.fecheModalAlerta();
  }

  carregueNovosPedidos(){
   // console.log ("Carregando novos pedidos " + new Date())
    let filtro: any = { emAberto: true, origens: this.origens, ultimoPedido: this.ultimoPedido ? this.ultimoPedido.id : null
    , avisos: true}

    if(!this.inIframe() && !this.isMobile && !this.constantsService.naoImprimir)
    {
      if(this.empresa.configImpressao && this.empresa.configImpressao.momentoImprimirAuto === EnumMomentoImprimirAuto.NovoPedido)
        filtro.naoImpressos = true

    } else
      console.log("Impressão automática desativada. Não carregará pedidos não impressos")

    if(this.pedidosMonitorarPagamento.length)
      filtro.onlineAguardando = this.pedidosMonitorarPagamento

    this.getService().listePedidos(0, 100, filtro, EnumFiltroDePedidos.todos).then( async (resposta) => {
      let novosPedidos = resposta ? resposta.pedidos : [];
      let pedidosPagos = resposta ? resposta.pedidosPagos : [];
      let contestados = resposta ? resposta.contestados || [] : [];
      let falhasNaImportacao = resposta ? resposta.falhasNaImportacao || [] : [];
      let tocarAlterta = false;

      this.avisos = resposta ? resposta.avisos : [];

      novosPedidos.forEach((pedido: any) => {
        if(this.chegouNovoPedido(pedido))
          tocarAlterta = true;
      });

      pedidosPagos.forEach((pedido: any) => {
        if(this.pagouPedidoAguardando(pedido))
          tocarAlterta = true;
      });

      contestados.forEach((pedido: any) => {
        if(this.chegouNovoPedidoContestado(pedido))
          tocarAlterta = true;
      })


      falhasNaImportacao.forEach((falhaImportacao: any) => {
         if(this.novoPedidoNoImportado(falhaImportacao))
           tocarAlterta  = true;
      })

      if(this.avisos.length >= 1)
        tocarAlterta = true;

      if(tocarAlterta)
        this.inicieAlerta();

      if(resposta.naoImpressos && resposta.naoImpressos.length)
        await this.imprimaNovosPedidos(resposta.naoImpressos);

    }).catch( () => {

    });
  }

  pagouPedidoAguardando(pedido: any){
    //as vezes sever cai, pode encavalar request.
    let pedidoExistente =  this.pedidosConfirmados.find((item: any) => item.id === pedido.id);

    if(pedidoExistente) return false;

    this.pedidosConfirmados.push(pedido);

    let iremover = this.pedidosMonitorarPagamento.indexOf(pedido.id);

    if(iremover >= 0)
      this.pedidosMonitorarPagamento.splice(iremover, 1);

    return true;

  }


  novoPedidoNoImportado(falhaImportacao: any){
    let falhaExistente = this.falhasImportacao.find((item: any) => item.id === falhaImportacao.id);

    if(falhaExistente) return false;

    this.falhasImportacao.push(falhaImportacao)

    return true;
  }

  chegouNovoPedidoContestado(pedido: any){
    let pedidoExistente =  this.pedidosContestados.find((item: any) => item.id === pedido.id);

    if(pedidoExistente) return false;

    this.pedidosContestados.push(pedido);

    return true;
  }

  chegouNovoPedido(pedido: any){
    //as vezes sever cai, pode encavalar request.
    let pedidoExistente =  this.novosPedidos.find((item: any) => item.id === pedido.id);

    if(pedidoExistente) return false;

    if(!this.ultimoPedido  || this.ultimoPedido.id < pedido.id)
      this.ultimoPedido = pedido;

    if(pedido.mesa){
      this.resumoMesas.value.totalNovo++
      let resumoMesa = this.resumoMesas.value.mesas.find( mesa => mesa.id === pedido.mesa.id);

      if(!resumoMesa){
        resumoMesa = { totalNovo: 0, mesa: pedido.mesa.nome }
        this.resumoMesas.value.mesas.push(resumoMesa)
      }

      resumoMesa.totalNovo++;
      this.resumoMesas.next(this.resumoMesas.value)
    }

    if(pedido.monitorarConfirmacaoPagamento)
      if(this.pedidosMonitorarPagamento.indexOf(pedido.id) === - 1)
        this.pedidosMonitorarPagamento.push(pedido.id)


    if(pedido.notificarNovo){
      this.novosPedidos.push(pedido);
      this.pedidos.push(pedido);
    }

    return pedido.notificarNovo;
  }

  zereComadasNaoLidas(comanda: any = null){
    this.resumoMesas.value.mesas.forEach((resumoMesa: any) => {
      if(!comanda || comanda.mesa.id === resumoMesa.id){
        if(comanda)
          this.resumoMesas.value.totalNovo -=  resumoMesa.totalNovo;
        else
          this.resumoMesas.value.totalNovo = 0;

        resumoMesa.totalNovo = 0;
      }
    })

    this.resumoMesas.next(this.resumoMesas.value)
  }

  alterouStatusPedido(pedido: any){

    if(pedido.mesa){
      let resumoMesa = this.resumoMesas.value.mesas.find( mesa => mesa.id === pedido.mesa.id);

      if(resumoMesa)   resumoMesa.totalNovo--;

      this.resumoMesas.value.totalNovo--;
    }
  }

  inicieAlerta(){

    if( this.tarefaAlerta) return;

    $("#alertaNovo").modal();

    $("#alertaNovo").on("hidden.bs.modal",   () => {
      this.pareAlerta();
    });

    this.tarefaAlerta =  timer(0, 1500  ).subscribe( () => {
      this.toqueAlerta();
    } )
  }

  pareAlerta(){
    this.beep.pause();
    this.beep.currentTime = 0;

    if(this.tarefaAlerta)
      this.tarefaAlerta.unsubscribe();

    delete this.tarefaAlerta;
    // window.scrollTo(0, document.body.scrollHeight);
  }

  toqueAlerta(){
    this.beep.play().then( () => {
    }).catch (e => {
      console.log('nao tocou alerta: ')
      console.log(e)
    });

  }

  fecheModalAlerta(){
    $("#alertaNovo").modal('hide');
  }

  pareMonitoramento() {
    if(this.timerPedidos)
      this.timerPedidos.unsubscribe();
  }

  notifiqueResumo(pedidos: any, contestados: any, falhasImportacao: any){
    let pedidosErro = pedidos.filter((pedido: any) => pedido.erroExterno && pedido.naoNotificado);
    let pedidosOnlineAguardando = pedidos.filter((pedido: any) => pedido.aguardandoPagamentoOnline);

    this.resumoPedidos.next({
      totalPendenteOnline: pedidosOnlineAguardando.length,
      totalErroParceiro: pedidosErro.length,
      totalContestados: contestados.length,
      totalErrosImportar: falhasImportacao.length,
      totalErroParceiroMesa: 0,
    });
  }

  notifiqueMesasComErro(totalErroParceiroMesa: number){
    this.mesasComErros.next({
      totalErroParceiroMesa : totalErroParceiroMesa
    })
  }

  notifiqueEstoqueAlertas(totalAlertas: number){
    this.resumoEstoque.next( { totalAlertas: totalAlertas})
  }

  verPedido(pedido: any) {
    this.fecheEZerePedidosNovos();
    this.router.navigateByUrl(  String(`/admin/pedidos/detalhes/${pedido.guid}?t=${pedido.id}`), { state: pedido });

  }

  totalPedidosMonitorados(){
    return this.novosPedidos.length + this.pedidosConfirmados.length;
  }

  toogleNaoImprimir() {
    this.constantsService.toogleNaoImprimir();

  }

  toogleNaoTocarGarcom() {
    this.constantsService.toogleNaoTocarGarcom();
    this.configurarOrigens();
  }

  temAvisosPedidos(){
    return this.novosPedidos.length   || this.pedidosContestados.length
    || this.pedidosEnderecoAlterado.length;
  }
}
