import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminSdrRoutingModule } from './admin-sdr-routing.module';
import { PainelSDRComponent } from './painel-sdr/painel-sdr.component';
import {ApplicationPipesModule} from "../pipes/application-pipes.module";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IntlModule} from "@progress/kendo-angular-intl";
import {CompartilhadoModule} from "../compartilhado/compartilhado.module";
import {PainelRedeRoutingModule} from "../admin-rede/painel-rede/painel-rede-routing.module";
import {InlineSVGModule} from "ng-inline-svg";
import {ChartsModule} from "@progress/kendo-angular-charts";
import {NgxMaskModule} from "ngx-mask";
import {DropDownsModule} from "@progress/kendo-angular-dropdowns";
import {DateInputsModule} from "@progress/kendo-angular-dateinputs";
import {CheckBoxModule, MaskedTextBoxModule, NumericTextBoxModule, SwitchModule} from "@progress/kendo-angular-inputs";
import {DialogModule, WindowModule} from "@progress/kendo-angular-dialog";
import {ButtonModule} from "@progress/kendo-angular-buttons";
import {ChatModule} from "@progress/kendo-angular-conversational-ui";
import {ExcelModule, GridModule} from "@progress/kendo-angular-grid";
import {ProgressBarModule} from "@progress/kendo-angular-progressbar";
import {TooltipModule} from "@progress/kendo-angular-tooltip";
import {UploadModule} from "@progress/kendo-angular-upload";
import {LabelModule} from "@progress/kendo-angular-label";
import {BrowserModule} from "@angular/platform-browser";
import {TelaSdrComponent} from "./tela-sdr/tela-sdr.component";


@NgModule({
  declarations: [
    PainelSDRComponent,
    TelaSdrComponent
  ],
    imports: [
        CommonModule,
        BrowserModule,
        ApplicationPipesModule,
        InfiniteScrollModule,
        FormsModule,
        ReactiveFormsModule,
        IntlModule,
        AdminSdrRoutingModule,
        InlineSVGModule, ChartsModule,
        NgxMaskModule, DropDownsModule, DateInputsModule,
        SwitchModule, WindowModule, ButtonModule, DialogModule, ChatModule,
        GridModule, ProgressBarModule, NumericTextBoxModule, MaskedTextBoxModule, TooltipModule, ExcelModule, UploadModule,
        LabelModule, CompartilhadoModule, CheckBoxModule
    ]
})
export class AdminSdrModule { }
