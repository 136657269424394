<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

  <div class="row">
    <div class="col-12">
      <div class="form-group  ">
        <label  >Nome </label>
        <input type="text" class="form-control" autocomplete="off"
               name="nome" [(ngModel)]="zonaDeEntrega.nome"
               [maxLength]="100"
               placeholder="Descrição da Zona de Entrega" value="" required>
        <span class="invalid-feedback" *ngIf="!zonaDeEntrega.nome">
                     Obrigatório
                   </span>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group  ">
        <label  >Valor da taxa de entrega </label>
        <kendo-numerictextbox [attr.type]="'number'"    class="form-control" [format]="'c2'"   name="valor"
                              [(ngModel)]="zonaDeEntrega.valor" required
                              [min]="0"   placeholder="Valor R$" >  </kendo-numerictextbox>

        <span class="invalid-feedback" *ngIf="zonaDeEntrega.valor == null">
                         obrigatório
                       </span>
      </div>
    </div>

    <div class="col-12">
      <label class="k-label mt-2">
        <input type="checkbox" kendoCheckBox [(ngModel)]="zonaDeEntrega.permiteFreteGratis" [ngModelOptions]="{standalone: true}" />
        &nbsp;Permite Frete Grátis
      </label>

    </div>

  </div>


  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >
      Salvar</button>
    <!--<button type="button" (click)="onRemover()" class="btn btn-danger waves-effect waves-light" *ngIf="formaDePagamento.id">Remover</button>-->
    <button type="button" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Fechar</button>
  </div>

</form>

