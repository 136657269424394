<div class="page-title-right">
  <ol class="breadcrumb m-0">
    <li class="breadcrumb-item active">Fidelidade</li>
  </ol>
</div>
<span *ngIf="!filtro?.exibir">
  <h4 class="page-title">      <i class="fe-users "></i> Contatos</h4>
  <div class="row">
    <div class="col-12">
      <div class="card filtro" >
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="row filtro">
                <div class="col-12 col-sm-7">
                    <div class="form-group"  >
                        <div class="input-group">
                          <div class="input-group-prepend" (click)="filtreContatos()">
                            <span class="input-group-text" id="basic-addon1">   <i class="fe-search"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="Buscar contato" aria-label="Buscar contato" [(ngModel)]="filtro.q"
                                 (keyup.enter)="filtreContatos()" >


                          <div class="input-group-append  ">
                            <button class="btn  btn-outline-secondary waves-effect waves-light d-none d-sm-block" type="button" (click)="exibaFiltroAvancado()" >
                              <i class="fe-filter"></i>
                              Avançado</button>
                          </div>
                        </div>
                      </div>
                  </div>

                  <div class="col-12 col-sm-5">
                    <kendo-combobox name="filtro" [(ngModel)]="filtro" [data]="filtros" [clearButton]="temFiltro()"
                                    class="form-control combo-avancado filtro-grande" [placeholder]="'Selecione um filtro'"
                                    [textField]="'nome'"  style="width: calc(100% - 70px);"
                                    [kendoDropDownFilter]="filterSettings"
                                     (valueChange)="filtreContatos()" >
                    </kendo-combobox>
                    <button class="btn btn-success  btn-lg" [disabled]="!filtro?.salvar" (click)="abraModalSalvarFiltro()"
                            ngbTooltip="salvar filtro"> <i class="fe-save"></i></button>
                  </div>

              </div>

              <div class="row d-sm-none mt-2">
                      <div class="col-12">
                           <button class="btn  btn-outline-secondary  btn-lg  waves-effect waves-light   " type="button"  (click)="exibaFiltroAvancado()"
                                   style="      width: 100%;">
                              <i class="fe-filter"></i>
                              Avançado
                           </button>
                      </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 ">
              <button type="button" class="btn btn-blue   btn-lg  waves-effect waves-light float-left " (click)="novoCartao()"  >
                <i class="fe-user-plus"></i> Novo contato
              </button>

              <button class="btn btn-warning btn-sm float-right"  kendoTooltip title="download contatos"  (click)="downloadCsv()" *ngIf="podeExportar">
                   <i class="fa fa-download fa-2x"></i>
              </button>
            </div>
          </div>

          <kendo-dialog title="Salvar filtro" top="0" (close)="fecheModalFiltro()" [minWidth]="250"
                        [width]="450" *ngIf="modalSalvarFiltro" height="250">
            <form class="k-form">
              <label class="k-form-field">
                <span>Descrição do filtro</span>
                <input class="form-control" name="nomeFiltro" type="text" placeholder="Descrição parar o filtro"  [(ngModel)]="filtro.nome"    />
              </label>

              <p class="text-danger" *ngIf="erroSalvarFiltro">
                <b>{{erroSalvarFiltro}}</b>
              </p>

              <div class="text-right">
                <button type="button" class="btn btn-primary" (click)="salveFiltro()" *ngIf="!filtro.id">Salvar</button>
                <button type="button" class="btn btn-primary" (click)="salveFiltro()" *ngIf="filtro.id">Atualizar</button>
                <button type="button" class="btn btn-secondary ml-2" (click)="fecheModalFiltro()">Cancelar</button>
              </div>
            </form>
          </kendo-dialog>
        </div>
      </div>
    </div>
  </div>

  <div class="row filtro-aplicado" *ngIf="temFiltro()">
    <div class="col-12">

     <div class="alert alert-warning" role="alert" >
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="limpeFiltros()" >
               <span aria-hidden="true">&times;</span>
        </button>
       <label class="badge badge-light mr-2" *ngFor="let item of filtro.dados | keyvalue" (click)="removerFiltro(item.key)">
           {{getDescricaoFiltro(item.value) }} <i class="fa fa-times fa-lg "  ></i>
      </label>

    </div>

    </div>
  </div>

  <div class="row resumo">
    <div class="col-3">
      <div class="  card-box com-borda">
        <div>
          <i class="fe-user "></i>
          <span>{{resumo.total}}</span>
        </div>
        <label>Total de contatos</label>
      </div>
    </div>
    <div class="col-3">
      <div class="  card-box">
        <div>
          <i class="fe-calendar "></i>
          <span>  {{resumo.qtdeAtivos}}</span>
        </div>
        <label class="d-none d-sm-block">Contatos ativos  no mês </label>
        <label class="d-sm-none">Contatos ativos</label>
      </div>
    </div>
    <div class="col-3">
      <div class="  card-box">
        <div>
          <i class="fe-calendar "></i>
          <span>  {{resumo.qtdeNovo}}</span>
        </div>
        <label class="d-none d-sm-block">Contatos novos no mês </label>
        <label class="d-sm-none">Contatos novos</label>
      </div>
    </div>
    <div class="col-3">
      <div class=" card-box">
        <div>
          <i class="fe-user-x"></i>
          <span>{{resumo.qtdeInativos}}</span>
        </div>
        <label class="d-none d-sm-block">Contatos inativos  no mês</label>
        <label class="d-sm-none">Contatos inativos  </label>
      </div>
    </div>
  </div>

  <div class="row">

    <div class="col-12">
      <div class="card contatos">
        <div class="card-body">
          <div class="row" *ngIf="carregando">
             <div class="col-12">
                <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;    height: 90px;" ></div>
             </div>
          </div>

          <div class="alert alert-info m-3" *ngIf="temFiltro() && !contatos.length && !carregando">
              <p>Nenhum contato encontrado!  </p>
          </div>

          <div *ngFor="let contato of contatos" class="card-box contato">
               <div class="row">
                 <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 " (click)="verContato(contato)" style="cursor: pointer">
                   <card-contato [contato]="contato"></card-contato>
                 </div>
                 <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" style="padding-left: 0;">
                    <div class="card-box text-center mt-2" style="    padding: 0.5em;">
                      <div class="row">
                        <div class="col-5" *ngIf="plano.id" style="padding-left: 0px;padding-right: 0px;">
                          <saldo-cartao [cartao]="getCartaoPlano(contato)"  ></saldo-cartao>
                        </div>

                        <div  [ngClass]=" { 'col-7':this.plano.id, 'col-12': !this.plano.id}" >
                          <span class="text-black"  > {{ contato.ultimaVisita | date: 'dd/MM/yyyy HH:mm'}}</span><br>
                          <label>Última visita</label>
                        </div>
                      </div>
                    </div>
                 </div>
                 <div class="col-xl-7 col-lg-6 col-md-4 col-12">
                      <div class="acoes">
                        <div class="row">
                            <div class="fa-pull-left editar"  >
                              <button class="btn btn-rounded btn-blue waves-effect waves-light editar" (click)="editarContato(contato)">
                                <i class="fe-edit"  > </i>
                              </button>
                            </div>
                            <div class="fa-pull-left botoes"  >
                              <button class="btn btn-rounded btn-outline-blue waves-effect width-lg" (click)="verContato(contato)">
                                <div class="icone tam1" [inlineSVG]="'/assets/fidelidade/icones/novoscartoes-dashboard.svg'"    [removeSVGAttributes]="['fill']"></div> Ver Contato
                              </button>
                              <span *ngIf="contato.cartoes.length">
                                    <button class="btn btn-rounded btn-outline-blue waves-effect width-lg" (click)="adcionarPontos(contato)" [hidden]="!plano.id">
                                         <div class="icone tam1" [inlineSVG]="'/assets/fidelidade/icones/novoselo.svg'" [removeSVGAttributes]="['fill']"></div>
                                      Adicionar Pontos
                                    </button>
                                    <button class="btn btn-rounded btn-outline-blue waves-effect width-lg" (click)="trocarPontos(contato)" [hidden]="!plano.id">
                                      <div class="icone tam1" [inlineSVG]="'/assets/fidelidade/icones/trocar-selo.svg'" [removeSVGAttributes]="['fill']"></div> Trocar Pontos
                                    </button>
                              </span>

                               <button *ngIf="!contato.cartoes.length" class="btn btn-rounded btn-outline-blue waves-effect width-lg" (click)="criarCartao(contato)" [hidden]="!plano.id">
                                  <div class="icone tam1" [inlineSVG]="'/assets/fidelidade/icones/novocartao.svg'"   [removeSVGAttributes]="['fill']"></div>

                                     Novo cartão
                               </button>

                              <a class="btn btn-rounded btn-outline-success waves-effect width-lg"  [href]="'http://wa.me/' + contato.codigoPais.replace('+', '') + contato.telefone" target="_blank">
                                <div class="icone "> <i class="fab fa-whatsapp" ></i> </div>
                                Enviar WhatsApp
                              </a>

                              <button class="btn btn-rounded btn-outline-danger waves-effect "  (click)="remover(contato)">
                                <div class="icone "> <i class="fas fa-trash" style="font-size: 24px; line-height: 32px" ></i> </div>
                              </button>

                            </div>

                        </div>
                      </div>
                 </div>
               </div>
          </div>

          <div class="row " *ngIf="contatos.length > 0">
             <div  class="col-12">
               <div class="mt-2">
                 <kendo-datapager [total]="paginacao.total"
                                  [skip]="paginacao.skip"
                              [pageSize]="paginacao.totalPorPagina"
                              (pageChange)="paginacaoMudou($event)">
                </kendo-datapager>
               </div>

             </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</span>

<div  [hidden]="!filtro || !filtro.exibir">
  <filtro-avancado #filtroComponent (onVoltar)="filtro.exibir=null" (onFiltrar)="onFiltrarAvancado($event)"    ></filtro-avancado>
</div>

<app-dialog-remover-contato [contatoSelecionado]="contatoSelecionado" [tela]="this" [abraDialogo]="abraDialogo"></app-dialog-remover-contato>


<br><br>
