<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    Sincronizar Preços
  </h4>
</kendo-dialog-titlebar>

<div  class="modal-body">

  <h4   *ngIf="buscando">
    <i class="k-i-loading k-icon"></i>
    Verificando novas atualizações, isso pode demorar alguns minutos... </h4>

  <h5 *ngIf="ultimaSincronizacao">Ultima sincronização preços realizada em <b>{{ultimaSincronizacao | date: 'dd/MM/yyyy HH:mm'}}</b></h5>


  <ng-container *ngIf="!buscando">

    <ng-container *ngIf="!sincronizado && !tudoSincronizado">
      <h4  class="float-left">
        Foram encontrados <b>{{produtosSincronizar.length}}</b> produtos
      </h4>

      <button class="btn btn-danger d-inline-block float-right" (click)="salvarNovosPrecos()" [disabled]="salvando">
        <i class="k-i-loading k-icon " *ngIf="salvando"></i>
        Atualizar todos
      </button>
      <div class="clearfix"></div>
    </ng-container>

    <div *ngIf="tudoSincronizado" class="alert alert-info" role="alert">
      Não há nenhum produto para sincronizar.
      <br>
      <button class="btn btn-warning mt-1" (click)="busqueTodosPrecos()" *ngIf="empresa.integracaoDelivery.sistema ==='bluesoft'">
        Nova busca sem a data ultima sincronização
      </button>
    </div>

    <div *ngIf="sincronizado" class="alert alert-success" role="alert">
      Sincronização realizada com sucesso!
      <button type="button" class="btn btn-light waves-effect" (click)="fecheModal()">
        Fechar
      </button>
    </div>

    <div *ngIf="erro" class="alert alert-danger" role="alert">
      {{erro}}
    </div>

    <ng-container *ngIf="produtosSincronizar.length">
      <h5><b>{{produtosSincronizar.length}}</b> Produtos  </h5>

      <kendo-grid   [data]="produtosFiltrados" [isDetailExpanded]="expandInStockProducts"
                    [kendoGridBinding]="produtosSincronizar" [height]="700"
                    [loading]="buscando" [scrollable]="'scrollable'">

        <ng-template kendoGridToolbarTemplate [position]="'top'">
          <input kendoTextBox placeholder="Busque por nome, categoria , ordem ou valor" class="form-control busca" [(ngModel)]="filtro"
                 appAutoFocus (input)="onFilterEvento($event)" *ngIf="!salvando"/>


          <kendo-progressbar [label]="label" [value]="percentualSalvos" *ngIf="salvando"></kendo-progressbar>

        </ng-template>

        <kendo-grid-column title="Id" field="id"    [width]="75"> </kendo-grid-column>
        <kendo-grid-column title="Cod." field="codigoPdv"    [width]="100"> </kendo-grid-column>
        <kendo-grid-column title="Imagem" class="coluna_menor" [width]="110">
          <ng-template kendoGridCellTemplate let-produto >

            <img [src]="produto.linkImagem" style="width: 80px;" *ngIf="produto.linkImagem">

            <i class="fe-image fa-2x" *ngIf="!produto.linkImagem"></i>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Nome" field="nome"     > </kendo-grid-column>

        <kendo-grid-column title="Preço"   [width]="200" >
          <ng-template kendoGridCellTemplate let-produto  >

              <span class="preco-novo text-success" *ngIf="!produto.precoAtualizado">{{produto.preco | currency: "R$"}}
              </span>

            <ng-container *ngIf="produto.precoAtualizado">

              <span class="preco-antigo text-muted" >{{produto.precoAntigo | currency: "R$"}}</span>

              <ng-container *ngIf="produto.atualizouDesconto">  novo desconto   </ng-container>
              <ng-container *ngIf="!produto.atualizouDesconto">  agora   </ng-container>

              <span  class="preco-novo  text-success"  >
                           <b>{{produto.precoAtualizado | currency: "R$"}}</b>
                 </span>

            </ng-container>

          </ng-template>
        </kendo-grid-column>



        <kendo-grid-command-column title="" [width]="75" [class]="{'comandos': true}">

          <ng-template kendoGridCellTemplate let-produto >
            <button kendoGridEditCommand [primary]="true"  *ngIf="!salvando && !produto.salvando && !produto.sincronizado" (click)="salvePreco(produto)"
                    kendoTooltip title="salvar preços">
              <i class="fa fa-save "></i>
            </button>

            <i class="k-icon k-i-loading" *ngIf="produto.salvando"></i>
            <i class="k-icon k-i-check-circle text-success fa-lg" *ngIf="produto.sincronizado"></i>

            <p class="text-danger" *ngIf="produto.erro">{{produto.erro}}</p>


          </ng-template>

        </kendo-grid-command-column>
        <ng-template kendoGridDetailTemplate let-produto     style="background: #fff;">


          <kendo-grid
            [data]="produto.opcoesAtualizadas"
            [loading]="buscando"   >
            <kendo-grid-column title="Id" field="id"    [width]="75"> </kendo-grid-column>
            <kendo-grid-column title="Cod." field="codigoPdv"   [width]="150" > </kendo-grid-column>
            <kendo-grid-column title="Nome" field="nome"     > </kendo-grid-column>

            <kendo-grid-column title="Preço Antigo"    [width]="125" >
              <ng-template kendoGridCellTemplate let-opcao  >
                <span class="preco-antigo text-muted">{{opcao.precoAntigo | currency: "R$"}}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Novo Preço"     [width]="125">
              <ng-template kendoGridCellTemplate let-opcao  >
                   <span  class="preco-novo text-success">
                      <b>{{opcao.novoPreco | currency: "R$"}}</b>
                    </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column      [width]="80">
              <ng-template kendoGridCellTemplate let-opcao  >
                <i class="k-icon k-i-check-circle text-success fa-lg" *ngIf="produto.sincronizado"></i>
              </ng-template>
            </kendo-grid-column>

          </kendo-grid>

        </ng-template>

      </kendo-grid>

    </ng-container>

  </ng-container>

</div>
