<h4 class="page-title">  <i class="far fa-building"></i>
  Catálogos</h4>

<div class="card">
  <div class="card-box">
<kendo-grid
  [kendoGridBinding]="catalogos"
  [loading]="carregando" style="min-height: 300px;"
  [scrollable]="'none'"
  (edit)="editarCatalogo($event.dataItem)"
  (remove)="abraDialogRemover($event)">
  <ng-template kendoGridToolbarTemplate [position]="'top'">
    <button class="btn btn-blue" (click)="criarCatalogo()">
      <i class="fe-plus mr-1"></i>
      Criar catálogo
    </button>
  </ng-template>
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Nenhum catálogo de produtos "
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column title="ID"    [width]="70">
    <ng-template kendoGridCellTemplate let-catalogo  >
      <span class="text-blue"><b>{{catalogo.id}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Nome">
    <ng-template kendoGridCellTemplate let-catalogo >
      <span><b>{{catalogo.nome}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Produtos" [width]="210">
    <ng-template kendoGridCellTemplate let-catalogo >
      <button class="btn btn-blue ml-1" (click)="verProdutos(catalogo)"
              kendoTooltip title="Ver Produtos" [disabled]="catalogo.processando">
        <i class="fas fa-boxes fa-lg"></i> Gerenciar Produtos
      </button>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Lojas" [width]="210">
    <ng-template kendoGridCellTemplate let-catalogo >
      <button   (click)="vaParaSincronizacao(catalogo)" class="btn btn-warning ml-1"
              kendoTooltip title="Ver Produtos" [disabled]="catalogo.processando">
        <i class="far fa-building fa-lg"></i> Sincronizar Lojas
      </button>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-catalogo>

      <button kendoGridEditCommand [primary]="true" (selectedChange)="editarCatalogo(catalogo)">
        <i class="fa fa-edit" kendoTooltip title="editar"></i>
      </button>
      <button kendoGridRemoveCommand kendoTooltip title="remover"><i class="fa fa-trash"></i></button>

      <button class="btn btn-xs btn-danger ml-1" (click)="desativarCatalogo(catalogo)"
              kendoTooltip title="Desativar Catálogo" *ngIf="catalogo.ativo" [disabled]="catalogo.processando">
        <i class="far fa-pause-circle fa-lg">  </i>
      </button>
      <button class="btn btn-xs btn-primary ml-1" (click)="ativarCatalogo(catalogo)"
              kendoTooltip title="Ativar Catálogo" *ngIf="!catalogo.ativo" [disabled]="catalogo.processando">
        <i class="far fa-play-circle fa-lg"  ></i>&nbsp;
      </button>


    </ng-template>
  </kendo-grid-command-column>

  <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
</kendo-grid>


<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Você tem certeza que deseja remover o catálogo
    "<strong>{{catalogoApagar.nome}} - {{catalogoApagar.codigo}}</strong>"?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="confirmeRemocao()" [primary]="true">Sim</button>
    <button kendoButton (click)="canceleRemocao()">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
  </div>
</div>
