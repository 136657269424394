
<div class="{{formClass}}"  [ngClass]="{'riscado': selectedItem.removido}" >
  <label *ngIf="exbirLabel">
    Selecione o {{labelNome}}</label>

  <kendo-dropdowntree #dropdowntree  kendoDropDownTreeExpandable  name="item{{indice}}"   class="form-control furniture"
    [kendoDropDownTreeHierarchyBinding]="data"     [(ngModel)]="selectedItem.insumo" required
     textField="nome"  valueField="id" childrenField="items"
    [disabled]=" selectedItem.removido"  [filterable]="true" (valueChange)="onValueChange($event)"
    [isNodeExpanded]="isNodeExpanded"  (nodeExpand)="handleExpand($event)" (nodeCollapse)="handleCollapse($event)">
  </kendo-dropdowntree>

  <div class="invalid-feedback">
    <p *ngIf="!selectedItem.insumo">Obrigatório</p>
  </div>

</div>


