<form [ngClass]="{'needs-validation': !frmM.submitted, 'was-validated': frmM.submitted}"
      novalidate #frmM="ngForm" (ngSubmit)="onSalvarMovimentacao()"  >

  <div class="row mb-2 mt-3">
    <div class="form-group  col-4"  *ngIf="movimentacao.insumo">
      <label  >Quantidade</label>
      <kendo-numerictextbox id="quantidade" [min]="0.1"   [format]="obtenhaFormato()"
                            class="form-control" name="quantidade" #quantidade=ngModel
                            [(ngModel)]="movimentacao.quantidade" required
                            placeholder="quantidade em {{obtenhaDescricaoUnidade(movimentacao.insumo)}}">
      </kendo-numerictextbox>

      <div class="invalid-feedback">
        <p *ngIf="quantidade.errors?.required">Quantidade é obrigatória</p>
        <p *ngIf="quantidade.errors?.minError">
          Quantidade deve ser maior que 0 </p>
      </div>
    </div>

    <div class="form-group col-6 col-sm-4">
      <label  >Motivo</label>

      <kendo-dropdownlist class="form-control" name="motivo" #motivo=ngModel
                          [data]="obtenhaMotivos()" required   (selectionChange)="alterouMotivo($event)"
                          [filterable]="false"
                          [textField]="'descricao'" [valueField]="'id'"
                          [(ngModel)]="movimentacao.motivoDaMovimentacao"
      >
      </kendo-dropdownlist>

      <div class="invalid-feedback">
        <p *ngIf="motivo.errors?.required">Motivo é obrigatório</p>
      </div>
    </div>

    <div class="form-group col-6 col-sm-4">
      <label for="horario">Data de movimentação</label>

      <div class="input-group">

        <kendo-datepicker [(ngModel)]="movimentacao.horario" id="horario"
                          name="horario" #horario=ngModel [format]="'dd/MM/yyyy HH:mm'"
                          class="form-control   k-display-inline-block" required  >
          <kendo-datepicker-messages
            today="Hoje"
            toggle="Alterar data"
          ></kendo-datepicker-messages>

        </kendo-datepicker>

        <div class="invalid-feedback">
          <p *ngIf="horario.errors?.required">Data é obrigatória</p>
        </div>

      </div>
    </div>
  </div>

  <div class="row mb-2">
    <div class="form-group  col-12">
      <label  >Observação</label>

      <textarea type="text" class="form-control" autocomplete="off" maxlength="255"
                id="observacao" name="observacao" [(ngModel)]="movimentacao.observacao" #observacao="ngModel"
                placeholder="Inclua uma observação se desejar" value=""  >
                 </textarea>

    </div>
  </div>

  <div *ngIf="varios">
    <kendo-grid    [data]="movimentacoes"  >
      <kendo-grid-messages
        pagerPage="Página"
        pagerOf="de"
        pagerItems="itens"
        noRecords="Nenhum grupo cadastrado"
        loading="Carregando"
        pagerItemsPerPage="ítems por página"
      >
      </kendo-grid-messages>

      <kendo-grid-column    [width]="30">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
          <span [class.removido]="dataItem.removido">         {{rowIndex + 1}}</span>

        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column   title="Insumo" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <app-selecionar-insumo [exbirLabel]="false" [selectedItem]="dataItem" [indice]="rowIndex" [formClass]="''">

          </app-selecionar-insumo>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Qtde"  [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div class="form-group mb-0">
            <kendo-numerictextbox   [min]="0.1"   [format]="obtenhaFormatoDoInsumo(dataItem.insumo)"
                                  class="form-control" name="quantidade{{rowIndex}}" #quantidade=ngModel
                                  [(ngModel)]="dataItem.quantidade" required
                                  placeholder="quantidade em {{obtenhaDescricaoUnidade(dataItem.insumo)}}">
            </kendo-numerictextbox>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column title=""  [class]="{'comandos': true}" [width]="100">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-item let-rowIndex="rowIndex">
          <button kendoGridRemoveCommand kendoTooltip title="remover" (click)="removaMovimentacao(rowIndex)"   >
            <i class="fa fa-trash text-danger"></i>
          </button>
        </ng-template>

      </kendo-grid-command-column>

    </kendo-grid>

    <button class="btn btn-outline-blue btn-xs mb-3 mt-2" type="button" (click)="novaMovimentacao()">
      <b> <i class="fe-plus"></i> Adicionar</b> </button>

  </div>


  <div class="modal-footer">

    <p  *ngIf="mensagemErro" class="text-danger ">
      <i class="mdi mdi-check-all mr-1"></i> {{mensagemErro}}
    </p>

    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]=" salvando"  >
      <i *ngIf="salvando" class="k-icon k-i-loading"></i>Salvar</button>

    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
            (click)="canceleMovimentacao()">Voltar</button>
  </div>

</form>
