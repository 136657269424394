import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ConstantsService} from "../../fidelidade/ConstantsService";

@Component({
  selector: 'app-selecionar-insumo',
  templateUrl: './selecionar-insumo.component.html',
  styleUrls: ['./selecionar-insumo.component.scss']
})
export class SelecionarInsumoComponent implements OnInit {
  @Input() selectedItem: any = {}
  @Input() indice: number;
  @Input() labelNome: any = 'insumo';
  @Input() exbirLabel = true;
  @Input() formClass = 'form-group';
  @ViewChild("dropdowntree", { static: true }) public dropdowntree: any;

  public data: any[] = [];
  public expandedNodes: number[] = [];
  constructor(private constantsService: ConstantsService) { }

  ngOnInit(): void {
   // this.dropdowntree.toggle(true);
   this.setInumos(this.constantsService.insumosDaLoja)
   if(this.selectedItem.insumo)
     this.updateExpandedKeys(this.selectedItem.insumo)
  }

  setInumos(insumos: any){
    insumos.forEach((insumo: any) => {
      let grupo = insumo.grupo || {id: -1, nome:  'OUTROS'};

      let itemGrupo = this.data.find((item: any) => item.nome === grupo.nome);

      if(!itemGrupo) {
        itemGrupo = { id: grupo.id, nome: grupo.nome.toUpperCase(), items: [],  disabled: true}
        this.data.push(itemGrupo)
      }

      itemGrupo.items.push(insumo);
    })

    this.data.sort((a: any, b: any) => a.nome.localeCompare(b.nome))

    this.data.forEach((item: any) => {
      item.items.sort((a: any, b: any) => a.nome.localeCompare(b.nome))
    })

  }

  onValueChange(item: any): void {

    // Verifica se o item possui filhos (items), impedindo sua seleção
    if (item && item.disabled) {
      // Fecha e reabre o dropdown para atualizar a seleção visual
      this.dropdowntree.toggle(false); // Fecha o dropdown
      setTimeout(() => {
        this.selectedItem.insumo = null;
        this.dropdowntree.toggle(true)}
        , 0);

    } else {
      this.selectedItem.insumo = item; // Permite a seleção apenas para itens internos
    }
  }

  updateExpandedKeys(itemSelecionado: any) {
    this.expandedNodes = [];
    this.data.forEach((grupo: any) => {
        grupo.items.forEach((item: any) => {
           if(item.id === itemSelecionado.id){
             debugger
             this.expandedNodes.push(grupo.id)
           }

        })
    })

  }

  public isNodeExpanded = (node: any): boolean => {
    return this.expandedNodes.indexOf(node.id) !== -1;
  };

  public handleCollapse(args: any): void {
    this.expandedNodes = this.expandedNodes.filter(
      (id) => id !== args.dataItem.id
    );
  }

  public handleExpand(args: any): void {
    this.expandedNodes = this.expandedNodes.concat(args.dataItem.id);
  }
}
