import {Component, EventEmitter, Input, OnInit, Output,  QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgImageSliderComponent} from "ng-image-slider";


@Component({
  selector: 'app-site-campo-adicional',
  templateUrl: './site-campo-adicional.component.html',
  styleUrls: ['./site-campo-adicional.component.scss']
})
export class SiteCampoAdicionalComponent implements OnInit {
  @ViewChildren('nav') navs: QueryList<NgImageSliderComponent>
  @Input()  produto: any;
  @Input()  campoAdicional: any;
  @Input()  exibirSabor = false;
  @Input()  tamanhoSabor: any;
  @Input()  itemPedido: any;
  @Input()  posicao: number;
  @Output() onMarcouOpcao = new EventEmitter();
  @Output() onDesmarcouOpcao = new EventEmitter();
  msgErro = '';
  clicouBusca: boolean;
  filtroOpcao: string;

  private timerBusca;
  public opcoesFiltradas: any[];

  constructor() { }

  ngOnInit() {
    if(this.campoAdicional.tipo === 'escolha-simples'){
      if(this.campoAdicional.opcoesDisponiveis.length === 1 && this.campoAdicional.obrigatorio) {
        this.itemPedido.adicionais[`escolhido_campo${this.posicao}`] = this.campoAdicional.opcoesDisponiveis[0].id;
        this.selecionouAdicionalSimples(this.posicao, this.campoAdicional.opcoesDisponiveis[0]);
      }
    }

    this.opcoesFiltradas = [...this.campoAdicional.opcoesDisponiveis]
    this.notifiqueOsQueEstaoSelecionados();

    if(this.exibirSabor)
      this.alterouTamanho(this.tamanhoSabor)


  }

  totalSelecionado(posicao: number){
    let item: any = this.itemPedido.adicionais['lista' + posicao];

    if(item) return item.totalSelecionado

    // console.log('Nenhum item na posiçao: ' + posicao)

    return 0;

  }

  diminuirQtde(opcao: any) {
    let itemPedidoOpcoes = this.itemPedido.adicionais['lista' + this.posicao]
    let itemPedidoOpcao = itemPedidoOpcoes['opcao_' + opcao.id]

    if(itemPedidoOpcao.qtde === 0) return;

    if(itemPedidoOpcao.qtde === 1) itemPedidoOpcao.selecionada = false;

    itemPedidoOpcao.qtde--
    itemPedidoOpcao.valorTotal = itemPedidoOpcao.qtde * opcao.valor
    itemPedidoOpcoes.totalSelecionado--

    this.itemPedido.atualizeTotal();
  }

  zereQtdeDaOpcao(opcao: any){
    let tipoDeCobranca = this.campoAdicional.tipoDeCobranca
    let itemPedidoOpcoes = this.itemPedido.adicionais['lista' + this.posicao]
    let itemPedidoOpcao = itemPedidoOpcoes['opcao_' + opcao.id]

    if(itemPedidoOpcao.qtde === 0) return;

    itemPedidoOpcao.valorTotal -= itemPedidoOpcao.qtde * opcao.valor

    itemPedidoOpcao.qtde = 0;
    itemPedidoOpcoes.totalSelecionado = 0;
    itemPedidoOpcao.selecionada = false;

    this.itemPedido.atualizeTotal();

  }

  aumentarQtde(opcao: any) {
    let itemPedidoOpcoes = this.itemPedido.adicionais['lista' + this.posicao]

    if(itemPedidoOpcoes.totalSelecionado === this.campoAdicional.qtdMaxima)
      return;


    let itemPedidoOpcao = itemPedidoOpcoes['opcao_' + opcao.id]

    if(itemPedidoOpcao.qtde === 0) itemPedidoOpcao.selecionada = true;

    itemPedidoOpcao.qtde++
    itemPedidoOpcao.valorTotal = itemPedidoOpcao.qtde * opcao.valor
    itemPedidoOpcoes.totalSelecionado++

    this.itemPedido.atualizeTotal()
  }

  vaiMudarSelecao(event: any, opcao: any) {
    let itemPedidoOpcao = this.itemPedido.adicionais['lista' + this.posicao]['opcao_' + opcao.id]
    let itemPedidoOpcoes = this.itemPedido.adicionais['lista' + this.posicao]

    let contagemAtual = itemPedidoOpcoes.totalSelecionado
    let vaiSelecionar = !itemPedidoOpcao.selecionada

    if(vaiSelecionar && contagemAtual === this.campoAdicional.qtdMaxima) event.preventDefault();
  }

  mudouSelecao(selecionou: any, opcao: any) {
    this.msgErro = '';

    let itemPedidoOpcao = this.itemPedido.adicionais['lista' + this.posicao]['opcao_' + opcao.id]
    let itemPedidoOpcoes = this.itemPedido.adicionais['lista' + this.posicao]
    if(selecionou) {
      itemPedidoOpcoes.totalSelecionado++
      itemPedidoOpcao.valorTotal = opcao.valor
      itemPedidoOpcao.qtde = 1
    }
    else {
      itemPedidoOpcao.valorTotal = 0
      itemPedidoOpcao.qtde = 0

      itemPedidoOpcoes.totalSelecionado--
    }

    itemPedidoOpcoes.valorTotal = opcao.valor * itemPedidoOpcoes.totalSelecionado
    this.itemPedido.atualizeTotal();
  }

  notifiqueOsQueEstaoSelecionados(){

    if(this.campoAdicional.opcoesDisponiveis.length) {
      this.campoAdicional.opcoesDisponiveis.forEach((opcaoDisponivel: any) => {
        if(opcaoDisponivel.dependencias && opcaoDisponivel.dependencias.length){
          opcaoDisponivel.esconder = true;
        }
      })
    }

    this.campoAdicional.esconder = !this.campoAdicional.opcoesDisponiveis.find((op: any) => !op.esconder);


    setTimeout( () => {
      if(this.campoAdicional.opcoesDisponiveis.length){
        this.campoAdicional.opcoesDisponiveis.forEach((opcaoDisponivel: any) => {
          if(this.campoAdicional.tipo === 'escolha-simples'){
            let campo = this.itemPedido.adicionais['escolhido_campo' + this.posicao];

            if(campo === opcaoDisponivel.id){
              this.notifiqueOpcaoMarcada(opcaoDisponivel)
            }
          }
        });

      }
    }, 250)

  }

  selecionouAdicionalSimples(posicao: any, opcao: any) {
    this.msgErro = '';

    this.itemPedido.adicionais['campo' + posicao] = opcao
    this.itemPedido.atualizeTotal();
    this.notifiqueOpcaoMarcada(opcao);

  }

  notifiqueOpcaoMarcada(opcao: any){
    let params: any = { opcaoId: opcao.id, adicionalId: this.campoAdicional.id}

    this.onMarcouOpcao.emit(params);
  }

  deselecione(posicao: any, opcao: any, evento: any = null) {
    if(this.itemPedido.adicionais['campo' + posicao] != null && this.itemPedido.adicionais['campo' + posicao].id === opcao.id) {
      delete this.itemPedido.adicionais['campo' + posicao];
      delete this.itemPedido.adicionais['escolhido_campo' + posicao];
      this.itemPedido.atualizeTotal();
      if(evento)
        evento.target.checked = false;
    }
    let params: any = { opcaoId: opcao.id, adicionalId: this.campoAdicional.id}
    this.onDesmarcouOpcao.emit(params);
  }

  alterouTamanho(produtoTamanho: any){
    this.campoAdicional.opcoesDisponiveis.forEach( (opcao: any) => {
      if(opcao.template && opcao.template.tamanho){
        opcao.disponivel = opcao.template.tamanho.id === produtoTamanho.template.id;
        if(!opcao.disponivel)
          this.deselecione(this.posicao, opcao)
      }
    })

    this.campoAdicional.semOpcaoDisponivel = !this.campoAdicional.opcoesDisponiveis.find((opcao) => opcao.disponivel);

    if(this.exibirSabor){

    }

  }

  exibaErro(msg: string) {
    this.msgErro = msg;
  }

  exibaFullScreen(i, opcao) {
    let id = 'nav' + i

    this.navs.find((item: NgImageSliderComponent, index: number, array: NgImageSliderComponent[]) => {
      let itemAny = item as any;
      if(itemAny.elRef.nativeElement.id === id)  {
        itemAny.imageOnClick(opcao)
        return true;
      }

      return false;
    })
  }

  abriuImagem($event: any) {

  }

  habilitarBusca() {
    this.clicouBusca = !this.clicouBusca
  }



  desmarcouOpcaoQueDepende(opcao: any){

    if(this.campoAdicional.opcoesDisponiveis.length){
      this.campoAdicional.opcoesDisponiveis.forEach((opcaoDisponivel: any) => {
        if(opcaoDisponivel.dependencias && opcaoDisponivel.dependencias.length){
          let dependenciasDoAdicional =
            opcaoDisponivel.dependencias.filter((dependencia: any) => dependencia.adicionalId === opcao.adicionalId)

          if(dependenciasDoAdicional.length){
            opcaoDisponivel.esconder = true;
            if(this.campoAdicional.tipo === 'escolha-simples'){
              this.deselecione(this.posicao, opcaoDisponivel)
            } else {
              this.zereQtdeDaOpcao(opcaoDisponivel)
            }
          }

        }
      })
    }

    this.campoAdicional.esconder = !this.campoAdicional.opcoesDisponiveis.find((op: any) => !op.esconder);
  }

  exibaOuEscondaOpcaoQueDepende(opcao: any){
    if(this.campoAdicional.opcoesDisponiveis.length){
      this.campoAdicional.opcoesDisponiveis.forEach((opcaoDisponivel: any) => {
        if(opcaoDisponivel.dependencias && opcaoDisponivel.dependencias.length){
          let dependenciasDoAdicional =
            opcaoDisponivel.dependencias.filter((dependencia: any) => dependencia.adicionalId === opcao.adicionalId)

          if(dependenciasDoAdicional.length){
            opcaoDisponivel.esconder =
              !dependenciasDoAdicional.find((dependencia: any) => dependencia.opcaoDependente.id === opcao.opcaoId)

            if(opcaoDisponivel.esconder){
              if(this.campoAdicional.tipo === 'escolha-simples'){
                this.deselecione(this.posicao, opcaoDisponivel)
              } else {
                this.zereQtdeDaOpcao(opcaoDisponivel)
              }
            }
          }
        }
      })
    }
    this.campoAdicional.esconder = !this.campoAdicional.opcoesDisponiveis.find((op: any) => !op.esconder);
  }

  onFilter() {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      if(!this.filtroOpcao) {
        this.opcoesFiltradas = [...this.campoAdicional.opcoesDisponiveis]
        return;
      }
      this.opcoesFiltradas = []
      console.log(this.filtroOpcao)


      let opcao: any;
      for(opcao of this.campoAdicional.opcoesDisponiveis)
        if (opcao.nome.toLowerCase().includes(this.filtroOpcao.toLowerCase()) ||
          (opcao.descricao && opcao.descricao.toLowerCase().includes(this.filtroOpcao.toLowerCase())))
          this.opcoesFiltradas.push(opcao)
    }, 1000);
  }
}

