<div>
  <h4 _ngcontent-mki-c5="" class="page-title"><i _ngcontent-eic-c2="" class="fab fa-whatsapp"></i> Templates de Mensagens</h4>

  <div class="card">
    <div class="row filtro">
      <div class="col-12 col-sm-3" *ngIf="redes">
        <div class="form-group ml-3 mt-3 mb-2  "   >
          <label >Rede</label>
          <kendo-combobox  name="rede" [(ngModel)]="filtro.rede" [data]="redes"
                           placeholder="Filtre pela rede" class="form-control" [textField]="'descricao'"
                           (ngModelChange)="carregueTemplates()">
          </kendo-combobox>


        </div>

      </div>
    </div>
    <hr>
    <div *ngIf="!this.filtro || !this.filtro.rede">
      <h4 class="mt-5 ml-auto mr-auto mb-5 text-center">É necessário selecionar uma rede para cadastrar templates</h4>
    </div>
    <kendo-grid *ngIf="this.filtro && this.filtro.rede" [pageSize]="paginacao.size"
                [skip]="paginacao.skip"
                [style]=""
                [kendoGridBinding]="templatesDeMensagem"
                [loading]="carregando" style="min-height: 300px;"
                [scrollable]="'none'"
                footerStyle="font-size: 11px;"
                [pageable]="{
              buttonCount: paginacao.buttonCount,
              info: paginacao.info,
              type: 'numeric',
              pageSizes: paginacao.pageSizes,
              previousNext: paginacao.previousNext
            }"
                (edit)="editarTemplate($event)"
                (pageChange)="mudouPaginacao($event)">
      <ng-template kendoGridToolbarTemplate [position]="'top'">
        <button class="  btn btn-blue"  (click)="novoTemplateDeMensagem()">
          <i class="fe-plus mr-1"></i>
          Adicionar template de mensagem
        </button>
      </ng-template>
      <kendo-grid-messages
        pagerPage="Página"
        pagerOf="de"
        pagerItems="itens"
        noRecords="nenhum template cadastrado"
        loading="Carregando"
        pagerItemsPerPage="itens por página"
      >
      </kendo-grid-messages>


      <kendo-grid-column field="id" title="ID"   [width]="70">  </kendo-grid-column>

      <kendo-grid-column title="Nome" [width]="200" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span><b>{{dataItem.nome}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Mensagem" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="text-primary">{{dataItem.mensagem}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Quem usa?"   [width]="110" >
        <ng-template kendoGridCellTemplate let-dataItem >
          <i class="fa fa-building fa-lg cpointer text-blue"
             (click)="abraGridEmpresasUsando(dataItem)" ></i>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column title="" [width]="100">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
          <button kendoGridEditCommand [primary]="true" (selectedChange)="editarTemplate($event)">Editar</button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

</div>

