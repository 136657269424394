
<h2>Sincronizar catálogos das lojas <b>{{nomeLojas}}</b></h2>


<div class="card-box">
  <h3 class="mt-2"><b>{{empresasSincronizar.length}}</b> empresas da rede para sincronizar</h3>

  <h4 [hidden]="!empresasNaoSincronizar.length">
    {{empresasNaoSincronizar.length}} serão ignoradas:

    <span *ngFor="let empresa of empresasNaoSincronizar" >
      <b> {{empresa.nome}}, </b>
    </span>


  </h4>

</div>

<div class="card-box" *ngIf="importacao">

  <h5 *ngIf="importacao.empresa">Importando empresa: <b>{{importacao.empresa.nome}} </b>
    <i class="k-icon k-i-loading" ></i></h5>

  <kendo-progressbar [label]="obtenhaLabel()" [value]="importacao.totalProcessados"    [style.width.%]="100"  [emptyCssStyle]="emptyStyles"
                     [max]="importacao.qtde" [progressCssStyle]="progressStyles"></kendo-progressbar>

  <div class="row  mt-3">
      <div class="col">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">
                Importaçoes completadas: {{respostas.concluidos.length}}
              </h4>
              <div class="inbox-widget" >
                  <div class="simplebar-content"  >
                    <div *ngFor="let resposta of respostas.concluidos " class="inbox-item">
                      <h5><b>{{resposta.empresa}}</b></h5>
                      <div *ngIf="!resposta.nenhumSincronizado" class="alert alert-success">
                        <span [hidden]="!resposta.totalNovos"> Novos <b>{{resposta.totalNovos}}</b></span>
                        <span [hidden]="!resposta.totalAtualizados"> Atualizados <b>{{resposta.totalAtualizados}}</b></span>
                        <span [hidden]="!resposta.totalRemovidos"> Removidos <b>{{resposta.totalRemovidos}}</b></span>
                      </div>

                      <div *ngIf="resposta.nenhumSincronizado" class="text-info">Catálogo já está sincronizado.</div>

                    </div>
                  </div>
             </div>
            </div>
          </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-3">
              Importaçoes incompletas: {{respostas.incompletos.length}}
            </h4>
              <button class="btn btn-danger mt-2" (click)="reimporteIncompletos()" [hidden]="!importacao.finalizada || !respostas.incompletos.length">
                <i class="fa fa-sync"></i> Reimportar incompletas</button>

              <div class="inbox-widget" >
                <div class="simplebar-content"  >
                  <div *ngFor="let resposta of respostas.incompletos " class="inbox-item">
                    <h5><b>{{resposta.empresa}}</b></h5>
                    <div *ngIf="!resposta.nenhumSincronizado" class="alert alert-success">
                      <span [hidden]="!resposta.totalNovos"> Novos <b>{{resposta.totalNovos}}</b></span>
                      <span [hidden]="!resposta.totalAtualizados"> Atualizados <b>{{resposta.totalAtualizados}}</b></span>
                      <span [hidden]="!resposta.totalRemovidos"> Removidos <b>{{resposta.totalRemovidos}}</b></span>
                    </div>

                    <div *ngFor="let erro of resposta.erros" class="text-info">
                       <p class="text-danger">{{erro}}</p>
                    </div>

                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-3">
              Importaçoes falhas: {{respostas.erros.length}}
            </h4>

            <button class="btn btn-danger mt-2" (click)="reimporteFalhas()" [hidden]="!importacao.finalizada || !respostas.erros.length">
              <i class="fa fa-sync"></i> Reimportar as que falharam</button>


            <div class="inbox-widget"  >
              <div class="simplebar-content"  >
                <div *ngFor="let resposta of respostas.erros " class="inbox-item">
                  <h5><b>{{resposta.empresa}}</b></h5>
                  <div class="text-danger">{{resposta.erro}}</div>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
  </div>

</div>

<div class="card-box" [hidden]="importacao">

  <h4 *ngIf="carregandoProdutos" >
    Buscando o catálogo de produtos do site...
    <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;    height: 90px;" ></div>

  </h4>

  <div *ngIf="erroBuscarCatalogo" class="alert alert-danger alert-dismissible fade show mt-3" role="alert">
    {{erroBuscarCatalogo}}
    <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div [hidden]="carregandoProdutos">
    <div class="row">
      <div class="col col-sm-3">
        <div class="form-group mt-2 mb-3   "  >
          <label class="mt-2 k-checkbox-label"  >
            <input type="checkbox" id="agendarEntrega" name="agendarEntrega" class="k-checkbox " kendoCheckBox
                   [(ngModel)]="opcoes.sincronizarPrecos"
            />

            Sincronizar Preços

          </label>
        </div>
      </div>
      <div class="col col-sm-3">
        <div class="form-group mt-2 mb-3   "  >
          <label class="mt-2 k-checkbox-label"  >
            <input type="checkbox" id="atualizarFotos" name="atualizarFotos" class="k-checkbox " kendoCheckBox
                   [(ngModel)]="opcoes.sincronizarImagens"
            />

            Atualizar Fotos

          </label>
        </div>
      </div>
    </div>

    <button class="btn btn-warning mt-2" (click)="inicieSincronizacao()" [disabled]=" this.importacao">
      <i class="fa fa-sync"></i> Sincronizar Todos Catálogos</button>

    <app-painel-importacao-produtos #painelImportacao  [modoExibicao] = true ></app-painel-importacao-produtos>
  </div>

</div>


