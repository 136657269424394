<div class="alert alert-warning" *ngIf="empresa.cardapio?.modoVisualizacao">
    <i class="k-icon k-i-warning"></i> Cardápio configurado para <b>modo visualizaçao</b>
</div>




<span [hidden]="empresa.sempreReceberPedidos">
  <div class="form-group mb-2 col-12 col-sm-6">
  <label  >Serviço </label>

  <kendo-combobox   name="servico" [data]="servicos"   (valueChange)="selecionouServico($event)"
                    class="form-control" appAutoFocus   [allowCustom]="false"
                    [(ngModel)]="servico"  [textField]="'descricao'">
  </kendo-combobox>
</div>

  <div class="form-group mb-2 col-12">
  <label  >Dias da semana</label>
  <form novalidate #form="ngForm" class="k-form">
    <kendo-grid #gridHorarios [data]="viewHorarios | async" [pageSize]="this.horariosDelivery.length"
                (edit)="editarHorario($event)" (cancel)="cancelarEdicaoHorario($event)"
                (remove)="removerHorario($event)"
                [groupable]="groupable"
                [group]="groups"
                (save)="salvarHorario($event)"   (dataStateChange)="onStateChange($event)" >

      <kendo-grid-column  field="diaDaSemana"  title="Dia Semana" [hidden]="true">
        <ng-template kendoGridGroupHeaderTemplate let-value="value">
          {{getDiaSemana(value)}}
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
          <span>{{ getDiaSemana(dataItem.diaDaSemana)  }} </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="funciona" editor="boolean" title="Funciona" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
          <i class="fa fa-check text-success" *ngIf="dataItem.funciona" > </i>
          <i class="fa fa-times text-danger" *ngIf="!dataItem.funciona" > </i>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input [(ngModel)]="dataItem.funciona" kendoGridFocusable name="funciona" type="checkbox"/>
        </ng-template>
      </kendo-grid-column>


      <kendo-grid-column     class="coluna_menor"  title="Horário de abertura" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span>{{ dataItem.horarioAbertura  | date:'HH:mm' }}</span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem"  >
          <kendo-timepicker kendoGridFocusable  name="horarioAbertura" [(ngModel)]="dataItem.horarioAbertura"></kendo-timepicker>

        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column     class="coluna_menor"  title="Horário de fechamento">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"  >
          <span>{{ dataItem.horarioFechamento  | date:'HH:mm' }} </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <kendo-timepicker kendoGridFocusable  name="horarioFechamento" [(ngModel)]="dataItem.horarioFechamento"></kendo-timepicker>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="" [width]="220">
        <ng-template kendoGridCellTemplate  let-dataItem="dataItem"  >
            <button kendoGridEditCommand type="button" [primary]="true"><i class="fa fa-edit"></i></button>
            <button
              kendoGridSaveCommand type="button"
              [disabled]="form.invalid || form.pristine">{{ !dataItem.id ? 'Salvar' : 'Atualizar' }}</button>
            <button kendoGridCancelCommand type="button">{{   'Cancelar' }}</button>
            <button class="k-button" kendoButton [hidden]="indiceLinhaEditada" type="button" title="Adicionar turno" (click)="adicionarTurno(dataItem)"><i class="far fa-plus-square"></i></button>
            <button kendoGridRemoveCommand  type="button"><i class="fa fa-trash"></i></button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </form>
</div>
</span>

<div class="form-group mb-4 col-12" >
  <div class="input-group mt-2 mb-2">
    <input  name="sempreReceberPedidos" id='sempreReceberPedidos' kendoCheckBox (change)="alterouSempreReceberPedidos()"  class="k-checkbox ng-untouched ng-pristine ng-valid" type="checkbox"
            [(ngModel)]="empresa.sempreReceberPedidos"/>
    <label  class="k-checkbox-label">Sempre receber pedidos</label>
  </div>

  <ng-container  *ngIf="empresa.sempreReceberPedidos">
    <br>
    <div class="alert alert-info alert-dismissible fade show mt-2" role="alert">
      Loja receberá pedidos 24hs por dia, todas os dias da semana
      <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

  </ng-container>


</div>
