import {TipoDePagamentoEnum} from "./TipoDePagamentoEnum";
import {FormaDePagamento} from "./FormaDePagamento";
import {TaxaCobranca} from "../../../../projects/loja/src/objeto/TaxaCobranca";

export class Pagamento {
  formaDePagamento: FormaDePagamento;
  trocoPara: number;
  temTroco: string;
  valor: number;
  taxa: number;
  desconto: number;
  removido: boolean;
  totalComTaxa: number;

  constructor(public id: number = null) {
    this.trocoPara = 0.00;
  }

  ehPagamentoEmDinheiro() {
    return this.formaDePagamento.nome === TipoDePagamentoEnum.Dinheiro;
  }

  setValorComTaxa(taxaCobranca: any){
    let valorTaxa = TaxaCobranca.calcule(taxaCobranca,    this.valor)

    this.taxa = Number(valorTaxa.toFixed(2))
    this.valor = Number( (   this.valor + this.taxa).toFixed(2) )

  }

  obtenhaValorSemTaxa(){
    let valor = this.valor;

    if(this.taxa) valor -= this.taxa;

    return  Number( valor.toFixed(2))
  }
}
