<form [ngClass]="{'needs-validation': !frmFormasDeEntrega.submitted, 'was-validated': frmFormasDeEntrega.submitted}"
      novalidate #frmFormasDeEntrega="ngForm" (ngSubmit)="onSubmitFormasRetiradas()">
  <div class="row">
    <div class="col-12">
      <div *ngFor="let forma of formasDeEntrega" class="card">

        <div class="card-header">
          <div class="form-group mb-0 ">
            <label class="k-checkbox-label"  >
              <input type="checkbox" id="{{forma.nome}}" name="{{forma.nome}}" class="k-checkbox "  kendoCheckBox  [(ngModel)]="forma.ativa" />
              {{forma.nome}}
            </label>
          </div>
        </div>

        <div class="card-body" *ngIf="forma.formaDeEntrega.id === 1 && forma.ativa" >
          <div class="row">
            <div class="form-group col col-sm-5 col-lg-4" >
              <h5 class="card-title">Valor mínimo do Pedido para retirada</h5>

              <input type="text" class="form-control" autocomplete="off"  #valorMinimoPedidoEntrega=ngModel
                     currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                     name="valorMinimoPedidoEntrega" [(ngModel)]="entregaRetirada.valorMinimoPedido"
                     [minimo]="0"
                     placeholder="Valor mínimo do pedido em R$ para entrega"   >


              <span class="text-muted"  >
                   <small>
                     Não receber pedidos com valores inferiores a <b>{{entregaRetirada.valorMinimoPedido | currency:"BRL"}} </b>
                    </small>
              </span>

            </div>
            <div class="form-group col col-sm-5 col-lg-4" >
              <h5 class="card-title">Valor máximo do Pedido para retirada</h5>

              <input type="text" class="form-control" autocomplete="off"  #valorMaximoPedido=ngModel
                     currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                     name="valorMaximoPedidoRetirada" [(ngModel)]="entregaRetirada.valorMaximoPedido"
                     [minimo]="0"
                     placeholder="Valor Máximo do pedido em R$ para entrega"   >


              <span class="text-muted"  >
                   <small>
                     Não receber pedidos com valores superiores a <b>{{entregaRetirada.valorMaximoPedido | currency:"BRL"}} </b>
                    </small>
              </span>

            </div>
          </div>
          <div class="form-group">
            <label class="k-checkbox-label"  >
              <input type="checkbox" id="retiradaPermiteAgendamento" name="retiradaPermiteAgendamento"
                     class="k-checkbox " kendoCheckBox
                     [(ngModel)]="forma.permiteAgendamento" />
              Permite agendamento da retirada
            </label>
          </div>

          <ng-container *ngIf="forma.permiteAgendamento">
            <div class="form-group   ml-3"  >
              <label class="k-checkbox-label"  >
                <input type="checkbox" id="retiradaAgendamentoObrigatorio" name="retiradaAgendamentoObrigatorio"
                       class="k-checkbox " kendoCheckBox
                       [(ngModel)]="forma.agendamentoObrigatorio" />
                Agendamento Obrigatório
              </label>
            </div>

            <div class="form-group  ml-3 w350">
              <label class="k-checkbox-label"  >
                <input type="checkbox" id="retiradaNaoPerguntarHorario" kendoCheckBox name="retiradaNaoPerguntarHorario" class="k-checkbox "
                       [(ngModel)]="forma.naoPerguntarHorario" />
                Agendamento não perguntar horário ao cliente
              </label>
            </div>


            <div class="form-group  ml-3  "  >
              <h5 class="card-title  ">Intervalo de tempo do agendamento (em minutos)</h5>

              <div class="input-group   d-inline-block w250"  >
                <div  class="d-inline-block">
                  <kendo-numerictextbox class="form-control   mr-2" autocomplete="off"  [min]="1" #retiradaIntervaloAgendamento="ngModel"
                                        name="retiradaIntervaloAgendamento" [format]="'0 minutos'"
                                        [(ngModel)]="forma.intervaloAgendamento" placeholder="30 minutos">     </kendo-numerictextbox>



                </div>
                <div class="invalid-feedback" >
                <span    *ngIf="retiradaIntervaloAgendamento?.errors?.minError">
                         Informe um intervalo maior que 0 minutos
                      </span>
                  <span>
                  {{retiradaIntervaloAgendamento?.errors}}
                </span>

                </div>

              </div>
            </div>

            <div class="form-group  ml-3 "  >
              <h5 class="card-title  ">Limitar tempo mínimo e máximo do agendamento
                <span [hidden]="forma.naoPerguntarHorario"> (em relação ao horário de compra do cliente)</span></h5>

              <div class="input-group   d-inline-block w250"  >
                <label class=" ">Horário Mínimo</label>

                <div  class="d-inline-block">
                  <kendo-numerictextbox class="form-control   mr-2" autocomplete="off"   #limiteMinimo="ngModel"
                                        name="retiradaLimiteMinimo" [format]="'0 horas'"
                                        [(ngModel)]="forma.agendamentoLimiteMinimo" placeholder="tempo minimo (hrs)">     </kendo-numerictextbox>



                </div>

              </div>

              <div class="input-group  d-inline-block w250"   >
                <label class=" ">Horário Máximo</label>

                <div class="d-inline-block">
                  <kendo-numerictextbox class="form-control  " autocomplete="off"  [min]="0" #limiteMaximo="ngModel"
                                        name="retiradaLimiteMaximo" [format]="'0 horas'"
                                        [(ngModel)]="forma.agendamentoLimiteMaximo" placeholder="tempo máximo (hrs)">     </kendo-numerictextbox>


                </div>

              </div>

              <h5 class="card-title mt-2  ">Limitar quantidade pedidos recebidos a cada 30min</h5>

              <div class="form-group  w350">
                <kendo-numerictextbox [attr.type]="'number'"    class="form-control" [format]="'máximo ##### pedidos'"   name="qtdePedidosRetirar"
                                      [(ngModel)]="forma.limitePedidosAgendados"
                                      [min]="0"   placeholder="quantidade máxima de pedidos (30min)" >   </kendo-numerictextbox>
              </div>

            </div>

          </ng-container>

          <div class="form-group">
            <label class="k-checkbox-label"  >
              <input type="checkbox" id="permiteComerNoLocal" name="permiteComerNoLocal"
                     class="k-checkbox " kendoCheckBox
                     [(ngModel)]="forma.permiteComerNoLocal" />
              Habilitar comer no local
            </label>
          </div>

          <div class="form-group mb-0"  >
            <label class="k-checkbox-label"  >
              <input type="checkbox" id="bairroOpcional" kendoCheckBox name="bairroOpcional" class="k-checkbox "   [(ngModel)]="forma.bairroOpcional" />
              Bairro Opcional
            </label>
          </div>

          <div class="row mt-3 mb-2">

            <div class="form-group  col  " >
              <h5 class="card-title">Tempo médio de retirada</h5>

              <div class="input-group mt-2">
                <label class="mt-2">Entre</label>

                <div style="width: 250px" class="d-inline-block">
                  <kendo-numerictextbox class="form-control ml-2 mr-2" autocomplete="off"  [min]="0" #alcance="ngModel"
                                        name="tempoMinimoRetirada" [format]="'0 min'"
                                        [(ngModel)]="forma.tempoMinimoRetirada" placeholder="tempo minimo (min)">     </kendo-numerictextbox>
                </div>

                <label class="mt-2">e</label>


                <div style="width: 250px" class="d-inline-block">
                  <kendo-numerictextbox class="form-control ml-2" autocomplete="off"  [min]="0" #alcance="ngModel"
                                        name="tempoMaximoRetirada" [format]="'0 min'"
                                        [(ngModel)]="forma.tempoMaximoRetirada" placeholder="tempo máximo (min)">     </kendo-numerictextbox>
                </div>

              </div>

            </div>
          </div>


        </div>

        <div *ngIf="forma.formaDeEntrega.id === 2 && forma.ativa" class="card-body">
          <fieldset>
            <legend>
              <h5 class="card-title mt-0 mb-0">Forma de cobrança</h5>
            </legend>

            <ng-container *ngIf="empresa?.integracaoUberdirect">
              <input id="apiuber" name="apiuber" type="radio"  class="k-radio"   kendoRadioButton
                     [(ngModel)]="forma.tipoDeCobranca"  [value]="'apiuber'"
                     [required]="true"/>
              <label for="apiuber" class="ml-1 mr-5">Por API Uber</label>

            </ng-container>

            <ng-container *ngIf="empresa?.integracaoOpendeliveryLogistica">
              <input id="cobrancaPorApi" name="cobrancaPorApi" type="radio"  class="k-radio"   kendoRadioButton
                     [(ngModel)]="forma.tipoDeCobranca"  [value]="'apiparceiro'"
                     [required]="true"/>
              <label for="cobrancaPorApi" class="ml-1 mr-5">Por API OpenDelivery</label>

            </ng-container>

            <input id="cobrancaPorDistancia" name="cobrancaPorDistancia" type="radio"  class="k-radio"   kendoRadioButton
                   [(ngModel)]="forma.tipoDeCobranca"  [value]="'distancia'"
                   [required]="true"/>
            <label for="cobrancaPorDistancia" class="ml-1 mr-5">Por distância</label>
            <input id="cobrancaPorZona" name="cobrancaPoZona" type="radio"  class="k-radio"   kendoRadioButton
                   [(ngModel)]="entregaEmCasa.tipoDeCobranca"  [value]="'zona'"
                   [required]="true"/>

            <label for="cobrancaPorZona" class="ml-1 mt-2 mr-5">Por região de entrega</label>

            <input id="cobrancaKML" name="cobrancaKML" type="radio"  class="k-radio"   kendoRadioButton
                   [(ngModel)]="entregaEmCasa.tipoDeCobranca"  [value]="'raio'"
                   [required]="true"/>

            <label for="cobrancaKML" class="ml-1 mt-2 mr-5">Por Raios de Alcance</label>


            <input id="cobrancaValorFixo" name="cobrancaValorFixo" type="radio"  class="k-radio"   kendoRadioButton
                   [(ngModel)]="entregaEmCasa.tipoDeCobranca"  [value]="'fixo'"
                   [required]="true"/>

            <label for="cobrancaValorFixo" class="ml-1 mt-2">Por Valor Fixo</label>

            <div class="ml-3 mt-1" *ngIf="forma.tipoDeCobranca == 'apiuber'">
              <div class="alert alert-info alert-dismissible fade show col col-sm-6" role="alert">
                <p>Valor do frete calculado diretamente (via api) pelo <b>Uber Direct</b></p>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <div class="form-group col col-sm-4">
                 <h5 class="card-title">Add taxa fixa no valor cobrado</h5>

                 <input type="text" class="form-control" autocomplete="off"
                        currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                        name="taxaFixa" [(ngModel)]="forma.taxaFixa"
                        [minimo]="0" #taxaFixa="ngModel"
                        placeholder="Taxa fixa para qualquer pedido"   >


               </div> -->
            </div>

            <div class="ml-3 mt-1" *ngIf="forma.tipoDeCobranca == 'apiparceiro'">
              <div class="alert alert-info alert-dismissible fade show col col-sm-6" role="alert">
                <p>Valor do frete calculado diretamente (via api) pelo pelo parceiro <b>{{empresa.integracaoOpendeliveryLogistica?.appid}}</b></p>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <!-- <div class="form-group col col-sm-4">
                 <h5 class="card-title">Add taxa fixa no valor cobrado</h5>

                 <input type="text" class="form-control" autocomplete="off"
                        currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                        name="taxaFixa" [(ngModel)]="forma.taxaFixa"
                        [minimo]="0" #taxaFixa="ngModel"
                        placeholder="Taxa fixa para qualquer pedido"   >


               </div> -->
            </div>

            <div class="ml-3 mt-1" *ngIf="forma.tipoDeCobranca === 'distancia'">
              <div class="form-group mb-2 mt-2 escolher">
              <span class="radio radio-blue">
                  <input id="arredondarDistancias" name="arredondarDistancias" type="checkbox"  class="k-checkbox"   kendoCheckBox
                         [(ngModel)]="forma.arredondarDistancias"
                         [required]="false"/>
                  <label for="perguntarEnderecoInicio" class="ml-1">Arredondar distâncias para cima (Exemplo: 1,6KM será arredondado como 2,0KM)</label>
              </span>
              </div>

              <h5 class="card-title ">Taxa e valores</h5>
              <table class="table table-sm taxas mb-1" >
                <thead>
                <tr>
                  <th  class="w160">Alcance  </th>
                  <th class="w160">Tipo de cobrança</th>
                  <th class="w125">Valor Mínimo</th>
                  <th class="w160">Valor / KM </th>
                  <th class="w160">Valor Mínimo Pedido </th>
                  <th class="w120"></th>
                </tr>
                </thead>
                <tbody *ngFor="let raioDeCobranca of  entregaEmCasa.raiosDeCobranca; let i = index;">
                <tr [ngClass] = "{'removido': raioDeCobranca.removido, 'desativado':  raioDeCobranca.desativado}">
                  <td>
                    <div class="form-group  ">
                      <kendo-numerictextbox class="form-control" autocomplete="off"  [min]="0" #alcance="ngModel"
                                            [format]="'até #####.00 km'" name="alcance{{i}}"  id="alcance{{i}}" required
                                            [(ngModel)]="raioDeCobranca.alcance" placeholder="Até em km">     </kendo-numerictextbox>

                      <div class="invalid-feedback" >
                        <span    *ngIf="!raioDeCobranca.alcance" >obrigatório</span>
                        <span    *ngIf="alcance?.errors?.minError">
                         informe um valor maior que 0km
                      </span>

                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group  ">
                      <kendo-combobox   name="tipo{{raioDeCobranca.id}}" [(ngModel)]="raioDeCobranca.tipoDaTaxa" [data]="tiposDeTaxas"
                                        [allowCustom]="false" placeholder="Selecione um tipo" class="form-control" [clearButton]="false"
                                        [textField]="'descricao'"   required
                                        (selectionChange)="alterouTipo(raioDeCobranca, $event)">
                      </kendo-combobox>

                      <span class="invalid-feedback" *ngIf="!raioDeCobranca.tipo">
                     obrigatório
                   </span>
                    </div>
                  </td>
                  <td>
                 <span *ngIf="raioDeCobranca.tipo === 'VALOR_POR_DISTANCIA'">
                     <div class="form-group  ">
                             <kendo-numerictextbox [attr.type]="'number'"    class="form-control" format="c2"   name="valorMinimoTaxa{{raioDeCobranca.id}}"
                                                   placeholder="Mínimo R$"  required   [(ngModel)]="raioDeCobranca.valorMinimoTaxa"     [min]="0"  >
                          </kendo-numerictextbox>
                     </div>

                     <span class="invalid-feedback" *ngIf="raioDeCobranca.valorMinimoTaxa == null">
                       obrigatório
                     </span>
                 </span>

                    <span *ngIf="raioDeCobranca.tipo !== 'VALOR_POR_DISTANCIA'" class="text-center d-block">
                     -
                 </span>
                  </td>
                  <td>
                  <span *ngIf="raioDeCobranca.tipo === 'VALOR_POR_DISTANCIA'">
                    <div class="form-group  ">
                       <kendo-numerictextbox [attr.type]="'number'"    class="form-control" [format]="'$0.0 por km'"   name="valorKmTaxa{{raioDeCobranca.id}}"
                                             [(ngModel)]="raioDeCobranca.valorKmTaxa" required
                                             [min]="0"   placeholder="Valor do KM" >   </kendo-numerictextbox>

                       <span class="invalid-feedback" *ngIf="raioDeCobranca.valorKmTaxa == null">
                         obrigatório
                       </span>
                    </div>
                  </span>

                    <span *ngIf="raioDeCobranca.tipo === 'VALOR_FIXO' || raioDeCobranca.tipo === 'FRETE_GRATIS'">
                    <div class="form-group  ">
                        <kendo-numerictextbox [attr.type]="'number'"    class="form-control" [format]="'c2'"   name="valorFixo{{raioDeCobranca.id}}"
                                              [(ngModel)]="raioDeCobranca.valorFixo" required
                                              [min]="0"   placeholder="Valor R$" [disabled]="ehFreteGratis(raioDeCobranca)" >  </kendo-numerictextbox>

                        <span class="invalid-feedback" *ngIf="raioDeCobranca.valorFixo == null">
                         obrigatório
                       </span>
                    </div>
                  </span>

                    <span *ngIf="raioDeCobranca.tipo === 'VALOR_MANUAL'" class="text-center d-block">
                     -
                 </span>

                  </td>
                  <td>
                    <kendo-numerictextbox class="form-control" autocomplete="off"  [min]="0"
                                          name="minimoPedido{{raioDeCobranca.id}}"   [format]="'c2'"
                                          [(ngModel)]="raioDeCobranca.valorMinimoPedido" placeholder="Valor R$">     </kendo-numerictextbox>
                  </td>
                  <td>
                    <ng-container *ngIf="!raioDeCobranca.removido">

                      <button type="button" class="btn btn-warning btn-xs mt-1 mr-1"  (click)="pauseRaioCobranca(raioDeCobranca)"
                              kendoTooltip title="Parar temporariamente esse radio de alcançe" *ngIf="!raioDeCobranca.desativado">
                        <i class="far fa-pause-circle fa-lg">  </i>
                      </button>


                      <button class="btn btn-xs btn-primary  mt-1  mr-1" (click)="ativeRaioCobranca(raioDeCobranca)"
                              kendoTooltip title="Ativar novamente esse raio de alcançe" *ngIf="raioDeCobranca.desativado"  >
                        <i class="far fa-play-circle fa-lg"  ></i>&nbsp;

                      </button>

                      <button type="button" class="btn btn-danger btn-xs mt-1"  (click)="removaRaioCobranca(raioDeCobranca, i)" >
                        <i class="fe-trash"></i>
                      </button>


                    </ng-container>


                    <button type="button" class="btn btn-warning btn-xs mt-1"  (click)="raioDeCobranca.removido = null;" *ngIf="raioDeCobranca.removido">
                      <i class="fa fa-undo  "></i>
                    </button>
                  </td>
                </tr>

                </tbody>
              </table>

              <button type="button" class="btn btn-blue btn-sm" (click)="novoRadioDeCobranca()">
                <i class="fe-plus f-lg "></i> Adicionar novo alcance
              </button>

            </div>

            <div class="ml-3 mt-1" *ngIf="forma.tipoDeCobranca == 'zona'">
              <div class="form-group mb-2 escolher">
              <span class="radio radio-blue">
                  <input id="selecionarBairroDaZona"  name="selecionarBairroDaZona" type="checkbox"  class="k-checkbox"   kendoCheckBox
                         [(ngModel)]="forma.selecionarBairroDaZona"
                         [required]="false"/>
                  <label for="selecionarBairroDaZona" class="ml-1">Zona de entrega por bairro</label>
              </span>
              </div>

              <app-grid-cadastro-regioes [empresa]="empresa" [zonasDeEntrega]="entregaEmCasa.zonasDeEntrega">

              </app-grid-cadastro-regioes>
            </div>

            <div class="ml-3 mt-1" *ngIf="forma.tipoDeCobranca === 'raio'">
              <div class="row">
                <div class="col-12 col-xl-8">
                  <app-cad-alcances></app-cad-alcances>
                </div>
              </div>
            </div>

            <div class="ml-3 mt-1" *ngIf="forma.tipoDeCobranca === 'fixo'">
              <div class="form-group col col-sm-4">
                <h5 class="card-title">Taxa fixa para todos os pedidos</h5>

                <input type="text" class="form-control" autocomplete="off"
                       currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                       name="taxaFixa" [(ngModel)]="forma.taxaFixa"
                       [minimo]="0" #taxaFixa="ngModel" appSelecionarNoFoco
                       placeholder="Taxa fixa para qualquer pedido"   >


              </div>
            </div>
          </fieldset>

          <div class="row mt-4">
            <div class="col">
              <h5 class="card-title">Restringir Área de Entrega (Por KML)</h5>
              <button type="button" class="btn btn-primary" (click)="abraTelaArquivoKML()">Configurar Áreas de Entrega</button>
            </div>
          </div>

          <app-form-restringir-cidades [empresa]="empresa"></app-form-restringir-cidades>


          <div class="row mt-4">

            <div class="form-group col col-sm-4">
              <h5 class="card-title">Frete grátis para valores acima de </h5>
              <kendo-numerictextbox class="form-control" autocomplete="off" [min]="0"
                                    name="valorMinimoFreteGratis"
                                    id="valorMinimoFreteGratis"

                                    [(ngModel)]="entregaEmCasa.valorMinimoFreteGratis">
              </kendo-numerictextbox>

              <span class="text-muted">
                   <small>O frete será grátis para pedidos acima de  <b>{{entregaEmCasa.valorMinimoFreteGratis | currency:"BRL"}} </b>
                    </small>
                </span>
            </div>

            <div class="form-group col col-sm-4">
              <h5 class="card-title">Valor mínimo do Pedido para entrega</h5>

              <input type="text" class="form-control" autocomplete="off"
                     currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                     name="valorMinimoPedido" [(ngModel)]="entregaEmCasa.valorMinimoPedido"
                     [minimo]="0" #valorMinimoPedido="ngModel" appSelecionarNoFoco
                     placeholder="Valor mínimo do pedido em R$ para entrega"   >

              <span class="text-muted">
                   <small>Não receber pedidos com valores inferiores a <b>{{entregaEmCasa.valorMinimoPedido | currency:"BRL"}} </b>
                    </small>
                </span>

            </div>

            <div class="form-group col col-sm-4">
              <h5 class="card-title">Valor máximo do Pedido para entrega</h5>

              <input type="text" class="form-control" autocomplete="off"
                     currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                     name="valorMaximoPedidoDelivery" [(ngModel)]="entregaEmCasa.valorMaximoPedido"
                     [minimo]="0"
                     placeholder="Valor Máximo do pedido em R$ para entrega"   >

              <span class="text-muted">
                   <small>Não receber pedidos com valores superiores a <b>{{entregaEmCasa.valorMaximoPedido | currency:"BRL"}} </b>
                    </small>
                </span>

            </div>

          </div>
          <div class="row mt-2">
            <div class="form-group col col-sm-6">
              <h5 class="card-title">Taxa extra de retorno á loja</h5>

              <input type="text" class="form-control" autocomplete="off"
                     currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                     name="taxaExtraRetorno" [(ngModel)]="entregaEmCasa.taxaExtraRetorno"
                     [minimo]="0" #taxaExtraRetorno="ngModel"
                     placeholder="Taxa extra em R$ de retorno loja"   >

              <span class="text-muted">
                   <small>Marque na forma de pagamento quais serão necessárias o retorno a loja</small>
                </span>

            </div>

          </div>
          <div class="form-group mb-2 mt-2   escolher">
              <span class="radio radio-blue">
                  <input id="exibirTelaBusca" name="exibirTelaBusca" type="checkbox"  class="k-checkbox"   kendoCheckBox
                         [(ngModel)]="forma.exibirTelaBusca"
                         [required]="false"/>
                  <label for="exibirTelaBusca" class="ml-1">Exibir Tela de Buscar Endereço</label>
              </span>
          </div>

          <div class="form-group mb-2 mt-2   escolher">
              <span class="radio radio-blue">
                  <input id="permiteUsarGps" name="permitirUsarGps" type="checkbox"  class="k-checkbox"   kendoCheckBox
                         [(ngModel)]="forma.permiteUsarGps"
                         [required]="false"/>
                  <label for="permiteUsarGps" class="ml-1">Permitir Usar Localização para Escolher Endereço</label>
              </span>
          </div>

          <div class="form-group mb-2 mt-2 escolher"  >
              <span class="radio radio-blue">
                  <input id="priorizarLocalizacao" name="priorizarLocalizacao" type="checkbox"  class="k-checkbox"   kendoCheckBox
                         [(ngModel)]="forma.priorizarLocalizacao"
                         [required]="false"/>
                  <label for="priorizarLocalizacao" class="ml-1">Exibir primeiro a opção de usar a localização, ao invés de digitar o endereço</label>
              </span>
          </div>

          <div class="form-group mb-2 mt-2   escolher" *ngIf="forma.arquivoGeoJson">
              <span class="radio radio-blue">
                  <input id="perguntarEnderecoInicio" name="perguntarEnderecoInicio" type="checkbox"  class="k-checkbox"   kendoCheckBox
                         [(ngModel)]="forma.perguntarEnderecoInicio"
                         [required]="false"/>
                  <label for="perguntarEnderecoInicio" class="ml-1">Perguntar Endereço antes de Montar o Carrinho</label>
              </span>
          </div>

          <div class="form-group mb-2 mt-2 escolher">
            <span class="radio radio-blue">
                <input id="naoEnviarBairro" name="naoEnviarBairro" type="checkbox"  class="k-checkbox"   kendoCheckBox
                       [(ngModel)]="forma.naoEnviarBairro"
                       [required]="false"/>
                <label for="permiteUsarGps" class="ml-1">Não Enviar Bairro para Achar Localização do Endereço no Google</label>
            </span>
          </div>


          <div class="form-group"  >
            <label class="k-checkbox-label"  >
              <input type="checkbox"  name="bairroOpcional" class="k-checkbox " kendoCheckBox   [(ngModel)]="forma.bairroOpcional" />
              Bairro Opcional
            </label>
          </div>

          <div class="form-group"  >
            <label class="k-checkbox-label"  >
              <input type="checkbox"  name="bloquearBairroAposCEP" class="k-checkbox " kendoCheckBox   [(ngModel)]="forma.bloquearBairroAposCEP" />
              Bloquear Bairro após informar o CEP
            </label>
          </div>

          <div class="form-group"  >
            <label class="k-checkbox-label"  >
              <input type="checkbox"  name="cepObrigatorio" class="k-checkbox " kendoCheckBox  [(ngModel)]="forma.cepObrigatorio" />
              CEP obrigatório
            </label>
          </div>

          <div class="form-group  "  >
            <label class="k-checkbox-label"  >
              <input type="checkbox"  name="complementoObrigatorio" class="k-checkbox " kendoCheckBox   [(ngModel)]="forma.complementoObrigatorio" />
              Complemento obrigatório
            </label>
          </div>

          <div class="form-group "  >
            <label class="k-checkbox-label"  >
              <input type="checkbox"  name="naoUsarCidadePadrao" class="k-checkbox " kendoCheckBox   [(ngModel)]="forma.naoUsarCidadePadrao" />
              Cidade da empresa não ser usado como padrão para preenchimentos novos endereços
            </label>
          </div>

          <div class="form-group">
            <label class="k-checkbox-label"  >
              <input type="checkbox" id="entregaPermiteAgendamento" name="entregaPermiteAgendamento"
                     kendoCheckBox class="k-checkbox " (ngModelChange)="selecionouPermiteAgendamento(forma)" [(ngModel)]="forma.permiteAgendamento" />
              Permite agendamento da entrega
            </label>

          </div>

          <ng-container *ngIf="forma.permiteAgendamento">
            <div class="form-group   ml-3" >
              <label class="k-checkbox-label"  >
                <input type="checkbox" id="entregaAgendamentoObrigatorio" name="entregaAgendamentoObrigatorio"
                       class="k-checkbox " kendoCheckBox
                       [(ngModel)]="forma.agendamentoObrigatorio" />
                Agendamento Obrigatório
              </label>
            </div>

            <div class="form-group  ml-3 w350">
              <label class="k-checkbox-label"  >
                <input type="checkbox" id="entregaNaoPerguntarHorario" name="entregaNaoPerguntarHorario"
                       class="k-checkbox " kendoCheckBox
                       [(ngModel)]="forma.naoPerguntarHorario" />
                Agendamento não perguntar horário ao cliente
              </label>
            </div>

            <div class="form-group  ml-3"  >
              <h5 class="card-title  ">Intervalo de tempo do agendamento (em minutos)</h5>

              <div class="input-group   d-inline-block w250"  >
                <div  class="d-inline-block">
                  <kendo-numerictextbox class="form-control   mr-2" autocomplete="off"  [min]="1" #entregaIntervaloAgendamento="ngModel"
                                        name="entregaIntervaloAgendamento" [format]="'0 minutos'"
                                        [(ngModel)]="forma.intervaloAgendamento" placeholder="30 minutos">     </kendo-numerictextbox>



                </div>

                <div class="invalid-feedback" >
                <span    *ngIf="entregaIntervaloAgendamento?.errors?.minError">
                         Informe um intervalo maior que 0 minutos
                      </span>

                </div>
              </div>
            </div>

            <div class="form-group ml-3"  >
              <h5 class="card-title  ">Limitar tempo mínimo e máximo do agendamento
                <span [hidden]="forma.naoPerguntarHorario">(em relação ao horário de compra do cliente)</span>
              </h5>

              <div class="input-group  d-inline-block w250  ml-3" >
                <label class=" ">Horário Mínimo</label>

                <div  class="d-inline-block">
                  <kendo-numerictextbox class="form-control   mr-2" autocomplete="off"  #limiteMinimo="ngModel"
                                        name="entregaLimiteMinimo" [format]="'0 horas'"
                                        [(ngModel)]="forma.agendamentoLimiteMinimo" placeholder="tempo minimo (hrs)">     </kendo-numerictextbox>



                </div>

              </div>

              <div class="input-group  d-inline-block w250  ml-3"  >
                <label class=" ">Horário Máximo</label>

                <div class="d-inline-block">
                  <kendo-numerictextbox class="form-control " autocomplete="off"  [min]="0" #limiteMaximo="ngModel"
                                        name="entregaLimiteMaximo" [format]="'0 horas'"
                                        [(ngModel)]="forma.agendamentoLimiteMaximo" placeholder="tempo máximo (hrs)">     </kendo-numerictextbox>


                </div>

              </div>

              <h5 class="card-title mt-2  ">Limitar quantidade pedidos recebidos a cada 30min</h5>

              <div class="form-group  w350 ml-3">
                <kendo-numerictextbox [attr.type]="'number'"    class="form-control" [format]="'máximo ##### pedidos'"   name="qtdePedidosCasa"
                                      [(ngModel)]="forma.limitePedidosAgendados"
                                      [min]="0"   placeholder="quantidade máxima de pedidos (30min)" >   </kendo-numerictextbox>
              </div>


            </div>

          </ng-container>

          <div class="row mt-3 mb-2">
            <div class="form-group  col  ">
              <h5 class="card-title">Tempo médio de entrega</h5>

              <div class="input-group mt-2  ml-3">
                <label class="mt-2">Entre</label>

                <div style="width: 250px" class="d-inline-block">
                  <kendo-numerictextbox class="form-control ml-2 mr-2" autocomplete="off"  [min]="0" #alcance="ngModel"
                                        name="tempoMinimo" [format]="'0 min'"
                                        [(ngModel)]="forma.tempoMinimo" placeholder="tempo minimo (min)">     </kendo-numerictextbox>
                </div>

                <label class="mt-2">e</label>


                <div style="width: 250px" class="d-inline-block">
                  <kendo-numerictextbox class="form-control ml-2" autocomplete="off"  [min]="0" #alcance="ngModel"
                                        name="tempoMaximo" [format]="'0 min'"
                                        [(ngModel)]="forma.tempoMaximo" placeholder="tempo máximo (min)">     </kendo-numerictextbox>
                </div>

              </div>

            </div>


          </div>


        </div>

      </div>
    </div>
  </div>
  <div class="alert alert-success alert-dismissible fade show mb-2" *ngIf="mensagemSucesso" role="alert">
    <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <p class="mt-2 mb-2 text-danger"  *ngIf="erro"   >
    <i class="fa fa-times mr-1"></i> {{erro}}
  </p>

  <button type="submit" class="btn btn-primary waves-effect waves-light width-lg mt-2" [disabled]="salvando">
    <i class="fa fa-save fa-lg fa-fw"></i>
    Salvar</button>
</form>
