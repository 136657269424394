<div class="row botoes">
  <div  kendoTooltip class="col-auto">
    <button  class="btn  btn-blue mb-2" (click)="abraModalGrupoInsumos()">
      <i class="fe-plus"></i> Adicionar Grupo
    </button>
  </div>
</div>

<kendo-grid
  [loading]="carregando"  [sortable]="true" [sort]="[{ field: 'nome', dir: 'asc' }]"
  (edit)="editarGrupo($event)"
  (remove)="abraDialogRemoverGrupo($event)"
  [data]="gruposTela"
  [class]="'gridGrupos'"   >
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Nenhum grupo cadastrado"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>

  <kendo-grid-column    [width]="80">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span class="text-muted"><b>  {{rowIndex + 1}}</b></span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Nome"  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span class="text-blue"><b>  {{dataItem.nome}}</b></span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]="160" [class]="{'comandos': true}">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-grupo>
      <button kendoGridEditCommand [primary]="true" (selectedChange)="abraModalGrupoInsumos(grupo)">
        <i class="fa fa-edit" kendoTooltip title="editar"></i>
      </button>

      <button   kendoGridRemoveCommand kendoTooltip ><i
        kendoTooltip [title]=" 'Remover grupo'"  class="fa fa-trash"></i></button>
    </ng-template>

  </kendo-grid-command-column>

</kendo-grid>



<kendo-dialog title="Atenção!" *ngIf="abraDialogo" (close)="fecheRemover()" [minWidth]="250" [width]="450">
  <div *ngIf="itemSelecionado && !excluindo">
    <p style="margin: 30px; text-align: center;">Esta operação é irreversível. Tem certeza que deseja excluir o grupo
      "<strong>{{itemSelecionado.nome}}?</strong>"</p>
  </div>
  <div *ngIf="excluindo">
    <p style="margin: 30px; text-align: center;">Excluindo o grupo "<strong>{{itemSelecionado.nome}}"...</strong></p>
    <div class="k-i-loading ml-1 mr-1" style="font-size: 20px; padding-bottom: 20px  " ></div>
  </div>
  <div *ngIf="mensagemRemocao">
    <p style="margin: 30px; text-align: center;" class="text-danger">
      <b>{{mensagemRemocao}}</b>
    </p>
  </div>

  <div class="modal-footer" *ngIf="!excluindo">
    <button type="button" *ngIf="itemSelecionado" (click)="removaGrupo()" class="btn btn-primary waves-effect waves-light" >Remover</button>
    <button type="button" *ngIf="itemSelecionado" (click)="fecheRemover()" primary="true"  class="btn btn-light waves-effect">Cancelar</button>
    <button type="button" *ngIf="!itemSelecionado" (click)="fecheRemover()" class="btn btn-primary waves-effect waves-light" >Fechar</button>
  </div>
</kendo-dialog>
