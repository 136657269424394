<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    Sincronizar com Catálogo do Ifood
  </h4>
</kendo-dialog-titlebar>


<div #pnl   class="modal-body"  >

  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onBusqueProdutos()"
        *ngIf="buscarProdutos" >

    <div class="row">
      <div class="col-12 col-sm-6 centralizado">
        <div class="card card-box">
          <div class="card-body">
            <div class="form-group mb-3"  >
              <label for="link">Link do Catálogo: </label>
              <input type="text" class="form-control" autocomplete="off"
                     id="link" name="link" [(ngModel)]="opcoes.link" #link="ngModel"
                     placeholder="Informe o link do catálogo no ifood" value="" required appAutoFocus [autoFocus]="true">
              <div class="invalid-feedback">
                <p *ngIf="link.errors?.required">Link é obrigatório</p>
              </div>
            </div>
            <div class="form-group mb-3"  >
              <label   >Percentual redução (opcional)   </label>
              <kendo-numerictextbox [attr.type]="'number'"    class="form-control"  [(ngModel)]="opcoes.reducao"
                                    #reducao="ngModel" [min]="1" [max]="100" name="reducao"  [format]="obtenhaFormatoPercentual()"  >
              </kendo-numerictextbox>

              <div class="invalid-feedback">
                <p *ngIf="reducao.errors">Valor do percentual tem ser 1 a 100</p>
              </div>
            </div>

            <div class="form-group mt-2 mb-3   "  >
              <label class="mt-2 k-checkbox-label"  >
                <input type="checkbox" id="sincronizarPrecos" name="sincronizarPrecos" class="k-checkbox " kendoCheckBox
                       [(ngModel)]="opcoes.sincronizarPrecos"
                />

                Sincronizar Preços

              </label>
            </div>

            <div class="form-group mt-2 mb-3   "  >
              <label class="mt-2 k-checkbox-label"  >
                <input type="checkbox" id="sincronizarImagens" name="sincronizarImagens" class="k-checkbox " kendoCheckBox
                       [(ngModel)]="opcoes.sincronizarImagens"
                />

                Sincronizar Imagens

              </label>
            </div>

            <div class="alert alert-danger alert-dismissible fade show mt-2" role="alert" *ngIf="mensagemErro">
              <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" (click)="fecheMensagemErro()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <h4 *ngIf="buscando" >
              Buscando o catálogo de produtos no Ifood...
              <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;    height: 90px;" ></div>

            </h4>

            <div class="alert alert-info alert-dismissible fade show" *ngIf="buscouProdutos && tudoSincronizado" role="alert">
              <h6>
                Não há nenhum produto novo no catálogo do ifood.
              </h6>
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

          </div>
        </div>

      </div>
    </div>

  </form>

  <div *ngIf="empresaNoIfood" class="col mt-2" style="border: 1px solid #2f2f35">
      <h4><b>{{empresaNoIfood.nome}}</b></h4>
      <p>{{empresaNoIfood.endereco}}</p>
  </div>
  <app-painel-importacao-produtos #painelImportacao [hidden]="buscarProdutos"  ></app-painel-importacao-produtos>

</div>

<div class="modal-footer"    >

  <button type="button" class="btn btn-primary waves-effect waves-light"
          [disabled]="buscando"   *ngIf="buscarProdutos" (click)="submitFormBusca()">
    <i class="k-icon k-i-loading" *ngIf="buscando"></i>  Buscar Produtos</button>

  <button class="btn btn-danger mr-2" *ngIf="!buscarProdutos"  (click)="painelImportacao.sincronizeTodos()"
          [disabled]="painelImportacao && painelImportacao.importacao.sincronizando"
          [hidden]="painelImportacao && painelImportacao.importacao.finalizou"   >

    <i class="k-icon k-i-loading" *ngIf="painelImportacao && painelImportacao.importacao.sincronizando"></i>
    Sincronizar todos</button>

  <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
          (click)="feche()">Cancelar</button>
</div>
