import {Injectable} from "@angular/core";
import {ServerService} from "../../services/ServerService";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class VitrinesService  extends  ServerService {
  constructor(http: HttpClient) {
    super(http)
  }
  listeVitrines(){
    return this.obtenha('/vitrines', {})
  }

  obtenhaVitrine(id: number){
    return this.obtenha('/vitrines/' + id, {})
  }

  salveVitrine(vitrine: any) {
    return this.salve('/vitrines', vitrine);
  }

  ativeVitrine(vitrine: any){
    return this.salve('/vitrines/ative', vitrine);
  }

  desativeVitrine(cupom: any) {
    return this.salve('/vitrines/desative', cupom);
  }

  subirParaTopo(vitrine: any){
    const dados = {
      id: vitrine.id,
      ordem: vitrine.ordem
    };

    return this.facaPut('/vitrines/subirParaTopo', dados);
  }
  descerParaFinal(vitrine: any){
    const dados = {
      id: vitrine.id,
      ordem: vitrine.ordem
    };

    return this.facaPut('/vitrines/descerParaFinal', dados);
  }
  subir1Posicao(vitrine: any){
    const dados = {
      id: vitrine.id,
      ordem: vitrine.ordem,
      comando: 'SUBIR'
    };

    return this.facaPut('/vitrines/ordem', dados);
  }

  descer1Posicao(vitrine: any){
    const dados = {
      id: vitrine.id,
      ordem: vitrine.ordem,
      comando: 'DESCER'
    };

    return this.facaPut('/vitrines/ordem', dados);
  }

  subir1PosicaoProduto(vitrine: any, produtoNaVitrine: any){
    const dados = {
      idVitrine: vitrine.id,
      idProdutoVitrine: produtoNaVitrine.id,
      comando: 'SUBIR'
    };

    return this.facaPut('/vitrines/produto/ordem', dados);
  }

  descer1PosicaoProduto(vitrine: any, produtoNaVitrine: any){
    const dados = {
      idVitrine: vitrine.id,
      idProdutoVitrine: produtoNaVitrine.id,
      comando: 'DESCER'
    };

    return this.facaPut('/vitrines/produto/ordem', dados);
  }


  subirProdutoParaTopo(vitrine: any, produtoNaVitrine: any){
    const dados = {
      idVitrine: vitrine.id,
      idProdutoVitrine: produtoNaVitrine.id,
    };

    return this.facaPut('/vitrines/produto/subirParaTopo', dados);
  }

  descerProdutoParaFinal(vitrine: any, produtoNaVitrine: any){
    const dados = {
      idVitrine: vitrine.id,
      idProdutoVitrine: produtoNaVitrine.id,
    };

    return this.facaPut('/vitrines/produto/descerParaFinal', dados);
  }

  adicioneProdutosVitrine(vitrine: any, produtos: any){
    return this.facaPost(String(`/vitrines/${vitrine.id}/produtos/add`), {vitrine: vitrine, produtos: produtos});
  }
  removaProdutoDaLista(vitrine: any, produto: any) {
    return this.facaPost(String(`/vitrines/${vitrine.id}/produtos/del`), produto);
  }
}
