<div>
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

    <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
      <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
    {{mensagemErro}}
  </span>
    </div>

    <div class="row">
      <div class="form-group mb-3 col">
        <label for="codigo">Informe o código do cupom</label>
        <input type="text" class="form-control" autocomplete="off" appAutoFocus [autoFocus]="true"
               id="codigo" name="codigo" [(ngModel)]="codigo" #txtCodigo="ngModel"
               placeholder="Código do cupom" value="" required/>
        <small id="emailHelp" class="form-text text-muted">Informe o código do cupom sem espaços</small>
        <div class="invalid-feedback">
          <p *ngIf="txtCodigo.errors?.required">Código do Cupom é obrigatório</p>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="enviando">Inserir Campo</button>
      <!--<button type="button" (click)="onRemover()" class="btn btn-danger waves-effect waves-light" *ngIf="formaDePagamento.id">Remover</button>-->
      <button type="button" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Fechar</button>
    </div>
  </form>
</div>
