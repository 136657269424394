<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    <i class="fa fa-file-import fa-lg mr-1"></i>Exportar Produtos
  </h4>

</kendo-dialog-titlebar>
<div   class="modal-body">
  <h5> Total produtos encontrados: <b>{{produtos.length}}</b> </h5>

  <kendo-grid    kendoGridSelectBy="id" #grid="kendoGrid"  style="min-height: 350px"
    [kendoGridBinding]="produtos"  [selectable]="{ mode: 'multiple', drag: false }"   [filterable]="'menu'"
                [(selectedKeys)]="produtosSelecionadosIds"   [loading]="carregandoProdutos"
    [class]="'gridProdutos'"  >
    <kendo-grid-messages
      pagerPage="Página"
      pagerOf="de"
      pagerItems="itens"
      noRecords="Sem produtos"
      loading="Carregando"
      pagerItemsPerPage="ítems por página"
    >
    </kendo-grid-messages>

    <ng-template kendoGridToolbarTemplate>
      <button type="button" class="btn btn-outline-secondary btn-sm   "
              (click)="exportToExcel(grid)" icon="file-excel">Exportar</button>

    </ng-template>
    <kendo-grid-checkbox-column [width]="50"     [showSelectAll]="true"  ></kendo-grid-checkbox-column>
    <kendo-grid-column title="Id" field="id"   [width]="90"  filter="numeric">
      <ng-template kendoGridCellTemplate let-produto  let-rowIndex="rowIndex">
        <span class="text-blue"><b>{{produto.id}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column   field="codigoPdv"  title="Cod. Pdv" [width]="100">
      <ng-template kendoGridCellTemplate let-produto  let-rowIndex="rowIndex">
        <span class=" "><b>{{produto.codigoPdv || '-'}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column   field="ordem"    [width]="0" [hidden]="true"></kendo-grid-column>

    <kendo-grid-column title="Categoria"  field="categoria.nome">
      <ng-template kendoGridCellTemplate let-produto  let-rowIndex="rowIndex">
        <span class=" "><b>{{produto.categoria ? produto.categoria.nome : '-'}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Produto"   field="nome"   >
      <ng-template kendoGridCellTemplate let-produto  let-rowIndex="rowIndex">
        <span class=" text-blue"><b>{{produto.nome  }}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column   title="Nome Adicional"   field="nomeAdicional"  [width]="0" [hidden]="true"></kendo-grid-column>

    <kendo-grid-column title="Opção Adicional"    field="nomeOpcao"  [width]="0" [hidden]="true" > </kendo-grid-column>

    <kendo-grid-column title="Preço"  field="precoTela"  [width]="110">
      <ng-template kendoGridCellTemplate let-produto  let-rowIndex="rowIndex">
        <app-produto-preco [podeEditar]="false" [produto]="produto" [catalogo]="catalogo"  > </app-produto-preco>

      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Disponivel" field="disponivel"  [width]="70" >
      <ng-template kendoGridCellTemplate let-produto  let-rowIndex="rowIndex">

        <span   class=" " [ngClass]="{'text-danger': produto.disponibilidade === 2,
        'text-success': produto.disponibilidade !== 2}">
          <b>{{produto.disponivel}}</b></span>


      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column title="Descrição"    field="descricao"  [width]="0" [hidden]="true" > </kendo-grid-column>
    <kendo-grid-column title="Foto"    field="imagem"  [width]="0" [hidden]="true"> </kendo-grid-column>

    <ng-template kendoGridDetailTemplate let-produto   >

       <h5>Adicionais do produto</h5>

      <kendo-grid    kendoGridSelectBy="id"  [data]="produto.opcoes"  [class]="'gridAdicionais'"  >
        <kendo-grid-messages
          pagerPage="Página"
          pagerOf="de"
          pagerItems="itens"
          noRecords="Sem adicionais"
          loading="Carregando"
          pagerItemsPerPage="ítems por página"
        >
        </kendo-grid-messages>
        <kendo-grid-column title="Id" [width]="90">
          <ng-template kendoGridCellTemplate let-opcao  let-rowIndex="rowIndex">
            <span class="text-blue"><b>{{opcao.id}}</b></span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Cod. Pdv" [width]="100">
          <ng-template kendoGridCellTemplate let-opcao  let-rowIndex="rowIndex">
            <span class=" "><b>{{opcao.codigoPdv || '-'}}</b></span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Nome"  >
          <ng-template kendoGridCellTemplate let-opcao  let-rowIndex="rowIndex">
            <span class=" text-blue"><b>{{opcao.nomeOpcao  }}</b></span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Adicional" >
          <ng-template kendoGridCellTemplate let-opcao  let-rowIndex="rowIndex">
            <span class=" "><b>{{opcao.nomeAdicional }}</b></span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Preço"  [width]="110">
          <ng-template kendoGridCellTemplate let-opcao  let-rowIndex="rowIndex">
            <app-produto-preco [podeEditar]="false" [produto]="opcao" [catalogo]="catalogo"  >

            </app-produto-preco>

          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Disponivel"  [width]="70" >
          <ng-template kendoGridCellTemplate let-opcao  let-rowIndex="rowIndex">
            <span *ngIf="opcao.disponivel === 'Sim'  " class="text-danger"><b>Sim</b></span>
            <span *ngIf="opcao.disponivel !== 'Sim' " class="text-success"><b>Não </b></span>
          </ng-template>
        </kendo-grid-column>

      </kendo-grid>

    </ng-template>

    <kendo-excelexport #excelExport [data]="produtos" [fileName]="obtenhaNomeArquivoDownload( ) "> </kendo-excelexport>
  </kendo-grid>
  <br><br>
</div>
