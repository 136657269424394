<span *ngIf="pedido.aceito && !pedido.naoNotificado"  style="position: relative">

  <ng-container *ngIf="imprimeNativo">
    <i class="fa fa-check-circle impresso text-success" kendoTooltip title="Pedido impresso"
       *ngIf=" pedido.impresso"></i>

    <i class="fa fa-times-circle impresso text-warning" kendoTooltip title="Pedido não impresso"
       *ngIf=" !pedido.impresso"></i>

  </ng-container>


  <kendo-dropdownbutton [data]="opcoesEdicao" [popupSettings]="{animate: true}" [themeColor]="'success'"
                        [hidden]="!opcoesEdicao.length" (click)="prevenirSelecao($event)"
                        *ngIf="!pedido.finalizado && !pedido.cancelado" (itemClick)="escolheuOpcaoEditar($event, pedido)">
    <i class="fa fa-edit"></i>
  </kendo-dropdownbutton>

  <ng-container *ngIf=" (!configImpressao || !configImpressao.multiplasImpressoras) && (!pedido.aguardandoPagamentoOnline || configImpressao?.imprimirOnlineNaoPago)">

    <button  type="button"   class="btn btn-outline-blue btn-xs ml-1" *ngIf="configImpressao?.layoutPedido !== 'Ambos' "
             (click)="imprimaLayoutUnico()" title="imprimir" kendoTooltip [hidden]="modoGrupoLojas">
      <i class="fa fa-print"></i></button>


    <kendo-dropdownbutton buttonClass="btn btn-outline-blue btn-xs ml-1" [data]="opcoesImpressao" [popupSettings]="{animate: true}"
                          (itemClick)="imprimaUmDosLeiautes($event)" *ngIf="configImpressao?.layoutPedido  ==='Ambos'">
      <i class="fa fa-print"  ></i>
    </kendo-dropdownbutton >


  </ng-container>

  <ng-container *ngIf="configImpressao?.multiplasImpressoras && (!pedido.aguardandoPagamentoOnline || configImpressao?.imprimirOnlineNaoPago)">

    <kendo-dropdownbutton buttonClass="btn btn-outline-blue btn-xs ml-1" [data]="impressoras" [popupSettings]="{animate: true}"
                          (itemClick)="imprimaNativamente( $event)" *ngIf="impressoras.length" [textField]="'setor'" >
      <i class="fa fa-print"></i>
    </kendo-dropdownbutton >
  </ng-container>
</span>

<ng-container *ngIf="pedido.aceito && pedido.naoNotificado && !pedido.cancelado">
  <button   type="button"   class="btn btn-danger btn-sm mb-3  " [hidden]="pedido.multipedido"
            [disabled]="processando"  *ngIf="!comanda ||  (comanda && pedido.garcom)"
            (click)="notifiqueSistemaIntegrado($event)" title="reenviar pedido {{empresa?.integracaoDelivery?.sistema}}" kendoTooltip >

    <i class="k-i-loading k-icon mr-1 " *ngIf="processando"></i>
    Reenviar</button>

  <button   type="button"   class="btn btn-warning btn-sm mb-3  "  (click)="abraModalVincularGarcom(pedido)"
        *ngIf="comanda && (!pedido.garcom || pedido.informarCodigoComanda)"   kendoTooltip>

    {{!pedido.garcom ?  'Definir Garçom': 'Definir Comanda'}}</button>


</ng-container>

<button class="btn btn-blue btn-sm" *ngIf="!pedido.aceito && !pedido.cancelado"  (click)="abraModalVerPedido(pedido)">
  <span *ngIf="!pedido.aceitando">Aceitar Pedido</span>
  <i class="k-i-loading k-icon  " *ngIf="pedido.aceitando"></i>

</button>




