<h4 class="page-title">  <i class="far fa-building"></i>
  Loja</h4>

<div class="card">
  <div class="card-box">
    <kendo-tabstrip #tabstrip  [scrollable]="{ scrollButtons: 'visible' }"
                      [style.width.%]="100">
      <kendo-tabstrip-tab [title]="'Formas de entrega'" [selected]="true">
        <ng-template kendoTabContent>
          <app-form-formasdeentrega #frmFormaEntrega [empresa]="empresa" ></app-form-formasdeentrega>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Horários'"  >
        <ng-template kendoTabContent>
          <h4>Horário de funcionamento</h4>

          <p>Configure os dias e horários da loja </p>

          <div class="form-group mb-2  " style="max-width: 350px">
            <label  >Fuso Horário</label>

            <kendo-combobox   name="fusoHorario" [data]="fusos"   (valueChange)="selecionouFuso()"
                              class="form-control" appAutoFocus   [allowCustom]="false" [required]="true"
                              #cbFusoHorario="ngModel" [(ngModel)]="fusoHorario"
                              [textField]="'descricao'">
            </kendo-combobox>
          </div>


          <kendo-tabstrip   class="nav-bordered  "    [scrollable]="{ scrollButtons: 'visible' }" >
            <kendo-tabstrip-tab [title]="'Horários da loja para pedidos'" [selected]="true">
              <ng-template kendoTabContent>
                <app-cad-horariosfuncionamento [empresa]="empresa"></app-cad-horariosfuncionamento>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab [title]="'Pausa Progamada'"  >
              <ng-template kendoTabContent>
                <app-tela-pausas-programadas [empresa]="empresa"></app-tela-pausas-programadas>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>

        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Cardápio'" *ngIf="moduloAtivo('pedidos')">
        <ng-template kendoTabContent>
          <app-upload-cardapio [empresa]="empresa"></app-upload-cardapio>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Impressão'" *ngIf="moduloAtivo('pedidos')">
        <ng-template kendoTabContent>
          <app-config-impressao [empresa]="empresa"></app-config-impressao>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab id="formaspagamento" [title]="nomeTabFormasPagamento" *ngIf="moduloAtivo('pedidos')">
        <ng-template kendoTabContent>
          <app-ativar-formas-de-pagamento  *ngIf="empresa.exibirBandeiras"></app-ativar-formas-de-pagamento>

          <app-cadastro-formas-de-pagamento *ngIf="!empresa.exibirBandeiras"></app-cadastro-formas-de-pagamento>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Mesas'" *ngIf="moduloAtivo('pedidos')" >
        <ng-template kendoTabContent>
          <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                novalidate #frm="ngForm" (ngSubmit)="salveConfig()" >

            <div class="form-group  col-12"  >
              <label class="k-checkbox-label">
                <input type="checkbox"   name="pedidoMesaNaoIdentificado" class="k-checkbox "  kendoCheckBox  [(ngModel)]="empresa.pedidoMesaNaoIdentificado" />
                Permitir compra de consumidor não identificado</label>
            </div>

            <div class="form-group  col-12"  >
              <label class="k-checkbox-label">
                <input type="checkbox"   name="destaque" class="k-checkbox "  kendoCheckBox  [(ngModel)]="empresa.avisosDeMesa" />
                Ativar avisos de solicitação de garçom e fechamento de mesa pelo cardápio digital</label>
            </div>


            <div class="form-group  col-12"  >
              <label class="k-checkbox-label">
                <input type="checkbox"   name="taxaDeServico" class="k-checkbox "  kendoCheckBox [(ngModel)]="empresa.cobrarTaxaServico" />
                Cobrar taxa de serviço</label>
            </div>

            <div class="form-group col col-sm-5 col-lg-4" *ngIf="empresa.cobrarTaxaServico" >
              <h5 class="card-title">Valor da taxa de serviço</h5>

              <input type="text" class="form-control" autocomplete="off"  #valorTaxaServico=ngModel
                     currencyMask [options]="{ prefix:'', suffix: '%', thousands: '.', decimal: ',', align: 'left' }"
                     name="valorMinimoPedidoEntrega" [(ngModel)]="empresa.valorTaxaServico"
                     [minimo]="0" value="10"
                     placeholder="Valor da taxa em % (ex: 10%)"   >


              <span class="text-muted"  >
                   <small>
                     Os pedidos de mesa terão <b>{{empresa.valorTaxaServico}}%</b> adicionados no valor no fechamento.
                    </small>
              </span>

            </div>

            <div class="form-group  col-12"  >
              <label class="k-checkbox-label">
                <input type="checkbox"   name="permitirCupomMesas" class="k-checkbox "  kendoCheckBox  [(ngModel)]="empresa.permitirCupomMesas" />
                Permitir cupons em mesas</label>
            </div>


            <div class="form-group mb-2 col-6">
              <label for="token">Escolha um prefixo que será inserido na frente de todos as mesas cadastradas (padrão: Mesa) </label>
              <input kendoTextBox id="token" name="token"  placeholder="Mesa"
                     class="form-control"   #token="ngModel"
                     [(ngModel)]="empresa.identificadorMesa" required/>

              <div class="invalid-feedback">
                <p  >Identificador é obrigatório</p>
              </div>

            </div>



            <div class="col-12">
              <button class="btn btn-success"  type="submit" [disabled]="salvando || !frm.dirty " >
                <i class="k-i-loading k-icon mr-1" *ngIf="salvando"></i>
                Salvar</button>
              <a class="btn btn-blue ml-1" [routerLink]="['/admin/mesas']">
                <i class="fa fa-table fa-lg mr-1"></i> Ver Mesas</a>


              <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="mensagemSucesso">
                <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" (click)="fecheMensagemSucesso()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

            </div>

          </form>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Cupons'" *ngIf="moduloAtivo('pedidos')" >
        <ng-template kendoTabContent>
          <app-tela-cupons></app-tela-cupons>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Promocoes'"  *ngIf="moduloAtivo('pedidos')">
        <ng-template kendoTabContent>
          <app-tela-promocoes></app-tela-promocoes>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Adicionais De Pedido'" >
        <ng-template kendoTabContent>
          <app-cad-adicionais-pedido #frmFormaEntrega></app-cad-adicionais-pedido>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Fidelidade'" *ngIf="moduloAtivo('pedidos') && moduloAtivo('fidelidade')" >
        <ng-template kendoTabContent>

          <kendo-tabstrip   class="nav-bordered  "   >
            <kendo-tabstrip-tab [title]="'Integração com Loja'" [selected]="true">
              <ng-template kendoTabContent>
                <app-form-integracaofidelidade  [usuario] = usuario  [empresa]="empresa"></app-form-integracaofidelidade>
              </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [title]="'Planos'"  >
              <ng-template kendoTabContent>
                <app-planos [empresa]="empresa"></app-planos>
              </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab [title]="'Atividades'"  >
              <ng-template kendoTabContent>
                <app-atividades [empresa]="empresa"></app-atividades>
              </ng-template>
            </kendo-tabstrip-tab>


            <kendo-tabstrip-tab [title]="'Brindes'"  >
              <ng-template kendoTabContent>
                <app-brindes [empresa]="empresa"></app-brindes>
              </ng-template>
            </kendo-tabstrip-tab>

          </kendo-tabstrip>

        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab  [title]="'Integração com Pdv'" *ngIf="moduloAtivo('pedidos')  ">
        <ng-template kendoTabContent>
          <app-form-integracaopedidos  [empresa]="empresa"></app-form-integracaopedidos>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Integração com Delivery'" *ngIf="moduloAtivo('pedidos')  " >
        <ng-template kendoTabContent>
          <app-form-integracaodelivery  [empresa]="empresa"></app-form-integracaodelivery>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Integração com Ifood'" *ngIf="moduloAtivo('pedidos')   " >
        <ng-template kendoTabContent>
          <app-tela-integracao-ifood  [empresa]="empresa"></app-tela-integracao-ifood>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Fotos'" >
        <ng-template kendoTabContent>
          <app-fotos #appFotos   [empresa]="empresa" [fotosDoAmbiente]="false"></app-fotos>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
