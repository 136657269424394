<div class="card contato">
  <div class="card-body">
    <div class="row">
      <div class="col-12 ">
        <div class="dados-contato" *ngIf="contato">
          <div class="row  justify-content-between">
            <div class="col-8" style="overflow: hidden">
              <label class="font-15 pr-2" class="abreviar mb-0" ><b > {{contato?.nome}} </b></label>
            </div>
            <div class="col mb-1">
              <contato-status [contato]="contato"></contato-status>
            </div>
          </div>

          <div class="row">
            <div class="col-12 " style="padding-right: 0px;" >
              <i class="fab fa-whatsapp pr-1 alinhamento-icone whatsapp fa-lg" style="color: #82c91e;"></i> <span>{{contato?.telefone | tel: contato?.codigoPais }}</span> <br>
            </div>
          </div>

          <div class="mt-2 mb-2 chatbot" *ngIf="possuiModuloChatbot && statusMia">
            <hr>
            <h4 class="header-title">Mia</h4>
            <div style="width: 100%;color: red;">
              <input id="desativarParaSempre" name="desativarParaSempre" type="checkbox"    [(ngModel)]="desativarParaSempre"
                     kendoCheckBox class="k-checkbox" (ngModelChange)="ativouDesativouParaSempre($event)"/>
              <label for="desativarParaSempre" class="ml-1" style="width: calc(100% - 40px);vertical-align: top;">
                Desativar permanentemente a Mia para este contato
              </label>
            </div>

            <div>
              <i class="fas fa-robot font-16" [style.color]="atendente ? '#f1556c' : '#568d15'"></i>&nbsp;
              <span *ngIf="!atendente">Mia está ativa</span>
              <span *ngIf="atendente">Mia está desativada</span>
            </div>

              <ng-container *ngIf="!desativarParaSempre">
                <button class="btn btn-danger btn-xs"(click)="assumirControle()"
                        title="ChatBot não vai mais responder o cliente por 1 hora." *ngIf="!atendente">Assumir controle</button>

                <button class="btn btn-primary btn-xs" (click)="ativarChatBot()" *ngIf="atendente" title="Mia será ativada para esse número">Ativar Mia</button>
              </ng-container>
              <hr>
          </div>

          <div *ngIf="exibirEndereco && contato?.enderecos?.length > 0">
            <div id="endereco" class="mt-1" *ngFor="let endereco of obtenhaEnderecosExibir()">
              <resumo-endereco [endereco]="endereco"></resumo-endereco>
            </div>
          </div>

          <div class="mt-1" *ngIf="contato?.tags && contato.tags.length">
            <kendo-chip
              *ngFor="let tag of contato.tags"
              [label]="tag.nome"
              [removable]="false"

            ></kendo-chip>
          </div>

          <div *ngIf="empresa.modulos && empresa.modulos.length > 0 && this.exibirUltimaVisita  " class="mt-2 ">
            <div class="row" *ngIf=" possuiFidelidade && contato?.ultimaVisita ">
              <div class="col-12 mensagem-modulo" style="padding-right: 0px; " >
                <span class="font-weight-500"><i class="fa fa-calendar pr-1 alinhamento-icone" ></i>  Última visita: </span>
                <span class="text-black"  > {{ contato?.ultimaVisita | date: 'dd/MM/yyyy HH:mm'}}</span>
              </div>
            </div>

            <div class="row" *ngIf=" possuiPedidos && contato?.dataUltimoPedido  && this.exibirUltimaVisita">
              <div class="col-12 mensagem-modulo" style="padding-right: 0px;" >
                <span class="font-weight-500"><i class="fa fa-shopping-cart pr-1 alinhamento-icone" style="    margin-left: -3px;"></i>  Último pedido: </span>
                <span class="text-black"  > {{ contato?.dataUltimoPedido | date: 'dd/MM/yyyy HH:mm'}}</span>
              </div>
            </div>

          </div>

        </div>
        <div class="dados-contato" *ngIf="!contato">
          <h5>Consumidor não identificado</h5>
        </div>
      </div>
    </div>
  </div>
</div>




