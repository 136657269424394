<div class="card card-body" style="padding: 1rem;">
  <h5 class="card-title mt-2"><strong>Arquivo KML</strong></h5>
  <ng-container *ngIf="formaDeEntrega && formaDeEntrega.arquivoKML">
    <div>
      Arquivo Enviado: <h5>{{formaDeEntrega.arquivoKML}}</h5>
    </div>
    <div *ngIf="dadosMapa">
      Qtde Regiões: <h5>{{dadosMapa.qtdeRegioes}}</h5>
    </div>

    <button class="btn btn-sm btn-danger" (click)="apagarArquivoKML()">Apagar Arquivo KML</button>
  </ng-container>

  <hr class="mt-0">
  <h4>Enviar Novo Arquivo:</h4>
  <kendo-upload #uploadComponent id="arquivo" name="arquivo"  (select)="evtSelecionouArquivo($event)"
                [(ngModel)]="files" #arquivo="ngModel" [saveUrl]="'/geo/uploadkml'"
                [multiple]="false"  [restrictions]="restricoes" required
                (error)="uploadFalhou($event)"
                (uploadProgress)="progressoUpload($event)"
                (complete)="evtTerminouUpload($event)"
                (success)="usuarioEnviouArquivo($event)">
    <kendo-fileselect-messages
      select="Selecione..."
      dropFilesHere="Arraste o arquivo KML aqui"
      clearSelectedFiles="Limpar">
    </kendo-fileselect-messages>
  </kendo-upload>

  <div class="alert alert-danger" role="alert" *ngIf="msgErroUpload">
    <i class="fas fa-exclamation-triangle"></i>
    <span class="msg-erro">
                          {{msgErroUpload}}
                        </span>
  </div>

  <div class="mt-2">
    <button class="btn btn-primary" (click)="abraTelaTestarEndereco()">Testar Endereço</button>
    <button class="ml-2 btn btn-secondary" (click)="abraTelaCadastrarTaxasDeEntrega()">Taxas de Entrega</button>
  </div>
</div>


<kendo-dialog title="Testar Endereço" *ngIf="testandoEndereco" (close)="fecharTelaTestar()"
              [minHeight]="altura" [height]="altura" [minWidth]="250" [width]="550">
  <div>
    <h4>Informe um endereço para calcular a taxa de entrega</h4>

    <app-form-endereco-contato (onSalvou)="salvouEndereco($event)" [nomeLocal]="false" #formEndereco
                               [endereco]="endereco" [textoBotaoSalvarEndereco]="'Testar Endereço'">
    </app-form-endereco-contato>

    <div class="alert alert-danger mt-3 mb-2" role="alert" *ngIf="msgErro">
      <i class="fas fa-exclamation-triangle"></i> {{msgErro}}
    </div>
  </div>
</kendo-dialog>


<div *ngIf="calculando" class="card-img-overlay d-flex align-content-center justify-content-center flex-wrap"
     style="background: rgba(0, 0, 0, .5);z-index: 9999;position: fixed">
  <div>
    <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;color: #fff;line-height: 40px"></div>
    <h4 style="color: #fff;" class="mt-3">Calculando Taxa de Entrega...</h4>
  </div>
</div>
