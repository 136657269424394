
<h4 *ngIf="insumo.id">Movimentações do Insumo <b>{{insumo.nome}}</b></h4>
<h4 *ngIf="produto.id">Movimentações do Produto <b>{{produto.nome}}</b></h4>

<kendo-grid
  [loading]="carregando"
  [data]="gridData"
  [pageSize]="paginacao.size"
  [skip]="paginacao.skip"

  [pageable]="true"
  (pageChange)="mudouPaginacao($event)">
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Nenhum movimentaçao registrada"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>

  <kendo-grid-column title="Id"   [width]="70" >
    <ng-template kendoGridCellTemplate let-movimentacao   >
      <b>{{movimentacao.id}} </b>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Tipo"   [width]="100"  >
    <ng-template kendoGridCellTemplate let-movimentacao    >
      <span [ngClass]="{'text-danger' : movimentacao.quantidade < 0, 'text-success':  movimentacao.quantidade > 0 }">
              <b>   {{movimentacao.tipo}}</b>
      </span>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Insumo"    *ngIf="exibirInsumo" >
    <ng-template kendoGridCellTemplate let-movimentacao    >
        <b>
            <span class="text-muted" *ngIf="movimentacao.insumo?.codigo">
              {{movimentacao.insumo?.codigo}} -
            </span>
            <span class="text-blue">
              {{movimentacao.insumo?.nome}}
            </span>
        </b>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Situação"   [width]="120" >
    <ng-template kendoGridCellTemplate let-movimentacao    >
      <span class="text-success"  [ngClass]="{'text-warning': movimentacao.reservada,
      'text-success':movimentacao.confirmada, 'text-danger' : movimentacao.cancelada}" >
              <b>   {{movimentacao.status}}</b>
      </span>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Qtde" field="quantidade"  [width]="90" >
    <ng-template kendoGridCellTemplate let-movimentacao    >
           <span [ngClass]="{'text-danger' : movimentacao.quantidade < 0, 'text-success':  movimentacao.quantidade > 0 }">
                 <b>{{movimentacao.quantidade}} {{obtenhaDescricaoUnidade(movimentacao)}} </b>
           </span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Pedido" field="pedido.id"   [width]="90"  >
    <ng-template kendoGridCellTemplate let-movimentacao    >
           <a  [routerLink]="'/admin/pedidos/detalhes/' + movimentacao.pedido.guid" *ngIf="movimentacao.pedido" href="#">
               <b>{{movimentacao.pedido.codigo}}  </b>
           </a>

        <span [hidden]="movimentacao.pedido">-</span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Retornável" field="retornavel"   [width]="75"  >
    <ng-template kendoGridCellTemplate let-movimentacao    >
        <i class="k-icon k-i-check-circle text-success"  *ngIf="movimentacao.retornarEstoque "></i>
        <i class="k-icon k-i-times-circle text-danger"  *ngIf="!movimentacao.retornarEstoque "></i>

    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Operação"   [width]="160"  >
    <ng-template kendoGridCellTemplate let-movimentacao    >
      <b>{{movimentacao.operador ? movimentacao.operador.nome : '-'}} </b>
      <br>
      <span class="text-muted">{{movimentacao.horario | date: "dd/MM/yyyy HH:mm:ss"}} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Observação"    >
    <ng-template kendoGridCellTemplate let-movimentacao   >
      <p *ngIf="movimentacao.motivoDaMovimentacao" class="mb-1">
        <b>{{movimentacao.motivoDaMovimentacao.descricao}}</b></p>

      <span *ngIf="movimentacao.observacao" class="text-muted ">
        <i>"{{movimentacao.observacao }}"</i>
      </span>

    </ng-template>
  </kendo-grid-column>

</kendo-grid>
