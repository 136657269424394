<div class="position-relative" style="border: solid 1px #e4e4e4;padding: 20px;border-radius: 5px;">
  <div class="ribbon ribbon-top-right" [class.sucesso]="notificacaoApp.status === 'Enviada'"><span>{{notificacaoApp.status}}</span></div>
  <h3>
    <a [href]="'/admin/nova-campanha-app/' + notificacaoApp.id" target="_blank">
      {{notificacaoApp.id}}. {{notificacaoApp.nome}}
    </a>
  </h3>

  <div class="row mt-3 position-relative">
    <div class="col-7">
      <div class="row">
        <div class="col-auto" *ngIf="notificacaoApp.linkImagem">
          <img class="img-fluid rounded" [src]="'/images/empresa/' + notificacaoApp.linkImagem" style="width: 200px"/>
        </div>
        <div class="col">
          <div class="position-relative text-center" style="width: 400px;">
            <img src="/assets/loja/preview_notificacao.png" style="min-height: 180px;"/>
            <p>
              Pré-visualização
            </p>

            <ng-template [ngTemplateOutlet]="preview"></ng-template>
          </div>
        </div>
      </div>

      <div class="mt-1">
        <span class="badge badge-pill badge-success" *ngIf="notificacaoApp.status === 'Nova' && notificacaoApp.status !== 'Nova'"> <i class="fe-user"></i> Nova</span>
        <span class="badge badge-pill badge-secondary" *ngIf="notificacaoApp.status === 'Enviada'"> <i class="fe-user"></i>Enviada</span>
        <span class="badge badge-pill badge-warning" *ngIf="notificacaoApp.status === 'DEV'"> <i class="fe-user"></i>Enviando</span>

        <div *ngIf="!notificacaoApp.foiTestada && notificacaoApp.tipoDeEnvio === 'Agendado' && notificacaoApp.status === 'Nova'">
                    <span class="badge badge-pill badge-danger">
                      Notificação precisa ser testada pra ser enviada.
                    </span>

          <button class="ml-2 btn btn-sm btn-secondary"
                  (click)="enviarCampanha(notificacaoApp);">Enviar Notificação</button>
        </div>

        <span class="badge badge-pill badge-warning" *ngIf="notificacaoApp.foiTestada && notificacaoApp.tipoDeEnvio === 'Agendado' && notificacaoApp.status === 'Nova'">
                    <i class="far fa-clock" ></i>
          {{notificacaoApp.horario}}
          </span>
      </div>

      <p class="mt-2 mb-1" *ngIf="notificacaoApp.status === 'Nova' && notificacaoApp.tipoDeEnvio === 'Agendado'">
        Horário Envio: <b>{{notificacaoApp.horarioEnvio | date: 'dd/MM/yyyy HH:mm:SS'}}</b>
      </p>
      <p *ngIf="notificacaoApp.link" class="mb-1">
        Link da Notificação:
        &nbsp;<a [href]="notificacaoApp.link" target="_blank" class="font-weight-bold">
        <i class="fas fa-link"></i> {{notificacaoApp.link}}</a>

        <span class="text-muted font-11 mb-1 d-block">
          (Não se esqueça de testar o link)
        </span>
      </p>
      <p>
        <span class="font-16">Status Aprovação: <i class="fas fa-check"></i>&nbsp; <strong>{{notificacaoApp.statusAprovacao}}</strong></span>
      </p>

      <div class="row mt-2" *ngIf="notificacaoApp.statusAprovacao === 'Pendente' && !modoAprovacao">
        <div class="col">
          <div class="alert alert-warning">
            <p>
              <strong>Atenção!</strong>
            </p>

            Notificação foi enviada para aprovação. Entre em contato com o suporte para acelerar a aprovação.
          </div>
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="row d-flex align-items-center" style="height: 120px;">
        <div class="col">
          <h1 class="mb-0">{{notificacaoApp.qtdeEnviadas  }}</h1>
          <p>enviados</p>
        </div>
        <div class="col">
          <h1 class="mb-0">{{notificacaoApp.qtdeMensagens }}</h1>
          <p>total</p>
        </div>
        <div class="col acoes" *ngIf="!modoAprovacao">
          <div class="row">
            <span class="badge badge-pill badge-success" *ngIf="notificacaoApp.status === 'Enviando' || notificacaoApp.status === 'ParouEnvio'">{{notificacaoApp.status}}</span>
            <button class="btn btn-blue btn-block"  (click)="editarCampanha(notificacaoApp)" *ngIf="notificacaoApp.status === 'Nova'">Editar</button>
            <button class="btn btn-primary btn-block"   (click)="enviarCampanha(notificacaoApp)" *ngIf="notificacaoApp.status === 'Nova' && notificacaoApp.statusAprovacao === 'Aprovada'">Enviar Campanha</button>
            <button class="btn btn-success btn-block"   (click)="verDetalhesCampanha(notificacaoApp)" *ngIf="notificacaoApp.status === 'Enviada'">Relatório de Mensagens</button>
          </div>
        </div>
        <div class="col acoes" *ngIf="modoAprovacao && notificacaoApp.statusAprovacao === 'Pendente'">
          <div class="row">
            <button class="btn btn-primary btn-block"  (click)="aprovarCampanha(notificacaoApp)" *ngIf="notificacaoApp.status === 'Nova'"><i class="far fa-thumbs-up"></i> Aprovar</button>
            <button class="btn btn-danger btn-block"   (click)="reprovarCampanha(notificacaoApp)" *ngIf="notificacaoApp.status === 'Nova'"><i class="far fa-thumbs-down"></i> Reprovar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="alert alert-success" role="alert" *ngIf="mensagem">
      <i class="mdi mdi-check-all mr-2"></i> <span class="msg">
        {{mensagem}}
      </span>
    </div>
    <div class="alert alert-warning" role="alert" *ngIf="mensagemWarning">
      <i class="fa fa-times mr-2"></i> <span class="msg">
        {{mensagemWarning}}
      </span>
    </div>
  </div>
</div>


<ng-template #preview>
  <div class="position-absolute text-left" style="background: #fff;padding: 10px;top: 80px;left: 30px;width: 345px;border-radius: 5px;">
    <div class="row">
      <div class="col">
        <span class="font-weight-bold;font-13"><strong>{{notificacaoApp.titulo ? notificacaoApp.titulo : "Informe o título"}}</strong></span>
        <p>{{notificacaoApp.mensagem ? notificacaoApp.mensagem : "Informe a mensagem"}}</p>
      </div>
      <div class="col-auto d-flex align-content-center justify-content-center">
        <i class="far fa-image" style="font-size: 40px;border: solid 1px #e2e2e2;padding: 10px;background: #e9ecef;"
           *ngIf="!notificacaoApp.urlImagem"></i>
        <img [src]="'/images/empresa/' + notificacaoApp.urlImagem" style="width: 64px;height: 64px;object-fit: cover" *ngIf="notificacaoApp.urlImagem"/>
      </div>
    </div>
  </div>
</ng-template>


<kendo-dialog title="Enviar Campanha" *ngIf="confirmarEnvioCampanha" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Confirma o Envio da notificação <b>{{notificacaoApp.nome}}</b>?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('sim')" [primary]="true">Sim</button>
    <button kendoButton (click)="close('nao')">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
