import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IntegracaoOpendeliveryService} from "../../services/integracaoopendelivery.service";

@Component({
  selector: 'app-cad-integracao-opendelivery-logistica',
  templateUrl: './cad-integracao-opendelivery-logistica.component.html',
  styleUrls: ['./cad-integracao-opendelivery-logistica.component.scss']
})
export class CadIntegracaoOpendeliveryLogisticaComponent implements OnInit {
  @Input() empresa: any = {};
  @ViewChild('frm', {}) frm:  any;
  integracao: any = { veiculoPadrao:  {}, notificarRetirada: true, notificarConclusao: true, retornarNaLoja: true};
  integracaoOpendelivery: any = { }
  salvando = false;
  processando = false;
  removendo = false;
  mensagemErro: string;
  gerandoChaves = false;
  textoCopiado: string
  veiculosTipos: any = ["MOTORBIKE_BAG", "MOTORBIKE_BOX", "CAR" , "BICYCLE" , "SCOOTER" , "VUC"];
  conteiners: any = ["NORMAL", "THERMIC"];
  conteinersTamanhos: any = ["SMALL", "MEDIUM" , "LARGE" , "EXTRA_LARGE"];
  constructor(private integracaoOpendeliveryService: IntegracaoOpendeliveryService) {

  }

  obtenhaBaseUrl(){
    return String(`https://${location.host}/opendelivery/hooks/deliveryEvent`)
  }

  ngOnInit(): void {
    if(this.empresa.integracaoOpendeliveryLogistica){
      this.integracao = this.empresa.integracaoOpendeliveryLogistica;
      this.integracao.veiculoPadrao = this.integracao.veiculoPadrao ? JSON.parse(this.integracao.veiculoPadrao) : {}
    }

    this.integracaoOpendelivery = this.empresa.integracaoOpendelivery;

    if(this.integracaoOpendelivery &&  this.integracaoOpendelivery.merchantId)
      this.integracao.merchantId = this.integracaoOpendelivery.merchantId
    else if(!this.integracao.merchantId)
      this.integracao.merchantId = this.empresa.dominio;

  }


  onSubmit(){
    delete this.mensagemErro;

    if (this.frm.valid) {
      this.salvando = true;
      this.integracaoOpendeliveryService.salveParametrizacaoLogistica(this.integracao).then((integracao) => {
        this.integracao = this.empresa.integracaoOpendeliveryLogistica = integracao;
        this.salvando = false;
      }).catch((err) => {
        this.salvando = false;
        this.mensagemErro = err;
      })
    } else {
      this.mensagemErro = 'Verifique os campos obrigatorios'
    }
  }

  desativeIntegracao(){
    this.processando = true;

    this.integracaoOpendeliveryService.desativeIntegracaoLogisca(this.integracao).then((integracao) => {
      this.integracao.ativa = false;
      this.processando = false;
    }).catch((err) => {
      this.processando = false;
      this.mensagemErro = err;
    })

  }

  ativeIntegracao(){
    this.processando = true;

    this.integracaoOpendeliveryService.ativeIntegracaoLogistica(this.integracao).then((integracao) => {
      this.integracao.ativa = true;
      this.processando = false;
    }).catch((err) => {
      this.processando = false;
      this.mensagemErro = err;
    })
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.textoCopiado = val;
  }

}
