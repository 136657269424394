<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    Categorias dos produtos
  </h4>

</kendo-dialog-titlebar>
<div #pnl class="modal-body">
  <h5 class="float-left" >Categorias: </h5>

  <button kendoButton class="   ml-1 float-right"   (click)="reordeneCategorias()"
          [disabled]="reordenando" >
    Reordenar
  </button>

  <kendo-button class="btn btn-primary float-right" (click)="novaCategoria()" [themeColor]="'primary'">
    Nova Categoria
  </kendo-button>

  <div class="clearfix mb-1"></div>
  <div class="row nivel" [hidden]="editarCategoria || excluindoCategoria">
    <div class="col">
      <div class="btn btn-block  btn-outline-dark mb-1  " style="text-align: left;">
        <label class="btn-label" > Destaque
        </label>{{empresa.nomeCategoriaDestaques}}
      </div>
    </div>
    <div class="col col-acoes">

      <div class="mt-1">
        <i class="fa fa-edit fa-lg mr-1" (click)="editarNomeCategoriaDestaque()" ></i>

      </div>
    </div>

  </div>
  <app-cad-categoria #cadCategoria  [hidden]="!editarCategoria || excluindoCategoria"
                     [catalogo]="catalogo" [empresa]="empresa"
                     (finalizou)="finalizouCadastro($event, sortable1)"></app-cad-categoria>



  <span [hidden]="editarCategoria || excluindoCategoria" >
      <kendo-sortable  *ngIf="!reordenando"
                       #sortableNivel1
                       [data]=" this.getCategoriasFilhas(1, null)"
                       [navigatable]="true"
                       [animation] = "true"
                       (dragOver)="onDragOver($event, 1, sortableNivel1)"
                       (navigate)="onNavigate($event, 1,  sortableNivel1)"
                       (dragEnd)="onDragEnd($event)"
                       class="" itemClass=" "  activeItemClass="   active">

        <ng-template let-categoria="item" >
          <div class="row nivel">
            <div class="col">
              <div class="btn btn-block  btn-outline-dark mb-1  " style="text-align: left;">
                  <label class="btn-label" > {{categoria.nivel}}.{{categoria.posicao}}
                  </label>{{categoria.nome}}
               </div>
            </div>
            <div class="col col-acoes">

              <div class="mt-1">
                 <ng-container *ngIf="temCategorias(2, categoria)">
                     <i class="fa fa-minus cpointer fa-lg mr-1" (click)="categoria.exibirFilhos = !categoria.exibirFilhos"
                        [hidden]="!categoria.exibirFilhos"></i>
                     <i class="fa fa-plus cpointer fa-lg mr-1" (click)="categoria.exibirFilhos = !categoria.exibirFilhos"
                        [hidden]="categoria.exibirFilhos"></i>
                 </ng-container>


                 <i class="fa fa-edit fa-lg mr-1" (click)="editeCategoria(categoria)" ></i>
                 <i class="fa fa-trash fa-lg  mr-1"  (click)="excluaCategoria(categoria)"></i>

              </div>
            </div>

            <div class="col-12" *ngIf="temCategorias(2, categoria)">
                <div class="row" [hidden]="!categoria.exibirFilhos">
                   <div class="col-1"></div>
                   <div class="col-11">
                     <kendo-sortable
                       #sortableNivel2
                       [data]=" this.getCategoriasFilhas(2, categoria)"
                       [navigatable]="true"
                       [animation] = "true"
                       (dragOver)="onDragOver($event, 2, sortableNivel2)"
                       (navigate)="onNavigate($event, 2, sortableNivel2)"
                       (dragEnd)="onDragEnd($event)"
                       class=""
                       itemClass=" "
                       activeItemClass="   active">

                            <ng-template let-categoria="item" >
                                      <div class="row nivel">
                                         <div class="col">
                                          <div class="btn btn-block  btn-outline-dark mb-1  " style="text-align: left;">
                                              <label class="btn-label" > 1.{{categoria.nivel}}.{{categoria.posicao}}
                                              </label>{{categoria.nome}}
                                           </div>
                                         </div>
                                         <div class="col col-acoes">
                                            <div class="mt-1">
                                               <i class="fa fa-edit fa-lg mr-1" (click)="editeCategoria(categoria)" ></i>
                                               <i class="fa fa-trash fa-lg  mr-1"  (click)="excluaCategoria(categoria)"></i>
                                            </div>
                                           </div>
                                             <div class="col-12" *ngIf="temCategorias(3, categoria)">
                                              <div class="row">
                                                 <div class="col-1"></div>
                                                 <div class="col-11">
                                                   <kendo-sortable
                                                     #sortableNivel3
                                                     [data]=" this.getCategoriasFilhas(3, categoria)"
                                                     [navigatable]="true"
                                                     [animation] = "true"
                                                     (dragOver)="onDragOver($event, 3, sortableNivel3)"
                                                     (navigate)="onNavigate($event, 3, sortableNivel3)"
                                                     (dragEnd)="onDragEnd($event)"
                                                     class=""
                                                     itemClass=" "
                                                     activeItemClass="   active">

                                                          <ng-template let-categoria="item" >
                                                                    <div class="row nivel">
                                                                       <div class="col">
                                                                      <div class="btn btn-block  btn-outline-dark mb-1  " style="text-align: left;">
                                                                          <label class="btn-label" >1.2.{{categoria.nivel}}.{{categoria.posicao}}
                                                                          </label>{{categoria.nome}}
                                                                       </div>
                                                                    </div>
                                                                       <div class="col col-acoes">
                                                                        <div class="mt-1">
                                                                           <i class="fa fa-edit fa-lg mr-1" (click)="editeCategoria(categoria)" ></i>
                                                                           <i class="fa fa-trash fa-lg  mr-1"  (click)="excluaCategoria(categoria)"></i>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                          </ng-template>
                                                        </kendo-sortable>
                                                  </div>
                                                    </div>
                                              </div>
                                      </div>
                            </ng-template>
                          </kendo-sortable>
                    </div>
                </div>
            </div>

          </div>

        </ng-template>

      </kendo-sortable>
    </span>
  <div [hidden]="!categoriaExcluir">
    <div  class="modal-content">
      <form [ngClass]="{'needs-validation': !frmExcluir.submitted, 'was-validated': frmExcluir.submitted}" novalidate #frmExcluir="ngForm" (ngSubmit)="onSubmitExcluir(1, sortable1)">

        <div #pnl class="modal-body">
          <div class="alert alert-success alert-dismissible fade show mt-2" *ngIf="mensagemSucesso" role="alert">
            <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="alert alert-danger alert-dismissible fade show mt-2" *ngIf="mensagemErro" role="alert">
            <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemErro()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="form-group mb-3" *ngIf="!chamandoServidor">
            <span>Você tem certeza que deseja excluir a categoria <strong>{{categoriaExcluir ? categoriaExcluir.nome : ''}}</strong>? Os produtos da categoria irão para 'Outros' após a exclusão.</span>

          </div>
          <div class="form-group mb-3" *ngIf="chamandoServidor">
            <i class="k-icon k-i-loading"></i> Excluindo categoria...
          </div>


          <div class="modal-footer" *ngIf="!chamandoServidor">
            <button type="submit" class="btn btn-primary waves-effect waves-light"  >Excluir</button>
            <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                    (click)="feche(null)">Voltar</button>
          </div>

        </div>

      </form>
    </div>

  </div>




</div>
