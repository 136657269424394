import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MensagemService} from "../services/mensagem.service";
import {GridComponent, GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {debounceTime, distinctUntilChanged, map, switchMap} from "rxjs/operators";
import {from, Subject, Subscription} from "rxjs";

@Component({
  selector: 'app-lista-de-mensagens',
  templateUrl: './lista-de-mensagens.component.html',
  styleUrls: ['./lista-de-mensagens.component.scss']
})
export class ListaDeMensagensComponent implements OnInit, AfterViewInit, OnDestroy {
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = false;
  public previousNext = true;

  mensagens: any;
  total: any;
  loading = false;
  public gridDataResult: GridDataResult;

  page = 0;
  tamanhoPagina = 10;
  @ViewChild('grid', {static: true})
  public grid: GridComponent;
  checked: any;

  statusPossiveisMensagens = [
    {id: 'ENVIADA', nome: 'Enviada'},
    {id: 'NOVA', nome: 'Pendentes'},
    {id: 'NAO_ENVIADA', nome: 'Não Enviada'},
    {id: 'NAO_TEM_WHATSAPP', nome: 'Não tem Whatsapp'},
    {id: 'ENVIANDO', nome: 'Enviando'}
  ];
  statusNaoSelecionado: any = {
    id: null, nome: 'Todos'
  };
  statusFiltro: any;
  filtroNome: any;

  public keyUp = new Subject<KeyboardEvent>();
  subscription: Subscription;
  resumo: any = [];

  constructor(private mensagemService: MensagemService) {
  }

  ngOnInit() {

  }

  carregue($event: PageChangeEvent) {
    this.page = $event.skip;
    this.loading = true;

    this.monteFiltro();
  }

  envieNovamente(mensagem: any) {
    mensagem.enviando = true;

    this.mensagemService.envieNovamente(mensagem).then( (resposta) => {
      mensagem.enviando = false;
      mensagem.status = 'NOVA';
    }).catch(erro => {
      mensagem.enviando = false;
    });
  }

  ngAfterViewInit(): void {
    this.subscription = this.keyUp.pipe(
      debounceTime(700),
      map((event: any) => event.target.value),
      distinctUntilChanged()).subscribe( (dados) => {
        this.monteFiltro();
    });

    this.loading = true;
    this.monteFiltro();
  }

  monteFiltro() {
    const root = { logic: 'and', filters: [], ...this.grid.filter };

    if( this.statusFiltro && this.statusFiltro.id ) {
      root.filters.push({
        field: 'status',
        operator: 'eq',
        value: this.statusFiltro.id
      });
    }

    if( this.filtroNome ) {
      root.filters.push({
        field: 'nome',
        operator: 'eq',
        value: this.filtroNome
      });
    }

    this.loading = true;
    this.mensagemService.obtenhaMensagensObservable(this.page, root).subscribe( (respMensagens) => {
      this.loading = false;
      if( !respMensagens || !respMensagens.sucesso ) {
        return
      }

      const objMensagens = respMensagens.data;
      this.loading = false;
      this.mensagens = objMensagens.mensagens;
      this.total = objMensagens.total;
      this.gridDataResult = {
        data: this.mensagens,
        total: this.total
      };

      this.mensagemService.obtenhaResumo(root).then( (respResumo) => {
        this.resumo = respResumo;
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
