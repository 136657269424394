export class UnidadeMedidaUtils{
  static   obtenhaFormatoItem(item: any){
    if(!item || !item.unidadeMedida || item.unidadeMedida.sigla === 'und')
      return '0 und';

    let sigla: string = item.unidadeMedida.sigla;

    if( sigla === 'g' || sigla === 'ml')
      return String(`0 ${sigla.toLowerCase()}`);

    return String(`0.000 ${sigla.toLowerCase()}`);
  }

  static obtenhaDescricaoUnidade(item: any){
    if(!item || !item.unidadeMedida   ) return '';

    return `${item.unidadeMedida.sigla.toLowerCase()}`
  }


}
