<h4   class="page-title">
  <i class="fas fa-images"></i>
  Cadastro de Banner</h4>

<div class="card">
  <div class="card-body">
    <div class="card-body" *ngIf="loading">
      <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;"></div>
    </div>

    <kendo-grid
      [data]="gridDataResult"
      [loading]="carregando" style="min-height: 300px;"
      [scrollable]="'none'"
      (edit)="editar($event)"
      (remove)="abraDialogRemover($event)">
      <ng-template kendoGridToolbarTemplate [position]="'top'">
        <button class="k-button btn btn-primary"  (click)="novo(false)">
          <i class="fe-plus mr-1"></i>
          Novo Banner
        </button>
      </ng-template>
      <kendo-grid-messages
        pagerPage="Página"
        pagerOf="de"
        pagerItems="itens"
        noRecords="Sem produtos"
        loading="Carregando"
        pagerItemsPerPage="ítems por página"
      >
      </kendo-grid-messages>
      <kendo-grid-column title="ID" [width]="70">
        <ng-template kendoGridCellTemplate let-dataItem  >
          <span class="text-blue"><b>{{dataItem.id}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Nome" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span><b>{{dataItem.nome}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Comando" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span><b>{{dataItem.comando}}</b></span>
        </ng-template>
      </kendo-grid-column>


      <kendo-grid-column title="Validade" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span><b>{{dataItem.validade | date: 'dd/MM/yyyy'}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Foto"  media="(min-width: 1300px)">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <a [href]="'/images/empresa/' + dataItem.linkImagem" target="_blank">
            <img [src]="'/images/empresa/' + dataItem.linkImagem" class="img-rounded" style="float: left;margin-left: 45px;width: 300px;"/>
          </a>
        </ng-template>
      </kendo-grid-column>


      <kendo-grid-command-column title="" [width]="100">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-formaPagamento >
          <ng-container *ngIf="formaPagamento.nome != 'cashback' ">
            <button kendoGridEditCommand [primary]="true" (selectedChange)="editar(formaPagamento)">
              <i class="fa fa-edit" kendoTooltip title="editar"></i>
            </button>
            <button kendoGridRemoveCommand kendoTooltip title="remover" ><i class="fa fa-trash"></i></button>
          </ng-container>

        </ng-template>
      </kendo-grid-command-column>

      <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
    </kendo-grid>
  </div>
</div>

<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Você tem certeza que deseja apagar o banner?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('sim')" [primary]="true">Sim</button>
    <button kendoButton (click)="close('nao')">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
