<h4   class="page-title"> Vitrines</h4>

<div class="card">
  <div class="card-body">


    <kendo-grid
      [kendoGridBinding]="vitrines"
      [loading]="carregando" style="min-height: 300px;"
      [scrollable]="'none'"
      (edit)="editarVitrine($event.dataItem)"
      (remove)="abraDialogRemover($event)">
      <ng-template kendoGridToolbarTemplate [position]="'top'">
        <button class="btn btn-blue" (click)="novaVitrine()">
          <i class="fe-plus mr-1"></i>
          Criar Vitrine
        </button>
      </ng-template>
      <kendo-grid-messages
        pagerPage="Página"
        pagerOf="de"
        pagerItems="itens"
        noRecords="Nenhuma vitrine "
        loading="Carregando"
        pagerItemsPerPage="ítems por página"
      >
      </kendo-grid-messages>

      <kendo-grid-column title="Ordem" [width]="90">
        <ng-template kendoGridCellTemplate let-vitrine  let-rowIndex="rowIndex">
          <div  class="ordem" >

            <kendo-dropdownbutton class="btn btn-block btn-light"
                                  [ngClass]="{ 'desabilitado': trocandoOrdem  }"   [data]="opcoesSubir"
                                  (itemClick)="clicouSubir(vitrine, rowIndex, $event)">
              <i class="fas fa-chevron-up   cpointer"    ></i>
            </kendo-dropdownbutton>


            <span class="text-center d-block font-16">{{vitrine.ordem}}</span>

            <kendo-dropdownbutton class="btn btn-block btn-light"
                                  [ngClass]="{ 'desabilitado': trocandoOrdem  }"  [data]="opcoesDescer"
                                  (itemClick)="clicouDescer(vitrine, rowIndex, $event)">
              <i class="fas fa-chevron-down   cpointer"  ></i>
            </kendo-dropdownbutton>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Descrição">
        <ng-template kendoGridCellTemplate let-vitrine >
          <span><b>{{vitrine.nome}}</b></span>
        </ng-template>
      </kendo-grid-column>


      <kendo-grid-column title="Produtos"   [width]="110" >
        <ng-template kendoGridCellTemplate let-vitrine >
          <i class="fas fa-box-open fa-lg cpointer text-blue"
             (click)="abraModalAssociarProdutos(vitrine)" ></i>

        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Disponível"   [width]="110" >
        <ng-template kendoGridCellTemplate let-vitrine >
          <i class="k-icon k-i-check text-success" *ngIf="vitrine.disponivel"></i>
          <i class="k-icon k-i-times text-danger" *ngIf="!vitrine.disponivel"></i>

        </ng-template>
      </kendo-grid-column>


      <kendo-grid-command-column title="" [width]="150">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-vitrine>
          <button kendoGridEditCommand [primary]="true" (selectedChange)="editarVitrine(vitrine)">
            <i class="fa fa-edit" kendoTooltip title="editar"></i>
          </button>
          <button kendoGridRemoveCommand kendoTooltip title="remover"><i class="fa fa-trash"></i></button>

          <button class="btn btn-xs btn-danger ml-1" (click)="desativarVitrine(vitrine)"
                  kendoTooltip title="Desativar vitrine" *ngIf="vitrine.disponivel" [disabled]="vitrine.processando">
            <i class="far fa-pause-circle fa-lg">  </i>
          </button>
          <button class="btn btn-xs btn-primary ml-1" (click)="ativarVitrine(vitrine)"
                  kendoTooltip title="Ativar vitrine" *ngIf="!vitrine.disponivel" [disabled]="vitrine.processando">
            <i class="far fa-play-circle fa-lg"  ></i>&nbsp;
          </button>


        </ng-template>
      </kendo-grid-command-column>

      <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
    </kendo-grid>

  </div>
</div>
