<h4 class="page-title">
  <i class="ml-2 far fa-building"></i>
  <span> Empresas que usam o template {{templateDeMensagem.nome}}</span>
</h4>

<div>

  <div class="row">
    <div class="col">

      <div class="mt-3"></div>
      <kendo-grid [data]="empresasResultSet" [pageSize]="this.tamanhoPagina"
                  [style]=""
                  [loading]="loading" style="min-height: 300px;"
                  [scrollable]="'none'"
                  [skip]="gridState.skip"
                  [sort]="gridState.sort"
                  [pageable]="true"
                  [sortable]="true"
                  (pageChange)="onPageChange($event)">
        <kendo-grid-messages
          pagerPage="Página"
          pagerOf="de"
          pagerItems="itens"
          noRecords="Nenhuma empresa usa este template ainda."
          loading="Carregando"
          pagerItemsPerPage="ítems por página"
        >
        </kendo-grid-messages>
        <ng-template kendoGridToolbarTemplate [position]="'top'">

          <div class="row">
            <div class="col-6">
              <input kendoTextBox placeholder="Busque por nome da empresa" class="form-control busca" [(ngModel)]="filtro.nome"
                     appAutoFocus kendoTextBox (input)="onFilter($event)"/>

            </div>
            <div class="col-3"></div>
          </div>

        </ng-template>
        <kendo-grid-column title=""   [width]="50" >
          <ng-template kendoGridCellTemplate   let-rowIndex="rowIndex">
            <span class="text-muted ">{{rowIndex + 1}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="ID" class="coluna_menor"   [width]="60">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span ><b>{{dataItem.id}}</b></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Nome" [sortable]="true" field="nome" class="coluna_menor">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>
        <a href="https://{{dataItem.dominio}}.promokit.com.br/admin/index" class="text-blue" target="_blank"><b>{{dataItem.nome}}</b></a>

        <span *ngIf="dataItem.online" class="badge badge-success ml-2">
          Online
        </span>
      </span>
            <p class="mt-1 mb-0 text-muted">
              <span>{{dataItem.telefone }}</span>
            </p>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Link da Loja" class="coluna_menor">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <a href="https://{{dataItem.dominio}}.promokit.com.br/" class="text-blue" target="_blank"><b>{{dataItem.dominio}}</b></a>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>

</div>

<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.getAttribute("data-title") }}</span>

</ng-template>
