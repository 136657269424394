import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {EstoqueService} from "../../services/estoque.service";
import {UnidadeMedidaUtils} from "../UnidadeMedidaUtils";

@Component({
  selector: 'app-form-movimentacao-insumo',
  templateUrl: './form-movimentacao-insumo.component.html',
  styleUrls: ['./form-movimentacao-insumo.component.scss']
})
export class FormMovimentacaoInsumoComponent implements OnInit {
  @Input() varios  = false;
  @Input() movimentacao: any = {};
  @Output() onSalvou: any =   new EventEmitter();
  @Output() onCancelou: any =   new EventEmitter();
  @ViewChild('frmM', {static: false}) frmM: NgForm;
  salvando = false;
  movimentacoes: any = [];
  mensagemErro: string;
  motivosMovimentacao   = [ ]
  constructor(private estoqueService: EstoqueService) { }

  ngOnInit(): void {
    delete this.mensagemErro;
    this.salvando = false;
    this.movimentacao.horario = new Date();
    if(this.varios)
      this.movimentacoes.push({ insumo: null, quantidade: null})

    this.estoqueService.listeMotivosMotimentacoes( ).then( (resposta: any) => {
      this.motivosMovimentacao = resposta;
    });

  }

  obtenhaMotivos( ){
    let beneficado = this.movimentacao.insumo  && this.movimentacao.insumo.tipo === 'produto-beneficiado';

    if(this.varios || beneficado ) return  this.motivosMovimentacao;

    return  this.motivosMovimentacao.filter((item: any) => !item.paraInsumoBeneficado)

  }


  alterouMotivo(motivoDaMovimentacao: any) {
    if(this.varios){
      if(motivoDaMovimentacao.paraInsumoBeneficado){
        this.movimentacoes.forEach((item: any) => {
           if(item.insumo && item.insumo.tipo !== 'produto-beneficiado'){
             item.insumo = null
           }
        })
      }
    }

  }

  onSalvarMovimentacao(){
    if(this.frmM.valid){
      this.salvando = true;

      if(this.varios){
        let dados: any = Object.assign({}, this.movimentacao)
        dados.insumos = this.movimentacoes;

        this.estoqueService.registreMovimentacoesManual(dados).then((resposta) => {
          this.salvando = false;
          this.onSalvou.emit( )
        }).catch((err) => {
          this.mensagemErro = err
          this.salvando = false;
        })
      } else {
        this.estoqueService.registreMovimentacaoManual(this.movimentacao).then((resposta) => {
          this.movimentacao.insumo.estoque =      resposta.insumo.estoque
          this.salvando = false;
          this.onSalvou.emit(this.movimentacao)
        }).catch((err) => {
          this.mensagemErro = err
          this.salvando = false;
        })
      }
    }
  }


  canceleMovimentacao(){
    this.onCancelou.emit({})
  }


  obtenhaFormato(){
    return this.obtenhaFormatoDoInsumo(this.movimentacao.insumo)
  }

  obtenhaFormatoDoInsumo(insumo: any){
    return UnidadeMedidaUtils.obtenhaFormatoItem(insumo)
  }

  obtenhaDescricaoUnidade(insumo: any){
    return UnidadeMedidaUtils.obtenhaDescricaoUnidade(insumo)
  }

  novaMovimentacao() {
    this.movimentacoes.push({})
  }

  removaMovimentacao(i: number) {
    this.movimentacoes.splice(i, 1);
  }


}
