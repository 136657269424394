import {Component, OnInit, ViewChild} from '@angular/core';
import {IntegracaoERPService} from "../../services/integracaoerp.service";
import {PainelImportacaoProdutosComponent} from "../painel-importacao-produtos/painel-importacao-produtos.component";
import {timer} from "rxjs";
import {ModalKendo} from "../../lib/ModalKendo";

@Component({
  selector: 'app-tela-importarerp',
  templateUrl: './tela-importar-produtos-erp.component.html',
  styleUrls: ['./tela-importar-produtos-erp.component.scss']
})
export class TelaImportarProdutosERPComponent extends ModalKendo  implements OnInit {
  @ViewChild('painelImportacao', {static: false}) painelImportacao: PainelImportacaoProdutosComponent;
  empresa: any;
  sincronizou: boolean;
  carregandoProdutos: boolean;
  timerImportacao: any
  erro: string;
  buscouProdutos: boolean;
  tudoSincronizado: boolean;
  codigoPdv: any
  informarCodigo: boolean;
  buscando: boolean;
  openDelivery = false;
  constructor( private integracaoERPService: IntegracaoERPService) {
    super()
  }

  ngOnInit(): void {
    if(!this.informarCodigo)
      this.carregueProdutosNovosAsync();

  }

   pareImportacao(erro){
    if(erro)
      this.erro = erro
    this.carregandoProdutos = false;
    this.timerImportacao.unsubscribe();
  }

  busqueProdutoPorCodigo(){
    delete this.erro;
    if(this.codigoPdv){
      this.buscando = true;
      this.integracaoERPService.obtenhaProduto(this.codigoPdv).then( (resposta: any) => {
        this.buscando = false;
        this.informarCodigo = false;
        this.tudoSincronizado = !resposta.categorias.length ;
        this.painelImportacao.setProdutosSincronizar(resposta.novos, resposta.atualizar, [],
          resposta.categorias)
      }).catch((erro) => {
        this.buscando = false;
        this.erro = erro;
      })
    } else {
      this.erro = 'Informe um código'
    }
  }
  async carregueProdutosNovosAsync(){
    this.carregandoProdutos = true;

    this.integracaoERPService.inicieImportacaoProdutos(this.openDelivery).then(
      () => {
        this.timerImportacao =  timer(2000, 1000 * 5  ).subscribe( () => {
            this.verifqueStatusImportacao();
        });
      }
    ).catch( erro => {
      this.erro = erro
      this.carregandoProdutos = false;
    })
  }

  verifqueStatusImportacao(){
    this.integracaoERPService.obtenhaStatusImportacao(this.openDelivery).then( (resposta) => {
      if(resposta.processando) return;
      this.pareImportacao(resposta.erro);

      if(resposta.novosProdutos){
        this.tudoSincronizado = !resposta.categorias.length ;
        this.painelImportacao.setProdutosSincronizar(resposta.novosProdutos, resposta.produtosAtualizar, [],
          resposta.categorias)

      }
    }).catch( erro => {
      this.pareImportacao(erro)
    })
  }

  async carregueProdutosNovos(){
    this.carregandoProdutos = true;

    let resposta: any;

    resposta = await this.integracaoERPService.listeProdutos().catch( erro => {
      this.erro = erro
    })

    this.carregandoProdutos = false;

    if(resposta){
      this.tudoSincronizado = !resposta.categorias.length ;
      this.painelImportacao.setProdutosSincronizar(resposta.novosProdutos, resposta.produtosAtualizar, [],
        resposta.categorias)
    }

  }

  feche() {
   this.fecheModal(this.painelImportacao.importou)
  }

  fecharAlerta() {
  }
}
