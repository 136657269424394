<div [hidden]="!visivel">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}" novalidate #frm="ngForm"
  (ngSubmit)="submitForm(frm)">

  <ng-container *ngIf="!pedido.aguardandoEstorno" >
    <label [hidden]="exibirApenasAtualizarEntregador || pedidoComandaFechada()">
       <b>Alterar para:</b></label>

    <div [hidden]="exibirApenasAtualizarEntregador" class="form-group   " >
      <div class="ui-g col-fixa" >
        <div class="row mb-1">
            <ng-container *ngFor="let novoStatus of obtenhaNovosStatus(); let i = index;">
              <div class="ui-g-12 col-6  mb-1" >
              <span class="radio radio-blue  " [ngClass]="{'selecionado': pedidoAlterado.status ===novoStatus }">
                  <input type="radio" name="group1" id="novostatus{{i}}" class="k-radio right  mr-1" [(ngModel)]="pedidoAlterado.status" kendoRadioButton
                         value="{{novoStatus}}" required/>
                 <label class="k-radio-label" for="novostatus{{i}}">{{novoStatus}}</label>
              </span>
              </div>
            </ng-container>
        </div>

      </div>
    </div>
  </ng-container>

  <div *ngIf="carregandoEntregadores">
    <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 50px;" ></div>
  </div>

  <div *ngIf="pedidoAlterado.status === 'Saiu para entrega' && entregadores && entregadores.length > 0" class=" mb-3  ">
    <label> <b> Selecionar entregador</b></label>
    <kendo-dropdownlist  name="entregado" [(ngModel)]="pedidoAlterado.objetoEntregador" [data]="entregadores"textField="nome"
                         class="form-control w-50">
    </kendo-dropdownlist>
  </div>

  <div class="form-group mb-3  " [hidden]="pedidoComandaFechada() || pedido.balcao"  >
    <input type="checkbox"   name="notificar" class="k-checkbox "   [(ngModel)]="notificar" kendoCheckBox />
    <label class="k-checkbox-label"  > Notificar cliente</label>
 </div>

  <div  *ngIf="atualizarFoiPago" class=" mb-3 " [hidden]="vaiCancelar() || exibirApenasAtualizarEntregador || pedidoComandaFechada()">
  <label><b>Foi pago?</b></label>
  <div class="form-group  ">
    <div class="ui-g " style="width:250px;margin-bottom:10px">
      <div class="row">
        <div class="ui-g-12  col-5">
          <input type="radio" name="group2" id="pago" class="k-radio right mr-1" [(ngModel)]="pedidoAlterado.pago" [value]="true"  kendoRadioButton required/>
          <label class="k-radio-label" for="pago">Sim</label>
        </div>
        <div class="ui-g-12 col-7">
          <input type="radio" name="group2" id="receberNaEntrega" class="k-radio right mr-1" kendoRadioButton [(ngModel)]="pedidoAlterado.pago" [value]="false"  required/>
          <label class="k-radio-label" for="receberNaEntrega">Não</label>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group mt-2"  *ngIf="pedidoAlterado.pago && !pedidoAlterado.comanda && (!pedido.pagamentos || !pedido.pagamentos.length)">
    <label>Escolha a opção de pagamento que o cliente utilizou</label>

    <div class="form-group mb-2 mt-2 escolher row" style="max-width: 450px;">
      <ng-container *ngFor="let formaDePagamento of empresa?.formasDePagamento">
        <div class="radio radio-blue mb-1 col-12 col-sm-6 "  *ngIf="!formaDePagamento.online"  >
          <input id="formaPagamento{{formaDePagamento.id}}" name="formaDePagamento" type="radio"
                 [(ngModel)]="pagamento.formaDePagamento" [value]="formaDePagamento" class="k-radio mr-1" kendoRadioButton
                 [required]="true"/>
          <label for="formaPagamento{{formaDePagamento.id}}" class="ml-1">{{formaDePagamento.descricao}}</label>
        </div>
      </ng-container>


      <div class="invalid-feedback"  >
        <p><b>Escolha a forma de pagamento do pedido</b></p>
      </div>

    </div>

  </div>

  <p  *ngIf="_pedido.pontosGanhos && !_pedido.pago" [hidden]="exibirApenasAtualizarEntregador">
    <i class="fe-star-on   text-blue"></i>
    Pontos a confirmar <b>{{_pedido.pontosGanhos}}</b></p>
</div>

  <div class="alert alert-danger mt-2" role="alert" *ngIf="erro">
    <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
      <span aria-hidden="true">&times;</span>
    </button>
    <i class="mdi mdi-check-all mr-2"></i> {{erro}}
  </div>

  <div class="alert alert-warning mt-2" role="alert" *ngIf="pedidoComandaFechada()">
    <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
      <span aria-hidden="true">&times;</span>
    </button>
    Pedido não pode ser alterado, reabra a comanda
  </div>

  <button  class="btn btn-blue btn-lg   waves-effect waves-light btn-atualizar "   type="submit"
            [disabled]="salvando" *ngIf="!estornarOnline() && !vaiCancelar()" [hidden]="pedido?.aguardandoEstorno">

    <ng-container *ngIf="pedidoComandaFechada()">
      Fechar
    </ng-container>

    <ng-container *ngIf="!pedidoComandaFechada()">
      <i class="fas fa-sync" *ngIf="!salvando"></i>
      <i class="k-i-loading k-icon" *ngIf="salvando"></i> Atualizar
    </ng-container>

  </button>

  <ng-container *ngIf="vaiCancelar() && !estornarOnline()">

    <ng-container *ngIf="pedido.doIfood">
      <app-razao-cancelamento-ifood #razaoCancelmentoComp [pedido]="pedido"></app-razao-cancelamento-ifood>
    </ng-container>

    <div class="form-group mb-3" *ngIf="!pedido.doIfood">
      <label for="motivoCancelamento"><i class="fas fa-comment-dots"></i>Motivo do cancelamento</label>

      <textarea type="text" class="form-control" autocomplete="off"  minlength="5" required
                id="motivoCancelamento" name="motivoCancelamento" [(ngModel)]="motivoCancelamento"
                placeholder="Informe o motivo" value=""  >
        </textarea>

      <div class="invalid-feedback">
          Informe um motivo para o cancelamento
      </div>
    </div>


    <button  class="btn btn-danger   btn-lg waves-effect waves-light btn-atualizar "   type="submit"
             [disabled]="cancelando" >
      <i class="fas fa-sync" *ngIf="!cancelando"></i>
      <i class="k-i-loading k-icon" *ngIf="cancelando"></i>
      Cancelar Pedido
    </button>

    <p class="text-black-50 mt-1" *ngIf="pedido?.aguardandoPdv">
      <b> Enviando solicitação cancelamento para {{pedido.doIfood ? 'Ifood' : 'PDV'}}, aguarde!</b>
    </p>

    <p class="text-danger mt-1" *ngIf="pedido?.motivoRejeicao">
      <b> PDV não aceitou o cancelamento <b>" {{pedido.motivoRejeicao}}"</b></b>
    </p>

  </ng-container>

    <p class="text-danger mt-1" *ngIf="pedido?.aguardandoEstorno">
      Solicitação reembolso foi enviada, aguarde retorno do   <b> {{pedido.gatewayPagamentoOnline}}</b>
    </p>


    <ng-container *ngIf="estornarOnline()">
    <button  class="btn btn-danger   btn-lg waves-effect waves-light btn-atualizar "   type="submit"
             [disabled]="salvando || cancelando" >
      <i class="fas fa-sync" *ngIf="!salvando && !cancelando"></i>
      <i class="k-i-loading k-icon" *ngIf="salvando || cancelando"></i>
      Estornar Pagamento
    </button>

    <p class="text-primary mt-2">
    <b> <i class="k-icon k-i-information  "></i> Ao cancelar esse pedido, o pagamento feito online será estornado.</b>
    </p>
  </ng-container>

</form>
</div>
