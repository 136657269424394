import { EmpresaService } from './services/empresa.service';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  HostListener,
  isDevMode,
  NgZone,
  OnDestroy, OnInit,
  ViewChild, ViewContainerRef
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import { distinctUntilChanged } from 'rxjs/operators';
import { PlatformLocation } from '@angular/common'
import {ClienteService} from "./services/cliente.service";
import {SwUpdate} from "@angular/service-worker";
import {DialogService} from "@progress/kendo-angular-dialog";
import {AutorizacaoService} from "./services/autorizacao.service";
import {WhatsappService} from "./services/whatsapp.service";
import {ConstantsService} from "./fidelidade/ConstantsService";
import {Subscription} from "rxjs";
import {StatusWhatsapp} from "./objetos/StatusWhatsapp";
import {NotificationService} from "@progress/kendo-angular-notification";
import { EmpresasService } from './superadmin/services/empresas.service';
import {NotificacoesService} from "./services/notificacoes.service";
import {BotsService} from "./services/bots.service";
import {ArmazenamentoService} from "./services/armazenamento.service";
import {MessageHandler} from "./services/MessageHandler";
import {PersistenciaDeChatsWhatappService} from "./services/persistencia-de-chats-whatapp.service";
import {GerenciadorDeAtendimentosService} from "./services/gerenciador-de-atendimentos.service";
import {IndexedDbService} from "./services/indexed-db.service";
import {ConfiguracoesMiaService} from "./services/configuracoes-mia.service";
import {ContatosService} from "./services/contatos.service";
import {AtualizacoesService} from "./services/atualizacoes.service";
import * as Sentry from '@sentry/angular-ivy';
import {SocketService} from "./services/socket.service";

declare var ga: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  static DOZE_HORAS: number = (60 * 60 * 12) * 1000; //12 horas

  title = 'PromoKit';

  usuarioLogado: any;
  iframe = false;
  dev = false;
  empresa: any;
  numeroWhatsapp: string;
  waId: string;
  msgErro: string;
  assinante: Subscription;
  whatsappInvalido = false;
  carregouPromokit: boolean;
  carregouWhatsapp: boolean;
  tempoUltimoPing: Date;
  beep;

  chatAtual: any;
  mapHorariosMsgsSaudades = {};
  eventSource: EventSource;

  messageHandler: MessageHandler;
  configuracoesMia: any;

  constructor(private app: ApplicationRef, private ngZone: NgZone, private route: ActivatedRoute,
              private clienteService: ClienteService, private router: Router,
              private _location: PlatformLocation, private swUpdate: SwUpdate,
              private dialogService: DialogService, private zone: NgZone,
              private autorizacaoService: AutorizacaoService, private empresasService: EmpresasService,
              private contatosService: ContatosService,
              private whatsappService: WhatsappService, private constantsService: ConstantsService,
              private notificationService: NotificationService, private notificacoesService: NotificacoesService,
              private botsService: BotsService, private armazenamentoService: ArmazenamentoService,
              private persistenciaDeChatsWhatappService: PersistenciaDeChatsWhatappService,
              private gerenciadorDeAtendimentosService: GerenciadorDeAtendimentosService,
              private indexDBService: IndexedDbService, private configuracoesMiaService: ConfiguracoesMiaService,
              private atualizacoesService: AtualizacoesService,
              private socketService: SocketService) {

    this.dev = (window.location.href.indexOf('localhost') !== -1);
    this.armazenamentoService.removaExpirados() //será executado a cada 24 horas
    // Using Rx's built in `distinctUntilChanged ` feature to handle url change c/o @dloomb's answer
    window['root'] = this;

    this.crieMessageHandler();

    router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
      // Subscribe to any `NavigationEnd` events where the url has changed
      if(current instanceof NavigationEnd) {
        return previous.url === current.url;
      }

      return true;
    })).subscribe((x: any) => {
    });

    this.iframe = this.inIframe();

    if( this.inIframe() ) {
      document.body.classList.add("iframe");

      this.crieIntegracaoWhatsapp();

      this.monitoreCarregamento();
      $(document).on('focus click', 'input',  function(e){
        window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {tipo: 'FOCOU_INPUT'}}, "*");
      });
    }

    this.assinante = this.autorizacaoService.usuarioLogado$.subscribe( (usuario) => {
      if( !usuario ) return;

      this.usuarioLogado = usuario;

      Sentry.setUser({
        username: usuario.nome,
        email: usuario.email,
        id: usuario.id
      });
    });

    this.constantsService.empresa$.subscribe( (empresa) => {
      if (!empresa) return;

      this.empresa = empresa;

      Sentry.setTag('empresa', this.empresa.dominio);
    });
  }

  monitoreCarregamento() {
    console.log('checando se promokit: ' + this.usuarioLogado);
    this.usuarioLogado = this.autorizacaoService.getUser();
    if( this.usuarioLogado ) {
      this.carregouPromokit = true;
      this.aguardeCarregarWhatsapp();
      return;
    }

    setTimeout(() => {
      this.monitoreCarregamento();
    }, 3000);
  }

  private crieIntegracaoWhatsapp() { //mensagens do whatsapp
    console.log('criando integração\n\n\n\n');
    window.onmessage = (e: MessageEvent) => {
      if( e.data.tipo === 'RESP_CARREGOU_WHATSAPP' ) {
        console.log('[AppComponent] RESP_CARREGOU_WHATSAPP');
        this.carregouWhatsapp = e.data.carregou;
        this.numeroWhatsapp = e.data.numero;
        this.waId = e.data.waId;
        console.log('carregou whatsapp: ' + this.carregouWhatsapp + '  numero: ' + this.waId);
      } else if( e.data.tipo === 'RESP_STATUS_WHATSAPP' ) {
        this.whatsappService.mudeStatus(new StatusWhatsapp(e.data.carregou, e.data.pronto,
          e.data.conectado, e.data.numero));
      } else if( e.data.tipo === 'SELECIONOU_CONTATO' ) {
        this.chatAtual = e.data.chat;
        if( e.data.chat && this.empresa.dominio.toUpperCase().startsWith('SDR') ) {
          this.mudeURL('/admin-sdr/index');
          return;
        }

        if( this.whatsappInvalido ) {
          return;
        }

        this.mudeURL(e.data.url);
        return;
      } else if( e.data.tipo === 'DIGITOU_MENSAGEM' ) {
        /*
        const contato = e.data.contato;
        try {
            this.botsService.salveMensagem(e.data.mensagem).then(() => {
            });
        } catch( erro: any ) {
          console.log(erro);
        }
         */
      }
      else if( e.data.tipo === 'NOVA_MENSAGEM' ) {
        return;
      }

      if( e.data.tipo ) {
        this.processeMensagem(e);
      }
    };

    this.whatsappService.monitoreStatusIntegracao();
  }

  private async obtenhaIdMensagem() {
    let idMensagem = this.armazenamentoService.carregue('idm2', false);

    if( !idMensagem ) {
      const resposta = await this.clienteService.idUltimaMensagem();
      idMensagem = resposta.id;

      console.log('AppComponent: Novo id mensagem: ' + idMensagem);
      this.armazenamentoService.salveSemExpirar('idm2', idMensagem);
    }

    return idMensagem;
  }

  toDataUrl(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  public obtenhaUrlServidor() {
    return window.location.protocol + '//' + window.location.hostname +
      (window.location.port ?  ':' + window.location.port : '');
  }

  private abraEventSource() {
    this.conecte().then( () => {});

    setInterval( () => {
      const tempo = new Date().getTime() - this.tempoUltimoPing.getTime();

      console.log ('tempo último ping: ' + tempo);
      if (tempo > 25000) { //como sistema não recebeu ping, vamos reconectar
        this.conecte().then(() => {
        });
      }
    }, 26000);

    return;
  }

  private async conecte() {
    console.log('conectando event source');

    this.tempoUltimoPing = new Date(); //tempo começa a contar a partir do momento que se conecta
    const idMensagem = await this.obtenhaIdMensagem();

    console.log('evs: ' + idMensagem);
    if( !idMensagem ) {
      return;
    }

    const urlFinal = "/api/mensagens/stream?id=" + idMensagem + '&ngsw-bypass=true&h=' + new Date().getTime();

    if( this.eventSource ) {
      console.log('fechando');

      this.eventSource.close();

      this.eventSource = null;
    }

    this.eventSource = new EventSource(urlFinal);

    this.eventSource.addEventListener('message', (e) => {
      if( e.lastEventId === '0' ) {
        console.log('ping');
        this.tempoUltimoPing = new Date();
        return;
      }
      const messageData = e.data;

      const msg = JSON.parse(messageData);

      console.log(msg);

      this.whatsappService.envieMensagemNoWhatsapp(msg);
    });

    this.eventSource.onerror = async (e) => {
      this.eventSource.close();
      this.eventSource = null;
    };
  }

  novasMensagens(mensagens) {
    if( mensagens.length === 0 ) {
      return;
    }

    const mensagem = mensagens[0];

    console.log(mensagem);
    this.envieMensagem('62982301144', mensagem, '', '');

    setTimeout( () => {
      this.novasMensagens(mensagens.slice(1));
    }, 6000);
  }

  envieMensagem(telefone, msg, latitude, longitude)
  {
    this.envieMensagemNoWhatsapp({
      telefone: '62982301144',
      mensagem: msg,
      tipo: 'CHAT'
    });

    const horarioMensagem = new Date();
    horarioMensagem.setTime(0);

    this.processeMensagensWhatsapp([{
      body: msg,
      chat: {
        id: '556282301144@c.us',
        contact: {
          verifiedName: 'Márcio Siqueira'
        }
      },
      horarioUltimaMensagem: horarioMensagem,
      lat: latitude,
      lng: longitude,
      type: latitude ? 'location' : 'chat',
      from: {
        user: '55' + telefone
      }
    }], true);
  }

  public envieMensagemNoWhatsapp(msg: any) {
    if( msg.beep ) {
      this.toqueAlerta();
    }

    if( msg.imagem ) {
      this.toDataUrl(this.obtenhaUrlServidor() + msg.imagem, (dados: any) => {
        msg.dadosImagem = dados;
        window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: msg}, "*");
      });
    } else {
      window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: msg}, "*");
    }
  }

  private processeMensagem(event: any) {
    if( event.data.tipo === 'ENVIOU_MENSAGEM' ) {
      let respostaEnvio = event.data.respostaEnvio;


      this.whatsappService.enviouMensagem(respostaEnvio).then( () => {
      });
    } else if( event.data.tipo === 'NOVAS_MENSAGENS' ) {
      const mensagens = event.data.mensagens;

      this.processeMensagensWhatsapp(mensagens, false);
      console.log(event.data.mensagens);
    }
  }

  obtenhaNomeContato(mensagem: any) {
    const chat = mensagem.chat;

    let nomeContato = '';
    if( chat && chat.contact ) {
      nomeContato = chat.contact.name ? chat.contact.name : chat.contact.displayName;

      if( !nomeContato ) nomeContato = chat.contact.pushname;
      if( !nomeContato ) nomeContato = chat.contact.verifiedName;
    }

    if( mensagem.sender ) {
      nomeContato = mensagem.sender.name ? mensagem.sender.name : mensagem.sender.formattedName;
    }

    return nomeContato;
  }

  obtenhaTelefoneCompleto(mensagem: any) {
    let usuarioDaMensagem = mensagem.from.user;

    if( !usuarioDaMensagem ) {
      usuarioDaMensagem = mensagem.from
    }

    let telefone = usuarioDaMensagem.replace('@c.us', '');

    return telefone;
  }

  obtenhaTelefone(mensagem: any) {
    let usuarioDaMensagem = mensagem.chatId._serialized;

    if( !usuarioDaMensagem ) {
      usuarioDaMensagem = mensagem.from
    }

    let telefone = usuarioDaMensagem.replace('@c.us', '');

    return '+' + telefone;
  }

  verificaNumeroNaLista(numeroWhatsApp: string, telefonesString) {
    if( !telefonesString ) {
      return false;
    }

    const numeroFormatado = numeroWhatsApp.replace(/\D/g, ''); // Remove caracteres não numéricos
    const numeroFormatadoCom9 = numeroFormatado.slice(0, 4) + '9' + numeroFormatado.slice(4); // Remove o nono dígito do número

    const telefones = telefonesString.split(','); // Transforma a string em uma lista de telefones

    for (const telefone of telefones) {
      const telefoneFormatado = telefone.replace(/\D/g, ''); // Remove caracteres não numéricos

      // Verifica se os números são iguais ou se o telefone da lista é igual ao número do WhatsApp sem o nono dígito
      if (telefoneFormatado === numeroFormatado || telefoneFormatado === numeroFormatadoCom9) {
        return true; // Encontrou o número na lista
      }
    }

    return false; // Não encontrou o número na lista
  }


  processeMensagensWhatsapp(mensagens: any, possuiSessaoAberta: boolean) {
    if( !Array.isArray(mensagens) ) {
      return;
    }

    for( let i = 0; i < mensagens.length; i++ ) {
      const mensagem = mensagens[i];

      console.log(`[processeMensagensWhatsapp] ${mensagem.id} começando -> Body: ${mensagem.body}`, 'Sender: ', mensagem.sender,
        'Mensagem: ', mensagem);

      const idChat = mensagem.chatId._serialized;

      if( idChat === 'status@broadcast' ) {
        console.log(`[processeMensagensWhatsapp] ${mensagem.id} Mensagem não atendeu aos critérios de: mensagem de status`,
          'Mensagem: ', mensagem);
        continue;
      }

      let dadosChat = this.persistenciaDeChatsWhatappService.recuperarMensagemPorChatId(idChat);

      let textoMensagem = this.messageHandler.obtenhaTextoMensagem(mensagem);

      let ehMensagemTeste = false;
      if( textoMensagem === 'teste' || textoMensagem === 'Teste' ) {
        ehMensagemTeste = true;
      }

      this.botsService.salveMensagem(mensagem).then( () => {});

      if( dadosChat && mensagem.timestamp <= dadosChat.timestamp  && !ehMensagemTeste ) {
        console.log(`[processeMensagensWhatsapp] ${mensagem.id}  Já foi processada`, new Date(mensagem.timestamp * 1000).toLocaleString(), ' - ', mensagem);
        continue;
      }

      let mensagemDeGrupo = false;
      if( idChat.indexOf('g.us') > -1 || mensagem.isGroupMsg ) {
        mensagemDeGrupo = true;
      }

      let monologo = (mensagem.to === mensagem.from);
      if( !mensagem || mensagemDeGrupo || mensagem.isNotification) {
        let motivoIgnorado = `[processeMensagensWhatsapp] ${mensagem.id} Mensagem não atendeu aos critérios de: `;

        if (!mensagem) {
          motivoIgnorado += 'mensagem vazia';
        } else if (mensagemDeGrupo) {
          motivoIgnorado += 'mensagem de grupo';
        } else if (mensagem.chat.contact === null) {
          motivoIgnorado += 'mensagem sem chat';
        } else if (mensagem.isNotification) {
          motivoIgnorado += 'mensagem de notificação';
        }

        console.log(motivoIgnorado, mensagem);
        continue;
      }

      console.log(`[processeMensagensWhatsapp] ${mensagem.id} vai processar mensagem`, 'Mensagem: ', mensagem);

      if( textoMensagem && textoMensagem.indexOf('(Eu Mesmo)') !== -1 ) {
        continue;
      }

      if( mensagem.type === 'location' ) {
        textoMensagem = `localizacao: ${mensagem.lat}, ${mensagem.lng}`;

      }

      if( !textoMensagem ) {
        console.log(`[processeMensagensWhatsapp] ${mensagem.id} Ignorando mensagem sem texto.`);
        continue;
      }

      this.persistenciaDeChatsWhatappService.salvarMensagem(mensagem);

      if( this.usuarioLogado ) {
        const statusMia = this.configuracoesMia?.status;

        possuiSessaoAberta = statusMia === 'ATIVADA';
        //const possuiSessaoAberta = false;

        let nome = this.obtenhaNomeContato(mensagem);
        let telefone = this.obtenhaTelefone(mensagem);
        let telefoneCompleto = idChat.replace('@c.us', '');

        /*
        if( mensagem.type === 'list_response' ) {
          const idOpcao = mensagem.listResponse.singleSelectReply.selectedRowId;

          this.envieRespostaMensagemLista(this.empresa, {nome: nome, codigoPais: '',  telefone: telefone},
            mensagem, idOpcao).then( () => {
          });

          return;
        }
         */

        const ehMensagemDeNumeroTeste = this.verificaNumeroNaLista(telefoneCompleto, this.configuracoesMia?.telefonesTeste);

        if( possuiSessaoAberta || (statusMia === 'MODO_TESTE' && ehMensagemDeNumeroTeste) ) { //checa se o bot está ativo na empresa
          console.log('Texto: ' + textoMensagem + ' -> ' + ' Sessão Aberta: ' + possuiSessaoAberta);

          mensagem.nome = nome;
          mensagem.telefone = telefone;

          const minhaMensagem = mensagem.fromMe;

          if( minhaMensagem ) {
            // Verifica se a mensagem é da Mia
            if( textoMensagem.indexOf('🤖:') !== -1 ) {
              console.log(`[processeMensagensWhatsapp] ${mensagem.id} Ignorando mensagem da Mia`);
              continue;
            }

            // Se não for da Mia e não for um monólogo, desativa a comunicação
            if( !monologo ) {
              console.log(`[processeMensagensWhatsapp] ${mensagem.id} Desativando a Mia pq o restaurante respondeu`);
              this.botsService.desativeComunicacao(this.usuarioLogado.empresa,
                {nome: nome, codigoPais: '',  telefone: telefone}).then(() => {});
              continue;
            }
          }

          this.whatsappService.marqueDigitando(mensagem.chatId._serialized);
          this.messageHandler.handleMessage(mensagem, (monologo ? 1000 : 5000));
        } else {
          if( !this.empresa || !this.empresa.enviarMensagemSaudacao ) {
            return;
          }

          if( (!(monologo) && mensagem.fromMe) ) {
            console.log(`[processeMensagensWhatsapp] ${mensagem.id} Ignorando mensagem do próprio usuário em conversa com outro usuário`);
            continue;
          }

          console.log(`[processeMensagensWhatsapp] ${mensagem.id} verificando se vai enviar a mensagem de saudação `)
          if( this.usuarioLogado && (this.empresa.id !== 1319 && this.empresa.id !== 991) &&
            this.usuarioLogado.numeroWhatsapp && !this.usuarioLogado.numeroWhatsapp.principal ) {
            return;
          }

          const agora = new Date();

          const tempo = agora.getTime() - mensagem.horarioUltimaMensagem.getTime();

          let tempoNovaMensagem = this.obtenhaTempoHoras(this.empresa);

          console.log(`[processeMensagensWhatsapp] ${mensagem.id} Tempo: ${tempo / 1000 / 60 / 60} horas Tempo Saudação: ${tempoNovaMensagem / 1000 / 60 / 60} horas.`);
          if( tempo > tempoNovaMensagem || ehMensagemTeste ) {
            let retorno = this.contatosService.extraiCodigoPaisETelefone(this.obtenhaTelefone(mensagem))

            const contato = {
              nome: this.obtenhaNomeContato(mensagem),
              codigoPais: retorno.codigoPais,
              telefone: retorno.telefone
            };

            console.log(`[processeMensagensWhatsapp] ${mensagem.id} Enviando msg de saudação para: `, contato);
            this.envieMensagemSaudacao(this.empresa, contato, mensagem, ehMensagemTeste);
          } else {
            console.log(`[processeMensagensWhatsapp] ${mensagem.id} Não vai enviar a mensagem de saudação pois não
passou o tempo necessário. Tempo: ${tempo / 1000 / 60} min.`);
          }
        }

        this.notificacoesService.salveMensagem(nome, telefone, textoMensagem).catch( (erro) => {});
      }
    }
  }

  //responda mensagem do tipo lista
  async envieRespostaMensagemLista(empresa: any, contato: any, mensagem: any, opcao: any) {
    console.log(`[envieMensagemSaudacao] ${mensagem.id} Enviando msg de saudação para: `, contato);
    const resposta = await this.whatsappService.obtenhaRespostaMensagemLista(this.empresa, contato, opcao);

    this.envieMensagemNoWhatsapp({
      telefone:  contato.codigoPais + contato.telefone,
      mensagem: resposta,
      assinar: false,
      assinatura: '',
      fazerPreview: true,
      marcarComoLida: true,
      enviarLinksBotao: mensagem.enviarLinksBotao,
      temMenu: false,
      tipo: 'SAUDACAO'
    });
  }

  envieMensagemCodigoValidacao(empresa: any, contato: any,  enviarSempre: boolean = false){
    console.log(`[envieMensagemCodigoValidacao] Enviando msg de validação para: `, contato);
    this.whatsappService.processeMensagemCodigoValidacao(this.empresa, contato).then((resposta) => {
      if( !resposta || !resposta.msg ) {
        return;
      }

      const msg = resposta.msg.mensagemFinal;

      this.envieMensagemNoWhatsapp({
        telefone:  contato.codigoPais + contato.telefone,
        mensagem: msg,
        assinar: resposta.msg.assinar,
        assinatura: resposta.msg.assinatura,
        fazerPreview: resposta.msg.fazerPreview,
        marcarComoLida: resposta.marcarComoLida,
        temMenu: resposta.msg.temMenu,
        menu: resposta.msg.menu,
        enviarLinksBotao: resposta.msg.enviarLinksBotao,
        tipo: 'CodigoConfirmacao'
      });
    })
  }

  envieMensagemSaudacao(empresa: any, contato: any, mensagem: any, enviarSempre: boolean = false) {
    console.log(`[envieMensagemSaudacao] ${mensagem.id} Enviando msg de saudação para: `, contato);
    this.whatsappService.processeMensagemSaudacao(this.empresa, contato).then((resposta) => {
      if( !resposta || !resposta.msg ) {
        return;
      }

      const msg = resposta.msg.mensagemFinal;

      if( !enviarSempre ) {
        const horarioMsgAnterior = this.mapHorariosMsgsSaudades[contato.telefone];

        if (horarioMsgAnterior) {
          const tempo = (new Date().getTime() - horarioMsgAnterior.getTime());

          const tempoEnviarNovaMsg = this.obtenhaTempoHoras(empresa);

          console.log(`[envieMensagemSaudacao] ${mensagem.id} Tempo entre msgs de saudação: ${tempo} Tempo Configurado: ${tempoEnviarNovaMsg}`);

          if (tempo < tempoEnviarNovaMsg) {
            console.log(`[envieMensagemSaudacao] ${mensagem.id} Não vai enviar msg de saudação que já foi enviada`);
            return;
          }
        }
      }

      this.mapHorariosMsgsSaudades[contato.telefone] = new Date();

      this.envieMensagemNoWhatsapp({
        telefone:  contato.codigoPais + contato.telefone,
        mensagem: msg,
        assinar: resposta.msg.assinar,
        assinatura: resposta.msg.assinatura,
        fazerPreview: resposta.msg.fazerPreview,
        marcarComoLida: resposta.marcarComoLida,
        temMenu: resposta.msg.temMenu,
        menu: resposta.msg.menu,
        enviarLinksBotao: resposta.msg.enviarLinksBotao,
        linkPreview: resposta.msg.linkPreview,
        tipo: 'SAUDACAO'
      });
    });
  }

  ehComandoOlaBot(textoMensagem) {
    if (!textoMensagem)
      return false;
    const nomeBot = 'BOT';

    const opcoes = ['OI ' + nomeBot, 'OIE ' + nomeBot, 'OLÁ ' + nomeBot];

    return (opcoes.indexOf(textoMensagem.toUpperCase()) !== -1);
  }

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  mudeURL(urlCompleta: string) {
    this.ngZone.run( () => {
      const url = urlCompleta.split('?')[0];
      const pedaco = urlCompleta.split('?')[1];

      const urlParams = new URLSearchParams(pedaco);
      const largura = urlParams.get('nome');
      const telefone = urlParams.get('')

      this.router.navigate([url], {queryParams: {nome: largura}}).then( () => {
        window.scrollTo(0, 0);

        this.app.tick();
      });
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.beep = document.getElementById("beep-bot");
    if( this.beep ) {
      this.beep.load();
    }

    this.router.events.subscribe((evt) => {
      if( evt instanceof NavigationStart ) {
        if( evt.navigationTrigger === 'popstate' ) {
          this.router.getCurrentNavigation().extras.state = {...evt.restoredState, navigationId: evt.id};
        }
      }

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    //carregar configuracoes Mia
    this.configuracoesMiaService.configuracoes$.subscribe(configuracoes => {
      if( !configuracoes ) {
        return;
      }

      this.configuracoesMia = configuracoes;
    });

    this.atualizacoesService.checkForUpdates();
  }

  digitouMensagemBot($event: any) {
    const mensagem = $event.target.value;

    window['root'].novasMensagens([mensagem]);

    $event.target.value = '';
  }

  recarregue() {
    window.location.reload();
  }

  private valideWhatsapp() {
    if( !this.usuarioLogado ) {
      return null;
    }

    let numeroWhatsapp = this.usuarioLogado.numeroWhatsapp;

    /*
    if( numeroWhatsapp.whatsapp !== this.numeroWhatsapp ) {
      return {
        sucesso: false,
        whatsappLogado: this.numeroWhatsapp,
        whatsappConfigurado: numeroWhatsapp.whatsapp
      };
    }

     */

    return {
      sucesso: true
    };
  }

  ngOnDestroy(): void {
    this.assinante.unsubscribe();
  }

  private aguardeCarregarWhatsapp() {
    console.log('[AppComponent] checando se promokit carregou: ' + this.carregouWhatsapp);

    window.parent.postMessage({tipo: "NOVA_MENSAGEM", text: {tipo: 'CARREGOU_WHATSAPP'}}, "*");

    if( this.carregouWhatsapp ) { //pare de buscar
      this.inicieIntegracaoWhastapp();
      return;
    }

    setTimeout(() => {
      this.aguardeCarregarWhatsapp();
    }, 3000);
  }

  private inicieIntegracaoWhastapp() {
    const respValide = this.valideWhatsapp();

    if( !respValide ) {
      return;
    }

    console.log('validou whatsapp: ', respValide);
    if( !respValide.sucesso ) {
      this.whatsappInvalido = true;
      this.router.navigate(['/whatsapp/invalido'], { queryParams: respValide }).catch( (erro) => {});

      return;
    }

    this.abraEventSource();
  }

  private obtenhaTempoHoras(empresa: any) {
    let tempoNovaMensagem = AppComponent.DOZE_HORAS;

    if( empresa.configWhatsapp ) {
      tempoNovaMensagem = empresa.configWhatsapp.tempoMsgSaudacao;
    }

    return tempoNovaMensagem;
  }

  toqueAlerta(){
    this.beep.load();
    this.beep.play().then(() => {
    }).catch(e => {
      console.log('nao tocou alerta: ')
      console.log(e)
    });

  }

  private crieMessageHandler() {
    this.messageHandler = new MessageHandler();
    this.messageHandler.messageComplete.subscribe((msgCompleta: any) => {
      const nome = msgCompleta.nome;
      const telefone = msgCompleta.telefone;
      const textoMensagem = msgCompleta.msg;
      const idChat = msgCompleta.idChat;
      const tipo = msgCompleta.tipo;
      const ultimaMsg = msgCompleta.ultimaMsg;

      this.chameBotService(nome, telefone, textoMensagem, tipo, idChat, 1, ultimaMsg);
    });
  }

  chameBotService(nome: string, telefone: string, textoMensagem: string, tipo: string, idChat: string, tentativa: number = 1,
                  ultimaMsg: any) {
    this.botsService.executeComando(nome, telefone, textoMensagem, tipo, idChat, this.waId,
      false, tentativa, ultimaMsg).then((resposta: any) => {
      console.log(telefone + ': ' + textoMensagem, resposta);

      if (!resposta.data) { //se não tem dados retorne
        return;
      }

      const msg = resposta.data.resposta;

      if( msg.comando === 'FACA_NADA' ) {
        return;

      }

      if (msg.encerrar) {
        this.encerreAtendimentoEChameAtendente(nome, telefone, idChat);
      }

      if( msg.reativou ) { //indica que a Mia foi reativada após uma pausa
        this.gerenciadorDeAtendimentosService.apagueAtendimentoPendente(telefone);
      }

      this.envieMensagemNoWhatsapp(msg);

      if (!resposta.sucesso ) {
        if( tentativa === 1 ) {
          this.chameBotService(nome, telefone, textoMensagem, tipo, idChat, tentativa + 1, ultimaMsg);
        }
      }
    });
  }

  private encerreAtendimentoEChameAtendente(nome: string, telefone: string, idChat: string) {
    this.gerenciadorDeAtendimentosService.adicioneAtendimentoPendente({
      nome: nome,
      telefone: telefone,
      idChat: idChat,
      horario: new Date()
    });

    this.botsService.desativeComunicacao(this.usuarioLogado.empresa,
      {nome: nome, codigoPais: '',  telefone: telefone}).then(() => {});
  }
}
