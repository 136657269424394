
<kendo-dialog title="Estornar pontos ganhos" *ngIf="acaoEstornar" (close)="fecheEstornar()" [minWidth]="250" [width]="450">
  <h4  ><b>{{contato.nome}}</b></h4>

  <p>    <app-icone-acao [acao]="acaoEstornar"></app-icone-acao>.
    Pontos estornar: <span class="font-20"><b>{{acaoEstornar.acumulado }}</b></span></p>


  <div class="form-group mb-3">
    <label for="motivoEstorno"><i class="fas fa-comment-dots"></i>Motivo estorno</label>

    <textarea type="text" class="form-control" autocomplete="off" maxlength="255" required
              id="motivoEstorno" name="motivoEstorno" [(ngModel)]="motivoEstorno"
              placeholder="Informe o motivo" value="" required>
        </textarea>

    <div class="invalid-feedback">
    </div>
  </div>

<div class="alert alert-danger alert-dismissible fade show" *ngIf="erroEstornar" role="alert">
  <i class="mdi mdi-check-all mr-2"></i> {{erroEstornar}}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

  <kendo-dialog-actions>
    <div class="mt-2 mb-2 mr-2">
      <button  class="btn btn-primary"  (click)="confirmouEstorno()" kendoButton [primary]="true" [disabled]="estornando">
        Confirmar Estorno

        <i class="k-icon k-i-loading ml-1" *ngIf="estornando"></i>
      </button>

      <button class="btn btn-light"   (click)="fecheEstornar()">Voltar</button>

    </div>

  </kendo-dialog-actions>

</kendo-dialog>


<div *ngIf="empresa &&  contato">
  <h4>Histórico</h4>
  <div class="card" >

    <div class="card-body">
      <kendo-tabstrip class="nav-bordered">
        <kendo-tabstrip-tab [title]="'Ações'" [selected]="true">
          <ng-template kendoTabContent>

            <kendo-grid   #gridAcoes="kendoGrid"
                          [data]="acoesResultSet"
                          (pageChange)="mudouPaginacaoAcoes($event)"
                          [pageSize]="gridStateAcoes.take"
                          [skip]="gridStateAcoes.skip"
                          [sort]="gridStateAcoes.sort"
                          [pageable]="true"
                          [sortable]="true"
                          [loading]="carregandoUltimasAcoes">
              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="Este cliente não ação."
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>

              <ng-template kendoGridToolbarTemplate>
                <div class="form-group mb-2  " style="    width: 300px;float: right;">
                  <div class="input-group mt-2">
                    <input  name="pontuado" id='pontuado'    class="k-checkbox  " type="checkbox" kendoCheckBox
                            [(ngModel)]="filtro.pontuado" (ngModelChange)="carregueUltimasAcoes()"/>
                    <label  class="k-checkbox-label" for="pontuado">Exibir apenas  que pontuaram</label>
                  </div>

                </div>
              </ng-template>

              <kendo-grid-column title="Descrição"     >
                <ng-template kendoGridCellTemplate let-acao >
                  <div [ngClass]="{'troca': acao.tipo == 3 || acao.tipo == 8, 'novo-contato': acao.tipo == 0,
                                  'removida': acao.removida}">

                  </div>
                  <app-icone-acao [acao]="acao"></app-icone-acao>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Acumulado" field="acumulado"    >  </kendo-grid-column>
              <kendo-grid-column title="Data" field="horario"    >  </kendo-grid-column>
              <kendo-grid-column title="Valor" field="valor"    >
                <ng-template kendoGridCellTemplate let-acao >
                  {{acao.pontuacao?.valor | currency: 'BRL'  }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title=""     >
                <ng-template kendoGridCellTemplate let-acao >
                  <a  class="btn btn-outline-blue btn-sm" href="/imprimir/troca/{{acao.brinde.codigo}}" [hidden]="acao.removida"
                      target="_blank" kendoTooltip title="imprimir" *ngIf="acao.brinde">
                    <i class="fa fa-print fa-lg  ">  </i>
                  </a>

                  <ng-container *ngIf="acao.credito">
                    <a class="btn btn-outline-blue btn-sm" href="/imprimir/pontuacao/{{acao.pontuacao.codigo}}"  target="_blank"
                       kendoTooltip title="imprimir" [hidden]="acao.removida">
                      <i class="fa fa-print fa-lg    ">  </i>
                    </a>

                    <button class="btn btn-outline-danger btn-sm ml-2"  (click)="confirmarCancelarPontos(acao)"
                            kendoTooltip title="cancelar pontos ganhos" [hidden]="acao.removida">
                      <i class="fa fa-trash"></i>
                    </button>

                  </ng-container>
                </ng-template>
              </kendo-grid-column>

            </kendo-grid>

          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Pedidos'" *ngIf="temModuloPedidos">
          <ng-template kendoTabContent>

            <kendo-grid   #grid="kendoGrid"
                          [data]="pedidosResultSet"
                          (pageChange)="mudouPaginacao($event)"
                          [pageSize]="gridState.take"
                          [skip]="gridState.skip"
                          [sort]="gridState.sort"
                          [pageable]="true"
                          [sortable]="true"
                          [loading]="carregandoUltimosPedidos">
              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="Este cliente não possui pedidos anteriores."
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>
              <kendo-grid-column title="Codigo" field="codigo"  [width]="100"  >
                    <ng-template kendoGridCellTemplate let-pedido >
                      <a class="font-weight-500 font-15 text-primary"  [routerLink]="'/admin/pedidos/detalhes/' + pedido.guid">#{{pedido.codigo}}</a>
                    </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Horario"       >
                <ng-template kendoGridCellTemplate let-pedido >
                  <div>{{pedido.horarioVenda | date: 'dd/MM/yyyy'}}</div>
                  <app-pedido-status [pedido]="pedido"></app-pedido-status>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Desconto" field="desconto"    [width]="120" >
                <ng-template kendoGridCellTemplate let-pedido >
                  <label>{{pedido.desconto | currency: 'BRL'}}</label>
                </ng-template>

              </kendo-grid-column>

              <kendo-grid-column title="Tx. Entrega" field="taxaEntrega"  media="(min-width: 900px)" [width]="125">
                <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex">
                  <span class="text-muted" *ngIf="pedido.taxaEntrega ===  -1"><b>A Informar</b></span>
                  <span class="text-muted" *ngIf="pedido.taxaEntrega !== -1"><b>{{pedido.taxaEntrega | currency: "BRL"}}</b></span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Total" field="valor"    [width]="120" >
                <ng-template kendoGridCellTemplate let-pedido >
                  <label>{{pedido.total | currency: 'BRL'}}</label>
                </ng-template>

              </kendo-grid-column>

              <kendo-grid-column title="Pagamento" field="descricaoFormasPagamento"    [width]="160" media="(min-width: 900px)">
                <ng-template kendoGridGroupHeaderTemplate let-value="value">
                  {{value}}
                </ng-template>
                <ng-template kendoGridCellTemplate let-pedido let-rowIndex="rowIndex"  >
                  {{pedido.aguardandoPagamentoOnline ? 'Online': pedido.descricaoFormasPagamento}}

                  <span *ngIf="pedido.pagamentoOnline" class="badge badge-primary ml-1">Online</span>
                </ng-template>
              </kendo-grid-column>



            </kendo-grid>

          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Produtos'" *ngIf="temModuloPedidos">
          <ng-template kendoTabContent>

            <div class="row">
              <div class="col">
                <kendo-daterange   >
                  <label>
                    <span class="label lh">De <br></span>
                    <kendo-dateinput kendoDateRangeStartInput [(ngModel)]="filtro.inicio"  (ngModelChange)="carregueHistoricoProdutos()"></kendo-dateinput>
                  </label>
                  <label >
                    <span class="label  lh">Até </span><br>
                    <kendo-dateinput kendoDateRangeEndInput [(ngModel)]="filtro.fim" (ngModelChange)="carregueHistoricoProdutos()" ></kendo-dateinput>
                  </label>
                </kendo-daterange>
              </div>
            </div>
            <div *ngIf="contato && maisVendidos.length == 0">
              <div class="row">
                <div class="col">
                  <i>Este cliente não comprou nenhum produto no período informado.</i>
                </div>
              </div>
            </div>
            <div class="row border-bottom" *ngIf="contato && maisVendidos.length > 0">
              <div class="col-3 font-weight-bold">
                Código
              </div>
              <div class="col-7 font-weight-bold">
                Nome
              </div>
              <div class="col-2 font-weight-bold">
                Qt
              </div>
            </div>
            <div class="row" *ngFor="let produto of maisVendidos; let i=index">
              <div class="col-3 font-15 border-right" [class.bg-light]="i % 2 == 0">
                <span class="font-weight-500">{{produto.id}}</span>
              </div>
              <div class="col-7 border-right" [class.bg-light]="i % 2 == 0">
                <div>{{produto.nome}}</div>
              </div>
              <div class="col-2" [class.bg-light]="i % 2 == 0">
                <label>{{produto.quantidade}}</label>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Emails'">
          <ng-template kendoTabContent>
            <kendo-grid   #grid="kendoGrid"
                          [data]="emailsResultSet"
                          (pageChange)="mudouPaginacaoEmails($event)"
                          [pageSize]="gridStateEmails.take"
                          [skip]="gridStateEmails.skip"
                          [sort]="gridStateEmails.sort"
                          [pageable]="true"
                          [sortable]="true"
                          [loading]="carregandoEmails">
              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="nenhum email enviado."
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>

              <kendo-grid-column title="Tipo"     >
                <ng-template kendoGridCellTemplate let-envioEmail >
                   <b>{{envioEmail.descricao}}</b>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="destinatario"     >
                <ng-template kendoGridCellTemplate let-envioEmail >
                  <span class="text-blue">{{envioEmail.destinatario.email}}</span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Data"     >
                <ng-template kendoGridCellTemplate let-envioEmail >
                  {{envioEmail.dataEnvio | date: "dd/MM/yyyy HH:mm"}}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title=""     >
                <ng-template kendoGridCellTemplate let-envioEmail >

                  <a target="_blank" [routerLink]="'//emails/visualizar/' + envioEmail.guid" title="Ver email" kendoTooltip>
                    <i class="k-icon k-i-email-box text-primary fa-2x"  >   </i>
                  </a>

                </ng-template>
              </kendo-grid-column>

            </kendo-grid>

          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>


    </div>
  </div>
</div>


