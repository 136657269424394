<h4 class="page-title">
  <i class="fas fa-box"></i>
  Controle de estoque

</h4>

<ng-container *ngIf="!exibirInsumosBaixoEstoque">

  <kendo-panelbar class="mb-0 pb-0" style="    border-bottom: 0;">
    <kendo-panelbar-item [title]="'Filtros'" [expanded]="true"  >
      <ng-template kendoPanelBarContent>
        <div class="card card-body  pt-2 pb-0">
          <div class="row botoes">
            <div class="col-6 col-sm-auto">
              <div class="form-group">
                <label>Buscar insumos</label>
                <input kendoTextBox placeholder="Busque por nome, grupo , codigo" class="form-control busca"
                       name="txtFiltro" [(ngModel)]="filtro.texto" kendoTextBox (input)="onFilter($event)"/>
              </div>
            </div>

            <div class="col-6 col-sm-auto">
              <div class="form-group">
                <label>Grupo</label> <br>
                <kendo-combobox id="cboCategorias" class="form-control" [data]="grupos" style="width: 250px"
                                textField="nome" valueField="id" [(ngModel)]="filtro.grupo" [allowCustom]="false"
                                (ngModelChange)="executeFiltroNaAba()">
                </kendo-combobox>
              </div>

            </div>

          </div>
        </div>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>

  <div class="card">
    <div class="card-body">
      <kendo-tabstrip #tabs class="nav-bordered" id="tabs" name="tabs" (tabSelect)="selecionouTab($event)">
        <kendo-tabstrip-tab [id]="'insumos'" [title]="'Insumos'" [selected]="true"  >
          <ng-template kendoTabContent>
            <div class="row botoes">
              <div  kendoTooltip class="col-auto">
                <button  class="btn  btn-blue mb-2" (click)="abraModalInsumo()">
                  <i class="fe-plus"></i> Adicionar Insumo
                </button>
              </div>
            </div>

            <app-listagem-insumos [grupos]="grupos" [insumos]="insumos" #listaInsumos ></app-listagem-insumos>

          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [id]="'fichatecnica'" [title]="'Ficha Tecnica'"   >
          <ng-template kendoTabContent>
            <div class="row botoes">
              <div  kendoTooltip class="col-auto">
                <button  class="btn  btn-blue mb-2" (click)="abraModalFichaTecnica()">
                  <i class="fe-plus"></i> Adicionar Ficha Tecnica
                </button>
              </div>
            </div>

            <app-listagem-insumos [grupos]="grupos" #listaFichas [insumos]="fichasTecnicas" ></app-listagem-insumos>

          </ng-template>
        </kendo-tabstrip-tab>


        <kendo-tabstrip-tab [id]="'grupos'" [title]="'Grupos'"   >
          <ng-template kendoTabContent>

            <app-listagem-grupo-insumo [grupos]="grupos" [carregando]="carregando"></app-listagem-grupo-insumo>


          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [id]="'movimentaçoes'" [title]="'Movimentações'"   >

          <ng-template kendoTabContent>

            <div class="row botoes">
              <div  kendoTooltip class="col-auto">
                <button  class="btn  btn-blue mb-2" (click)="abraModalNovaMovimentacao()">
                  <i class="fe-plus"></i> Gerar Movimentaçoes
                </button>
              </div>
            </div>


            <app-tela-historico-movimentacoes #historicoMovimentacoes [exibirInsumo]="true">

            </app-tela-historico-movimentacoes>

          </ng-template>

        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </div>


</ng-container>

<ng-container *ngIf="exibirInsumosBaixoEstoque">
  <div class="card-box"  >
    <h4 class="mb-3">Insumos com estoque próximo ou abaixo da <b>quantidade mínima</b></h4>

    <kendo-grid
      (edit)="editarInsumo($event)"
      [group]="groups" [groupable]="true"

      [data]="insumosAlertaGrid"
      [class]="'gridProdutos'"   >
      <kendo-grid-messages   pagerPage="Página"         pagerOf="de"
                             pagerItems="itens"  pagerItemsPerPage="ítems por página"  >
      </kendo-grid-messages>

      <kendo-grid-column field="nivelDescricao" [width]="0">
        <ng-template kendoGridGroupHeaderTemplate let-field="field" let-value="value">
          <strong>{{value}}</strong>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Codigo"  [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span><b>  {{dataItem.codigo || '-'}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Nome"  [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="text-blue"><b>  {{dataItem.nome}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Tipo"  [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span><b>  {{dataItem.objeto}}</b></span>

          <span class="badge badge-info" *ngIf="dataItem.beneficiado">beneficiado</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Grupo"  [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="text-muted" >{{dataItem.grupo ? dataItem.grupo.nome : '-'}} </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Atual"    [width]="90" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span [ngClass]="{'text-success': dataItem.nivelEstoque > 1.5,
                              'text-warning': dataItem.nivelEstoque <= 1.5 ,
                              'text-danger': dataItem.nivelEstoque < 1 }">
              <b> {{dataItem.estoque.quantidade}} {{dataItem.unidadeMedida.sigla}}</b></span>

          <i class="k-icon k-i-list-bulleted cpointer text-blue ml-1" (click)="abraModalMovimentacoes(dataItem)"
             style="    margin-top: -3px  " title="ver movimentações" kendoTooltip></i>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Minimo"    [width]="90">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <b>{{dataItem.estoque.quantidadeMinima}} {{dataItem.unidadeMedida.sigla}}</b>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column title="" [width]="70" [class]="{'comandos': true}">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-insumo>
          <button kendoGridEditCommand [primary]="true" >
            <i class="fa fa-edit" kendoTooltip title="editar"></i>
          </button>


        </ng-template>

      </kendo-grid-command-column>


    </kendo-grid>
  </div>
</ng-container>
