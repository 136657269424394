<form [hidden]="pedido.id"  [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSubmit()">

 <div *ngIf="!produtoSelecionado || !produtoSelecionado.id">
   <h4>
     Escolha o Produto que deseja adicionar
   </h4>
   <div>
     <small class="">Dica: Duplo clique também seleciona o produto.</small>
   </div>

   <kendo-grid #gridProdutos [data]="gridDataResult"
               [loading]="loading"
               [height]="alturaGrid"
               [scrollable]="'scrollable'" [sortable]="true" [sort]="this.state.sort"
               [groupable]="false"
               [selectable]="configuracoesDeSelecao"
               [filter]="state.filter"
               (dblclick)="duploClique($event)"
               (selectionChange)="selecionouProduto($event)"
               (dataStateChange)="dataStateChange($event)">
     <kendo-grid-messages
       pagerPage="Página"
       pagerOf="de"
       pagerItems="itens"
       noRecords="Cadastre Produtos do Pedido"
       loading="Carregando"
       pagerItemsPerPage="ítems por página"
     >
     </kendo-grid-messages>
     <ng-template kendoGridToolbarTemplate>
       <input kendoTextBox placeholder="Busque por Código ou Nome"  *ngIf="!editando"
              class="form-control w-100" appAutoFocus (input)="onFilterEvento($event)"/>
     </ng-template>
     <kendo-grid-column-group title="Teste" [width]="10">
      <kendo-grid-column field="categoria.nome" title="Categoria" [hidden]="true">
       <ng-template kendoGridGroupHeaderTemplate let-value="value">
         {{value}}
       </ng-template>
     </kendo-grid-column>
     </kendo-grid-column-group>
     <kendo-grid-column title="Cod" [width]="65" [style]="{padding: '8px 6px'}" [headerStyle]="{verticalAlign: 'middle', padding: '8px 6px'}">
       <ng-template kendoGridHeaderTemplate>
            <span style="font-size:11px;">
              Cód.
            </span>
       </ng-template>
       <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span style="font-size:12px;">
          {{dataItem.id}}
        </span>
       </ng-template>
     </kendo-grid-column>
     <kendo-grid-column field="nome" title="Produto" [style]="{padding: '8px 6px'}">
       <ng-template kendoGridHeaderTemplate style="vertical-align: middle;">
         <span style="font-size:13px;">Produto</span>
       </ng-template>
       <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span style="font-size:12px;">
          {{dataItem.nome}}
        </span>
       </ng-template>
     </kendo-grid-column>
     <kendo-grid-column title="Preço" [width]="100" headerClass="centralizado">
       <ng-template kendoGridHeaderTemplate>
        <span style="font-size:11px;">
          Preço
        </span>
       </ng-template>
       <ng-template kendoGridCellTemplate let-dataItem  >
        <span class="font-12" *ngIf="!dataItem.valorMinimo">
        {{ (dataItem.novoPreco ? dataItem.novoPreco : dataItem.preco) | currency:"BRL"}}
          <span class="  font-13" *ngIf="exibirUnidade(dataItem)"> / kg </span>
        </span>

         <span class="font-12  " *ngIf="dataItem.valorMinimo">
            A partir de {{dataItem.valorMinimo | currency:"BRL"}}
         </span>


       </ng-template>
     </kendo-grid-column>
     <kendo-grid-column field="nome" title="Produto" [width]="110" media="sm" [style]="{padding: '8px 6px'}">
       <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
         <button class="btn btn-primary btn-sm" (click)="selecionarProduto(dataItem)">Selecionar</button>
       </ng-template>
     </kendo-grid-column>
   </kendo-grid>

   <div class="mt-3" *ngIf="!editando">
     <button class="btn btn-blue btn-block d-lg-none" type="button" (click)="selecionarProdutoBtn()" [disabled]="!produtoSelecionadoNaGrid">Selecionar Produto</button>
   </div>
 </div>

  <div *ngIf="produtoSelecionado">
    <div class="row">
      <h4 class="col-12">
        <button class="btn btn-outline-blue btn-rounded" (click)="cancelar()"><i class="fa fa-arrow-left ct-point"></i></button>
        &nbsp;<strong>{{produtoSelecionado.nome}}</strong>
      </h4>
      <div class="col-12 mt-2"></div>

      <div class="col-12 col-sm-6">
        <div class="form-group mb-12">
          <label for="qtdeDeItens">Qtde. Itens</label>


          <kendo-numerictextbox id="qtdeDeItens" name="qtdeDeItens" class="form-control" [(ngModel)]="itemPedido.qtde"
                                [format]="obtenhaFormato()" [step]="obtenhaIncremento()"   appRemovaWheel  [min]="0"   [autoCorrect]="true">
          </kendo-numerictextbox>
        </div>
      </div>
    </div>

    <ng-container *ngIf="produtoSelecionado">

      <ng-container *ngIf="produtoSelecionado.tamanhos">

        <app-adicionais-customizados #adicionaisCustomizados
                                     (alterouTamanho)="onAlterouTamanho($event)"   [itemPedido]="itemPedido" [produto]="produtoSelecionado">

        </app-adicionais-customizados>

      </ng-container>


      <div *ngFor="let campoAdicional of produtoSelecionado.camposAdicionais">
        <app-site-campo-adicional #adicionalComponent [campoAdicional]="campoAdicional" [itemPedido]="itemPedido" [posicao]="campoAdicional.posicao"
                (onDesmarcouOpcao)="desmarcouNovaOpcao($event)"     (onMarcouOpcao)="escolheuNovaOpcao($event)" ></app-site-campo-adicional>

      </div>

      <ng-container *ngFor="let sabor of itemPedido.sabores">
        <ng-container *ngIf="sabor.produto !== itemPedido.produto?.id">
          <div *ngFor="let adicionalSabor of sabor.camposAdicionais">

            <app-site-campo-adicional #adicionalComponent [id]="'adicional_' + adicionalSabor.id" [campoAdicional]="adicionalSabor"
                                      [itemPedido]="itemPedido" [posicao]="adicionalSabor.posicao"
                                      [exibirSabor]="true"  [tamanhoSabor]="sabor.produtoTamanho"
                                      (onDesmarcouOpcao)="desmarcouNovaOpcao($event)"  (onMarcouOpcao)="escolheuNovaOpcao($event)" ></app-site-campo-adicional>
          </div>
        </ng-container>
      </ng-container>

    </ng-container>

    <div class="mt-2">
      <div class="mt-3"></div>
      <div class="form-group mb-3">
        <label for="observacao"><i class="fas fa-comment-dots"></i> Alguma Observação?</label>
        <textarea type="text" class="form-control" autocomplete="off" maxlength="255"
                  id="observacao" name="observacao" [(ngModel)]="itemPedido.observacao" #observacao="ngModel"
                  placeholder="Inclua alguma observação sobre este item no pedido." value="" required>
        </textarea>

        <div class="invalid-feedback">
        </div>
      </div>
    </div>

    <p class="text-danger mt-1"><b>{{mensagemErro}}</b></p>

    <div class="mt-3">
      <button class="btn btn-blue btn-block" type="button" (click)="adicionarProduto()" [disabled]="!produtoSelecionado" *ngIf="!editando">Adicionar</button>
      <button class="btn btn-blue btn-block" type="button" (click)="atualizeProduto()"  *ngIf="editando">Atualizar</button>
      <button class="btn btn-secondary btn-block" type="button" (click)="cancelar()">Cancelar</button>
    </div>
  </div>
</form>
