<div class="" style="max-width: 900px">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        novalidate #frm="ngForm" (ngSubmit)="onSubmit()" >
    <h4 class="mt-2"><b>Minhas chaves</b></h4>
    <div class="row">
      <div class="col ">
        <div class="form-group mt-2">
          <label>Url Webhook (Tracking Event Webhook)</label>

          <input kendoTextBox   name="urlTracking"    class="form-control"
                 [value]="urlWebHooks()" readonly/>

          <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(urlWebHooks())"
             [ngClass]="{'text-success': textoCopiado === urlWebHooks()}"
             title="copiar url webhook de eventos" kendoTooltip></i>
        </div>
      </div>
    </div>
    <h4>Credenciais do Uber Direct   </h4>

    <div class="row">
      <div class="col col-6">
        <div class="form-group ">
          <label>ID do usuário</label>
          <input kendoTextBox name="appid" class="form-control" placeholder="ID do usuário"
                 [(ngModel)]="integracao.costumerId" required />
          <div class="invalid-feedback"  *ngIf="!integracao.costumerId">
            <p  >Campo é obrigatório</p>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="row">
      <div class="col">
        <div class="form-group ">
          <label>Client ID</label>
          <input kendoTextBox name="logisticClientId" class="form-control" placeholder="clientId para gerar o token"
                 [(ngModel)]="integracao.clientId"  required/>
          <div class="invalid-feedback"  *ngIf="!integracao.clientId">
            <p  >Campo é obrigatório</p>
          </div>

        </div>
      </div>
      <div class="col">
        <div class="form-group ">
          <label>Client Secret</label>
          <input kendoTextBox name="logisticClientSecret" class="form-control" placeholder="clientSecret para gerar o token"
                 [(ngModel)]="integracao.clientSecret"  required/>
          <div class="invalid-feedback"  *ngIf="!integracao.clientSecret">
            <p  >Campo é obrigatório</p>
          </div>
        </div>
      </div>

    </div> -->

    <div class="row">
      <div class="form-group col col-sm-6" *ngIf="integracao?.id">
        <label><b>Webhook’s</b> Chave de autenticação</label>
        <input kendoTextBox class="form-control"    #signingKey="ngModel" name="signingKey"  readonly
               [(ngModel)]="integracao.signingKey" placeholder="chave Webhook" />

        <div class="invalid-feedback"  *ngIf="!integracao.signingKey">
          Campo obrigatório
        </div>
      </div>
    </div>

    <h4>Configurações </h4>
    <fieldset>

      <div class="row">
        <div class="form-group col col-sm-4">
          <div class="input-group mt-1 mb-2">
            <input  name="restaurante" id='restaurante'    class="k-checkbox  " type="checkbox" kendoCheckBox
                    [(ngModel)]="integracao.restaurante"/>
            <label  class="k-checkbox-label" for="restaurante">Estabelecimento é um <b>restaurante</b></label>


          </div>
        </div>

        <div class="form-group col col-sm-4" *ngIf="integracao.restaurante">
          <label>Tempo preparo (minutos)</label>
          <kendo-numerictextbox class="form-control   mr-2" autocomplete="off"  [min]="0" #tempoLimiteRetirada="ngModel"
                                name="tempoPreparo" [format]="'0 min'" required
                                [(ngModel)]="integracao.tempoPreparo" placeholder="tempo de preparação">     </kendo-numerictextbox>
          <div class="invalid-feedback"  *ngIf="!integracao.tempoPreparo">
            Campo obrigatório
          </div>
        </div>

      </div>

      <div class="row">
        <div class="form-group col">
          <label for="instrucoesRetirada">Instruçoes retirada enviar para os entregadores</label>
          <textarea class="form-control" id="instrucoesRetirada" rows="3" name="instrucoesRetirada" [(ngModel)]="integracao.instrucoesRetirada"
                      maxlength="500"  ></textarea>
        </div>
      </div>

      <div class="row">
        <div class="form-group col col-sm-6">
          <label>Ação não entrega padrão</label>

          <kendo-combobox  name="acaoretorno" [(ngModel)]="integracao.acaoretorno"
                              [data]="acoesRetorno" textField="descricao" valueField="id"
                              placeholder="Informe açao ser tomada " class="form-control"
                              required >
          </kendo-combobox>

          <div class="invalid-feedback"  *ngIf="!integracao.acaoretorno">
            Campo  é obrigatório
          </div>

        </div>
      </div>

    </fieldset>


    <div class="row mt-2">
      <div class="col">

        <div *ngIf="mensagemErro" class="alert alert-danger mt-2 mb-2" role="alert">
          {{mensagemErro}}
          <button type="button" class="close" aria-label="Close" (click)="fecharAlerta()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <button class="btn btn-warning waves-effect  " type="button"   [disabled]="processando"
                (click)="ativeIntegracao()"   *ngIf="this.integracao.id && !this.integracao.ativa">
          Ativar <i class="k-i-loading k-icon" *ngIf="processando" ></i>
        </button>

        <ng-container *ngIf="!this.integracao.id || this.integracao.ativa">
          <button class="btn btn-success waves-effect" type="submit"   [disabled]="salvando || processando  "  >
            Salvar <i class="k-i-loading k-icon" *ngIf="salvando" ></i>
          </button>

          <button class="btn btn-danger waves-effect ml-2" type="button"   [disabled]="salvando || processando" *ngIf="integracao.id"
                  (click)="desativeIntegracao()" >
            Desativar <i class="k-i-loading k-icon" *ngIf="processando" ></i>
          </button>
        </ng-container>


      </div>
    </div>
  </form>
</div>
