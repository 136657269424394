import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ConstantsService} from "../../../fidelidade/ConstantsService";
import {EstoqueService} from "../../../services/estoque.service";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";

import {KendoPopupUtils} from "../../../lib/KendoPopupUtils";
import {ModalMovimentacoesEstoqueComponent} from "../../../tela-gerenciar-estoque/modal-movimentacoes-estoque";

@Component({
  selector: 'app-associar-insumo',
  templateUrl: './associar-insumo.component.html',
  styleUrls: ['./associar-insumo.component.scss']
})
export class AssociarInsumoComponent implements OnInit {
  @Input() tipo = 'produto';
  @Input() objeto: any = {};
  temModuloEstoque: boolean
  insumos = [];
  insumosFiltrados = [];
  carregandoInsumo;
  insumoAtual: any;
  empresa: any = {};
  public data: any[] = [];
  @ViewChild("dropdowntree", { static: false }) public dropdowntree: any;
  public expandedNodes: number[] = [];
  constructor(private constantsService: ConstantsService, private dialogService: DialogService,
              private estoqueService: EstoqueService) {

  }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe(   empresa => {
        if(empresa){
          this.empresa = empresa;
          if(this.empresa.estoqueVinculadoProduto)
            if(!this.objeto.estoque)
              this.objeto.estoque = {}
        }
    });

    this.constantsService.moduloEstoque$.subscribe( async data => {
      this.temModuloEstoque = data

      if (this.estoqueSalvo() && this.temModuloEstoque) {
        this.objeto.controlarEstoque = true;
        this.insumoAtual = this.objeto.insumo;
        await this.carregueInsumos();
      }
    })
  }

  estoqueSalvo(){
    if(this.objeto.insumo && this.objeto.insumo.id) return true;
    if(this.objeto.estoque && this.objeto.estoque.id) return true;

    return false;
  }

  obtenhaFormatoEstoque( ) {
    const unidadeMedida = this.empresa.estoqueVinculadoProduto ? this.objeto.unidadeMedida :
      ( this.objeto.insumo ? this.objeto.insumo.unidadeMedida : null  ) ;

    if(!unidadeMedida || unidadeMedida.sigla === 'und')
      return '0';

    let sigla: string = unidadeMedida.sigla;

    if( sigla === 'g' || sigla === 'ml')
      return String(`0 ${sigla}`);

    return String(`0.00 ${sigla}`);
  }

  alterouInsumo($event: any) {

  }

  onFilterInsumo(filterValue: any) {

    this.insumosFiltrados = this.insumos.filter((insumo) => {
      return insumo.descricao.toLowerCase().includes(filterValue.toLowerCase());
    });
  }

  onValueChange(item: any): void {

    // Verifica se o item possui filhos (items), impedindo sua seleção
    if (item && item.disabled) {
      // Fecha e reabre o dropdown para atualizar a seleção visual
      this.dropdowntree.toggle(false); // Fecha o dropdown
      setTimeout(() => {
          this.objeto.insumo = null;
          this.dropdowntree.toggle(true)}
        , 0);

    } else {
      this.objeto.insumo = item; // Permite a seleção apenas para itens internos
    }
  }

  async carregueInsumos(){
    this.carregandoInsumo = true;
    this.insumos = await this.estoqueService.listeInsumos(0, 500, {vincular: true})

    if(this.insumos){
      this.insumos.forEach((insumo: any) => {
        insumo.vincular = true;
        insumo.descricao = `${insumo.nome} - ${insumo.grupo ? insumo.grupo.nome : 'OUTROS'}`;
      })
      this.setInumos(this.insumos);
    }

    if(this.objeto.insumo){
      let atual: any = this.insumos.find((item: any) => item.id ===  this.objeto.insumo.id);
      if(atual){
        this.objeto.insumo = atual
        this.updateExpandedKeys(atual)
      }
    }


    this.carregandoInsumo = false;
  }

  setInumos(insumos: any){
    insumos.forEach((insumo: any) => {
      let grupo = insumo.grupo ?  insumo.grupo : {id: -1, nome:  'OUTROS'};

      let itemGrupo = this.data.find((item: any) => item.nome === grupo.nome);

      if(!itemGrupo) {
        itemGrupo = { id: grupo.id, nome: grupo.nome.toUpperCase(), items: [],  disabled: true}
        this.data.push(itemGrupo)
      }

      itemGrupo.items.push(insumo);
    })

    this.data.sort((a: any, b: any) => a.nome.localeCompare(b.nome))

    this.data.forEach((item: any) => {
      item.items.sort((a: any, b: any) => a.nome.localeCompare(b.nome))
    })

  }

  async alterouControlarEstoque() {
    if(!this.insumos.length)
      await this.carregueInsumos();

    if(this.objeto.codigoPdv && !this.objeto.insumo)
      this.objeto.insumo = this.insumos.find((item: any) => item.codigo  === this.objeto.codigoPdv)
  }



  setInsumoDesvinculado(){
    if(this.insumoAtual ){
      if( !this.objeto.controlarEstoque){
        this.objeto.insumoRemover = this.insumoAtual;
        delete  this.objeto.insumo;
      }  else if(this.objeto.insumo.id !== this.insumoAtual.id ){
        this.objeto.insumoRemover = this.insumoAtual;
      }
    }
  }

  salvou(){
    if(this.objeto.insumo &&  this.objeto.insumo.vincular){
      delete this.objeto.insumo.vincular;
      this.insumoAtual = this.objeto.insumo
    }
  }

  abraModalMovimentacoes(produto: any) {
    //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      content: ModalMovimentacoesEstoqueComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      cssClass: 'bsModal',
      maxHeight:  window.innerHeight - 100

    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)
    windowRef.content.instance.produto = produto;
    windowRef.result.subscribe( (result: any) => { },
      (a) => {      });
  }

  updateExpandedKeys(itemSelecionado: any) {
    // Caminho para o item selecionado
    let grupo = itemSelecionado.grupo ? itemSelecionado.grupo.id  : -1;
    // Atualiza as expandedKeys com os nós necessários
    this.expandedNodes = [grupo];
  }

  public isNodeExpanded = (node: any): boolean => {
    return this.expandedNodes.indexOf(node.id) !== -1;
  };

  public handleCollapse(args: any): void {
    this.expandedNodes = this.expandedNodes.filter(
      (id) => id !== args.dataItem.id
    );
  }

  public handleExpand(args: any): void {
    this.expandedNodes = this.expandedNodes.concat(args.dataItem.id);
  }

}
