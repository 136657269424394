import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmpresasService} from "../../superadmin/services/empresas.service";

@Component({
  selector: 'app-form-integracao-foodydelivery',
  templateUrl: './form-integracao-foodydelivery.component.html',
  styleUrls: ['./form-integracao-foodydelivery.component.scss']
})
export class FormIntegracaoFoodydeliveryComponent implements OnInit {
  @Input() empresa: any = {};
  @ViewChild('frm', {}) frm:  any;
  integracaoFoodyDelivery: any = {}
  integracao = { id: 1, descricao: 'Food Delivery' , sistema: 'foodydelivery', tipo: 'Delivery' }
  salvando = false;
  removendo = false;
  confirmarRemover: any;
  mensagemErro: string;
  constructor(private  empresaService: EmpresasService) { }

  ngOnInit(): void {
    if(this.empresa.integracaoFoodyDelivery)
      this.integracaoFoodyDelivery = this.empresa.integracaoFoodyDelivery;

    if(this.integracaoFoodyDelivery.configuracoesEspecificas)
      this.integracaoFoodyDelivery.integrarAutoSempre =
        this.integracaoFoodyDelivery.configuracoesEspecificas.integrarAutoSempre

  }

  onSubmit() {
    delete this.mensagemErro;

    if (this.frm.valid) {
      this.salvando = true;
      this.integracaoFoodyDelivery.sistema = this.integracao.sistema;
      this.integracaoFoodyDelivery.tipo = this.integracao.tipo;

      this.empresaService.salveIntegracaoDelivery(this.empresa, this.integracaoFoodyDelivery).then( (integracao: any) => {
        this.integracaoFoodyDelivery.id = integracao.id;
        this.integracaoFoodyDelivery.data = integracao.data;
        this.empresa.integracaoFoodyDelivery = this.integracaoFoodyDelivery;
        this.integracaoFoodyDelivery.editar = false;
        this.salvando = false;
      }).catch( (erro) => {
        this.mensagemErro = erro;
        this.salvando = false
      })
    }

  }
  confirmeRemover() {
    this.confirmarRemover = true;
  }

  editarIntegracao(){
    this.integracaoFoodyDelivery.editar = true;
  }

  canceleRemover() {
    this.confirmarRemover = false;
  }

  removerIntegracao() {

    this.removendo = true;

    this.empresaService.removaIntegracaoFoodyDelivery(this.empresa).then(  () => {
      this.removendo = false;
      delete  this.empresa.integracaoFoodydelivery;
      this.integracaoFoodyDelivery = {}
    }).catch((erro) => {
      this.removendo = false;
      alert(erro)
    })

  }

  obtenhaUrlHook() {
    return  String(`${window.location.origin}/hooks/foodydelivery/order`)
  }

  fecharAlerta() {

  }
}
