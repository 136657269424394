<div class="row">
<div class="col">
  <h4 class="page-title">
    <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
    &nbsp; <i class="fe-user "></i> Nova Campanha</h4>

  <ng-container *ngIf="carregandoTela">
    <div class="k-i-loading ml-1 mr-1 mt-4" style="font-size: 40px;"></div>
  </ng-container>

  <div *ngIf="carregouTela && campanha.status === 'Enviando'">
    <app-gerenciador-envio-campanha [campanha]="campanha" [titulo]="'Sua campanha já está sendo enviada'"></app-gerenciador-envio-campanha>
  </div>

  <div style="position: relative" [ngClass]="{'disabled-div': statusPodeEditar.indexOf(campanha.status) === -1}" kendoTooltip
  [attr.title]="statusPodeEditar.indexOf(campanha.status) === -1 ? 'Você não pode Editar Campanha ' + campanha.status: ''">
  <kendo-tabstrip class="nav-bordered" #tabSet (tabSelect)="passoContatosFoiSelecionado($event)">
    <kendo-tabstrip-tab id="tabPasso1" [title]="'Passo 1 - Mensagem'" [selected]="true">
      <ng-template kendoTabContent>
        <form id="frmMensagem" #frmMensagem="ngForm" (submit)="mudarParaDadosBasicos()"  [ngClass]="{'needs-validation': !frmMensagem.submitted,
        'was-validated': frmMensagem.submitted}" novalidate>
          <div class="card">

            <div class="card-body">
              <div class="row">
                <div class="col-fixa" style="width: 150px">
                  <img class="float-left" src="https://media.discordapp.net/attachments/1008571037862080542/1071792301132755026/Marcio_Generate_an_ultra-realistic_portrait_image_of_a_confiden_5d1a44d9-a729-4227-8087-22f2d88fd3d4.png?width=619&height=619"
                       width="150px"/>
                </div>

                <div  class="col">
                  <h4>M<b>ia</b> - Assistente virtual para criação de campanhas por Inteligência Artificial (Experimental)</h4>

                  <span class="text-muted">A Mia consegue criar campanhas de marketing de maneira eficiente e rápida.
                    Ela gera textos de alta qualidade e com links para realização de pedidos, além de incluir cupons de desconto e regras de validade.
                    Tudo isso sem prejudicar a qualidade do texto, que é escrito de acordo com as normas da língua portuguesa.
                  </span>

                  <div class="mt-2">
                    <button class="btn btn-primary" type="button" (click)="ativarMia()"><i class="fas fa-magic"></i> Gerar sugestões de campanha</button>
                  </div>

                  <div class="mt-1 text-muted float-left" kendoTooltip title="Você tem direito a 10 créditos por mês. Eles são renovados automaticamente todo mês."
                       style="cursor: pointer">
                    Créditos Restantes: <span class="font-weight-bold font-13 text-black">{{saldoIA}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <label for="nome">Nome da Campanha</label>
            <input type="text" class="form-control" autocomplete="off"
                   id="nome" name="nome" [(ngModel)]="campanha.nome" #nome="ngModel"
                   placeholder="Nome do Contato" value="" appAutoFocus [autoFocus]="true" required="true">
            <div class="invalid-feedback">
              <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
            </div>
          </div>

          <div class="p-2 rounded">
            <div class="form-group mb-3 mt-2" style="position: relative;">
              <div style="max-width: 400px">
                <app-upload-imagem [objeto]="campanha"></app-upload-imagem>
              </div>
            </div>
            <div class="form-group mb-3 mt-2" style="position: relative;">
              <label>Mensagem da Campanha</label>

              <app-controle-texto-mensagem #ctlTextoMensagem [campanha]="campanha"></app-controle-texto-mensagem>
            </div>

            <hr>
            <div class="row">
              <div class="col">
                <app-controle-menu-whatsapp [objeto]="campanha"></app-controle-menu-whatsapp>
              </div>
            </div>
          </div>

          <div class="row d-flex align-content-center mt-3 mb-5 mx-auto">
            <button class="btn btn-lg btn-primary" type="submit">
              Próximo Passo
            </button>
          </div>
        </form>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab id="tabPasso2" [title]="'Passo 2 - Contatos'" [disabled]="frmMensagem && !frmMensagem.valid" (select)="passoContatosFoiSelecionado($event)">
      <ng-template kendoTabContent>
      <form id="frmContatos" #frmContatos="ngForm" (ngSubmit)="apenasSalvar()" [ngClass]="{'needs-validation': !frmContatos.submitted,
        'was-validated': frmContatos.submitted}" novalidate>

        <div class="form-group mb-1 p-2" [ngClass]="{'disabled-div': campanhaJaFoiEnviada}" kendoTooltip [attr.title]="campanhaJaFoiEnviada ? tooltipCampanhaEnviando: ''">
          <label for="nome">Tipo de Envio</label>
          <div class="mt-1 mb-1" style="width:250px;">
            <label class="k-radio-label mr-1" for="tipoDeEnvio1">
              <input type="radio" #tipoDeEnvio="ngModel" name="tipoDeEnvio" id="tipoDeEnvio1" class="k-radio right" kendoRadioButton value="Unico" [(ngModel)]="campanha.tipoDeEnvio" required/>
              Único
            </label>
            <label class="k-radio-label" for="tipoDeEnvio2">
              <input type="radio" #tipoDeEnvio="ngModel" name="tipoDeEnvio" id="tipoDeEnvio2" class="k-radio right ml-2" kendoRadioButton value="Agendado" [(ngModel)]="campanha.tipoDeEnvio" required/>
              Agendado
            </label>

            <div class="invalid-feedback" *ngIf="tipoDeEnvio.errors?.required">
              Tipo de Envio é obrigatório
            </div>
          </div>
        </div>

        <div class="form-group mb-3" *ngIf="campanha.tipoDeEnvio === 'Agendado'" kendoTooltip
             [ngClass]="{'disabled-div': campanhaJaFoiEnviada}" kendoTooltip [attr.title]="campanhaJaFoiEnviada ? tooltipCampanhaEnviando: ''">
          <label for="nome">Enviar campanha no horário</label>
          <div class="mt-1 mb-1" style="width:250px;">
            <kendo-datetimepicker class="form-control" id="horarioEnvio" name="horarioEnvio"
                                  [min]="agora"
                                  [steps]="steps" [(ngModel)]="campanha.horarioEnvio">
            </kendo-datetimepicker>
            <div class="invalid-feedback"  >
              Horário é obrigatório
            </div>
          </div>
        </div>

        <div class="card w-100" [ngClass]="{'disabled-div': campanhaJaFoiEnviada}">
          <div class="card-body">
              <div class="form-group mb-2">
                <label>Deseja limitar qtde de contatos que vão receber a mensagem?</label>
                <div class="mt-1 mb-1" style="width:250px;">
                  <label class="k-radio-label" for="limitarQtdeMensagensSim">
                    <input type="radio" #configQtdeMsgs="ngModel" name="configQtdeMsgs" id="limitarQtdeMensagensSim"
                           class="k-radio right ml-2" [value]="true" [(ngModel)]="campanha.limitarQtdeContatos" required (click)="mudouQtdeMensagensAEnviar()" kendoRadioButton />
                    Sim
                  </label>
                  <label class="k-radio-label" for="limitarQtdeMensagensNao">
                    <input type="radio" #configQtdeMsgs="ngModel" name="configQtdeMsgs" id="limitarQtdeMensagensNao"
                           class="k-radio right ml-2" [value]="false" kendoRadioButton
                           [(ngModel)]="campanha.limitarQtdeContatos" required (click)="mudouQtdeMensagensAEnviar()" />
                    Não
                  </label>

                  <div class="invalid-feedback" *ngIf="configQtdeMsgs?.errors?.required">
                    Config Quantidade Mensagens a Enviar
                  </div>
                </div>
              </div>
              <div class="form-group mb-2" *ngIf="campanha.limitarQtdeContatos">
                <label for="qtdeMensagens">Quantidade de Mensagens</label>
                <input type="text" class="form-control" autocomplete="off"
                       id="qtdeMensagens" name="qtdeMensagens" [(ngModel)]="campanha.qtdeContatosEnviarMsg" #qtdeContatosMsgs="ngModel"
                       #txtQtdeMensagens (ngModelChange)="mudouQtdeMensagensAEnviar()"
                       placeholder="Quantidade de contatos pra receber a mensagem" value="" required>
                <div class="invalid-feedback">
                  <p *ngIf="qtdeContatosMsgs.errors?.required">Quantidade de Mensagens é Obrigatório</p>
                </div>
              </div>

              <div class="form-group mb-2 escolher">
                <label class="mt-2 k-checkbox-label"  >
                  <input type="checkbox" id="agendarEntrega" name="agendarEntrega" class="k-checkbox " kendoCheckBox
                         [(ngModel)]="campanha.naoEnviarMsgParaQuemRecebeuRecente" (ngModelChange)="mudouQtdeMensagensAEnviar()"
                  />
                  Não enviar mensagens para quem recebeu mensagem recente?
                </label>
              </div>
              <div class="form-group mb-2" *ngIf="campanha.naoEnviarMsgParaQuemRecebeuRecente">
                <label for="qtdeDiasUltimaNotificacao">Quantidade dias Desde a última Notificação</label>
                <input type="text" class="form-control" autocomplete="off"
                       id="qtdeDiasUltimaNotificacao" name="qtdeDiasUltimaNotificacao" [(ngModel)]="campanha.qtdeDiasUltimaNotificacao"
                       #txtQtdeDiasUltimaNotificacao="ngModel"
                       (ngModelChange)="mudouQtdeMensagensAEnviar()"
                       placeholder="Informe a quantidade de dias" value="" required>
                <div class="invalid-feedback">
                  <p *ngIf="txtQtdeDiasUltimaNotificacao.errors?.required">Quantidade de dias é obrigatório</p>
                </div>
              </div>
          </div>
        </div>

        <div class="form-group mb-3" kendoTooltip [ngClass]="{'disabled-div': campanhaJaFoiEnviada}" kendoTooltip [attr.title]="campanhaJaFoiEnviada ? tooltipCampanhaEnviando: ''">
          <label for="nome">Lista de Contatos</label>
          <div class="mt-1 mb-1" style="width:400px;">
            <label class="k-radio-label mr-1" for="origemContatos1">
              <input type="radio" #origemContatos="ngModel" name="origemContatos" id="origemContatos1" class="k-radio right" kendoRadioButton value="Filtro"
                     [(ngModel)]="campanha.origemContatos" required (ngModelChange)="mudouOrigemContatos()"/>
              De um Filtro
            </label>
            <label class="k-radio-label" for="origemContatos2">
              <input type="radio" #origemContatos="ngModel" name="origemContatos" id="origemContatos2" class="k-radio right ml-2" kendoRadioButton value="Arquivo"
                     [(ngModel)]="campanha.origemContatos" required (ngModelChange)="mudouOrigemContatos()"/>
              Arquivo de Contatos
            </label>
            <label class="k-radio-label" for="origemContatos3">
              <input type="radio" #origemContatos="ngModel" name="origemContatos" id="origemContatos3"
                     class="k-radio right ml-2" kendoRadioButton value="Todos"
                     [(ngModel)]="campanha.origemContatos" required (ngModelChange)="mudouOrigemContatos()"/>
              Todos
            </label>

            <div class="invalid-feedback" *ngIf="origemContatos?.errors?.required">
              Tipo de campanha é obrigatório
            </div>
          </div>
        </div>

        <div *ngIf="campanha.origemContatos === 'Filtro'" [ngClass]="{'disabled-div': campanhaJaFoiEnviada}" kendoTooltip [attr.title]="campanhaJaFoiEnviada ? tooltipCampanhaEnviando: ''">
          <div class="form-group mb-12">
          <label for="filtro">Filtro</label>

          <kendo-combobox id="filtro" name="filtro" [(ngModel)]="campanha.filtro" [data]="filtros"
                          class="form-control" [placeholder]="'Selecione um filtro'"
                          [textField]="'nome'"  [valueField]="'id'"
                          [kendoDropDownFilter]="filterSettings" required="true"
                          (ngModelChange)="filtreContatos()">
          </kendo-combobox>
          <div class="invalid-feedback">
            Filtro é obrigatório
          </div>
        </div>
          <ng-container *ngIf="!carregandoContatos">
            <div class="mb-1" *ngIf="resumo !== null">
              <span class="font-14">Total de contatos Selecionados: </span>
              <span class="font-16 font-weight-bold">{{resumo.total}}</span>
            </div>
            <div class="alert alert-info m-3" *ngIf="temFiltro() && contatos.length==0">
              <p>Nenhum contato encontrado! Verifique se os contatos filtrados não receberam campanha recentemente.</p>
            </div>

            <div class="card contatos pt-2" *ngIf="contatos.length > 0">
              <div *ngFor="let contato of contatos | slice:0:5" class="card-box contato">
                <div class="row">
                  <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <card-contato [contato]="contato"></card-contato>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="carregandoContatos">
            <div class="card contatos pt-2">
              <div class="k-i-loading ml-1 mr-1 p-4" style="font-size: 40px;"></div>
            </div>
          </ng-container>
        </div>

        <div [hidden]="campanha.origemContatos !== 'Arquivo'">
          <div class="mb-1" *ngIf="resumo !== null">
            <span class="font-14">Total de contatos Selecionados: </span>
            <span class="font-16 font-weight-bold">{{resumo.total}}</span>
          </div>

          <app-upload-contatos-campanha #uploadContatos [campanha]="campanha" (onNovosContatos)="usuarioFezUploadContatos($event)" required></app-upload-contatos-campanha>
        </div>

        <div *ngIf="campanha.origemContatos === 'Todos'">
          <div class="mb-1" *ngIf="resumo !== null">
            <span class="font-14">Total de contatos Selecionados: </span>
            <span class="font-16 font-weight-bold">{{resumo.total}}</span>
          </div>
        </div>

        <div class="alert alert-danger" *ngIf="mensagemErro">
          <i class="fas fa-exclamation-triangle"></i> <span [innerHTML]="mensagemErro"></span>
        </div>

        <div class="row d-flex align-content-center mt-1 mb-5 mx-auto">
          <button class="btn btn-lg btn-primary">
            Salvar Campanha
          </button>
        </div>
      </form>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <kendo-dialog title="Existem problemas na campanha" *ngIf="confirmarProximoPasso && (!ctlTextoMensagem.mensagemPossuiLinkCardapio || !ctlTextoMensagem.mensagemPossuiNomeContato)" (close)="fecharDialogAlertas()" [minWidth]="250" [width]="450">
    <div class="form-group mb-3">
      Atenção, existem algumas recomendações para a campanha que não foram seguidas:<br><br>
      <ng-container *ngIf="!ctlTextoMensagem.mensagemPossuiNomeContato">
        - É recomendado incluir o nome do contato na mensagem
      </ng-container>
      <ng-container *ngIf="!ctlTextoMensagem.mensagemPossuiLinkCardapio">
        - É recomendado incluir o link do cardápio na mensagem
      </ng-container><br><br>
      Deseja continuar mesmo assim?
    </div>
    <div class="modal-footer">
      <button type="button" (click)="confirmouContinuarCampanha()" class="btn btn-primary waves-effect waves-light" >Continuar</button>
      <button type="button" (click)="fecharDialogAlertas()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
    </div>
  </kendo-dialog>
</div>
</div>

<div style="border-left: solid 1px #ccc;width: 300px;max-width: 300px;background: #fff;padding: 0.5rem;">
  <div>
    <div id="chatPanel" style="position:fixed;width: 280px;">
      <app-controle-previa-mensagem-whatsapp [mensagem]="obtenhaCampanhaAny()"></app-controle-previa-mensagem-whatsapp>
    </div>
  </div>
</div>

<kendo-dialog title="Mia - Assistente de Marketing por IA" *ngIf="miaAtivada" (close)="fecharMia()" [minWidth]="400" [width]="'50%'">
  <form #frmMia (ngSubmit)="gerarSugestao()">
    <ng-container *ngIf="false">
      <div class="form-group">
        <input type="checkbox" #chkUsarCupomDesconto kendoCheckBox [(ngModel)]="ia.usarCupomDeDesconto" />
        <kendo-label
          class="k-checkbox-label"
          [for]="chkUsarCupomDesconto"
          text="Cupom de Desconto"></kendo-label>
      </div>
      <div class="form-group ml-2" *ngIf="ia.usarCupomDeDesconto">
        <label [for]="cupom">Qual código do cupom?</label>
        <input #cupom name="cupom" type="text" class="form-control" [(ngModel)]="ia.cupom" />
      </div>


      <div class="form-group">
        <input type="checkbox" #chkValorMinimo kendoCheckBox [(ngModel)]="ia.temValorMinimo" />
        <kendo-label
          class="k-checkbox-label"
          [for]="chkValorMinimo"
          text="Valor mínimo"
        ></kendo-label>
      </div>

      <div class="form-group ml-2" *ngIf="ia.temValorMinimo">
        <label [for]="cupom">Qual valor mínimo da oferta?</label>
        <input #cupom name="cupom" type="text" class="form-control" [(ngModel)]="ia.valorMinimo" />
      </div>
    </ng-container>

    <ng-container>
      <div class="mt-2" *ngIf="prompts.length > 0" style="max-height: 450px;overflow-y: auto;">
        <ul class="list-group">
          <li class="list-group-item p-0" *ngFor="let prompt of prompts; let i = index">
            <div class="p-2"><strong>Ideia: {{prompt.prompt}}</strong></div>

            <div style="background: #f7f8f8" class="p-2" [ngClass]="{'text-center': gerandoSugestao && i === prompts.length - 1}">
              <span [innerHTML]="prompt.sugestao"></span>
              <i class="k-icon k-i-loading" style="font-size: 48px;" *ngIf="gerandoSugestao && i === prompts.length - 1"></i>
            </div>

            <div class="p-2">
              <button type="button" class="btn btn-sm btn-primary" (click)="usarEssa(prompt)">
                <i class="fas fa-clipboard"></i> Usar Essa
              </button>
            </div>
          </li>
        </ul>
      </div>
      <ng-container>
        <div class="mt-3">

          Adicione alguma ideia que você gostaria de ver na mensagem (cupom de desconto, valor mínimo, etc):
          <div class="mt-1 p-1 card" style="">
          <textarea #txtNovaIdeia class="form-control" id="exampleFormControlTextarea1" rows="1"
                    (input)="resize(txtNovaIdeia)" style="max-height: 300px;border: none;"
            [(ngModel)]="ia.novaIdeia"
          ></textarea>
            <div class="send-icon" style="cursor: pointer;position: absolute;right: 5px;bottom: 5px;padding: 10px;" (click)="gerarSugestao()">
              <i class="fas fa-paper-plane"></i>
            </div>
            <div _ngcontent-ghe-c941="" class="text-muted" kendoTooltip title="Você tem direito a 10 créditos por mês. Eles são renovados automaticamente todo mês."
                 style="right: 40px;position: absolute;bottom: 13px;cursor: pointer">
              Créditos Restantes: <span class="font-weight-bold font-13 text-black">{{saldoIA}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>
  <div class="modal-footer">
    <button type="submit" (click)="gerarSugestao()" [disabled]="gerandoSugestao || ia.novaIdeia?.length < 5" class="btn btn-primary waves-effect waves-light" >Gerar Sugestão</button>
    <button type="button" (click)="fecharMia()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
  </div>
</kendo-dialog>
