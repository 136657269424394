<h4 _ngcontent-mki-c5="" class="page-title"><i _ngcontent-eic-c2="" class="fab fa-whatsapp"></i> Notificações</h4>

<div class="card">
  <div class="card-body">
    <div class="card-body" *ngIf="loading">
      <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;"></div>
    </div>
    <div class="ui-g-12 mt-1" *ngIf="false">
      <input type="checkbox" id="enviarLinksBotao" class="k-checkbox" [(ngModel)]="empresa.enviarLinksBotao" name="enviarLinksBotao" kendoCheckBox (change)="onEnviarLinksBotaoChange()" />
      <label class="k-checkbox-label" for="enviarLinksBotao">Enviar links como botão no Whatsapp </label>
      <span class="badge badge-warning">(Experimental)</span>
      <div *ngIf="mensagemSucessoEnviarLinksBotao" class="alert alert-success mt-2" role="alert">
        {{mensagemSucessoEnviarLinksBotao}}
      </div>
    </div>

    <kendo-tabstrip class=" mt-2"   #tabs>
      <kendo-tabstrip-tab [title]="'Notificações Fidelidade'" id="fidelidade" *ngIf="temModuloFidelidade" [selected]="temModuloFidelidade">
        <ng-template kendoTabContent>
          <kendo-tabstrip class="nav-bordered " *ngIf="!loading" [scrollable]="true">
            <kendo-tabstrip-tab [title]="notificacao.tipoDeNotificacao" *ngFor="let notificacao of notificacoesFidelidade; index  as i" [selected]="i === 0">
              <ng-template kendoTabContent>
                <app-form-notificacao [notificacao]="notificacao"></app-form-notificacao>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Notificações Pedidos'" id="pedidos"
                          *ngIf="temModuloPedidos"
                          [selected]="!temModuloFidelidade">
        <ng-template kendoTabContent>
          <kendo-tabstrip class="nav-bordered" *ngIf="!loading" [scrollable]="true">
            <kendo-tabstrip-tab [title]="notificacao.tipoDeNotificacao" *ngFor="let notificacao of notificacoesPedidos; index as i" [selected]="i === 0">
              <ng-template kendoTabContent>
                <app-form-notificacao [notificacao]="notificacao"></app-form-notificacao>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Notificações SDR'" id="notificacoesSDR" *ngIf="notificacoesSDR.length > 0">
        <ng-template kendoTabContent>
          <kendo-tabstrip class="nav-bordered" *ngIf="!loading" [scrollable]="true">
            <kendo-tabstrip-tab [title]="notificacao.tipoDeNotificacao" *ngFor="let notificacao of notificacoesSDR; index as i" [selected]="i === 0">
              <ng-template kendoTabContent>
                <app-form-notificacao [notificacao]="notificacao"></app-form-notificacao>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Notificações Entregas'" id="notificacoesEntregas">
        <ng-template kendoTabContent>
          <kendo-tabstrip class="nav-bordered" *ngIf="!loading" [scrollable]="true">
            <kendo-tabstrip-tab [title]="notificacao.tipoDeNotificacao" *ngFor="let notificacao of notificacoesEntregas; index as i" [selected]="i === 0">
              <ng-template kendoTabContent>
                <app-form-notificacao [notificacao]="notificacao"></app-form-notificacao>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Templates'">
        <ng-template kendoTabContent>
          <app-templates-de-mensagem></app-templates-de-mensagem>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
