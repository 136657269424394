import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
  ViewChild,
  ComponentFactory, ViewContainerRef, ComponentFactoryResolver
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PedidosService} from "../../services/pedidos.service";
import {MonitoradorPedidos} from "../../fidelidade/MonitoradorPedidos";
import {GroupDescriptor, orderBy, process, SortDescriptor} from "@progress/kendo-data-query";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {ImpressaoService} from "../../services/impressao.service";
import {ComandosImprimirComanda} from "../ComandosImprimirComanda";
import {ComandaService} from "../../services/comanda.service";
import {CellClickEvent, GridComponent} from "@progress/kendo-angular-grid";
import {CadEnderecoContatoComponent} from "../../fidelidade/cad-endereco-contato/cad-endereco-contato.component";

import {GrupolojasService} from "../../superadmin/services/grupolojas.service";
import {ExcelExportData} from "@progress/kendo-angular-excel-export";
import {EntregadorService} from "../../services/entregador.service";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";
import {PedidoAcoesComponent} from "../../fidelidade/pedido-acoes/pedido-acoes.component";

@Component({
  selector: 'app-grid-pedidos',
  templateUrl: './grid-pedidos.component.html',
  styleUrls: ['./grid-pedidos.component.scss']
})
export class GridPedidosComponent extends ComandosImprimirComanda implements OnInit  {
  @ViewChild('grid', { static: false}) grid: any;
  @Output() onAtualizouComanda = new EventEmitter();
  @Input() pedidos = [];
  @Input() comanda;
  @Input() mesa = false;
  @Input() editarPedido = true;
  @Input() carregando: any = false;
  @Input() modoGrupoLojas: any = false;
  @Input() modoSimples = false;
  @Input() orderByDesc = false;

  entregadores: any = [];
  ordenacao: SortDescriptor[] = []
  listaPedidos = [];
  agrupar = false;
  public groups: GroupDescriptor[] = [ ];
  public aggregates: any[] = [{ field: 'total', aggregate: 'sum' }, { field: 'id', aggregate: 'count' }];
  groupable: any;
  filtro: any = {};
  categoria: string;

  exibindoMapa = false;
  taxaSelecionada: any;
  public windowTop = 100;
  public windowLeft = 50;
  public altura = 0;

  public pedidosSelecionadosIds: number[]  = [];
  factory: ComponentFactory<PedidoAcoesComponent>;
  imprimindo = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, protected pedidosService: PedidosService,
              protected comandaService: ComandaService, public dialogService: DialogService,
              private     grupolojasService: GrupolojasService,
              protected constantsService: ConstantsService, protected impressao: ImpressaoService,
              public monitoradorPedidos: MonitoradorPedidos,
              private entregadorService: EntregadorService,
              private viewContainerRef: ViewContainerRef, private resolver: ComponentFactoryResolver) {
    super(pedidosService, constantsService, impressao);

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if( this.mesa ) {
        this.groupable = { showFooter: true };
      } else {
        this.groupable = true;
      }
    });
    this.entregadorService.liste().then((entregadores: any) => {
      this.entregadores = entregadores
    })

    this.factory = this.resolver.resolveComponentFactory(PedidoAcoesComponent);
  }

 async imprimirVarios(impressora: any = null, layoutPedido: string  = null){
    let layout: string = this.configImpressao ? this.configImpressao.layoutPedido : null;

    let pedidos = this.listaPedidos.filter((item: any) =>    this.pedidosSelecionadosIds.indexOf(item.id) >= 0 );

    this.imprimindo = true;

    if(!impressora)
      impressora = this.configImpressao.impressoras[0];

    if(layoutPedido)
      layout = layoutPedido === 'Completo' ? 'PedidoCompleto' : 'PedidoResumido';

    if(!impressora.layout)
      impressora.layout =  layout;

    await this.impressao.imprimaEmLote(pedidos, impressora)

    this.imprimindo = false;
    this.pedidosSelecionadosIds = []

  }

  evitarSelecao(event: any){
    event.stopPropagation();
    return false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.atualizeGridPedidos();
  }

  atualizeGridPedidos(pedidos =  [], categoria = '', ordernar: boolean = null) {
    this.categoria = categoria;
    this.listaPedidos = this.mesa ? pedidos.filter((item: any) => item.mesa) : pedidos;

    if(ordernar)  this.ordenacaoPadrao();

    if( this.mesa ) {

      let group: any = {field: 'comanda.id', aggregates: this.aggregates};
      if(this.orderByDesc)  group.dir = 'desc'

      this.groups = [group];
    } else {
      this.agrupar = this.listaPedidos.find( (pedido) => pedido.aguardandoPagamentoOnline) != null;
      this.groups = this.agrupar ?   [{ field: 'grupo', dir: 'desc' }] : [];
    }

    this.listaPedidos.forEach( (pedido: any) => {
      pedido.grupo =   pedido.aguardandoPagamentoOnline ? 'Aguardando Confirmação de Pagto. Online' : 'Pedidos Recebibos';
    });
  }

  verFechamento(grupo){
    const comanda = grupo.items[0].comanda;

    if(!comanda.fechada)   this.vaParaFecharMesa(comanda)

    return false;
  }

  verPedidoNaLoja(pedido: any){
   window.open(String(`https://${pedido.empresa.dominio}.promokit.com.br/admin/pedidos/detalhes/${pedido.guid}`))
  }

  abriuPedido(event: any) {
    let pedido: any = event.dataItem;

    if(!pedido.itens.length){
      pedido.carregando = true;
      this.getService().obtenhaPorCodigo(pedido.guid, pedido.empresa.id).then(( pedidoCompleto: any) => {
        pedido.carregando = false;
        pedido.itens = pedidoCompleto.itens;
        pedido.adicionaisImprimir = pedidoCompleto.adicionaisImprimir;
      });
    }

    if (pedido)
      this.getService().foiVisualizado(pedido, pedido.empresa.id).then(() => {
        pedido.visualizado = true;
        this.monitoradorPedidos.atualizeFoiLido(pedido)

      })
  }

  fecharMesa(grupo: any) {
    const comanda = grupo.items[0].comanda;

    if( !comanda ) { return; }

    this.vaParaFecharMesa(comanda)

  }

  vaParaFecharMesa(comanda){
    /* Removed unsupported properties by Angular migration: queryParams. */
    this.router.navigateByUrl('/admin/fechar-mesa/' + comanda.id, {}).then( () => {})
  }

  obtenhaComanda(grupo: any) {
    return grupo.items[0].comanda;
  }

  verErroIntegracao(pedido: any, event) {

    event.stopPropagation();
    event.preventDefault();
  }

  clicouNaCelula($event: CellClickEvent) {
    if($event.columnIndex  < 2 ) return;

    if( $event.dataItem.expandido ) {
      $event.sender.collapseRow($event.rowIndex);
      $event.dataItem.expandido = false;
    } else {
      $event.sender.expandRow($event.rowIndex)
      $event.dataItem.expandido = true;
      this.abriuPedido($event)
    }

    return false;
  }

  abraModalEditarEndereco(event: any, pedido: any) {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadEnderecoContatoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)


    windowRef.content.instance.setEndereco(pedido.endereco, true);

    windowRef.result.subscribe((result: any) => {
      if(result && result.id) {
        pedido.endereco = Object.assign(pedido.endereco, result);
        pedido.enderecoCompleto = result.enderecoCompleto;
      }
    } );

    event.stopPropagation();
    event.preventDefault();
  }

  getService(): any {
    if (this.modoGrupoLojas)
      return this.grupolojasService;

    return this.pedidosService;
  }

  public exportToExcel(grid: GridComponent): void {
    let colunasOcultas = ['cashbackUsado'];

    if(this.exibirColunaEntregador()) {
      let colunasPagamentos: any = ['taxaEntrega', 'total', 'descricaoFormasPagamento'];
      colunasOcultas.push(...colunasPagamentos)
    }

    colunasOcultas.forEach((nomeColuna) => {
      let coluna = grid.columns.find((item: any) => item.field === nomeColuna );

      coluna.hidden = false;
    })

    grid.saveAsExcel();

    colunasOcultas.forEach((nomeColuna) => {
      let coluna = grid.columns.find((item: any) => item.field === nomeColuna );

      coluna.hidden = true;
    })
  }

  public pedidosExportar = (): any  => {
    let myState: any = {};

    myState.skip = 0;
    myState.take = this.pedidos.length;

    const result: ExcelExportData =  {
      data: process(this.pedidos, myState).data
    };
    return result;
  };

  expandirDetalhes(){
    return !this.modoSimples
  };

  obtenhaNomeArquivoDownload(nome: string) {
    let sufixo =  this.categoria,
      horario = new Date().getTime();

    if(this.mesa){
       sufixo += '-mesa'
    } else {
      sufixo += '-delivery'
    }


   // sufixo = String(`${inicio}_e_${fim}`)

    return String(`${nome}-${sufixo}.xls`)
  }

  exibaMapa(event, pedido) {
    event.preventDefault();

    let taxa = {
      fazEntrega: true,
      erro: '',
      localizacao: pedido.endereco.localizacao,
      valor: pedido.taxaEntrega
    };

    this.exibindoMapa = true;
    if( taxa.fazEntrega ) {
      this.taxaSelecionada = {
        localizacao: taxa.localizacao,
        msg: "Dentro da área de entrega: " + new Intl.NumberFormat('pt-BR',
          {style: 'currency', currency: 'BRL'}).format(taxa.valor)
      };
    } else {
      this.taxaSelecionada = {
        localizacao: taxa.localizacao,
        msg: taxa.erro
      };
    }

    return false;
  }

  fecharTelaMapa() {
    this.exibindoMapa = false;
  }

  reabraComanda(comanda: any){
    comanda.atualizando = true;
    this.comandaService.reabraComanda(comanda).then(() => {
        comanda.atualizando = false;
        comanda.abertura = true;
        comanda.fechada = false;
       this.vaParaFecharMesa(comanda)

    }).catch((erro) => {
      comanda.atualizando = false;
      alert(erro)
    })
  }

  exibirColunaEntregador() {
    if(!this.empresa) return false;

    if(this.empresa.integracaoUberdirect && this.empresa.integracaoUberdirect.ativa) return true;

    if(this.empresa.integracaoFoodyDelivery && this.empresa.integracaoFoodyDelivery.ativa) return true;

    if(this.temIntegracaoIfoodDelivery()) return true;

    return this.empresa.integracaoOpendeliveryLogistica && this.empresa.integracaoOpendeliveryLogistica.ativa
  }

  temIntegracaoIfoodDelivery(){
    return this.empresa.integracoesIfood && this.empresa.integracoesIfood.find((item: any) => item.shippingApi) != null;
  }

  obtenhaLinkContato(pedido: any){
    if(pedido.cliente.telefone) return String(`/admin/contatos/${pedido.cliente.codigoPais}${pedido.cliente.telefone}`)

    return String(`/admin/contatos/id/${pedido.cliente.id}`)
  }


  onSortChange(sort: SortDescriptor[]): void {
    this.listaPedidos = orderBy(this.listaPedidos, sort);

    const campo: SortDescriptor = sort[0];

    if(campo.field === 'codigo' && campo.dir){
      localStorage.setItem('ordenarPorCodigo', campo.dir)
    } else {
      localStorage.removeItem('ordenarPorCodigo');
      this.ordernePorPrevisaoFicarPronto();
    }
  }

  ordernePorCodigo(direcao: any = 'asc'){
    this.ordenacao = [{ field: 'codigo', dir: direcao }];
    //this.listaPedidos = orderBy(this.listaPedidos,  this.ordenacao);
  }

  ordenacaoPadrao(){
    let salvoPorCodigo = localStorage.getItem('ordenarPorCodigo')

    if(salvoPorCodigo){
      this.ordernePorCodigo(salvoPorCodigo);
    } else {
      this.ordernePorPrevisaoFicarPronto();
    }
  }

  ordernePorPrevisaoFicarPronto(){

    this.listaPedidos.sort((a, b) => {
      const dataA = new Date(a.horarioPrevisoFicarPronto);
      const dataB = new Date(b.horarioPrevisoFicarPronto);

      // Ordem decrescente (do mais recente para o mais antigo)
      return dataA.getTime() - dataB.getTime()
    });
  }
}
