import {Component, OnInit} from "@angular/core";
import {ModalKendo} from "../lib/ModalKendo";

@Component({
  selector: 'app-modal-gerar-movimentacoes',
  template: `

    <kendo-dialog-titlebar (close)="fecheModal(null)"  >
      <h4 class="modal-title">
        <img src="assets/fidelidade/icones/adicionar-insumo.png" style="height: 32px;"/>
        Gerar Movimentações
      </h4>
    </kendo-dialog-titlebar>

    <div   class="modal-body">
      <app-form-movimentacao-insumo
        (onSalvou)="salvouMovimentacao($event)" [varios]="true" (onCancelou)="cancelouMovimentacao()">

      </app-form-movimentacao-insumo>


    </div>

  `
})
export class ModalGerarMovimentacoesEstoqueComponent   extends ModalKendo implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  cancelouMovimentacao(){
    this.fecheModal()
  }

  salvouMovimentacao(movimentacoes: any) {
    this.fecheModal(movimentacoes)
  }
}
