<div class="card mt-4" style="max-width: 800px;">
  <div class="modal-header">
    <button class="btn-voltar btn btn-outline-light btn-rounded" (click)="voltar()">
      <i class="fa fa-arrow-left ct-point" ></i>
    </button>
    <h4 class="modal-title" id="myModalLabel">
      <img src="assets/fidelidade/icones/trocar-selo.svg" style="height: 32px;"/> Trocar Pontos
    </h4>
  </div>

  <div class="card-body">
    <form [ngClass]="{'needs-validation': !f.submitted, 'was-validated': f.submitted}" #f="ngForm" novalidate (ngSubmit)="trocarPontosSubmit()">

  <div>
    <app-buscar-contato #buscarContato mensagem="Escolha o contato para trocar os pontos"></app-buscar-contato>

      <div class="row contato" *ngIf="contato && contato.id">
        <div class="col-5">
          <card-contato [contato]="contato"></card-contato>
        </div>
        <div class="col-7">
          <div class="card-box text-center" style="padding: 0px;" >
            <div class="row">
              <div class="col-4">
                <saldo-cartao [cartao]="cartao"  class="pontos"></saldo-cartao>
              </div>

            <div class="col-8">
              <span class="text-black" style="    line-height: 40px;">{{ contato.ultimaVisita | date: 'dd/MM/yyyy HH:mm' }}</span><br>
              <label>Última visita</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="linha"></div>

    <div class="form-group   mt-3" *ngIf="contato.cartoes && contato.cartoes.length > 1">
      <label for="plano">Plano Fidelidade:  </label>
      <kendo-combobox id="plano" name="plano" [(ngModel)]="cartao"  [data]="contato.cartoes"   [required]="contato.cartoes.length > 1"
                      [allowCustom]="false" [clearButton]="false"  placeholder="Selecione um Plano" class="form-control"  (ngModelChange)="alterouPlano()"
                      [kendoDropDownFilter]="filterSettings"             textField="nomePlano" >


      </kendo-combobox>
      <div class="invalid-feedback">
        Plano é obrigatório
      </div>
    </div>

    <div class="form-group mb-12 mt-3">

      <label for="brindeEscolhido">Selecione o Brinde:</label>
      <kendo-combobox id="brindeEscolhido" name="brinde" [(ngModel)]="brindeEscolhido" [data]="cartao?.brindes" [filterable]="true"
                      [allowCustom]="false" placeholder="Selecione um brinde" class="form-control"
                      textField="nome" required="true"
                      [kendoDropDownFilter]="filterSettings"
                      [valueField]="'id'">
        <ng-template kendoComboBoxItemTemplate let-brinde>
              <span class="k-state-default">
                <img src="/images/empresa/{{brinde.linkImagem}}" style="width: 48px;"/>
              </span>
          <span class="k-state-default ml-2">
                <p class="mb-1 mt-1" style="font-size: 16px;">{{brinde.nome}}</p>
                <p> <span *ngIf="ehCashback()">Mínimo de </span> {{brinde.valorEmPontos}} pontos</p></span>
        </ng-template>
      </kendo-combobox>

    </div>

    <div class="form-group   mb-3"  *ngIf=" ehCashback() ">
      <label>Saldo utilizar</label>

      <div class="input-group">
        <kendo-numerictextbox name="ctrlSaldo" #ctrlSaldo=ngModel [(ngModel)]="saldoUtilizar"  format="c2" class="form-control"
                              [max]="cartao.pontos" [min]="brindeEscolhido ? brindeEscolhido.valorEmPontos : 0"
                              [required]="ehCashback()"  >

        </kendo-numerictextbox>

        <div class="invalid-feedback" >
          <span *ngIf="ctrlSaldo.errors?.required">Informe  o saldo a ser usado </span>
          <span *ngIf="ctrlSaldo.errors?.maxError">Seu saldo é de {{ctrlSaldo.errors.maxError.maxValue  | currency: "BRL"}} </span>
          <span *ngIf="ctrlSaldo.errors?.minError">Valor  mínimo para troca é  de {{ctrlSaldo.errors.minError.minValue  | currency: "BRL"}} </span>
        </div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label for="txtMensagem">Mensagem</label>

      <textarea class="form-control" id="txtMensagem" rows="5" name="txtMensagem" [(ngModel)]="mensagem" #txtMensagem="ngModel" required appAutoFocus></textarea>

      <div *ngIf="txtMensagem.invalid && (txtMensagem.dirty || txtMensagem.touched || f.submitted)" class="invalid-feedback">
        <div *ngIf="txtMensagem.errors.required">
          Informe uma mensagem que será enviada ao cliente.
        </div>
      </div>
    </div>

    <div class="alert alert-success alert-dismissible fade show mt-2" *ngIf="mensagemSucesso" role="alert">
      <a href="/imprimir/troca/{{codigoTroca}}" style="color: inherit;" target="_blank">
        <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}} <i class="fa fa-print fa-lg ml-2"></i>
      </a>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="alert alert-danger alert-dismissible fade show mt-2" *ngIf="mensagemErro" role="alert">
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemErro()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="enviando">Trocar Pontos</button>
    <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="fechar()">Fechar</button>
  </div>
</form>
  </div>
</div>
