<kendo-dialog title="Atenção!" *ngIf="abraDialogo" (close)="close('nao')" [minWidth]="250" [width]="450">
  <div *ngIf="contatoSelecionado && !excluindoContato">
    <p style="margin: 30px; text-align: center;">Esta operação é irreversível. Tem certeza que deseja excluir o contato <strong>{{contatoSelecionado.nome}}?</strong></p>
  </div>
  <div *ngIf="excluindoContato">
    <p style="margin: 30px; text-align: center;">Excluindo <strong>{{contatoSelecionado.nome}}...</strong></p>
    <div class="k-i-loading ml-1 mr-1" style="font-size: 20px; padding-bottom: 20px  " ></div>
  </div>
  <div *ngIf="removeu">
    <p style="margin: 30px; text-align: center;">O contato foi removido com sucesso.</p>
  </div>


  <kendo-dialog-actions>
    <button kendoButton *ngIf="contatoSelecionado" (click)="close('sim')" >Remover</button>
    <button kendoButton *ngIf="contatoSelecionado" (click)="close('nao')" [primary]="true" >Cancelar</button>
    <button kendoButton *ngIf="!contatoSelecionado" (click)="removeuContato()" [primary]="true">Fechar</button>
  </kendo-dialog-actions>
</kendo-dialog>
