import {Component, Input, OnInit} from '@angular/core';
import {EstoqueService} from "../../services/estoque.service";
import {UnidadeMedidaUtils} from "../UnidadeMedidaUtils";

@Component({
  selector: 'app-tela-historico-movimentacoes',
  templateUrl: './tela-historico-movimentacoes.component.html',
  styleUrls: ['./tela-historico-movimentacoes.component.scss']
})
export class TelaHistoricoMovimentacoesComponent implements OnInit {
  @Input() insumo: any = {}
  @Input() produto: any = {}
  @Input() exibirInsumo = false;
  carregando: boolean;
  movimentacoes = [];
  paginacao = {
    size: 5,
    skip: 0,
    total: 0,
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: false,
    previousNext: true
  };
  gridData: any  = {}
  filtro: any  = { inicio:  null, fim:  null}
  constructor(private estoqueService: EstoqueService) { }

  ngOnInit(): void {
    setTimeout(async () => {
      await this.carregueMovimentacoes();
    }, 0)
  }

  async carregueMovimentacoes(inicio = 0, termo: string = null, grupo: any = null){
    this.carregando = true;
    let resposta: any;
    if(this.insumo.id){
      resposta = await this.estoqueService.listeMovimentacoesInsumo(this.insumo, inicio, this.paginacao.size);
    } else if (this.produto.id) {
      resposta = await this.estoqueService.listeMovimentacoesProduto(this.produto, inicio, this.paginacao.size);
    } else {
      this.paginacao.size = 20;
      resposta =  await this.estoqueService.listeMovimentacoes(inicio,  this.paginacao.size, this.filtro.inicio, this.filtro.fim ,
        termo, grupo)
    }

    if(resposta){
      this.gridData = {
        data: resposta.movimentacoes,  // Dados da página atual
        total: resposta.total  // Total de itens
      };
    }

    this.carregando = false;
  }

  public mudouPaginacao(event: any): void {
    this.paginacao.skip = event.skip;
    this.paginacao.size = event.take;
    setTimeout(async () => {
      await this.carregueMovimentacoes(  this.paginacao.skip);
    }, 0)

  }

  obtenhaDescricaoUnidade( movimentacao: any){
    return UnidadeMedidaUtils.obtenhaDescricaoUnidade(movimentacao)
  }

}
