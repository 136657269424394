<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    <img src="../../../../assets/fidelidade/icones/trocar-selo.svg" style="height: 32px;"/> Cadastro de Brinde
  </h4>

</kendo-dialog-titlebar>
<div #pnl class="modal-body">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit(frm)">
    <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible mt-2">
      <button type="button" class="close" (click)="fecheMensagemSucesso()">&times;</button>
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
    </div>

    <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible mt-2">
      <button type="button" class="close" (click)="fecheMensagemErro()">&times;</button>
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
    </div>
    <div class="row">
      <div class="col-8">
        <div class="form-group mb-3">
          <label for="nome">Nome do Brinde</label>
          <input type="text" class="form-control" autocomplete="off"
                 id="nome" name="nome" [(ngModel)]="brinde.nome" #nome="ngModel"
                 placeholder="Nome do Brinde" value="" required appAutoFocus [autoFocus]="true">
          <div class="invalid-feedback">
            <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
          </div>
        </div>
      </div>
      <div class="col-4">
        <label for="plano">Artigo</label>

        <kendo-dropdownlist id="artigo" name="artigo" [(ngModel)]="brinde.artigo" [data]="artigos" [filterable]="false"
                            placeholder="Selecione um artigo" class="form-control"
                            required>
        </kendo-dropdownlist>
        <div class="invalid-feedback" *ngIf="!brinde.artigo">
          Artigo é obrigatório
        </div>
      </div>
    </div>

    <div class="form-group mb-12">
      <label for="plano">Plano</label>

      <kendo-dropdownlist id="plano" name="tipoDeAcumulo" [(ngModel)]="brinde.plano" [data]="planos" [filterable]="true"
                          placeholder="Selecione um Plano" class="form-control" [textField]="'nome'"
                          required>
      </kendo-dropdownlist>
      <div class="invalid-feedback" *ngIf="!brinde.plano">
        Plano é obrigatório
      </div>
    </div>
    <div class="form-group mb-3">
      <label for="valorEmPontos">Valor mínimo em {{brinde.plano  ?  brinde.plano.tipoDeAcumulo : 'Pontos'}} necessário para o resgate</label>
      <input type="text"  id="valorEmPontos" name="valorEmPontos"  class="form-control" autocomplete="off"
             [(ngModel)]="brinde.valorEmPontos"       placeholder="Valor em pontos"   required    >
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Valor é obrigatório</p>
      </div>
    </div>



    <app-upload-imagem [objeto]="brinde" [maxsize]="800"></app-upload-imagem>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando"  >
        <i class="k-icon k-i-loading" *ngIf="salvando"></i>
        Salvar</button>
      <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="this.fecheModal()">Fechar</button>
    </div>
  </form>
</div>
