<kendo-grid [pageSize]="paginacao.size"
            [skip]="paginacao.skip"
            [style]=""
            [kendoGridBinding]="brindes"
            [loading]="loading" style="min-height: 300px;"
            [scrollable]="'none'"
            [pageable]="{
              buttonCount: paginacao.buttonCount,
              info: paginacao.info,
              type: 'numeric',
              pageSizes: paginacao.pageSizes,
              previousNext: paginacao.previousNext
            }"
            (edit)="editarBrinde($event)"
            (remove)="abraDialogRemoverBrinde($event)"
            (pageChange)="mudouPaginacao($event)">
  <ng-template kendoGridToolbarTemplate [position]="'top'">
    <button class="btn btn-blue btn-sm" (click)="exibaModalBrinde()">
      <i class="fe-plus"></i>
      Novo Brinde
    </button>
  </ng-template>
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Sem brindes"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column title="ID" class="coluna_menor"  [width]="70">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>{{dataItem.id}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Imagem" class="coluna_menor" [width]="120">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <img [src]="'/images/empresa/'+dataItem.linkImagem" style="width: 80px;">
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Nome" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span><b>{{dataItem.nome}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Pontos" class="coluna_menor" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span class="text-success"><b>{{dataItem.valorEmPontos}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Plano" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>{{dataItem.plano.nome}}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
      <button kendoGridEditCommand [primary]="true" (selectedChange)="editarBrinde(dataItem)">Editar</button>
      <button kendoGridRemoveCommand><i class="fa fa-trash"></i></button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
<kendo-dialog title="Atenção!" *ngIf="abraDialogo" (close)="fechar()" [minWidth]="250" [width]="450">
  <div *ngIf="brindeSelecionado && !excluindoBrinde">
    <p style="margin: 30px; text-align: center;">Esta operação é irreversível. Tem certeza que deseja excluir o brinde "<strong>{{brindeSelecionado.nome}}?</strong>"</p>
  </div>
  <div *ngIf="excluindoBrinde">
    <p style="margin: 30px; text-align: center;">Excluindo o brinde "<strong>{{brindeSelecionado.nome}}"...</strong></p>
    <div class="k-i-loading ml-1 mr-1" style="font-size: 20px; padding-bottom: 20px  " ></div>
  </div>
  <div *ngIf="mensagemRemocao">
    <p style="margin: 30px; text-align: center;">{{mensagemRemocao}}</p>
  </div>

  <div class="modal-footer" *ngIf="!excluindoBrinde">
    <button type="button" *ngIf="brindeSelecionado" (click)="removaBrinde()" class="btn btn-primary waves-effect waves-light" >Remover</button>
    <button type="button" *ngIf="brindeSelecionado" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
    <button type="button" *ngIf="!brindeSelecionado" (click)="fechar()" class="btn btn-primary waves-effect waves-light" >Fechar</button>
  </div>
</kendo-dialog>

