<ng-container *ngIf="usuario != null && usuario.empresa != null">
<!-- Begin page -->
<div id="wrapper">
  <!-- Topbar Start -->
  <div class="navbar-custom" >
    <ul class="list-unstyled topnav-menu float-right mb-0">

      <li class="nav-item fechar_loja" *ngIf="empresa">
        <div class="nav-link">
          <button href="#" target="_blank" class="btn btn-primary mr-2" (click)="recarregarAbas()" title="Recarrega todas as abas abertas dessa empresa. Inclusive em outros computadores"
                  kendoTooltip>
            <i class="fas fa-sync-alt"></i></button>
          <button href="#" target="_blank" class="btn btn-danger" (click)="fecharAgora()"
                  [hidden]="!empresa.estaAberta || empresa.fechadoTemporariamente">
            Fechar Agora</button>
          <button href="#" target="_blank" class="btn btn-primary" (click)="reabrir()"
                  *ngIf="empresa.fechadoTemporariamente" [disabled]="empresa.reabrindo">
            Abrir Novamente</button>

        </div>
      </li>


      <li class="nav-item fechar_loja" *ngIf="empresa">
        <div class="nav-link text-center bloco_status" href="#"
             [ngClass]="{'maior': servico.estaAberta || servico.estaRecebendoPedidos || empresa?.integracoesIfood?.length}" >
          <div class="row">
            <div class="col-auto" style="padding-right: 0px;">

              <kendo-dropdownbutton *ngIf=" empresa.horariosDoServico.length > 1"
                                    [data]="empresa.horariosDoServico"  [(ngModel)]="servico" [textField]="'servico'"
                                    (itemClick)="mudouServico( $event)" style="    margin-top: -5px;">
                {{ servico.servico }}:  {{(servico.estaAberta && servico.estaRecebendoPedidos) ? 'Loja Aberta' : 'Loja Fechada' }}
                <i class="fas fa-chevron-down  cpointer ml-1"    ></i>
              </kendo-dropdownbutton>

              <p class="mb-0 text-white font-15"  [hidden]="empresa.horariosDoServico.length > 1" >
                <span *ngIf="servico.estaAberta && servico.estaRecebendoPedidos">
                  Loja Aberta
                </span>
                <span *ngIf="!servico.estaAberta || !servico.estaRecebendoPedidos">
                  Loja Fechada
                </span>

              </p>
              <p class="mb-0 mt-1 font-13" *ngIf="!empresa.mensagemPausaProgramada">{{servico.descricaoHorario}}</p>
              <p class="mb-0 mt-1 font-13" *ngIf="empresa.mensagemPausaProgramada">Pausa Programada</p>
            </div>
            <div class="col-auto mt-2" style="padding-left: 5px;">
              <i class="fas fa-ban" style="font-size: 21px;"
                 *ngIf="!servico.estaAberta || !servico.estaRecebendoPedidos"></i>
              <i class="fas fa-check-circle text-white" style="font-size: 21px;"
                 *ngIf="servico.estaAberta && servico.estaRecebendoPedidos"></i>
            </div>

            <ng-container  *ngIf="empresa.integracoesIfood?.length"  >

              <div class="col-auto"   *ngFor="let ifoodLoja of monitoradorPedidos.lojasIfood; let i = index">
                <div class=" mt-2 status_ifood" kendoPopoverAnchor  [popover]="popoverContent"  showOn="hover">
                    <span class="badge cpointer"  [ngClass]="{'badge-success':ifoodLoja.aberta, 'badge-danger':!ifoodLoja.aberta }"
                          (click)="monitoradorPedidos.autalizeStatusIfood()"    >
                        Ifood {{ifoodLoja.aberta  ? 'On': (ifoodLoja.desativado ? 'Desativado' : 'Off')}} </span>
                </div>

                <kendo-popover #popoverContent [width]="300" position="bottom">
                  <ng-template kendoPopoverBodyTemplate>
                    <div style="min-width: 270px;  ">
                      <h4>{{ifoodLoja.loja}}</h4>
                      <p>{{ifoodLoja.mensagem}}</p>

                      <small class="text-muted font-11">
                        Esta informação pode levar até 1 minuto  para atualizar depois de ser alterada.
                      </small>
                    </div>
                  </ng-template>
                </kendo-popover>
              </div>
            </ng-container>

          </div>
        </div>
      </li>

      <li class="nav-item fechar_loja" *ngIf="empresa && possuiModuloFidelidade && possuiModuloPedidos" style="display: none;">
        <div class="nav-link text-center bloco_status" href="#">
          <app-saldo-mensagens></app-saldo-mensagens>
        </div>
      </li>

      <li class="dropdown notification-list topbar-dropdown">
        <a class="nav-link dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
           href="#" role="button" aria-haspopup="false" aria-expanded="false" *ngIf="!modoGarcom">
          <i class="fe-bell noti-icon" *ngIf="monitoradorPedidos.tocarAlerta"></i>
          <i class="fe-bell-off noti-icon" *ngIf="!monitoradorPedidos.tocarAlerta"></i>
          <span class="badge  rounded-circle noti-icon-badge"
             [ngClass]="{'badge-danger': monitoradorPedidos.totalPedidosMonitorados() >  0 ,
                          'badge-light': !monitoradorPedidos.totalPedidosMonitorados() }">
            {{monitoradorPedidos.totalPedidosMonitorados()}}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-right dropdown-lg">

          <div class="dropdown-item noti-title">
            <h5 class="m-0">
                Alerta de novos pedidos

              <span (click)="clicouAlerta($event)" class="ml-1">

                <kendo-switch [(ngModel)]="monitoradorPedidos.tocarAlerta" (ngModelChange)="alterouStatusAlerta($event)"
                              [onLabel]="'Sim'"
                              [offLabel]="'Não'"></kendo-switch>
              </span>


            </h5>
          </div>
          <div class="slimscroll noti-scroll">

            <a *ngIf="!monitoradorPedidos.pedidos.length" class="dropdown-item notify-item text-muted text-center " >
                Nenhum pedido recente
            </a>

            <!-- item-->
            <a  (click)="monitoradorPedidos.verPedido(pedido)" class="dropdown-item notify-item cpointer "
                *ngFor="let pedido of monitoradorPedidos.pedidos" style="position: relative">
              <div class="notify-icon"  >
                  <small class="text-blue d-block">#{{pedido.codigo}}</small>

              </div>
              <p class="notify-details">
                {{pedido.cliente.nome}}

              </p>
              <p class="text-muted mb-0 user-msg">
                <small>{{pedido.cliente.telefone | tel}}</small>
              </p>

              <p class=" mb-0 user-msg valor">
                <small>{{pedido.total | currency: "BRL"}}</small>
              </p>

            </a>
          </div>
          <div *ngIf="!isMobile && !monitoradorPedidos.inIframe()" style="padding: 15px 20px">
            <h5 class="m-0">
              Desativar impressão neste computador?
                          <span (click)="clicouNaoImprimir($event)"   class="ml-1">

                <kendo-switch [(ngModel)]="constantsService.naoImprimir" (ngModelChange)="alterouNaoImprimir($event)"
                              [onLabel]="'Sim'"
                              [offLabel]="'Não'"></kendo-switch>
              </span>
            </h5>

          </div>
          <div *ngIf="!isMobile && !monitoradorPedidos.inIframe()" style="padding: 15px 20px">
            <h5 class="m-0">
              Desativar alerta de pedido de garçom?
              <span (click)="clicouNaoImprimir($event)"   class="ml-1">

                <kendo-switch [(ngModel)]="constantsService.naoTocarGarcom" (ngModelChange)="alterouNaoTocarGarcom($event)"
                              [onLabel]="'Sim'"
                              [offLabel]="'Não'"></kendo-switch>
              </span>
            </h5>

          </div>
        </div>
      </li>
      <li class="dropdown notification-list">
        <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
          <img style="width:32px; height: 32px;" src="/assets/fidelidade/icones/user-icon.png" alt="user-image">
          <span class="pro-user-name ml-1">
                                {{usuario.nome}} <i class="mdi mdi-chevron-down"></i>
                            </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
          <!-- item-->
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Bem vindo!</h6>
          </div>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <i class="fe-user"></i>
            <span>Minha Conta</span>
          </a>
          <div class="dropdown-divider"></div>

          <!-- item-->
          <a href="javascript:void(0);"  (click)="logout()" class="dropdown-item notify-item">
            <i class="fe-log-out"></i>
            <span>Sair</span>
          </a>

        </div>
      </li>

    </ul>

    <!-- LOGO -->
    <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
      <li>
        <button (click)="clicouHome()" class="button-menu-mobile waves-effect waves-light">
          <i class="fe-menu"></i>
        </button>
        <a class="navbar-brand" href="#" (click)="naoFacaNada()">
          <i class="fab fa-whatsapp" [style.color]="statusWhatsapp.online ? '#1bff00' : '#fd0f2d'"
             [title]="statusWhatsapp.online ? 'Envio Whatsapp está ativo.' : 'Envio Whatsapp não está ativo.'"></i>
          <ng-container *ngIf="possuiModuloChatbot">
          <i [style.color]="miaLigada ? '#1bff00' : '#fd0f2d'"
             [title]="(configuracoesMia) ? 'Mia ' + statusBot : 'Mia não está ativa'"
             class="fas fa-robot ml-1"></i>
          <i style="color: #1bff00;position: absolute;font-size: 14px;left: 42px;" *ngIf="modoTeste"
              class="fas fa-rocket" title="Mia está em modo teste"></i>
          </ng-container>
            {{empresa?.nome}}
        </a>
      </li>
      <li class="d-none">
        <form class="app-search">
          <div class="app-search-box">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Buscar...">
              <div class="input-group-append">
                <button class="btn" type="submit">
                  <i class="fe-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </li>
    </ul>
    <div class="logo-box">
      <a [routerLink]="'/admin/index'" class="logo text-center">
                        <span class="logo-lg">
                            <img src="/assets/fidelidade/promokit-horizontal-branco.png" alt="" height="36">
                          <!-- <span class="logo-lg-text-light">UBold</span> -->
                        </span>
        <span class="logo-sm">
                            <!-- <span class="logo-sm-text-dark">U</span> -->
                            <img src="/assets/fidelidade/zapkit-logo.png" alt="" height="36">
                        </span>
      </a>
    </div>

    <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
      <!--
      <li>
        <button class="button-menu-mobile waves-effect waves-light">
          <i class="fe-menu"></i>
        </button>
      </li>
      -->

    </ul>
  </div>
  <!-- end Topbar -->

  <!-- ========== Left Sidebar Start ========== -->
  <app-menu-fidelidade></app-menu-fidelidade>


  <!-- Left Sidebar End -->
  <!-- ============================================================== -->
  <!-- Start Page Content here -->
  <!-- ============================================================== -->
  <div class="content-page">
    <div class="content">

      <!-- Start Content-->
      <div class="container-fluid">

        <!-- start page title -->
        <div class="row" #appendTo>
          <div class="col-12 conteudo-componente">
            <div class="page-title-box">

              <div id="alertaNovo" class="modal fade" tabindex="-1" role="dialog"   aria-modal="true"  data-keyboard="false" >
                <div class="modal-dialog ">
                  <div class="modal-content">
                    <div class="modal-body p-4">
                      <app-alerta-pedidos [monitoradorPedidos]="monitoradorPedidos"></app-alerta-pedidos>

                    </div>
                  </div><!-- /.modal-content -->
                </div><!-- /.modal-dialog -->
              </div>

              <ng-template #template>
                <i class="fe-printer fa-lg mr-1"></i>
                Conectando com a impressora... <i class="k-icon k-i-loading"></i>
              </ng-template>



              <ng-template #templateErro>
                <i class="fe-printer fa-lg mr-1"></i>

                <span *ngIf="errosImpressao.length === 1">
                   Falha na impresão de um pedido:
                </span>
                <span *ngIf="errosImpressao.length > 1">
                     Falha na impressão de {{errosImpressao.length}}   pedidos:
                </span>


                <p *ngFor="let erro of errosImpressao" class="mt-1 mb-0">
                 <i class="k-icon k-i-error"></i> {{erro}}
                </p>

              </ng-template>

              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
        <!-- end page title -->

      </div> <!-- container -->

    </div> <!-- content -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- end Footer -->

  </div>

  <!-- ============================================================== -->
  <!-- End Page content -->
  <!-- ============================================================== -->

</div>
<!-- END wrapper -->

<!-- Right Sidebar -->
<div class="right-bar">
  <div class="rightbar-title">
    <a href="javascript:void(0);" class="right-bar-toggle float-right">
      <i class="dripicons-cross noti-icon"></i>
    </a>
    <h5 class="m-0 text-white">Settings</h5>
  </div>
  <div class="slimscroll-menu">
    <!-- User box -->
    <div class="user-box">
      <div class="user-img">
        <img src="/assets/template/images/users/user-1.jpg" alt="user-img" title="Mat Helme" class="rounded-circle img-fluid">
        <a href="javascript:void(0);" class="user-edit"><i class="mdi mdi-pencil"></i></a>
      </div>

      <h5><a href="javascript: void(0);">Geneva Kennedy</a> </h5>
      <p class="text-muted mb-0"><small>Admin Head</small></p>
    </div>

    <!-- Settings -->
    <hr class="mt-0" />
    <h5 class="pl-3">Basic Settings</h5>
    <hr class="mb-0" />

    <div class="p-3">
      <div class="checkbox checkbox-primary mb-2">
        <input id="Rcheckbox1" type="checkbox" checked>
        <label for="Rcheckbox1">
          Notifications
        </label>
      </div>
      <div class="checkbox checkbox-primary mb-2">
        <input id="Rcheckbox2" type="checkbox" checked>
        <label for="Rcheckbox2">
          API Access
        </label>
      </div>
      <div class="checkbox checkbox-primary mb-2">
        <input id="Rcheckbox3" type="checkbox">
        <label for="Rcheckbox3">
          Auto Updates
        </label>
      </div>
      <div class="checkbox checkbox-primary mb-2">
        <input id="Rcheckbox4" type="checkbox" checked>
        <label for="Rcheckbox4">
          Online Status
        </label>
      </div>
      <div class="checkbox checkbox-primary mb-0">
        <input id="Rcheckbox5" type="checkbox" checked>
        <label for="Rcheckbox5">
          Auto Payout
        </label>
      </div>
    </div>

    <!-- Timeline -->
    <hr class="mt-0" />
    <h5 class="pl-3 pr-3">Messages <span class="float-right badge badge-pill badge-danger">25</span></h5>
    <hr class="mb-0" />
    <div class="p-3">
      <div class="inbox-widget">
        <div class="inbox-item">
          <div class="inbox-item-img"><img src="/assets/template/images/users/user-2.jpg" class="rounded-circle" alt=""></div>
          <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">Tomaslau</a></p>
          <p class="inbox-item-text">I've finished it! See you so...</p>
        </div>
        <div class="inbox-item">
          <div class="inbox-item-img"><img src="/assets/template/images/users/user-3.jpg" class="rounded-circle" alt=""></div>
          <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">Stillnotdavid</a></p>
          <p class="inbox-item-text">This theme is awesome!</p>
        </div>
        <div class="inbox-item">
          <div class="inbox-item-img"><img src="/assets/template/images/users/user-4.jpg" class="rounded-circle" alt=""></div>
          <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">Kurafire</a></p>
          <p class="inbox-item-text">Nice to meet you</p>
        </div>

        <div class="inbox-item">
          <div class="inbox-item-img"><img src="/assets/template/images/users/user-5.jpg" class="rounded-circle" alt=""></div>
          <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">Shahedk</a></p>
          <p class="inbox-item-text">Hey! there I'm available...</p>
        </div>
        <div class="inbox-item">
          <div class="inbox-item-img"><img src="/assets/template/images/users/user-6.jpg" class="rounded-circle" alt=""></div>
          <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">Adhamdannaway</a></p>
          <p class="inbox-item-text">This theme is awesome!</p>
        </div>
      </div> <!-- end inbox-widget -->
    </div> <!-- end .p-3-->

  </div> <!-- end slimscroll-menu-->
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>

<div class="bloqueio" *ngIf="bloqueado"> </div>

<audio id="beep"  >   <source src="/assets/audio/beet-pedido.mp3" /> </audio>

  <kendo-window class="printmanager" title="Pedidos enviados para impressão"  *ngIf="impressaoService.filaImpressao.length"
                [width]="500"  [height]="300"  [draggable] = false  (close)="impressaoService.limpeFilaImpressao()" >
    <div  >
      <kendo-grid  #gridImpressoes="kendoGrid" style="    margin-left: -16px;  margin-right: -16px; top: -16px;"
                [scrollable]="'scrollable'" [height]="300"   [data]="impressaoService.filaImpressao" >
        <kendo-grid-messages
          pagerPage="Página"
          pagerOf="de"
          pagerItems="itens"
          noRecords="Nenhuma impressão na fila"
          loading="Carregando"
          pagerItemsPerPage="ítems por página"
        >
        </kendo-grid-messages>
        <kendo-grid-column title="Pedido" field="codigo" [width]="80" >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
           <span class="text-primary">  <b>{{dataItem.codigo}}</b></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Impressora"   [width]="120" >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="text-muted"> {{dataItem.impressora?.nome}}</span>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Status"   >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

            <span *ngIf="dataItem.impresso">
               <i class="k-icon k-i-check-circle text-success"  ></i>impresso
           </span>

            <span *ngIf="dataItem.imprimindo">
              <i class="k-icon k-i-loading" ></i>
               {{dataItem.statusImpressao}} imprimindo...
            </span>

            <span *ngIf="dataItem.aguardando">
                <i class="fa fa-clock" ></i> na fila
            </span>

            <span *ngIf="dataItem.erroImpressao" class="text-danger">
              {{dataItem.erroImpressao}}
            </span>

          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Açoes"   [width]="100" >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
             <button class="btn btn-xs btn-danger" *ngIf="dataItem.aguardando">
                 <i class="k-icon k-i-trash"></i>
             </button>

            <button class="btn btn-xs btn-outline-blue" *ngIf="dataItem.erroImpressao || dataItem.impresso " [disabled]="impressaoService.imprimindoEmLote"
                  (click)="impressaoService.reimprimaPedidoNaFila(dataItem)"  kendoTooltip title="reimprimir">
              <i class="k-icon k-i-printer"></i>
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>

    </div>
  </kendo-window>

<div kendoWindowContainer></div>
</ng-container>
