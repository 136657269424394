<h4 class="page-title">    <i class="fas fa-medal"></i>
  Fidelidade Relatorios</h4>

<div class="card">
  <div class="card-box">
    <kendo-tabstrip #tabstrip  [scrollable]="{ scrollButtons: 'visible' }"
                    [style.width.%]="100"  (tabSelect)="selecionouTab($event)">
      <kendo-tabstrip-tab [title]="'Pontuações'" [selected]="true">
        <ng-template kendoTabContent>
          <h5 class=" mb-2 page-title">Pontuações Registradas</h5>

          <kendo-panelbar  >
            <kendo-panelbar-item [title]="'Filtros'" [expanded]="true"  >
              <ng-template kendoPanelBarContent>
                <div class="p-3">
                  <div class="row filtro">
                    <div class="col">
                      <label class="mb-1">Contato </label>
                      <br>
                      <kendo-autocomplete   name="contatoFiltroPts"    class="form-control"
                                            [data]="contatosBusca"
                                            [filterable]="true" [highlightFirst]="true"
                                            valueField="descricao"
                                            [(ngModel)]="contatoFiltro"
                                            (filterChange)="alterouCampoBusca($event)"
                                            (ngModelChange)="carreguePontuacoesHistorica()"

                                            [placeholder]="'Nome ou telefone com DDD'"    [loading]="buscandoContatos">


                        <ng-template kendoAutoCompleteItemTemplate let-contato>
                          <div class="row">
                            <div class="col">
                              <div><b> {{contato.nome}} </b><br>
                                <span>{{contato.telefone | tel: contato.codigoPais}}</span>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template kendoAutoCompleteNoDataTemplate>
                          <h5 class="mt-4" *ngIf="!buscandoContatos">Contato não encontrado. </h5>
                          <span *ngIf="buscandoContatos"> <i class="k-i-loading k-icon ml-2"></i> Buscando</span>
                        </ng-template>
                      </kendo-autocomplete>

                    </div>

                    <div class="col">
                      <label class="mb-1">Período  </label>
                      <br>
                      <kendo-daterange   >
                        <label>
                          <span class="label"></span>
                          <kendo-dateinput kendoDateRangeStartInput [(ngModel)]="filtro.inicio"
                                           (ngModelChange)="carreguePontuacoesHistorica()"></kendo-dateinput>
                        </label>
                        <label>
                          <span class="label ml-2 mr-2">Até </span>
                          <kendo-dateinput kendoDateRangeEndInput [(ngModel)]="filtro.fim"
                                           (ngModelChange)="carreguePontuacoesHistorica()" ></kendo-dateinput>
                        </label>
                      </kendo-daterange>
                    </div>

                    <div class="col">
                      <label class="mb-1">Plano Fidelidade</label>
                      <kendo-combobox   name="plano" [(ngModel)]="filtro.plano" [data]="planos" [filterable]="true"
                                        [allowCustom]="false" placeholder="Filtre por um Plano" class="form-control"
                                        [textField]="'nome'" [valueField]="'id'"
                                        (ngModelChange)="alterouPlano()">
                      </kendo-combobox>
                    </div>
                  </div>
                </div>

              </ng-template>
            </kendo-panelbar-item>
          </kendo-panelbar>

          <div class="  mt-2">
            <kendo-grid [data]="dataGrid"
                        [pageSize]="gridState.take"
                        [skip]="gridState.skip"
                        [sort]="gridState.sort"
                        [style]=""
                        [loading]="carregando" style="min-height: 300px;"
                        [pageable]="true"
                        (pageChange)="onPageChange($event)"
                        (dataStateChange)="onStateChange($event)"
                        footerStyle="font-size: 11px;">
              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="Nenhuma pontuação no período"
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>
              <ng-template kendoGridToolbarTemplate>
                <div class="float-right">
                  <button type="button" class="btn btn-outline-secondary btn-sm   "
                          kendoGridExcelCommand icon="file-excel">Exportar</button>


                </div>
              </ng-template>
              <kendo-grid-column title=""   [width]="50" >
                <ng-template kendoGridCellTemplate   let-rowIndex="rowIndex">
                  <span class="text-muted ">{{rowIndex + 1}}</span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Contato"   field="cartao.contato.nome">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a class="text-blue" (click)="verContato(dataItem.cartao.contato)" href="" ><b>{{dataItem.cartao.contato.nome}}</b></a>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Programa"   field="cartao.plano.nome" >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span ><b>{{dataItem.cartao.plano.nome}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Pontuaçao"    [width]="120" field="pontosTexto" >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span class="text-success" ><b>{{dataItem.pontosTexto}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Operador"    field="operador.nome"  >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span class="text-blue" > <b>{{dataItem.operador ? dataItem.operador.nome : "-"}}</b> </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Horario" field="horario"    [width]="180"  >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span class="text-muted" > {{dataItem.horario | date: 'dd/MM/yyyy HH:mm'}} </span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('pontuacoes-')"></kendo-grid-excel>
            </kendo-grid>

          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Resgates'"  >
        <ng-template kendoTabContent>
          <h5 class="  mb-2 page-title">Resgates Realizados</h5>

          <kendo-panelbar  >
            <kendo-panelbar-item [title]="'Filtros'" [expanded]="true"  >
              <ng-template kendoPanelBarContent>
                <div class="p-3">
                  <div class="row filtro">
                    <div class="col" style="max-width: 280px !important;">
                      <label class="mb-1">Contato </label>
                      <br>
                      <kendo-autocomplete   name="contatoFiltroTrocas"    class="form-control"
                                            [data]="contatosBusca" [(ngModel)]="contatoFiltroTroca"
                                            [filterable]="true" [highlightFirst]="true"               valueField="descricao"
                                            (filterChange)="alterouCampoBusca($event)"  (ngModelChange)="carregueTrocasHistorico()"
                                            [placeholder]="'Nome ou telefone com DDD'"    [loading]="buscandoContatos">

                        <ng-template kendoAutoCompleteItemTemplate let-contato>
                          <div class="row">
                            <div class="col">
                              <div><b> {{contato.nome}} </b><br>
                                <span>{{contato.telefone | tel: contato.codigoPais}}</span>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template kendoAutoCompleteNoDataTemplate>
                          <h5 class="mt-4" *ngIf="!buscandoContatos">Contato não encontrado. </h5>
                          <span *ngIf="buscandoContatos"> <i class="k-i-loading k-icon ml-2"></i> Buscando</span>
                        </ng-template>
                      </kendo-autocomplete>

                    </div>

                    <div class="col">
                      <label class="mb-1">Período  </label>
                      <br>
                      <kendo-daterange   >
                        <label>
                          <span class="label"></span>
                          <kendo-dateinput kendoDateRangeStartInput [(ngModel)]="filtro.inicio"
                                           (ngModelChange)="carregueTrocasHistorico()"></kendo-dateinput>
                        </label>
                        <label>
                          <span class="label ml-2 mr-2">Até </span>
                          <kendo-dateinput kendoDateRangeEndInput [(ngModel)]="filtro.fim"
                                           (ngModelChange)="carregueTrocasHistorico()" ></kendo-dateinput>
                        </label>
                      </kendo-daterange>
                    </div>

                    <div class="col" style="max-width: 240px !important;">
                      <label class="mb-1">Plano Fidelidade</label>
                      <kendo-combobox   name="plano" [(ngModel)]="filtro.plano" [data]="planos" [filterable]="true"
                                        [allowCustom]="false" placeholder="Filtre por um Plano" class="form-control"
                                        [textField]="'nome'" [valueField]="'id'"
                                        (ngModelChange)="alterouPlano()">
                      </kendo-combobox>
                    </div>

                    <div class="col">
                      <label class="mb-1">Brindes</label>
                      <kendo-combobox   name="plano" [(ngModel)]="filtro.brinde" [data]="brindes"
                                        [allowCustom]="false" placeholder="Filtre por um Brinde" class="form-control" [clearButton]="true"
                                        [textField]="'nome'" [valueField]="'id'"
                                        (ngModelChange)="carregueTrocasHistorico()">
                      </kendo-combobox>
                    </div>
                  </div>
                </div>

              </ng-template>
            </kendo-panelbar-item>
          </kendo-panelbar>

          <div class="  mt-2">
            <kendo-grid [data]="dataGridTrocas"
                        [pageSize]="gridStateTrocas.take"
                        [skip]="gridStateTrocas.skip"
                        [sort]="gridStateTrocas.sort"
                        [style]=""
                        [loading]="carregando" style="min-height: 300px;"
                        [pageable]="true"
                        (pageChange)="onPageChange($event)"
                        (dataStateChange)="onStateChange($event)"
                        footerStyle="font-size: 11px;">
              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="Nenhum resgate no período"
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>
              <ng-template kendoGridToolbarTemplate>
                <div class="float-right">
                  <button type="button" class="btn btn-outline-secondary btn-sm   "
                          kendoGridExcelCommand icon="file-excel">Exportar</button>


                </div>
              </ng-template>
              <kendo-grid-column title=""   [width]="50" >
                <ng-template kendoGridCellTemplate   let-rowIndex="rowIndex">
                  <span class="text-muted ">{{rowIndex + 1}}</span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Contato"  field="cartao.contato.nome"  >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a class="text-blue" (click)="verContato(dataItem.cartao.contato)" href="" ><b>{{dataItem.cartao.contato.nome}}</b></a>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Programa"   field="cartao.plano.nome"  >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span ><b>{{dataItem.cartao.plano.nome}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Brinde"   field="brinde.nome" >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span class="text-success" ><b>{{dataItem.brinde?.nome}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Valor"  field="pontosTexto"   [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span class="text-success" ><b>{{dataItem.pontosTexto}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Operador"  field="operador.nome"    [width]="150" >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span class="text-blue" > <b>{{dataItem.operador ? dataItem.operador.nome : "-"}}</b> </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Horario"   field="horario"    [width]="180">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span class="text-muted" > {{dataItem.horario | date: 'dd/MM/yyyy HH:mm'}} </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('resgates-')"></kendo-grid-excel>
            </kendo-grid>

          </div>
        </ng-template>

      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>

</div>







