
<kendo-grid
  [data]="gridDataResult"   [group]="groups"  [groupable]="true"
  [loading]="carregando" style="min-height: 300px;"

  footerStyle="font-size: 11px;"
  (edit)="editarEntregador($event.dataItem)"
  (remove)="abraDialogRemover($event)">
  <ng-template kendoGridToolbarTemplate [position]="'top'">
    <button class="btn btn-blue" (click)="criarEntregador()">
      <i class="fe-plus mr-1"></i>
      Criar entregador
    </button>
  </ng-template>
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Nenhum entregador cadastrado "
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column title="ID"    [width]="70">
    <ng-template kendoGridCellTemplate let-entregador  >
      <span class="text-blue"><b>{{entregador.id}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Nome">
    <ng-template kendoGridCellTemplate let-entregador >
      <span><b>{{entregador.nome}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Telefone">
    <ng-template kendoGridCellTemplate let-entregador >
      <span class="d-block">{{entregador.telefone | mask: '(99) 9-9999-9999' }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Email">
    <ng-template kendoGridCellTemplate let-entregador >
      <span><a *ngIf="entregador.email" [href]="'mailto:' + entregador.email">{{entregador.email}}</a>{{entregador.email ? "" : "-"}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-entregador>
      <button kendoGridEditCommand [primary]="true" (selectedChange)="editarEntregador(entregador)">
        <i class="fa fa-edit" kendoTooltip title="editar"></i>
      </button>
      <button kendoGridRemoveCommand kendoTooltip title="remover"><i class="fa fa-trash"></i></button>

      <!--
      <button class="btn btn-xs btn-danger ml-1" (click)="desativarCupom(cupom)"
              kendoTooltip title="Desativar Cupom" *ngIf="cupom.ativo" [disabled]="cupom.processando">
        <i class="far fa-pause-circle fa-lg">  </i>
      </button>
      <button class="btn btn-xs btn-primary ml-1" (click)="ativarCupom(cupom)"
              kendoTooltip title="Ativar cupom" *ngIf="!cupom.ativo" [disabled]="cupom.processando">
        <i class="far fa-play-circle fa-lg"  ></i>&nbsp;
      </button>
-->

    </ng-template>
  </kendo-grid-command-column>

  <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
</kendo-grid>


<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="canceleRemocao()" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Você tem certeza que deseja remover o entregador
    "<strong>{{entregadorApagar.nome}}</strong>"?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="confirmeRemocao()" [primary]="true">Sim</button>
    <button kendoButton (click)="canceleRemocao()">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
