<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    Cadastro de Atividade
  </h4>
</kendo-dialog-titlebar>
<div #pnl class="modal-body">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit()">
    <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible mt-2">
      <button type="button" class="close" (click)="fecheMensagemSucesso()">
        <span>&times;</span>
      </button>
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
    </div>

    <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible mt-2">
      <button type="button" class="close" (click)="fecheMensagemErro()">
        <span>&times;</span>
      </button>
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
    </div>

    <div class="form-group mb-3">
      <label for="nome">Nome da Atividade</label>
      <input type="text" class="form-control" autocomplete="off"
             id="nome" name="nome" [(ngModel)]="atividade.nome" #nome="ngModel"
             placeholder="Nome da Atividade" value="" required appAutoFocus [autoFocus]="true">
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
      </div>
    </div>

    <div class="form-group mb-3">
      <label for="valorEmPontos">Valor em R$</label>
      <kendo-numerictextbox  type="number" class="form-control" autocomplete="off" format="c2"
                             id="valorEmPontos" name="valorEmPontos" [(ngModel)]="atividade.valor" #valorEmPontos="ngModel"
                             placeholder="Valor"   required    >  </kendo-numerictextbox>
      <div class="invalid-feedback">
        <p *ngIf="valorEmPontos.errors?.required">Valor é obrigatório</p>
      </div>
    </div>


    <div class="form-group mb-12">
      <label for="plano">Plano</label>


      <kendo-dropdownlist id="plano" name="tipoDeAcumulo" [(ngModel)]="atividade.plano" [data]="planos" [filterable]="true"
                          placeholder="Selecione um Plano" class="form-control" [textField]="'nome'"
                          required="true">
      </kendo-dropdownlist>
      <div class="invalid-feedback" *ngIf="!atividade.plano">
        Plano é obrigatório
      </div>
    </div>

    <div class="form-group mb-3" *ngIf="informarPontosNaAtividade()">
      <label  >{{atividade.plano  ?  atividade.plano.tipoDeAcumulo : 'Pontos'}} ganhos</label>
      <input type="number" class="form-control" autocomplete="off"
             id="pontosGanhos" name="pontosGanhos" [(ngModel)]="atividade.pontosGanhos" #pontosGanhos="ngModel"
             placeholder="Pontos ganhos" value=""  [required]="informarPontosNaAtividade()"    >
      <div class="invalid-feedback">
        <p *ngIf="pontosGanhos.errors?.required">Pontos ganhos é obrigatório</p>
      </div>
    </div>

    <div class="form-group mb-3" *ngIf="informarCashback()">
      <label for="cashback">Cashback</label>
      <kendo-numerictextbox   id="cashback" name="cashback" class="form-control" autocomplete="off"  placeholder="Retorno do cashback"
                              [format]="'p'"  [step]="0.01"  [min]="0"  [max]="1"
                              [(ngModel)]="atividade.cashback" #cashback="ngModel"  [required]="informarCashback()"    >

      </kendo-numerictextbox>
      <div class="invalid-feedback">
        <p *ngIf="cashback.errors?.required">Cashback é obrigatório</p>
        <p *ngIf="cashback.errors?.maxError">Valor  máximo é {{cashback.errors?.maxError?.maxValue}} </p>
        <p *ngIf="cashback.errors?.minError">Valor  mínimo é {{cashback.errors?.minError?.maxValue}} </p>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary waves-effect waves-light"  [disabled]="salvando" >
        <i class="k-icon k-i-loading" *ngIf="salvando"></i>
        Salvar</button>
      <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="this.fecheModal()">
        Fechar</button>
    </div>
  </form>
</div>
