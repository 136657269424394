
<div class="modal-content">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel">
         <i class="fe-user"></i> Salvar Contato
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="this.fecheModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div  class="modal-body">
      <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible fade show mt-2" role="alert">
        <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" (click)="fecheMensagemSucesso()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mt-2" role="alert">
        <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <app-buscar-contato #buscarContatoComponent mensagemObrigatorio="Telefone é obrigatório"
                          [validarContato]="false" [mensagem]="'Informe o telefone ou nome do contato'"></app-buscar-contato>

      <div class="form-group mb-3">
        <label for="nome">Nome do Contato</label>
        <input type="text" class="form-control" id="nome" name="nome"
               [(ngModel)]="contato.nome" #nome="ngModel"   placeholder="Nome do Contato" value="" required>
        <div class="invalid-feedback" >
            <span *ngIf="!contato.nome">Nome é obrigatório</span>
        </div>
      </div>

      <div class="form-group mb-3" *ngIf="exibirEmail">
        <label for="nome">Email</label>
        <input type="email" class="form-control" autocomplete="off"  required
               id="email" name="email"  [(ngModel)]="contato.email" #email="ngModel"
               placeholder="Email do contato"  email  >

        <div class="invalid-feedback">
          <p *ngIf="email.errors" >Email inválido</p>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group mb-12">
            <label  >Data Nascimento</label>
            <kendo-datepicker [(ngModel)]="contato.dataNascimento" class="form-control" id="dataNascimento" name="dataNascimento">
            </kendo-datepicker>
          </div>
        </div>
        <div class="col">
          <div class="form-group mb-3">
            <label  >Sexo</label>
            <div class="mt-1 mb-1" style="width:250px;">
              <input type="radio" name="sexo" id="sexohi" class="k-radio right" value="Homem"  [(ngModel)]="contato.sexo" required kendoRadioButton/>
              <label class="k-radio-label mr-1" for="sexohi">Homem</label>
              <input type="radio" name="sexo" id="sexomi" class="k-radio right" value="Mulher"  [(ngModel)]="contato.sexo" required kendoRadioButton/>
              <label class="k-radio-label" for="sexomi">Mulher</label>
              <div class="invalid-feedback"  >
                <span *ngIf="!contato.sexo">    Sexo é obrigatório</span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary waves-effect waves-light"  >Salvar</button>
      <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="this.fecheModal()">Fechar</button>
    </div>
  </form>
</div><!-- /.modal-content -->
