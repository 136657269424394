<div class="row">
  <div class="col botao-abrir-caixa" *ngIf="!caixa && !online">
    <button class="btn btn-blue
       waves-effect waves-light float-left  btn-novo " (click)="abrirCaixa()">Abrir caixa</button>
  </div>
  <div class="col botao-abrir-caixa" *ngIf="caixa">
    <button class="btn btn-danger
       waves-effect waves-light float-left  btn-novo " (click)="fecharCaixa()">Fechar caixa</button>
  </div>

</div>
<div class="container-fechado" *ngIf="!caixa">
  <div *ngIf="!carregando" class="mensagem">
    <p>Caixa ainda não está aberto.</p>
  </div>
  <div *ngIf="carregando" class="mensagem">
    <i class="k-icon k-i-loading mr-1" style="font-size: xxx-large"></i>
    <p class="mt-2">
      Carregando caixa...
    </p>

  </div>

</div>
<div class="container-aberto" *ngIf="caixa">


  <div class="grid-resumo-container">

    <div *ngIf="caixa" class="info-container">
      <app-grid-transacoes-caixa [resumo]="resumo" [caixa]="caixa" [editavel]="true"></app-grid-transacoes-caixa>
      <!--<button class="btn btn-primary" (click)="adicionarTransacao()">Adicionar Saldo</button>-->
      <button class="btn btn-danger" *ngIf="!online" (click)="realizarSangria()">Realizar Sangria</button>

    </div>
    <div class="painel ">
      <div class="painel-corpo">
          <div class="resumo-caixa" *ngIf="this.resumo">
      <div class="resumo-linha">
        <div>(+) SALDO INICIAL:</div>
        <div class="valor">{{resumo.saldoInicial | currency:'BRL'}}</div>
      </div>
      <div class="resumo-linha">
        <div>(+) ENTRADAS NO CAIXA</div>
      </div>
      <div class="resumo-linha ml-1">
        <div>ACRÉSCIMOS</div>
      </div>
      <ng-container *ngFor="let totalDaForma of resumo.totalEntradasPorFormaDePagamento">
        <div class="resumo-linha ml-2" *ngIf="totalDaForma.valor > 0">
          <div>{{totalDaForma.descricao}}:</div>
          <div class="valor">{{totalDaForma.valor | currency:'BRL'}}</div>
        </div>
      </ng-container>
      <div class="resumo-linha ml-1">
        <div>TOTAL - ENTRADAS</div>
      </div>
      <div class="resumo-linha ml-2">
        <div>Total:</div>
        <div class="valor">{{resumo.totalEntradas | currency:'BRL'}}</div>
      </div>
      <div class="resumo-linha">
        <div>(-) SAÍDAS DO CAIXA</div>
      </div>
      <div class="resumo-linha ml-1">
        <div>SANGRIAS E DESPESAS</div>
      </div>
      <div class="resumo-linha ml-2">
        <div>Dinheiro:</div>
        <div class="valor">{{resumo.totalSangrias | currency:'BRL'}}</div>
      </div>
      <div class="resumo-linha ml-1">
        <div>PEDIDOS CANCELADOS</div>
      </div>
      <ng-container *ngFor="let totalDaForma of resumo.totalSaidasPorFormaDePagamento">
        <div class="resumo-linha ml-2" *ngIf="totalDaForma.valor > 0">
          <div>{{totalDaForma.descricao}}:</div>
          <div class="valor">{{totalDaForma.valor | currency:'BRL'}}</div>
        </div>
      </ng-container>
      <div class="resumo-linha ml-1">
        <div>TOTAL - SAÍDAS</div>
      </div>
      <div class="resumo-linha ml-2">
        <div>Total:</div>
        <div class="valor">{{resumo.totalSaidas | currency:'BRL'}}</div>
      </div>
      <div class="resumo-linha">
        <div>(=) SALDO FINAL</div>
      </div>
      <div class="resumo-linha ml-2">
        <div>Somente Dinheiro:</div>
        <div class="valor">{{resumo.saldoDinheiro | currency:'BRL'}}</div>
      </div>
      <div class="resumo-linha ml-2">
        <div>Tudo:</div>
        <div class="valor">{{resumo.saldo | currency:'BRL'}}</div>
      </div>




    </div>
          <button (click)="imprimirResumo()" class="btn btn-dark
       waves-effect waves-light btn-imprimir btn-novo"><i class="fas fa-print"></i> Imprimir resumo</button>
      </div>
    </div >


  </div>


</div>
<kendo-dialog *ngIf="abrirDialog" (close)="cancelar()" >
  <kendo-dialog-titlebar>
    Abrir caixa
  </kendo-dialog-titlebar>
  <div>
    <div class="info-container">
      <div class="info-row">
        <div class="info-title"><strong>Usuário:</strong></div>
        <div class="info-data">{{ usuarioLogado.nome }}</div>
      </div>
      <div class="info-row">
        <div class="info-title"><strong>Hora:</strong></div>
        <div class="info-data">{{ hora | date:'shortTime':'':'pt-BR' }}</div>
      </div>
    </div>
    <p>Qual o saldo inicial em dinheiro?</p>
    <kendo-numerictextbox style="width: 100%" [(ngModel)]="saldoInicial" format="c2"></kendo-numerictextbox>
  </div>
  <div class="alert alert-danger mt-2 mb-2" role="alert" *ngIf="mensagemErro">
    <i class="fas fa-exclamation-triangle"></i> {{mensagemErro}}
  </div>

  <kendo-dialog-actions>
    <div class="mt-2 mb-2 mr-2">
      <button kendoButton  class="btn btn-light  waves-effect waves-light  ml-2" [disabled]="abrindoCaixa"  (click)="cancelar()">Cancelar</button>
      <button kendoButton class="btn btn-primary waves-effect waves-light  ml-2" [disabled]="abrindoCaixa"  (click)="confirmar()">Confirmar</button>

    </div>
  </kendo-dialog-actions>
</kendo-dialog>
<kendo-dialog *ngIf="fecharDialog" (close)="cancelarFechamento()" >
  <kendo-dialog-titlebar>
    Fechar caixa
  </kendo-dialog-titlebar>
  <div>
    <div class="info-container">
      <div class="info-row">
        <div class="info-title"><strong>Usuário:</strong></div>
        <div class="info-data">{{ usuarioLogado.nome }}</div>
      </div>
      <div class="info-row">
        <div class="info-title"><strong>Hora:</strong></div>
        <div class="info-data">{{ hora | date:'shortTime':'':'pt-BR' }}</div>
      </div>
      <div class="info-row" *ngIf="!online">
        <div class="info-title"><strong>Saldo atual em dinheiro:</strong></div>
        <div class="info-data">{{ resumo.saldoDinheiro | currency:'BRL':true }}</div>
      </div>
      <div class="info-row">
        <div class="info-title"><strong>Saldo total (dinheiro + cartão - saídas):</strong></div>
        <div class="info-data">{{ resumo.saldo | currency:'BRL':true }}</div>
      </div>
    </div>
    <ng-container *ngIf="!online">
      <p>Quanto você encontrou na conferência do dinheiro no caixa?</p>
      <kendo-numerictextbox style="width: 100%" [(ngModel)]="saldoConferido" format="c2"></kendo-numerictextbox>
      <div class="alert alert-warning mt-2 mb-2" role="alert" *ngIf="saldoConferido !== resumo.saldoDinheiro ">
        <i class="fas fa-exclamation-triangle"></i> Há diferença entre o saldo em dinheiro e o valor conferido.
      </div>
      <div class="alert alert-success mt-2 mb-2" role="alert" *ngIf="saldoConferido === resumo.saldoDinheiro">
        <i class="fas fa-check-circle"></i> Saldo conferido com sucesso!
      </div>
    </ng-container>

  </div>
  <div class="alert alert-danger mt-2 mb-2" role="alert" *ngIf="mensagemErroFechamento">
    <i class="fas fa-exclamation-triangle"></i> {{mensagemErroFechamento}}
  </div>
  <div class="alert alert-warning mt-2 mb-2" role="alert" *ngIf="mensagemAdvertenciaFechamento">
    <i class="fas fa-exclamation-triangle"></i> {{mensagemAdvertenciaFechamento}}
  </div>



  <kendo-dialog-actions>
    <div class="mt-2 mb-2 mr-2">
      <button class="btn btn-danger waves-effect waves-light ml-2" (click)="cancelarFechamento()">Cancelar</button>
      <button class="btn btn-light waves-effect waves-light ml-2" (click)="confirmarFechamento()" primary>Confirmar</button>
      <button class="btn btn-primary waves-effect waves-light ml-2" (click)="confirmarEImprimirRelatorio()" primary>
        <i class="fas fa-print"></i> Confirmar e Imprimir Relatório
      </button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog  *ngIf="sangriaDialog" (close)="cancelarSangria()" >
  <kendo-dialog-titlebar>
    Realizar Sangria
  </kendo-dialog-titlebar>
  <div style="width: 500px">
    <div class="info-container">
      <div class="info-row">
        <div class="info-title"><strong>Usuário:</strong></div>
        <div class="info-data">{{ usuarioLogado.nome }}</div>
      </div>
      <div class="info-row">
        <div class="info-title"><strong>Hora:</strong></div>
        <div class="info-data">{{ hora | date:'shortTime':'':'pt-BR' }}</div>
      </div>
      <div class="info-row">
        <div class="info-title"><strong>Saldo atual em dinheiro:</strong></div>
        <div class="info-data">{{ resumo.saldoDinheiro | currency:'BRL':true }}</div>
      </div>
    </div>
    <p>Informe o valor da sangria:</p>
    <kendo-numerictextbox style="width: 100%" [(ngModel)]="valorSangria" format="c2"></kendo-numerictextbox>
    <div class="alert alert-warning mt-2 mb-2" role="alert" *ngIf="valorSangria > resumo.saldoDinheiro">
      <i class="fas fa-exclamation-triangle"></i> O valor informado para sangria é maior que o saldo disponível.
    </div>
    <p class="mt-2">Justificativa:</p>
    <textarea class="form-control" [(ngModel)]="justificativaSangria" rows="3"></textarea>
  </div>
  <div class="alert alert-danger mt-2 mb-2" role="alert" *ngIf="mensagemErroSangria">
    <i class="fas fa-exclamation-triangle"></i> {{mensagemErroSangria}}
  </div>

  <kendo-dialog-actions>
    <div class="mt-2 mb-2 mr-2">
      <button class="btn btn-light waves-effect waves-light ml-2" (click)="cancelarSangria()">Cancelar</button>
      <button class="btn btn-primary waves-effect waves-light ml-2" [disabled]="valorSangria > resumo.saldoDinheiro" (click)="confirmarSangria()" primary>Confirmar</button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>
