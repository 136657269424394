<h4 class="mb-2">Produtos da Vitrine "<b>{{vitrine.nome}}</b>"</h4>

<ng-container *ngIf="!adicionar">

  <kendo-grid
    [kendoGridBinding]="vitrine.produtosNaVitrine"   (remove)="removerProduto($event)"
    [loading]="carregando" [height]="500">
    <ng-template kendoGridToolbarTemplate [position]="'top'">
      <div class="row">
        <div class="col">
          <button class="btn btn-blue float-right" (click)="adicionarProduto()">
            <i class="fe-plus mr-1"></i>
            Adicionar
          </button>
        </div>

      </div>

    </ng-template>
    <kendo-grid-messages
      pagerPage="Página"
      pagerOf="de"
      pagerItems="itens"
      noRecords="Nenhum produto na vitrine"
      loading="Carregando"
      pagerItemsPerPage="ítems por página"
    >
    </kendo-grid-messages>

    <kendo-grid-column title="Ordem" [width]="90">
      <ng-template kendoGridCellTemplate let-produtoNaVitrine  let-rowIndex="rowIndex">
        <div  class="ordem" >

          <kendo-dropdownbutton class="btn btn-block btn-light"
                                [ngClass]="{ 'desabilitado': trocandoOrdem  }"   [data]="opcoesSubir"
                                (itemClick)="clicouSubir(produtoNaVitrine, rowIndex, $event)">
            <i class="fas fa-chevron-up   cpointer"    ></i>
          </kendo-dropdownbutton>


          <span class="text-center d-block font-16">{{produtoNaVitrine.posicao}}</span>

          <kendo-dropdownbutton class="btn btn-block btn-light"
                                [ngClass]="{ 'desabilitado': trocandoOrdem  }"  [data]="opcoesDescer"
                                (itemClick)="clicouDescer(produtoNaVitrine, rowIndex, $event)">
            <i class="fas fa-chevron-down   cpointer"  ></i>
          </kendo-dropdownbutton>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Imagem"   [width]="110">
      <ng-template kendoGridCellTemplate let-produtoNaVitrine let-rowIndex="rowIndex">

        <img [src]="'/images/empresa/'+produtoNaVitrine.produto.imagens[0].linkImagem" style="width: 80px;"
             *ngIf="produtoNaVitrine.produto.imagens && produtoNaVitrine.produto.imagens.length > 0">

        <i class="fe-image fa-2x" *ngIf="!produtoNaVitrine.produto.imagens || produtoNaVitrine.produto.imagens.length == 0"></i>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Nome"   >
      <ng-template kendoGridCellTemplate let-produtoNaVitrine  >
        <span class="text-blue" [ngClass]="{'riscado': produtoNaVitrine.removido}" ><b>{{produtoNaVitrine.produto.nome}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Categoria"   >
      <ng-template kendoGridCellTemplate let-produtoNaVitrine  >
        <span > {{produtoNaVitrine.produto.categoria ? produtoNaVitrine.produto.categoria.nome : '-'}} </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="" [width]="75">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-produtoNaVitrine>

        <button kendoGridRemoveCommand kendoTooltip title="remover" [disabled]="produtoNaVitrine.removendo"
                *ngIf="!produtoNaVitrine.removido"  ><i class="fa fa-trash text-danger"></i></button>


        <i class="k-i-loading k-icon" *ngIf="produtoNaVitrine.removendo"></i>
      </ng-template>
    </kendo-grid-command-column>

  </kendo-grid>
</ng-container>


<ng-container *ngIf="adicionar">

  <ng-container *ngIf="totalAdicionado > 0">
    <div class="alert alert-success mt-2" role="alert">
      <i class="mdi mdi-check-all mr-2"></i> <b>{{totalAdicionado}}</b> produto(s) adicionados com sucesso!
      <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
        <span aria-hidden="true">&times;</span>
      </button>

      <div class="mt-2">
        <button class="btn btn-blue" (click)="adicionarMais()">
          <i class="fe-plus mr-1"></i>
          Adicionar mais
        </button>

        <button class="btn btn-secondary ml-1" (click)="volteAdicionar(true)" [disabled]="salvando">
          <i class="fe-chevron-left mr-1"></i>
          Voltar
        </button>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="!totalAdicionado">
    <div class="alert alert-danger alert-dismissible fade show mt-2" role="alert" *ngIf="erro">
      <i class="mdi mdi-check-all mr-2"></i> {{erro}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <kendo-grid #gridNovos
                [kendoGridBinding]="produtosAdicionar" [selectable]="selectableSettings"
                [(selectedKeys)]="mySelection"  kendoGridSelectBy="id"           (selectedKeysChange)="onSelectedKeysChange($event)"
                [loading]="carregando" [height]="500"

                footerStyle="font-size: 11px;">
      <ng-template kendoGridToolbarTemplate [position]="'top'">
        <div class="row">
          <div class="col">
            <input kendoTextBox placeholder="Busque por nome do produto" class="form-control busca" [(ngModel)]="filtro.texto"
                   appAutoFocus   (input)="onFilterDisponiveis($event)"/>
          </div>

          <div class="col ">
            <div class="form-group">

              <kendo-combobox id="cboCategorias" class="form-control" [data]="empresa.catalogo.categorias" style="width: 250px"
                              textField="nome" valueField="id" [(ngModel)]="filtro.categoria" [allowCustom]="false"
                              (valueChange)="carregueProdutosDisponiveis()">
              </kendo-combobox>
            </div>

          </div>

          <div class="col">
            <button class="btn btn-success" (click)="salveNovosProdutos()" [disabled]="salvando">
              <i class="fe-save mr-1"></i>
              Salvar <i class="k-icon k-i-loading" *ngIf="salvando"></i>
            </button>

            <button class="btn btn-secondary ml-2" (click)="volteAdicionar(false)" [disabled]="salvando">
              <i class="fe-chevron-left "></i>
              Voltar
            </button>

          </div>
        </div>

      </ng-template>
      <kendo-grid-messages
        pagerPage="Página"
        pagerOf="de"
        pagerItems="itens"
        noRecords="Nenhum produto disponível "
        loading="Carregando"
        pagerItemsPerPage="ítems por página"
      >
      </kendo-grid-messages>
      <kendo-grid-checkbox-column [width]="50" [showSelectAll]="true"></kendo-grid-checkbox-column>
      <kendo-grid-column title="Imagem" class="coluna_menor" [width]="110">
        <ng-template kendoGridCellTemplate let-produto let-rowIndex="rowIndex">

          <img [src]="'/images/empresa/' + produto.imagens[0].linkImagem" style="width: 80px;" *ngIf="produto.imagens && produto.imagens.length > 0" alt="Imagem produto">

          <i class="fe-image fa-2x" *ngIf="!produto.imagens || produto.imagens.length == 0"></i>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Nome"   >
        <ng-template kendoGridCellTemplate let-produto  >
          <span class="text-blue"><b>{{produto.nome}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Categoria"   >
        <ng-template kendoGridCellTemplate let-produto  >
          <span > {{produto.categoria ? produto.categoria.nome : '-'}} </span>
        </ng-template>
      </kendo-grid-column>


    </kendo-grid>
  </ng-container>
</ng-container>

