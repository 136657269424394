<h4   class="page-title"><i class="fas fa-motorcycle"></i> Taxas De Entregas Calculadas</h4>

<div class="card">
  <div class="card-body">

    <kendo-grid
      #grid
      [data]="view"
      [height]="600"
      [loading]="carregando"
      [pageSize]="gridState.take"
      [pageable]="true"
      [skip]="gridState.skip"
      [sort]="gridState.sort"
      [sortable]="true"
      (pageChange)="onPageChange($event)"
      (dataStateChange)="onStateChange($event)"
    >
      <ng-template kendoGridToolbarTemplate>
        <button type="button" class="k-button" (click)="carregueTaxas()">Recarregar</button>
      </ng-template>
      <kendo-grid-column field="id" title="ID" [width]="50">
      </kendo-grid-column>
      <kendo-grid-column field="horario" title="Horário" [width]="50">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem.horario | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fazEntrega" title="Entrega" [width]="50">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="badge bg-success rounded-pill" *ngIf="dataItem.fazEntrega">Entrega</span>
          <span class="badge bg-danger rounded-pill" *ngIf="!dataItem.fazEntrega">Fora Área de Entrega</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tipoDeCobranca" title="Tipo De Cobrança" [width]="30">
      </kendo-grid-column>
      <kendo-grid-column field="distancia" title="Distância" [width]="30">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.distancia">{{dataItem.distancia | number: '1.2-2'}} KM</span>
          <span *ngIf="!dataItem.distancia">-</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="valor" title="Valor" [width]="30">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.fazEntrega" class="text-success font-weight-bold">{{dataItem.valor | currency: 'BRL'}}</span>
          <span *ngIf="!dataItem.fazEntrega" class="text-danger font-12">Não entrega</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="mapa" title="Rota" [width]="25">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>
            <a [href]="dataItem.linkMapa" target="_blank" title="Ver rota no Google Maps." kendoTooltip *ngIf="dataItem.localizacao">
              <i class="fas fa-route font-18"></i>
            </a>
            <a [routerLink]="[]" class="ml-2" (click)="exibaMapa(dataItem);" title="Ver endereço no Mapa." kendoTooltip *ngIf="dataItem.localizacao">
              <i class="fas fa-map-marker font-18"></i>
            </a>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="erro" title="Endereço/Mensagem" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="font-13 font-weight-bold">{{dataItem.endereco}}</span>
          <p class="font-12 mb-0">{{dataItem.erro}}</p>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>

<kendo-dialog title="Localização Endereço" *ngIf="exibindoMapa" (close)="fecharTelaMapa()"
              [minHeight]="altura" [height]="altura" [width]="800">
  <div style="margin: -16px;">
    <app-tela-areas-de-entrega [exibirFormKML]="false" [localizacao]="taxaSelecionada"
                               [componente]="true"></app-tela-areas-de-entrega>
  </div>
</kendo-dialog>
