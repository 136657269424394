import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfiguracoesMiaService} from "../../services/configuracoes-mia.service";
import {NgForm} from "@angular/forms";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {NotificacoesService} from "../../services/notificacoes.service";
import {
  FreeTrialConfirmationModalComponent
} from "../../free-trial-confirmation-modal/free-trial-confirmation-modal.component";
import { trigger, transition, style, animate } from '@angular/animations';
import {Router} from "@angular/router";

declare var moment;

@Component({
  selector: 'app-tela-config-atendente-virtual',
  templateUrl: './tela-config-atendente-virtual.component.html',
  styleUrls: ['./tela-config-atendente-virtual.component.scss']
})
export class TelaConfigAtendenteVirtualComponent implements OnInit {
  @ViewChild('frm') frm: NgForm;

  public configuracoes = {
    nome: '',
    status: 'DESATIVADA',
    telefonesTeste: '',
    modelo: '',
    comportamentoForaDoEscopo: '',
    responderSobreProdutos: false,
    tempoPausaMia: 1800,
    recuperarCarrinho: false,
    tempoRecuperarCarrinho: 20 * 60,
    dataInicioTrial: null,
    usarFluxoTypebot: false,
    idFluxoTypebot: null,
    idFluxoTypebotWhatsapp: null,
    idFluxoTypebotInstagram: null
  }
  msgSucesso = '';
  telefones: Array<string> = [];
  empresa: any;
  comportamentoForaDoEscopoEnum: string;
  notificacao: any;

  @ViewChild('freeTrialConfirmationModal', {static: false}) freeTrialConfirmationModal: FreeTrialConfirmationModalComponent;

  /*
  planosMia: PlanoMia[] = [
    {
      nome: "Básico",
      modelo: 'Basico',
      icone: "fas fa-circle",
      preco: 0,
      billingPeriod: 'mês',
      caracteristicas: [
        {texto: "Respostas da IA são dinâmicas", disponivel: true},
        {texto: "Memória limitada à última mensagem", disponivel: true},
        {texto: "Responde dúvidas dos clientes", disponivel: true},
        {texto: "Personalizar nome da Mia", disponivel: false},
        {texto: "Conhecimentos extras", disponivel: false},
        {texto: "Sem custo", disponivel: false}
      ],
      testeGratis: false
    },
    {
      nome: "Intermediário",
      modelo: 'Intermediario',
      icone: "fa fa-robot",
      preco: 49.90,
      billingPeriod: 'mês',
      caracteristicas: [
        {texto: "Respostas da IA são dinâmicas", disponivel: true},
        {texto: "Responde dúvidas dos clientes", disponivel: true},
        {texto: "Personalizar Nome da Mia", disponivel: true},
        {texto: "Memória das últimas 5 mensagens", disponivel: true},
        {texto: "Respostas mais contextuais", disponivel: true},
        {texto: "Conhecimentos extras", disponivel: true}
      ],
      testeGratis: true
    },
    {
      nome: "Ultra Inteligente (GPT-4)",
      preco: 99.90,
      icone: "fas fa-lightbulb",
      modelo: 'Avancado',
      billingPeriod: 'mês',
      caracteristicas: [
        {texto: "Respostas da IA são dinâmicas", disponivel: true},
        {texto: "Personalizar Nome da Mia", disponivel: true},
        {texto: "Memória de toda sessão de conversa", disponivel: true},
        {texto: "Entende perguntas mais complexas", disponivel: true},
        {texto: "Respostas mais contextuais", disponivel: true},
        {texto: "Conhecimentos extras", disponivel: true}
      ],
      testeGratis: true
    }
  ];
  planoEscolhido: any = {};
   */
  criandoFluxosTypebot: boolean;

  constructor(private configuracoesMiaService: ConfiguracoesMiaService, private constantsService: ConstantsService,
              private notificacoesService: NotificacoesService, private router: Router) { }

  ngOnInit(): void {
      this.notificacoesService.carregue('Carrinho Abandonado').then((notificacao) => {
        if( !notificacao ) {
          notificacao = {
            ativada: true,
            fazerPreview: true
          };
        }

        this.notificacao = notificacao;
        //carrega a configuracao
        this.configuracoesMiaService.obtenhaConfiguracoes().then((configuracoes) => {
            this.configuracoes = configuracoes;
            this.telefones = this.configuracoes.telefonesTeste.split(',');
        });

        //carrega a empresa
        this.constantsService.empresa$.subscribe((empresa) => {
            if (empresa && empresa.id) {
                this.empresa = empresa;
            }
        });
      });
  }

  fechar() {

  }

  onSubmit() {
    this.msgSucesso = '';
    if( !this.configuracoes.nome ) {
      return;
    }

    this.configuracoes.telefonesTeste = this.telefones.join(",");
    this.configuracoesMiaService.salveConfiguracoes(this.configuracoes).then((configuracaoSalva) => {
      this.configuracoes = configuracaoSalva;
      this.msgSucesso = 'Salvou configurações da Mia';
    });
  }

  onTelefonesMudou($event: any[]) {
    this.telefones = $event;
  }

  formatTime(seconds: number): string {
    const duration = moment.duration(seconds, 'seconds');
    let formattedTime = '';

    if (duration.hours() > 0) {
      formattedTime += `${duration.hours()} ${this.pluralize(duration.hours(), 'hora', 'horas')} `;
    }

    if (duration.minutes() > 0 || duration.hours() > 0) {
      formattedTime += `${duration.minutes()} ${this.pluralize(duration.minutes(), 'minuto', 'minutos')} `;
    }

    if( duration.seconds() > 0 ) {
      formattedTime += `${duration.seconds()} ${this.pluralize(duration.seconds(), 'segundo', 'segundos')}`;
    }

    return formattedTime;
  }

  pluralize(quantity: number, singular: string, plural: string): string {
    return quantity === 1 ? singular : plural;
  }

  /*
  escolhiPlano(plano: PlanoMia) {
    this.freeTrialConfirmationModal.abraModal(plano);
  }
   */

  onConfirmSubscription(plano: any) {
    const configuracoesModificadas = { ...this.configuracoes };
    configuracoesModificadas.modelo = plano.modelo;

    this.configuracoesMiaService.atualizeMudouPlano(configuracoesModificadas).then( (resposta) => {
      this.configuracoes.modelo = plano.modelo;

      this.configuracoes.dataInicioTrial = new Date();

      //this.escolhendoPlano = false;
      //this.loading = false;
      this.freeTrialConfirmationModal.feche();
    });
  }

  onCancelSubscription() {
    //alert('Processo de assinatura cancelado');
    //this.router.navigate(['/home']);
  }

  mudarPlano() {
    //this.escolhendoPlano = true;
  }

  voltar() {
    //this.escolhendoPlano = false;
  }

  onUsarFluxoTypebotChange($event: any) {
    if (!this.configuracoes.usarFluxoTypebot) {
      return;
    }

    debugger;
    this.criandoFluxosTypebot = true;
    this.configuracoesMiaService.crieFluxosTypebotPadrao().then( (resposta: any) => {
      this.criandoFluxosTypebot = false;
      this.configuracoes.idFluxoTypebotWhatsapp = resposta.idFluxoTypebotWhatsapp;
      this.configuracoes.idFluxoTypebotInstagram = resposta.idFluxoTypebotInstagram;

    }).catch( (erro: any) => {
      this.criandoFluxosTypebot = false;
    });
  }

  editarFluxoTypebot(tipoDeFluxo: string, nomeFluxo: string) {
    if( tipoDeFluxo === 'whatsapp' ) {
      this.router.navigateByUrl('/admin/chatbot/editor-fluxo?id=' + this.configuracoes.idFluxoTypebotWhatsapp + '&nome=' + nomeFluxo);
    } else if( tipoDeFluxo === 'instagram' ) {
      this.router.navigateByUrl('/admin/chatbot/editor-fluxo?id=' + this.configuracoes.idFluxoTypebotInstagram + '&nome=' + nomeFluxo);
    }
  }
}
