import {EventEmitter, Injectable} from '@angular/core';
import {ConstantsService} from "../fidelidade/ConstantsService";
import {DeviceDetectorService} from "ngx-device-detector";
import {ProcessadorReciboTexto} from "./impressao/ProcessadorReciboTexto";
import {PedidosService} from "./pedidos.service";

declare var qz: any;


@Injectable({
  providedIn: 'root'
})
export class ImpressaoService {
  printer: any;
  qzInstalado = false;
  conectou: EventEmitter<boolean> = new EventEmitter();
  private realizouTentativaDeConexao: boolean;
  conectando = false;
  conectandoQzTray: EventEmitter<boolean> = new EventEmitter<boolean>()
  onErrosImpressao: any =   new EventEmitter<any>()
  impressoras: string[];
  imprimindo = false;
  qtdImpressoes = 0;
  public filaImpressao = [];
  imprimindoEmLote = false;
  categorias: any = []
  constructor(private constantsService: ConstantsService, private pedidosService: PedidosService,
              private deviceService: DeviceDetectorService) {
    if(this.deviceService.isMobile())   return;
    this.conectandoQzTray.emit(true)
    qz.security.setCertificatePromise((resolve, reject) => {
      fetch("/assets/cert/cert.pem", {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
        .then( (data) => {
          console.log(data);
          if(data.ok) {
            let texto = data.text();
            console.log(texto);
            return resolve(texto);
          }
          this.onErrosImpressao.emit('não foi possível carregar o certificado do qz-tray')
          reject(data.text())

        });
    })

    qz.security.setSignatureAlgorithm("SHA512");
    qz.security.setSignaturePromise(function(toSign) {
      return function(resolve, reject) {
        fetch("/pedidos/impressao/assine?request=" + toSign, {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
          .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });
      };
    });

    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) return;
      this.conectando = true
      this.categorias = empresa.catalogo.categorias;
      qz.websocket.connect().then(() => {
        this.realizouTentativaDeConexao = true;
        this.conectando = false;

        this.qzInstalado = true;
        qz.printers.find().then(data => {
          data.sort();
          console.log(data);
          this.impressoras = data;
          this.conectou.emit(true)
        })
        /*
        if(empresa.configImpressao.imprimirTXT && empresa.configImpressao.impressora) {
          qz.printers.find(empresa.configImpressao.impressora.nome).then((found) => {
            this.printer = found;
          });
        } else
          this.printer = null;

         */

      }).catch((erro) => {
        this.realizouTentativaDeConexao = true;
        this.conectou.emit(false)
        this.qzInstalado = false;
        this.conectando = false;
      });

    });

  }

  conecteQZ() {
    return new Promise((resolve) => {
      this.conectando = true
      qz.websocket.connect().then(() => {
        this.conectando = false
        this.realizouTentativaDeConexao = true;
        this.qzInstalado = true;

        qz.printers.find().then(data => {
          data.sort();
          console.log(data);
          this.impressoras = data;
          resolve(null);
        })

        /*
        if(empresa.configImpressao.imprimirTXT && empresa.configImpressao.impressora) {
          qz.printers.find(empresa.configImpressao.impressora.nome).then((found) => {
            this.printer = found;
          });
        } else
          this.printer = null;

         */

      }).catch((erro) => {
        this.realizouTentativaDeConexao = true;
        this.conectou.emit(false)
        this.conectando = false;
        this.qzInstalado = false;
        console.log('')
        this.onErrosImpressao.emit('Não foi possível conectar a impressora. O qz-tray está aberto?')
        resolve(null);
      })
    })
  }

  possuiQZ() {
    return new Promise((resolve) => {
      if(qz.websocket.isActive()) return resolve(true)

      if(this.conectando) {
        this.conectandoQzTray.emit(true)
        let inscricao = this.conectou.subscribe((conectou: boolean) => {
          inscricao.unsubscribe();

          if (conectou) {
            setTimeout(() => {
              this.conectandoQzTray.emit(false)
            }, 0)

            return resolve(conectou);
          }

          this.conecteQZ().then(() => {
            setTimeout(() => {
              this.conectandoQzTray.emit(false)
            }, 0)

            return resolve(qz.websocket.isActive())
          })
        })
      } else {
        this.conectandoQzTray.emit(true)
        this.conecteQZ().then(() => {
          setTimeout(() => {
            this.conectandoQzTray.emit(false)
          }, 0)

          return resolve(qz.websocket.isActive())
        })
      }
    })
  }

  imprimaHTML(url: string, impressora: any, impressoraTinta = null): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      if(this.imprimindo) {
        setTimeout(() => {
          this.imprimaHTML(url, impressora).then(async (resultado) =>
          resolve(resultado));
          }, 1000);
        return;
      }
      this.imprimindo = true;

      let timeoutBotarFalse = setTimeout(() => { this.imprimindo = false; }, 10000)

      try {

        this.qtdImpressoes++;

        console.log("Job número " + this.qtdImpressoes);


        let nomeImpressora

        //if(isDevMode())
//          url = "http://localhost:3100" + url

        console.log('url: ' + url)

        for(let i = 0; i < this.impressoras.length; i++)
          if(this.impressoras[i].toLowerCase().indexOf(impressora.nome.toLowerCase()) >= 0)
          {
            nomeImpressora = this.impressoras[i];
            break;
          }

        for(let i = 0; i <  this.impressoras.length; i++)
          if(this.impressoras[i] === impressora.nome)
          {
            nomeImpressora = this.impressoras[i];
            break;
          }


        if(!nomeImpressora) {
          this.imprimindo = false;
          clearTimeout(timeoutBotarFalse);
          throw new Error("Não foi possível econtrar a impressora " + impressora.nome + " neste computador.")
        }

        let params: any = { encoding: 'Cp1252'};
        let config = qz.configs.create(nomeImpressora, params);

        let data: any = [{
          type: 'html',
          format: 'file', // or 'plain' if the data is raw HTML
          data: url,
          options: { language: "ESCPOS" }
        }]

        if(impressoraTinta){
          config = qz.configs.create(nomeImpressora);

          const response = await fetch(url);
          const pdfData = await response.blob();
          // Converter o Blob para ArrayBuffer
          const arrayBuffer = await pdfData.arrayBuffer()

          async function arrayBufferToBase64(buffer) {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
          }

          const base64Data = await arrayBufferToBase64(arrayBuffer); // Convertendo para Base64


          data = [{
            type: 'pdf',
            format: 'base64', // Mude para base64
            data: base64Data
          }];

          console.log("Dados para impressão:", data); // Verifique a estrutura
        }


        qz.print(config, data).then(( ) => {
          let feed = []

          if(impressora.comandosFimImpressao && impressora.cortarAutomatico) {
            let comandos = this.convertaParaArrayHex(impressora.comandosFimImpressao)
            for(let comando of comandos)
              feed.push(comando)
          }

          if(impressora.emitirBeep) {
            let quantidadeBeeps = String.fromCharCode(impressora.quantidadeBeeps)
            let duracaoBeep = String.fromCharCode(Math.floor(impressora.duracaoBeep / 100))
            let comando = '\x1B\x28\x41\x05\x00\x61\x64' + quantidadeBeeps + duracaoBeep + '\x05'
            feed.push(comando)
          }

          if(impressora.cortarAutomatico) {
            feed.push('\x1B' + '\x69')
            qz.print(config,  feed).then(() => {
              this.imprimindo = false;
              clearTimeout(timeoutBotarFalse);
            }).catch((e) => {
              this.imprimindo = false;
              clearTimeout(timeoutBotarFalse);
              console.error(e); }).finally(() => {
              this.imprimindo = false;
              clearTimeout(timeoutBotarFalse);
            })
          } else if(impressora.emitirBeep) {
            qz.print(config,  feed).then(() => {
              this.imprimindo = false;
              clearTimeout(timeoutBotarFalse);
            }).catch((e) => {
              this.imprimindo = false;
              clearTimeout(timeoutBotarFalse);
              console.error(e); }).finally(() => {
              this.imprimindo = false;
              clearTimeout(timeoutBotarFalse);
            })
          } else {
            this.imprimindo = false;
            clearTimeout(timeoutBotarFalse);
          }

          resolve(true)

        }).catch((e) => {
          this.imprimindo = false;
          clearTimeout(timeoutBotarFalse);
          console.error(e);
        }).finally(() => {
          this.imprimindo = false;
          clearTimeout(timeoutBotarFalse);
          resolve(false)
        })


      } catch(e) {
        this.imprimindo = false;
        clearTimeout(timeoutBotarFalse);
        resolve(false)
        console.error(e);
      }
    })

  }

  async imprimaTexto(texto: string, impressora: any) {
    let nomeImpressora;

    if(!this.impressoras || !this.impressoras.length)
      throw new Error("Nenhuma impressora disponível para impressão")

    for(let i = 0; i < this.impressoras.length; i++)
      if(this.impressoras[i].toLowerCase().indexOf(impressora.nome.toLowerCase()) >= 0)
      {
        nomeImpressora = this.impressoras[i];
        break;
      }

    for(let i = 0; i < this.impressoras.length; i++)
      if(this.impressoras[i] === impressora.nome)
      {
        nomeImpressora = this.impressoras[i];
        break;
      }

    if(!nomeImpressora)
      throw new Error("Não foi possível encontrar a impressora " + impressora.nome + " neste computador.")

    let config = qz.configs.create(nomeImpressora, { encoding: 'Cp1252'});

    let paginas = texto.split(ProcessadorReciboTexto._MARCADOR_FIM_PAPEL).filter(x => x)

    console.log(paginas)
    console.log("Quantidade de paginas a imprimir: " + paginas.length)



    for(let pagina of paginas) {
      await this.imprimaPaginaTexto(pagina, config, impressora).catch((error: any) => {
        throw new Error(error.message)
      })
    }

  }

  marqueProdutosDaImpressora(impressora: any, pedido: any){
    impressora.produtos = []

    pedido.itens.map( itemPedido => itemPedido.produto).forEach(produto => {
      if(produto.categoria){
        let categoria = this.categorias.find( cat => cat.id === produto.categoria.id);

        if(categoria && categoria.impressoras.length){
          categoria.impressoras.map(item => item.id).forEach((impressoraId: number) => {
             if(impressoraId === impressora.id)
               impressora.produtos.push(produto)
          })
        }
      }
    });
  }

  async imprimaPedidoNaImpressora(pedidoCompleto, impressora: any, pedidoMarcado: any = null) {

    this.marqueProdutosDaImpressora(impressora, pedidoCompleto)

    let processadorDeRecibo = new ProcessadorReciboTexto(impressora)

    const imprimiu =  await this.imprima(processadorDeRecibo.obtenhaRecibo(pedidoCompleto, impressora.layout),
      processadorDeRecibo.impressora, null, pedidoCompleto.id)

    if(imprimiu)
      await this.marquePedidoImpresso(pedidoMarcado || pedidoCompleto)
  }


  async imprimaQzTray(pedido: any, impressora: any){
    try {

      //se ja esta sendo impresso , nao deve acumular....
      if(pedido.imprimindo){

      }

      this.marquePedidoImprimir(pedido);

      let  pedidoCompleto: any = await  this.pedidosService.obtenhaPorCodigo(pedido.guid).catch((err) => {
           this.marqueErroImpresso(pedido, 'Falha ao carregar pedido: ' + err)
      });

      if(pedidoCompleto)
          await this.imprimaPedidoNaImpressora(pedidoCompleto, impressora, pedido)

    } catch(erro) {
      console.error(erro)
      this.marqueErroImpresso(pedido, erro)
    }
  }

  marquePedidoImprimir(pedido: any){
    pedido.imprimindo = true;
    pedido.aguardando = false;
    delete pedido.erroImpressao
  }

  async marquePedidoImpresso(pedido: any){
    pedido.impresso = true;
    pedido.imprimindo = false;
    pedido.aguardando = false;
    delete     pedido.erroImpressao;

    await this.pedidosService.marqueFoiImpresso(pedido)
  }
  marqueErroImpresso(pedido: any, erro: string){
    pedido.imprimindo = false;
    pedido.aguardando = false;
    pedido.erroImpressao = erro;
  }

  imprima(texto: string, impressora: any, operacao: string = null, id: number = null): Promise<boolean> {
    return this.possuiQZ().then( async (possui: boolean) => {
      try{
        if(!possui)
          throw new Error("É necessário instalar/iniciar o QZ Tray antes de realizar a impressão")

        if(!impressora )
          throw new Error("É necessário informar a impressora")

        let impressoraTinta = impressora.tamanhoPapel === 'LayoutA4';

        if(impressora.modoHTML || impressoraTinta){
          let impresso = await this.imprimaHTML(texto, impressora, impressoraTinta)
          return Promise.resolve(impresso)
        } else{
          let erroTexto;

          await this.imprimaTexto(texto, impressora).catch((error: any) => {
            erroTexto = error.message;
          })

          if(erroTexto)
            throw new Error(erroTexto)
        }

        return Promise.resolve(true)

      }catch (erro){
        this.emitaErroImpresso(`Houve um erro ${operacao ? ' ao ' + operacao : ' ao imprimir'}: ${erro}`, id)
        return Promise.resolve(false)
      }
    })

  }

  private emitaErroImpresso(erro: string, id: number){
    let pedido: any;

    if(id)
     pedido = this.filaImpressao.find((item: any) => item.id === id);

    if(pedido) //exibir erro fila de impressao
      this.marqueErroImpresso(pedido, erro)
    else
      this.onErrosImpressao.emit(erro)

  }

  private convertaParaArrayHex(comandosFimImpressao: string) {
    return comandosFimImpressao.replace(/\\x([0-9A-F]{2})/ig, function() {
      return String.fromCharCode(parseInt(arguments[1], 16));
    }).split(',');
  }

  listeImpressoras() {
    return new Promise((resolve: any) => {
      this.possuiQZ().then((possui: boolean) => {
        if(!possui) {
          resolve({
            sucesso: false,
            erro: 'Não foi possível listar as impressoras. O QZ-tray está aberto?'
          })
        }
        qz.printers.find().then(function(data) {
          data.sort();
          resolve({
            sucesso: true,
            data: data
          })
        }).catch(function(e) {
          resolve({
            sucesso: false,
            erro: e
          })
        });

      })

    })
  }

  private async imprimaPaginaTexto(pagina: string, config: any, impressora: any) {
    return new Promise((resolve: any, reject: any) => {
      console.log("Imprimindo página")
      console.log(pagina)
      let linhas = pagina.split("\n")
      linhas = linhas.map(i =>  i.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "\n");

      //linhas.push('\x1B' + '\x69')

      // '\x1B\x28\x41\x04\x00\x31\x1F4\x3E800\x01' fn n1
      //  n2
      //  vol

      let data = impressora.naoInicializaImpressao ?  linhas : ['\x1B' + '\x40'].concat(linhas);

      console.log("Dados a serem impressos: ", data)

      return qz.print(config,  data).then((err) => {
        let feed = []

        feed.push('\x0A')
        feed.push('\x0A')

        if(impressora.comandosFimImpressao) {
          let comandos = this.convertaParaArrayHex(impressora.comandosFimImpressao)
          for(let comando of comandos)
            feed.push(comando)
        }

        if(impressora.emitirBeep) {
          let quantidadeBeeps = String.fromCharCode(impressora.quantidadeBeeps)
          let duracaoBeep = String.fromCharCode(Math.floor(impressora.duracaoBeep / 100))
          let comando = '\x1B\x28\x41\x05\x00\x61\x64' + quantidadeBeeps + duracaoBeep + '\x05'
          console.log(comando)
          feed.push(comando)
        }


        //  if(impressora.cortarAutomatico) {
        feed.push('\x1B' + '\x69')
        qz.print(config,  feed).then(function() {
          resolve();
        }).catch((error: any) => {
          return reject(error)
        });
        //  }

      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  async imprimaEmLote(pedidos: any[], impressora: any) {
    try{
      this.imprimindoEmLote = true;
      for(let i = 0; i <  pedidos.length; i++){
        let pedido =  Object.assign({}, pedidos[i]);

        pedido.aguardando =  true;
        pedido.impressora = impressora;

        this.filaImpressao.splice(0, 0, pedido);
      }

      await this.imprimaPedidosDaFila(impressora);

    } finally {
      this.imprimindoEmLote = false;
    }

  }

  async reimprimaPedidoNaFila(pedido: any){
    pedido.impresso = false;
    await this.imprimaQzTray(pedido, pedido.impressora);
  }

  async imprimaPedidosDaFila(impressora) {
    for(let i = 0; i <  this.filaImpressao.length; i++){
      let pedido: any = this.filaImpressao[i];

      if(!pedido.impresso)
        await this.imprimaQzTray(pedido, impressora);

    }
  }

  limpeFilaImpressao(){
    this.filaImpressao   = [];
  }

}
