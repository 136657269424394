
<kendo-grid
  [data]="gridDataResult"   [group]="groups"  [groupable]="true"
  [loading]="carregando" style="min-height: 300px;"

  footerStyle="font-size: 11px;"
  (edit)="editarCupom($event.dataItem)"
  (remove)="abraDialogRemover($event)">
  <ng-template kendoGridToolbarTemplate [position]="'top'">
    <button class="btn btn-blue" (click)="criarCupom()">
      <i class="fe-plus "></i>
      Novo cupom
    </button>
  </ng-template>
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Nenhum cupom "
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column field="status" [width]="0">
    <ng-template kendoGridGroupHeaderTemplate let-field="field" let-value="value">
      Cupons &nbsp;&nbsp;<strong>{{value}}</strong>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="ID"    [width]="70">
    <ng-template kendoGridCellTemplate let-cupom  >
      <span class="text-blue"><b>{{cupom.id}}</b></span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Código" field="codigo"   >
    <ng-template kendoGridCellTemplate let-cupom >
      <b> {{cupom.codigo}}</b>

      <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(cupom.codigo)"  [ngClass]="{'text-success': mensagemCopiada === cupom.codigo}"
         title="copiar código" kendoTooltip></i>

      <span class="text-muted"><br><b>{{cupom.nome}}</b></span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Desconto"     >
    <ng-template kendoGridCellTemplate let-cupom >

      <b>
        <span *ngIf="cupom.valor >= 0">    {{cupom.valor | currency: "BRL"}}</span>
        <span *ngIf="cupom.percentual >= 0">    {{cupom.percentual }}%</span>
      </b>


      <span class="text-primary">
        {{ this.cupom.aplicarNaTaxaDeEntrega ? ' na taxa de entrega' :
        (this.cupom.aplicarNaFidelidade ? 'pontos fidelidade' : 'em produtos')}}
      </span>

      <span *ngIf="cupom.valorMinimo" class="text-muted">
        <br> pedidos acima de {{(cupom.valorMinimo | currency: 'BRL')}}
      </span>

    </ng-template>
  </kendo-grid-column>



  <kendo-grid-column title="Validade" field="tipo"   >
    <ng-template kendoGridCellTemplate let-cupom >

      <ng-container *ngIf="cupom.horarios?.length">

        <span *ngFor="let horario of cupom.horarios; let i = index; let ultimo = last">
          <b> {{getDia(horario.dia) }} <span [hidden]="ultimo">, </span></b>
        </span>
      </ng-container>

      <span class="ml-1" [ngClass]="{'text-danger': cupom.vencido, 'text-success': !cupom.vencido}" *ngIf="cupom.validade">
        até <b>{{cupom.validade | date: 'dd/MM/yyyy'}}</b>
      </span>


      <span></span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Restrições"   [width]="110" >
    <ng-template kendoGridCellTemplate let-cupom >
      <i class="fa fa-users fa-lg cpointer text-blue"  *ngIf="cupom.restrito || cupom.restritoContatoPerdido"
         (click)="abraModalAssociarContatos(cupom)" ></i>

      <span class="text-center text-muted" *ngIf="!cupom.restrito">-</span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Link"    >
    <ng-template kendoGridCellTemplate let-cupom >
      <a href="{{cupom.link}}" target="_blank" class="text-blue"  >
        abrir link
      </a>

      <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(cupom.link)"  [ngClass]="{'text-success': mensagemCopiada === cupom.link}"
         title="copiar link" kendoTooltip></i>

    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-cupom>
      <button kendoGridEditCommand [primary]="true" (selectedChange)="editarCupom(cupom)">
        <i class="fa fa-edit" kendoTooltip title="editar"></i>
      </button>
      <button kendoGridRemoveCommand kendoTooltip title="remover"><i class="fa fa-trash"></i></button>

      <button class="btn btn-xs btn-danger ml-1" (click)="desativarCupom(cupom)"
              kendoTooltip title="Desativar Cupom" *ngIf="cupom.ativo" [disabled]="cupom.processando">
        <i class="far fa-pause-circle fa-lg">  </i>
      </button>
      <button class="btn btn-xs btn-primary ml-1" (click)="ativarCupom(cupom)"
              kendoTooltip title="Ativar cupom" *ngIf="!cupom.ativo" [disabled]="cupom.processando">
        <i class="far fa-play-circle fa-lg"  ></i>&nbsp;
      </button>


    </ng-template>
  </kendo-grid-command-column>

  <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
</kendo-grid>


<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="canceleRemocao()" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Você tem certeza que deseja remover o cupom
    "<strong>{{cupomApagar.nome}} - {{cupomApagar.codigo}}</strong>"?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="confirmeRemocao()" [primary]="true">Sim</button>
    <button kendoButton (click)="canceleRemocao()">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
