<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    <i class="fe-pie-chart fa-2x mr-1"></i> Templates Pizza
  </h4>

</kendo-dialog-titlebar>

<div   class="modal-body">

  <div class="row" *ngIf="templateSelecionado?.excluir">
    <div class="col">
      <div class="alert alert-blue">
        <p>Antes de excluir, vefirique se o template não é usado por nenhum produto. </p>

        Confirma a exclusão do template <b>{{this.templateSelecionado.nome}}</b> ?

        <br>



        <div *ngIf="erroExcluir" class="alert alert-danger alert-dismissible mt-2 mb-2">
          {{erroExcluir}}
          <button type="button" class="close" (click)="erroExcluir = null">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>


        <button type="button" class="btn btn-danger waves-effect waves-light mr-2"   [disabled]="excluindo" (click)="excluaTemplate()">
          <i class="k-icon k-i-loading" *ngIf="excluindo"> </i>
          Confirmar
        </button>

        <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                (click)="  templateSelecionado.excluir = false">Cancelar</button>


      </div>

    </div>
  </div>


  <ng-container *ngIf="novoTemplate">
    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
          novalidate #frm="ngForm" (ngSubmit)="onSubmitNovoTemplate(frm)" >

      <div class="row">
        <div class="col-6">
          <div class="form-group ">
            <label >Nome do Template</label>
            <input type="text" class="form-control" autocomplete="off"
                   name="nome" [(ngModel)]="novoTemplate.nome"
                   placeholder="Informe um nome para o template" value="" required   >
            <div class="invalid-feedback">
              Nome do template é obrigatório
            </div>
          </div>
        </div>
        <div class="form-group mt-1 col-12">
          <label class="k-checkbox-label"  >
            <input type="checkbox"  name="cobrarTaxaExtra" class="k-checkbox " kendoCheckBox
                   [(ngModel)]="novoTemplate.ativo" />
            Ativo
          </label>
        </div>

        <div class="form-group col-6" >
          <label for="templatemodelo">Modelo</label>
          <kendo-combobox id="templatemodelo" name="templatemodelo" [data]="templates" placeholder="selecionar"
                          class="form-control" appAutoFocus [autoFocus]="true"  [allowCustom]="false"  [clearButton]="false"
                          [(ngModel)]="novoTemplate.modelo" [valueField]="'id'"   [textField]="'nome'">
          </kendo-combobox>
          <small class="text-muted"> Modelo para os tamanhos, massas e bordas que serão criados</small>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">

          <div *ngIf="erroSalvar" class="alert alert-success alert-dismissible mt-2 mb-2">
            {{erroSalvar}}
            <button type="button" class="close" (click)="erroSalvar = null">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <button type="submit" class="btn btn-primary waves-effect waves-light"   [disabled]="salvando" >
            Salvar</button>
          <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                  (click)="  novoTemplate = null">Cancelar</button>
        </div>
      </div>

    </form>
  </ng-container>
  <ng-container *ngIf="!novoTemplate && !templateSelecionado?.excluir ">
    <div class="row">
      <div class="form-group col-6" >
        <label for="template">Templates</label>
        <kendo-combobox id="template" name="template" [data]="templates" placeholder="selecionar" (selectionChange)="alterouTemplate($event)"
                        class="form-control" appAutoFocus [autoFocus]="true"  [allowCustom]="false"  [clearButton]="false"
                        [(ngModel)]="templateSelecionado" [valueField]="'id'"   [textField]="'descricao'">
        </kendo-combobox>
      </div>

      <div class="form-group col-6" >
        <button class="btn btn-danger mt-3 mr-2" (click)="confirmeExcluirTemplate()" *ngIf="templateSelecionado">
          <i class="fe-trash"></i>Excluir Template</button>

        <button class="btn btn-blue mt-3" (click)="adicionarTemplate()"><i class="fe-plus"></i>Novo Template</button>
      </div>

    </div>

    <kendo-tabstrip class="nav-bordered" (select)="limpeMensagens()" [hidden]="!templateSelecionado">
      <kendo-tabstrip-tab [title]="'Tamanhos'" [selected]="true">
        <ng-template kendoTabContent>
          <h4 class="mt-2">Tamanhos</h4>
          <div class="row sub-titulo">

            <div class="col-1">Id</div>
            <div class="col-4">Nome</div>
            <div class="col">Qtde Pedaços</div>
            <div class="col">Qtde Sabores</div>
            <div class="col-1">Disponível</div>
            <div class="col-2"></div>
          </div>

          <ng-container *ngFor="let tamannho  of templateSelecionado?.tamanhos;let i = index;" >
            <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                  novalidate #frm="ngForm" (ngSubmit)="onSubmit()">

              <div class="row mt-2">
                <div class="col-1 text-muted"><b>{{tamannho.id}}</b></div>
                <div class="col-4">
                  <input type="text" class="form-control" autocomplete="off"
                         name="descricao{{i}}" [(ngModel)]="tamannho.descricao"
                         placeholder="Descriçao do tamanho" value="" required   >


                  <div class="invalid-feedback">
                    Obrigatório
                  </div>
                </div>
                <div class="col">

                  <kendo-numerictextbox class="form-control" autocomplete="off"  [min]="0"
                                        [format]="tamannho.qtdePedacos  > 1 ? '##### pedaços' : '##### pedaço'" name="qtdePedacos" required  name="qtdePedacos{{i}}"
                                        [(ngModel)]="tamannho.qtdePedacos" placeholder="Qtde pedaços">     </kendo-numerictextbox>


                  <div class="invalid-feedback">
                    Obrigatório
                  </div>

                </div>
                <div class="col">
                  <kendo-numerictextbox class="form-control" autocomplete="off"  [min]="0"
                                        [format]="tamannho.qtdeSabores  > 1 ? '##### sabores' : '##### sabor'" name="qtdeSabores{{i}}" required
                                        [(ngModel)]="tamannho.qtdeSabores" placeholder="Qtde sabores">     </kendo-numerictextbox>

                  <div class="invalid-feedback">
                    Obrigatório
                  </div>
                </div>
                <div class="col-1">
                  <label class="k-checkbox-label">
                    <input  name="disponivel{{i}}" kendoCheckBox class="k-checkbox  checkboxlg  mr-2 mt-1" type="checkbox"
                            [(ngModel)]="tamannho.disponivel"/>


                  </label>
                </div>
                <div class="col-2">
                  <button type="button " class="btn btn-success btn-sm" [disabled]="!frm.dirty"  (click)="salvarTemplateTamanho(tamannho, frm)">
                    <i class="fe-save "  *ngIf="!tamannho.salvando"></i>

                    <i class="k-icon k-i-loading"  *ngIf="tamannho.salvando"></i>
                  </button>

                  <button type="button"  class="btn btn-sm btn-light ml-1" *ngIf="!tamannho.id" (click)="canceleSalvarTamanho(tamannho)">
                    <i class="fa fa-times" ></i>
                  </button>

                </div>
              </div>
            </form>
          </ng-container>
          <button class="btn btn-outline-blue mt-2 " type="button" (click)="novoTamanho()" [hidden]="!templateSelecionado">
            <i class="fa fa-plus "></i> Adicionar tamanho</button>


          <div class="modal-footer mt-2">

            <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                    (click)="fecheModal()">Fechar</button>
          </div>


        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Massas'">

        <ng-template kendoTabContent>
          <div class="row sub-titulo">
            <div class="col-3">Nome</div>
            <div class="col-2">Valor</div>
            <div class="col-2">Tamanho</div>
            <div class="col-2">Cod. Pdv</div>
            <div class="col">Disponível</div>
            <div class="col">Sem Borda</div>
            <div  class="col"></div>
          </div>

          <ng-container *ngFor="let opcao of templateMassa.opcoes;let i = index;">
            <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                  novalidate #frm="ngForm" (ngSubmit)="onSubmit()">
              <div class="row mt-2">
                <div class="col-3">
                  <input type="text" class="form-control" autocomplete="off"
                         name="nome{{opcao.id}}" [(ngModel)]="opcao.nome"
                         placeholder="Nome da massa" value="" required   >
                </div>

                <div class="col-2">
                  <kendo-numerictextbox type="Number"   [min]="0"  class="form-control" name="valor-{{i}}"
                                        [format]="'c2'"   [(ngModel)]="opcao.valor" required  >
                  </kendo-numerictextbox>
                </div>

                <div class="col-2">
                  <kendo-combobox   name="tamanhos" [(ngModel)]="opcao.tamanho" [data]="templateSelecionado.tamanhos"
                                    placeholder="Todos" class="form-control" [textField]="'descricao'">
                  </kendo-combobox>

                </div>

                <div class="col-2">
                  <input type="text" class="form-control" autocomplete="off"
                         name="codigoPdv-{{i}}" [(ngModel)]="opcao.codigoPdv" #codigoPdv="ngModel"
                         placeholder="Cod. PDV"     [required]="empresa?.integracaoDelivery?.sistema==='ecletica'"     >
                  <div class="invalid-feedback">
                    <p *ngIf="codigoPdv.errors?.required">Código do pdv é obrigatório</p>
                  </div>
                </div>

                <div class="col">
                  <label class="k-checkbox-label">
                    <input  name="disponivel{{i}}" kendoCheckBox class="k-checkbox  checkboxlg  mr-2 mt-1" type="checkbox"
                            [(ngModel)]="opcao.disponivel"/>


                  </label>
                </div>

                <div class="col">
                  <label class="k-checkbox-label">
                    <input  name="semborda{{i}}" kendoCheckBox class="k-checkbox  checkboxlg  mr-2 mt-1" type="checkbox"
                            [(ngModel)]="opcao.semborda"/>


                  </label>
                </div>

                <div  class="col">
                  <button type="button " class="btn btn-success btn-sm" [disabled]="!frm.dirty"
                          (click)="salvarTemplateAdicional(templateMassa, opcao, frm)">
                    <i class="fe-save "  *ngIf="!opcao.salvando"></i>

                    <i class="k-icon k-i-loading"  *ngIf="opcao.salvando"></i>
                  </button>

                  <button type="button"  class="btn btn-sm btn-light ml-1" *ngIf="!opcao.id" (click)="canceleSalvarOpcao(templateMassa, opcao)">
                    <i class="fa fa-times" ></i>
                  </button>


                </div>
              </div>

            </form>
          </ng-container>

          <button class="btn btn-outline-blue mt-2 " type="button" (click)="novaOpcaoAdicional(templateMassa)">
            <i class="fa fa-plus "></i> Adicionar massa</button>

          <div class="modal-footer mt-2">

            <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                    (click)="fecheModal()">Fechar</button>
          </div>


        </ng-template>

      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Bordas'">

        <ng-template kendoTabContent>


          <div class="row sub-titulo">
            <div class="col-4">Nome</div>
            <div class="col-2">Valor</div>
            <div class="col-2">Tamanho</div>
            <div class="col-2">Cod. Pdv</div>
            <div class="col-1">Disponível</div>
            <div class="col-1"></div>
          </div>


          <ng-container *ngFor="let opcao of templateBorda.opcoes;let i = index;">

            <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                  novalidate #frm="ngForm" (ngSubmit)="onSubmit()">

              <div class="row mt-2">
                <div class="col-4">
                  <input type="text" class="form-control" autocomplete="off"
                         name="nome{{i}}" [(ngModel)]="opcao.nome"
                         placeholder="Nome da borda" value="" required   >
                </div>

                <div class="col-2">
                  <kendo-numerictextbox   [min]="0"  class="form-control" name="valor-{{i}}"
                                          [format]="'c2'"   [(ngModel)]="opcao.valor" required >
                  </kendo-numerictextbox>
                </div>

                <div class="col-2">
                  <kendo-combobox   name="tamanhos" [(ngModel)]="opcao.tamanho" [data]="templateSelecionado.tamanhos"
                                    placeholder="Todos" class="form-control" [textField]="'descricao'">
                  </kendo-combobox>

                </div>


                <div class="col-2">
                  <input type="text" class="form-control" autocomplete="off"
                         name="codigoPdv-{{i}}" [(ngModel)]="opcao.codigoPdv" #codigoPdv="ngModel"
                         placeholder="Cod. PDV"     [required]="empresa?.integracaoDelivery?.sistema==='ecletica'"     >
                  <div class="invalid-feedback">
                    <p *ngIf="codigoPdv.errors?.required">Código do pdv é obrigatório</p>
                  </div>
                </div>


                <div class="col-1">
                  <label class="k-checkbox-label">
                    <input  name="disponivel{{i}}" class="k-checkbox  checkboxlg  mr-2 mt-1" type="checkbox" kendoCheckBox
                            [(ngModel)]="opcao.disponivel"/>


                  </label>
                </div>

                <div class="col-1">
                  <button type="button " class="btn btn-success btn-sm" [disabled]="!frm.dirty"
                          (click)="salvarTemplateAdicional(templateBorda, opcao, frm)">
                    <i class="fe-save "  *ngIf="!opcao.salvando"></i>

                    <i class="k-icon k-i-loading"  *ngIf="opcao.salvando"></i>
                  </button>


                  <button type="button"  class="btn btn-sm btn-light ml-1" *ngIf="!opcao.id" (click)="canceleSalvarOpcao(templateBorda, opcao)">
                    <i class="fa fa-times" ></i>
                  </button>


                </div>
              </div>
            </form>
          </ng-container>

          <button class="btn btn-outline-blue mt-2 " type="button" (click)="novaOpcaoAdicional(templateBorda)">
            <i class="fa fa-plus "></i> Adicionar borda</button>



          <div class="modal-footer mt-2">

            <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                    (click)="fecheModal()">Fechar</button>

          </div>


        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Vários Sabores'">
        <ng-template kendoTabContent>

          <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                novalidate #frm="ngForm" (ngSubmit)="onSubmitTipoDeCobranca(frm)">

            <h4 class="mt-2">Tipos de cobrança</h4>

            <input id="cobrancaMedia" name="cobrancaMedia" type="radio"  class="k-radio"   kendoRadioButton
                   [(ngModel)]="templateSelecionado.tipoDeCobranca"  [value]="'media'"
                   [required]="true"/>

            <label for="cobrancaMedia" class="ml-1 mt-2 mr-3">Cobrar média</label>

            <input id="cobrancaMaior" name="cobrancaMaior" type="radio"  class="k-radio"   kendoRadioButton
                   [(ngModel)]="templateSelecionado.tipoDeCobranca"  [value]="'maior'"
                   [required]="true"/>
            <label for="cobrancaMaior" class="ml-1 mr-3">Cobrar maior pizza</label>



            <div class="form-group mb-2 mt-2">
              <label for="campoOrdem"  >Ordem exibição:</label>
              <kendo-combobox  (valueChange)="mudoCampoOrdenar($event)" textField="descricao" valueField="campo"
                               [(ngModel)]="campoOrdenar" [data]="camposOrdenar"

                               class="form-control w-50  " id="campoOrdem" name="campoOrdem"
                               placeholder="Selecione em qual ordem sera exibida"
              >
              </kendo-combobox>
            </div>

            <div class="form-group mt-3 ">
              <label class="k-checkbox-label"  >
                <input type="checkbox" id="cobrarTaxaExtra" name="cobrarTaxaExtra" class="k-checkbox " kendoCheckBox
                       [(ngModel)]="templateSelecionado.cobrarTaxaExtra" />
                Cobrar taxa extra
              </label>
            </div>

            <div class="form-group mb-3"  *ngIf="templateSelecionado.cobrarTaxaExtra">
              <kendo-numerictextbox   [min]="0"  class="form-control col-sm-4 col-12" name="taxaExtra"
                                      [format]="'c2'"   [(ngModel)]="templateSelecionado.taxaExtra"  required   >
              </kendo-numerictextbox>

              <div class="invalid-feedback">
                <p >Informe valor da taxa</p>
              </div>

            </div>

            <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible mt-2">
              <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
              <button type="button" class="close" (click)="fecheMensagemSucesso()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-footer mt-2">
              <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="!frm.dirty"  >Salvar</button>
              <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                      (click)="fecheModal()">Fechar</button>
            </div>


          </form>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Pontuação Fidelidade'" *ngIf="pontuarFidelidade()">
        <ng-template kendoTabContent>
          <div class="card-box">
            <form [ngClass]="{'needs-validation': !frmPts.submitted, 'was-validated': frmPts.submitted}"
                  novalidate #frmPts="ngForm" (ngSubmit)="onSubmitSalvarPontos(frmPts)">

              <ng-container *ngIf="!acumulaReais() ">
                <h5> Quantidade de  <b> {{empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo}}</b> ganhos por Tamanho</h5>

                <div class="row" >
                  <div class="col-4" *ngFor="let tamanho of templateSelecionado?.tamanhos; let i = index">
                    <div class="form-group mb-3"  >
                      <label for="pontosGanhos{{i}}">
                        Tamanho <b>{{tamanho.descricao}}</b>
                      </label>
                      <kendo-numerictextbox id="tamanho{{i}}" [min]="0"  [(ngModel)]="tamanho.pontosGanhos" required
                                            class="form-control" name="pontosGanhos{{i}}" #pontosGanhos=ngModel >
                      </kendo-numerictextbox>

                      <div class="invalid-feedback">
                         Infome pontuação do tamanho
                      </div>
                    </div>
                  </div>
                </div>

                <p class="text-muted mt-2" >
                  Pontuação  padrão: <b> {{empresa.integracaoPedidoFidelidade.atividade.pontosGanhos}}
                  {{empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo}}</b></p>
              </ng-container>

              <ng-container *ngIf="acumulaReais() ">
                <h5> Cashback ganho por Tamanho</h5>

                <div class="row" >
                  <div class="col-4" *ngFor="let tamanho of templateSelecionado?.tamanhos; let i = index">
                    <div class="form-group mb-3"  >
                      <label for="pontosGanhos{{i}}">
                        Tamanho <b>{{tamanho.descricao}}</b>
                      </label>
                      <kendo-numerictextbox   id="cashback{{i}}" name="cashback{{i}}" class="form-control" autocomplete="off"
                                              placeholder="Retorno do cashback"
                                              [format]="obtenhaFormatoPercentual()"  [step]="1"  [min]="0"  [max]="100"
                                              [(ngModel)]="tamanho.cashback" #cashback="ngModel"       >

                      </kendo-numerictextbox>
                      <div class="invalid-feedback">
                        <p *ngIf="cashback.errors?.required">Cashback é obrigatório</p>
                        <p *ngIf="cashback.errors?.maxError">Valor  máximo é {{cashback.errors?.maxError?.maxValue}} </p>
                        <p *ngIf="cashback.errors?.minError">Valor  mínimo é {{cashback.errors?.minError?.maxValue}} </p>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="text-muted mt-2" >
                  Cashback padrão: <b> {{getCashbackExibir(empresa.integracaoPedidoFidelidade.atividade)}}</b></p>
              </ng-container>

              <button type="submit" class="btn btn-success " [disabled]="!frmPts.dirty || salvandoPontos">
                <i class="fe-save "  *ngIf="!salvandoPontos"></i> Salvar pontuação
                <i class="k-icon k-i-loading"  *ngIf="salvandoPontos"></i>
              </button>
            </form>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Integração '  + empresa?.integracaoDelivery?.sistema" *ngIf="empresa?.integracaoDelivery">
        <ng-template kendoTabContent>

          <ng-container *ngFor="let tamannho  of templateSelecionado.tamanhos;let i = index;" >
            <div class="card-box">

              <h4 class="mt-2 float-left">{{tamannho.descricao}}</h4>

              <div class=" clearfix"></div>

              <div class="tabela">
                <div class="row sub-titulo">
                  <div class="col-2">Id</div>
                  <div class="col">Qtde Sabores</div>
                  <div class="col">Código Pdv </div>
                  <div class="col-2"></div>
                </div>

                <ng-container *ngFor="let dePara  of tamannho.deParaTamanhoSabores;let i = index;" >
                  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                        novalidate #frm="ngForm" (ngSubmit)="onSubmit()">

                    <div class="row mt-2">
                      <div class="col-2">
                        <b>{{dePara.id}}</b>

                        <span *ngIf="!dePara.id">-</span>
                      </div>

                      <div class="col">
                        <kendo-combobox   name="qtdeSabores{{i}}" [data]="dePara.listaQtdesSabores" placeholder="selecione"

                                          class="form-control"    [allowCustom]="false"  [clearButton]="false"
                                          [(ngModel)]="dePara.qtdeSabores"   required textField="valor" valueField="id"  >
                        </kendo-combobox>

                        <div class="invalid-feedback">
                          <p  >Quantidade é obrigatório</p>
                        </div>

                      </div>

                      <div class="col">
                        <input type="text" class="form-control" autocomplete="off"
                               name="codigoPdv{{i}}" [(ngModel)]="dePara.codigoPdv"
                               placeholder="Código do Pdv"   required   >
                        <div class="invalid-feedback">
                          Obrigatório
                        </div>
                      </div>

                      <div class="col-2">
                        <button type="button " class="btn btn-success btn-sm" [disabled]="!frm.dirty && dePara.id"
                                (click)="salvarDePara(tamannho, dePara, frm)">
                          <i class="fe-save "  *ngIf="!dePara.salvando"></i>

                          <i class="k-icon k-i-loading"  *ngIf="dePara.salvando"></i>
                        </button>

                        <button type="button"  class="btn btn-sm btn-light ml-1"   (click)="removerSalvarDePara(tamannho, dePara, i)">

                          <i class="fa fa-trash "  *ngIf="!dePara.removendo"></i>

                          <i class="k-icon k-i-loading"  *ngIf="dePara.removendo"></i>

                        </button>

                      </div>
                    </div>
                  </form>
                </ng-container>

                <div class="mt-1 row">
                  <div class="col">
                    <p *ngIf="tamannho.erro" class="text-danger">
                      <i class="k-icon k-i-error mr-1"></i>
                      <b>{{tamannho.erro}}</b></p>

                    <p *ngIf="tamannho.mensagem" class="text-success">
                      <i class="k-icon k-i-check mr-1"></i>
                      <b>{{tamannho.mensagem}}</b></p>

                  </div>
                </div>
              </div>

              <button class="btn btn-outline-blue btn-xs btn-block mt-1 col-2  " (click)="adicionarDePara(tamannho)" >
                <i class="k-i-plus k-icon mr-1"> </i> Adicionar
              </button>

            </div>
          </ng-container>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="'Geral' " >
        <ng-template kendoTabContent>

          <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                novalidate #frm="ngForm" (ngSubmit)="onSubmitVendas(frm)">

            <div class="row">
              <div class="col-6">
                <div class="form-group ">
                  <label >Nome do Template</label>
                  <input type="text" class="form-control" autocomplete="off"
                         name="nome" [(ngModel)]="templateSelecionado.nome"
                         placeholder="Informe um nome para o template" value="" required   >
                  <div class="invalid-feedback">
                    Nome do template é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group ">
                  <label >Identificador</label>
                  <input type="text" class="form-control" autocomplete="off"
                         name="identificador" [(ngModel)]="templateSelecionado.identificador"
                         placeholder="Pizza, Calzone etc" value="" required   >
                  <div class="invalid-feedback">

                  </div>
                </div>

              </div>

              <div class="form-group mt-1 col-12">
                <label class="k-checkbox-label"  >
                  <input type="checkbox"  name="cobrarTaxaExtra" class="k-checkbox " kendoCheckBox
                         [(ngModel)]="templateSelecionado.ativo" />
                  Template Ativo
                </label>
              </div>

              <div class="form-group mt-1 col-12">
                <label class="k-checkbox-label"  >
                  <input type="checkbox"  name="exibirPrecosTamanhos" class="k-checkbox " kendoCheckBox
                         [(ngModel)]="templateSelecionado.exibirPrecosTamanhos" />
                  Exibir os preços dos tamanhos na loja
                </label>
              </div>


              <div class="col-6">
                <div class="form-group mb-3">
                  <label class="k-checkbox-label mb-2">
                    <input type="checkbox"   name="montarPizza" class="k-checkbox " kendoCheckBox
                           [(ngModel)]="templateSelecionado.montarPizza" />
                    Monte Sua Pizza </label>

                  <br>
                  <small class="ml-2">Exibe uma categoria de venda "Monte sua pizza"</small>
                </div>
              </div>

              <div class="col-6" *ngIf="templateSelecionado.montarPizza">
                <div class="form-group ">
                  <label >Descrição da categoria na loja</label>
                  <input type="text" class="form-control" autocomplete="off"
                         name="nomeCategoriaMontar" [(ngModel)]="templateSelecionado.nomeCategoriaMontar"
                         required   >
                  <div class="invalid-feedback">
                    Descrição da catogoria é obrigatório
                  </div>
                </div>
              </div>


              <div class="col-6" *ngIf="templateSelecionado.montarPizza">
                <div class="form-group ">
                  <label class="k-checkbox-label mb-2">
                    <input type="checkbox"   name="ocultarProdutos" class="k-checkbox " kendoCheckBox
                           [(ngModel)]="templateSelecionado.ocultarProdutos" />
                    Não exibir produtos do template na loja</label>

                  <br>
                  <small class="ml-2">Produtos serão exibidos apenas via seleção no <b>Montar {{templateSelecionado.nomeCategoriaMontar}}</b></small>
                </div>
              </div>

            </div>


            <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible mt-2">
              <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
              <button type="button" class="close" (click)="fecheMensagemSucesso()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>


            <div class="modal-footer mt-2">
              <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="!frm.dirty || this.salvando"  >Salvar</button>
              <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                      (click)="fecheModal()">Fechar</button>
            </div>

          </form>
        </ng-template>
      </kendo-tabstrip-tab>



    </kendo-tabstrip>
  </ng-container>

</div>
