<div class="modal-content">
  <div class="modal-header" >
    <h4 class="modal-title" id="myModalLabel">
      Notificaçoes Recebidas Ifood para Pedido <b>{{pedido.codigo}}</b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="fecheModal(this.pedido)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div   >
  <div   class="modal-body">

    <kendo-grid [data]="notificacoes"
                [loading]="carregando" style="min-height: 300px;">
      <kendo-grid-messages
        pagerPage="Página"
        pagerOf="de"
        pagerItems="itens"
        noRecords="Sem notificacoes"
        loading="Carregando"
        pagerItemsPerPage="ítems por página"
      >
      </kendo-grid-messages>
      <kendo-grid-column title="Delivery Id"    >
        <ng-template kendoGridCellTemplate let-dataItem  >
          <span class="text-blue"><b>{{dataItem.orderId}}</b></span>
        </ng-template>
      </kendo-grid-column>



      <kendo-grid-column title="Tipo"  [width]="300" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span><b>{{dataItem.code}}</b> - {{dataItem.fullCode}}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Executado"   [width]="85">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="badge badge-success" *ngIf="dataItem.executada">Sim</span>
          <span class="badge badge-danger" *ngIf="!dataItem.executada">Não

          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Horário"   >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="text-muted"><b>{{dataItem.horarioNotificado | date: 'dd/MM/YYYY HH:mm:ss'}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridDetailTemplate let-dataItem   >
        <section>
          <div class="alert alert-info" *ngIf="dataItem.dados">
            Dados: {{dataItem.dados | json}}</div>

          <div class="alert alert-danger mt-1" *ngIf="dataItem.erro">


            {{dataItem.erro}}
          </div>
        </section>
      </ng-template>

    </kendo-grid>
  </div>

</div>
