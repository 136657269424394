import {Component, Input, OnInit} from '@angular/core';
import {ProdutoService} from "../../services/produto.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {EstoqueService} from "../../services/estoque.service";

@Component({
  selector: 'app-tela-associar-insumo',
  templateUrl: './tela-associar-insumo.component.html',
  styleUrls: ['./tela-associar-insumo.component.scss']
})
export class TelaAssociarInsumoComponent implements OnInit {
  @Input() insumo: any = {};
  empresa: any;
  adicionar = false;
  carregando = false;
  totalAdicionado = 0;
  erro: string;

  produtos  = []
  produtosAdicionar  = []
  opcoesAdicionar  = []

  selectableSettings: any = {
    checkboxOnly: false,
    mode: 'multiple'
  };

  public idsProdutosSelecionados: number[] = [];
  public idsOpcoesSelecionadas: number[] = [];
  salvando: boolean;
  filtro: any = {};
  private timerBusca;
  paginacao: any = {
    pagina: 0,
    qtde: 25
  }
  totalComposicoes  = 0;
  totalProdutos  = 0;
  totalComplementos  = 0;
  constructor(private produtoService: ProdutoService, private constantsService: ConstantsService,
              private estoqueService: EstoqueService) {}

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if(empresa )  this.empresa = empresa;
    });

    this.totalProdutos = this.insumo.produtos.length;
    this.totalComplementos = this.insumo.opcoes.length;

    if(this.insumo.id){
      this.estoqueService.listeComposicaoDo(this.insumo).then((composicoes: any) => {
        this.insumo.composicoes = composicoes || []
        this.totalComposicoes = composicoes.length;
      });
    }
  }

  associarInsumoProdutos(){
    this.adicionar = true;
    this.idsProdutosSelecionados = [];
    delete this.filtro.texto;
    this.carregueProdutosDisponiveis();
  }

  associarInsumoOpcoes(){
    this.adicionar = true;
    this.idsOpcoesSelecionadas = [];
    delete this.filtro.texto;
    this.carregueOpcoesDisponiveis();
  }

  salveNovosProdutos() {
    if(this.salvando ) return;
    delete this.erro;
    this.salvando = true;
    this.estoqueService.vinculeProdutosNoInsumo(this.insumo, this.idsProdutosSelecionados).then( (resp: any) => {
      this.salvando = false;
      this.insumo.produtos = resp.produtos;
      this.insumo.salvou = true;
      this.totalAdicionado = resp.total;
    }).catch( (err: any) => {
      this.erro = err;
      this.salvando = false;
    })
  }

  vinculeNovamenteProduto(produto: any){
    produto.vinculando = true;

    this.estoqueService.vinculeProdutosNoInsumo(this.insumo,  [produto.id]).then( (resp: any) => {
      produto.vinculando = false;
      produto.removido  = false;
      this.insumo.salvou = true;
      this.insumo.totalVinculos++;
    }).catch( (err: any) => {
      alert(err)
      produto.vinculando = false;
    })

  }


  salveNovasOpcoes(){
    if(this.salvando ) return;
    delete this.erro;
    this.salvando = true;
    this.estoqueService.vinculeOpcoesNoInsumo(this.insumo, this.idsOpcoesSelecionadas).then( (resp: any) => {
      this.salvando = false;
      this.insumo.opcoes = resp.opcoes;
      this.totalAdicionado = resp.total;
      this.insumo.salvou = true;
    }).catch( (err: any) => {
      this.erro = err;
      this.salvando = false;
    })

  }

  vinculeNovamenteOpcao(opcao: any){
    opcao.vinculando = true;

    this.estoqueService.vinculeOpcoesNoInsumo(this.insumo,  [opcao.id]).then( (resp: any) => {
      opcao.vinculando = false;
      opcao.removido  = false;
      this.insumo.totalVinculos++;
      this.insumo.salvou = true;
    }).catch( (err: any) => {
      alert(err)
      opcao.vinculando = false;
    })
  }


  public carregueProdutosDisponiveis(filtro: string = null) {
    this.produtosAdicionar = [];
    this.carregando   = true;
    this.produtoService.liste(this.empresa.catalogo.id, true, 0 , 50, filtro,
      this.filtro.categoria).then( (resposta: any) => {
      this.carregando = false;
      this.produtosAdicionar = resposta.produtos.filter(
        (produto: any) => !this.insumo.produtos.find( (produtoNoInsumo: any) => produtoNoInsumo.id === produto.id))
    } )
  }

  private carregueOpcoesDisponiveis(texto: string = null){
    this.opcoesAdicionar = [];
    this.carregando   = true;
    this.produtoService.listeComplementos(this.empresa.catalogo.id,  0 , 50, texto, this.filtro.porCodigo ).then( (resposta: any) => {
      this.opcoesAdicionar = resposta.opcoes.filter(
        (opcao: any) => !this.insumo.opcoes.find( (opcaoNoInsumo: any) => opcaoNoInsumo.id === opcao.id))

      this.carregando = false;
    } )
  }

  async desvinculeOpcaoInsumo($event: any){
    let opcaoAssociada = $event.dataItem;

    if(   opcaoAssociada.removendo) return;

    opcaoAssociada.removendo = true;

    let resposta: any = await this.estoqueService.removaVinculoOpcao(this.insumo,  opcaoAssociada).catch((err) => {
      alert(err)
    })

    opcaoAssociada.removendo = false;

    if(resposta){
      opcaoAssociada.removido = true;
      this.insumo.totalVinculos--;
      this.insumo.salvou = true;
    }
  }
  async desvinculeProdutoInsumo($event: any){
    let produtoAssociado = $event.dataItem;


    if(   produtoAssociado.removendo) return;

    produtoAssociado.removendo = true;

    let resposta: any = await this.estoqueService.removaVinculoProduto(this.insumo,  produtoAssociado).catch((err) => {
      alert(err)
    })

    produtoAssociado.removendo = false;

    if(resposta){
      produtoAssociado.removido = true;
      this.insumo.totalVinculos--;
      this.insumo.salvou = true;
    }

  }

  fecheMensagemErro() {
    delete  this.erro;
  }

  onFilterProdutos(event: any) {
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.carregueProdutosDisponiveis(event.target.value)
    }, 1000)
  }

  alterouPorCodigo(){
    if(this.filtro.texto)
      this.carregueOpcoesDisponiveis(this.filtro.texto)
  }

  onFilterOpcoes(event: any){
    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.carregueOpcoesDisponiveis(event.target.value)
    }, 1000)
  }

  adicionarMaisProdutos() {
    delete this.totalAdicionado;
    this.associarInsumoProdutos();
  }

  adicionarMaisOpcoes(){
    delete this.totalAdicionado;
    this.associarInsumoOpcoes();
  }

  volteAdicionarProdutos(recarregar) {
    this.adicionar = false;
    delete this.totalAdicionado
    if(recarregar) this.carregueProdutosDisponiveis();
  }

  volteAdicionarOpcoes(recarregar) {
    this.adicionar = false;
    delete this.totalAdicionado
    if(recarregar) this.carregueOpcoesDisponiveis();
  }

  selecionouTab(tab){

  }

  onSelectedKeysChange(e: any) { }
}
