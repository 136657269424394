<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel">
      Finalizar pedidos em aberto
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="fecheModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div   >
  <div   class="modal-body">
      <h4 class="text-center" *ngIf="!confirmou">
        Serão finalizados <b>{{pedidosFinalizar.length}} </b> pedidos abertos  realizados até o dia

        <div class="input-group d-inline-block" style="width: 200px">

          <kendo-datepicker [(ngModel)]="dataLimite"     name="dataLimite" [format]="'dd/MM/yyyy'"
                            (ngModelChange)="filterPedidos()"   >
            <kendo-datepicker-messages
              today="Hoje"
              toggle="Alterar data"
            ></kendo-datepicker-messages>

          </kendo-datepicker>

        </div>

        <br>
        <button class="btn btn-blue  mt-2" (click)="confirmouFinalizar()">Confirmar </button>
        <button class="btn btn-secondary ml-1 mt-2" (click)="fecheModal()"> Voltar  </button>
      </h4>


      <div *ngIf="confirmou" class="mt-2 mb-4" [hidden]="finalizou">

        <h4 class="text-center">
          <i class="k-i-loading k-icon fa-2x "></i> Aguarde...
        </h4>

        <kendo-progressbar [label]="labelFinalizados" [value]="tarefa.processados" [style.width.%]="100" [emptyCssStyle]="emptyStyles"
                           [max]="tarefa.total" [progressCssStyle]="progressStyles"></kendo-progressbar>


      </div>

     <div *ngIf="finalizou">
       <h4 class="text-center" *ngIf="pedidosFinalidados.length">
           <i class="k-i-check text-success k-icon fa-2x "></i>
           Foram finalizados {{pedidosFinalidados.length}} pedidos

       </h4>

       <h4 *ngIf="pedidosIgnorados.length" class="mt-2 text-center">
         <i class="k-i-warning text-warning k-icon fa-2x "></i>
         {{pedidosIgnorados.length}} {{ pedidosIgnorados.length === 1 ? 'pedido ignorado' : 'pedidos ignorados '}}

             por ter erro na integração:

         <br>

         <span *ngFor="let pedido of pedidosIgnorados; let ultimo = last" class="mr-1 badge badge-danger">
            {{pedido.codigo}}  </span>

       </h4>

       <ng-container *ngIf="pedidosErros">
         <h4>Total pedidos com erro, nao podem ser finalizados: <b>{{pedidosErros.length}}</b></h4>
         <p *ngFor="let pedido of pedidosErros">
           <i class="k-i-error text-danger k-icon fa-2x "></i>
           <span class="mr-1 badge badge-danger">
              {{pedido.codigo}}
           </span>
           <b>  {{pedido.erro}}</b>
         </p>
       </ng-container>

       <button class="btn btn-success  mt-2 text-center" style="    width: 150px;margin: 0 auto;     display: block;}"
               (click)="fecheModal(true)"> Fechar  </button>


     </div>

  </div>

</div>
