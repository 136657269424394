import {Component, OnInit, Input, Output, EventEmitter, ApplicationRef} from '@angular/core';
import * as libphonenumber from 'google-libphonenumber';
import {CountryService} from "../../../services/country.service";

@Component({
  selector: 'app-seletor-codigo-pais-server',
  templateUrl: './seletor-codigo-pais-server.component.html',
  styleUrls: ['./seletor-codigo-pais-server.component.scss']
})
export class SeletorCodigoPaisServerComponent implements OnInit {
  private _selectedCountryCode: string;
  @Input()
  set selectedCountryCode(value: string) {
    this._selectedCountryCode = value;

    if(this.selectedCountry && this.selectedCountry.phone !== this._selectedCountryCode)
      this.selecionePais()
  };


  get selectedCountryCode(): string {
    return this._selectedCountryCode;
  }

  @Output() selectedCountryCodeChange = new EventEmitter<string>();
  @Output() phoneMaskChange = new EventEmitter<string>();

  countries: any[] = [];
  selectedCountry: any = {};
  dados: any[] = [];

  private selecionePais() {
    this.selectedCountry = this.countries.find(country => country.phone === this.selectedCountryCode)
    if(this.selectedCountry)
      this.phoneMaskChange.emit(this.getPhoneMask(this.selectedCountry.alpha2))
  }




  constructor(private app: ApplicationRef,
              private  countryService: CountryService) {
    this.countryService.getCountries().then((countries: any) => {
      this.countries = countries.data.map((country) => {
        country.alphaPhone = country.alpha2 + "(" + country.phone + ")"
        return country;
      });

      this.dados = this.countries;

      if(this.selectedCountryCode) {
        this.selecionePais();
      }

    });
  }

  ngOnInit(): void {


  }

  onCountryChange(pais: any) {
    if(!pais)
      return;

    this.selectedCountryCodeChange.emit(pais.phone);
    this.phoneMaskChange.emit(this.getPhoneMask(pais.alpha2))


  }

  getPhoneMask(countryCode: string): string {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const exampleNumber = phoneUtil.getExampleNumberForType(countryCode, libphonenumber.PhoneNumberType.MOBILE);

    let format: any = phoneUtil.format(exampleNumber, libphonenumber.PhoneNumberFormat.NATIONAL);

    if(format) {
      format = format.split("")

      format = format.map((item: any) => {
          if (/\d/.test(item)) {
            return /\d/;
          } else {
            return item;
          }
      }

      )
    }


    console.log("Máscara telefone (" + countryCode + "): " + format )
    return format
  }

  handleFilter(value: any) {
    this.dados = this.countries.filter(
      (s) => s.phone.toLowerCase().indexOf(value.toLowerCase()) !== -1 || s.alpha2.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
